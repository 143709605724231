import { ENABLE_BLOCK, STATUS } from "@share/configs/const";
import {
  CheckCircleIcon,
  CloseIconAlert,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  MoreIcon,
} from "@share/icons";
import { Image, List, Modal, Popover, Toast } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IMAGE_POPUP_ACTION } from "../../helpers";
import { ImagePopup } from "../../ImagePopupForm";
import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";

interface ImgPopupItemProps {
  imgPopup: ImagePopup;
  onOpenEditImgPopup: (item: ImagePopup) => void;
  onChangeItem: (status: IMAGE_POPUP_ACTION, item: ImagePopup) => void;
}

const ImgPopupItem = (props: ImgPopupItemProps) => {
  const { t } = useTranslation();

  const { imgPopup, onChangeItem, onOpenEditImgPopup } = props;

  const isEnable = !!(imgPopup && imgPopup.enable === ENABLE_BLOCK);
  const popoverRef = useRef(null);

  const optionalActions: Action[] = [
    {
      key: IMAGE_POPUP_ACTION.SWITCH_STATUS,
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_image_label")
        : t("ss_builder_hide_image_label"),
    },
    {
      key: IMAGE_POPUP_ACTION.REMOVE_ITEM,
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_image_label"),
    },
  ];

  const MoreOptionalActions = () => {
    return (
      <div className="flex flex-row items-center gap-[15px]">
        {/* {!isEnable && (
          <div className="text-[13px]">
            {t("ss_builder_hiding_block_label")}
          </div>
        )} */}
        <div
          className=""
          onClick={handleOpenOptionalAction}
        >
          <Popover.Menu
            mode="dark"
            actions={optionalActions}
            placement="left"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => popoverRef.current}
            onAction={handleOptionalActions}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  const handleOptionalActions = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case IMAGE_POPUP_ACTION.SWITCH_STATUS:
        handleSwitchImgPopupStatus();
        break;
      case IMAGE_POPUP_ACTION.REMOVE_ITEM:
        confirmRemoveItem();
        break;
      default:
        // to do
        break;
    }
  };

  const confirmRemoveItem = () =>
    Modal.confirm({
      header: (
        <CloseIconAlert
          className=""
          fillColor="#FF3141"
          width={40}
          height={40}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_confirm_delete_image_popup_image_label"),
      cancelText: t("ss_builder_continue_edit_image_popup_image_label"),
      onConfirm: () => {
        onChangeItem(IMAGE_POPUP_ACTION.REMOVE_ITEM, imgPopup);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => void 0,
    });

  const handleEditImgPopup = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    onOpenEditImgPopup(imgPopup);
  };

  const handleSwitchImgPopupStatus = () => {
    onChangeItem(IMAGE_POPUP_ACTION.SWITCH_STATUS, imgPopup);

    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable
            ? t("ss_builder_disable_image_successfully_label")
            : t("ss_builder_enable_image_successfully_label")}
        </div>
      ),
    });
  };

  const handleOpenOptionalAction = (event) => {
    event.preventDefault();
  };

  return (
    <div ref={popoverRef}>
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={<MoreOptionalActions />}
          onClick={handleEditImgPopup}
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              !isEnable && "opacity-40"
            )}
          >
            <Image
              src={imgPopup.image}
              width={40}
              height={40}
              fit="cover"
            />
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                {imgPopup?.link?.type !== LinkTypeEnum.SERVICE
                  ? imgPopup?.link?.value
                  : t(getServiceLabelKey(imgPopup?.link?.value))}
              </div>
              {imgPopup?.link?.type === LinkTypeEnum.WEB && (
                <>
                  {imgPopup.use_aff_url === STATUS.ENABLE &&
                  imgPopup?.link?.value ? (
                    <div className="flex flex-row gap-1">
                      <div>
                        <CheckCircleIcon
                          className="w-4 h-4"
                          fillColor="#00B578"
                        />
                      </div>
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                        {t("ss_builder_product_link_wrapped_label")}
                      </div>
                    </div>
                  ) : (
                    <span className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                      {imgPopup?.link?.value || ""}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

export default ImgPopupItem;
