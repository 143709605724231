import { uploadPartFile } from "@share/api";
import { sleep } from "antd-mobile/es/utils/sleep";

export const DESKTOP_BREAK_POINT = 768;

export const FILE_SIZE_MAXIMUM = 50000000; //50MB = 50000000 bytes

export const allowedImageFormat = [
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/png",
  "image/bmp",
  "image/webp",
  "image/heif",
  "image/heic",
  "image/svg+xml"
];

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function mockUploadFile(
  file: File,
  userId: string
) {

  if (!allowedImageFormat.includes(file?.type?.toLocaleLowerCase())) {
    return {
      url: null,
      isValidFormat: true,
    };
  } else if (file?.size > FILE_SIZE_MAXIMUM) {
    return {
      url: null,
      isValidSize: true,
    };
  } else {
    await sleep(1000);
    try {
      const dataBase64 = await getBase64(file) as string;
      let dataChunk = [];
      let offset = 0;
      let sort_order = 1;
      const dataLength = dataBase64.length;

      if(dataLength > 0){
        const limit = Math.round(dataLength / 10);
        
        while (offset <= dataLength) {
          const sliceBase64 = dataBase64.slice(offset, offset + limit);
          dataChunk.push({
            content: sliceBase64,
            sort_order: sort_order
          })
          offset += limit;
          sort_order ++;
        }
      }
      const uploadPart = await Promise.all(dataChunk?.map(async(data) => {
        const dataResquest = {
          user_id: userId,
          content: data.content,
        }
         return await uploadPartFile(dataResquest)
      }));
      return {
        url: URL.createObjectURL(file),
        dataBase64: dataBase64,
        fileName: file.name,
      };
    } catch (error) {
      console.log(error);
    }
  }
}

export const resize = (source, fileType, maxWidth, maxHeight,typeResize) => {
  return new Promise((resolve) => {
    const img = document.createElement("img");
    img.src = source;
    img.onload = (e: any) => {
      // Create your canvas
      const canvas = document.createElement("canvas");

      let width = e.target.width;
      let height = e.target.height;
      // Add the resizing logic
      switch (typeResize) {
        // resize chieu lon hon
        case 1 :
          if (width > height) {
            if (maxWidth && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (maxHeight) {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;       
              }
            } else {
              if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            }
          }   
          break;
          //resize chieu nho hon
        case 2:
          if (width < height) {
            if (maxWidth && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (maxHeight) {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            } else {
              if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            }
          }
          break;
        //resize chieu rong (W)
        case 3:
          if(maxWidth && width > maxWidth){
            height *= maxWidth/width
            width = maxWidth
          }
          break;
          //resize chieu cao (H)
        case 4:
          if(maxHeight && height > maxHeight){
            width *= maxHeight/height
            height = maxHeight;
          }
          break;
        case 5:
          break;
        default:
        
      }
      //Specify the resizing result
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      return resolve(canvas.toDataURL(fileType));
    };
  });
};
