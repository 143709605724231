const  th =  {
 translation: {
  "button_confirm_exit_TOD": "ออก",
  "button_confirm_off_TOD": "ปิด",
  "button_title_default_donation": "โดเนท 💵 ให้ {name} น้าาา",
  "cashback_default_text_button": "รับเงินคืนทันที!",
  "cashback_default_title": "ช้อปสนุก คืนเงินสุดๆ",
  "cm_home": "หน้าหลัก",
  "default_content_setup_brand_gift": "เลือกเทมเพลตหรือสร้างของคุณเอง",
  "default_content_setup_donation": "บริการนี้ได้ตั้งค่าโดยอัตโนมัติ หากคุณต้องการเปลี่ยนแปลง โปรดไปยังส่วนโดเนทที่ account.passio.eco",
  "default_title_setup_brand_gift": "ตั้งค่า",
  "default_title_setup_donation": "ตั้งค่าบริการ",
  "error_thumbnail_size_TOD": "ความจุภาพมากเกินไป",
  "invitation_default_donation": "เพื่อนรัก {name}?",
  "label_tab_bar_brand_gift": "เทมเพลต",
  "label_text_button_brand_gift": "ปุ่ม",
  "label_text_title_brand_gift": "หัวข้อ",
  "login": "เข้าสู่ระบบ",
  "message_default_donation": "ขอบคุณที่สนับสนุน {name} หวังว่าเราจะมีโอกาสเจอกันใหม่น้าาา!",
  "placeholder_text_button_brand_gift": "กรอกข้อความของปุ่ม",
  "placeholder_text_title_brand_gift": "กรอกหัวข้อของคุณ",
  "ss_background_TOD": "รูปหน้าปก",
  "ss_brand_cpc_bonus": "th_Hoa hồng",
  "ss_brand_cpc_campaign_title": "th_Nhận hoa hồng",
  "ss_brand_cpc_description": "th_Mô tả chương trình",
  "ss_brand_cpc_download": "th_Tải xuống",
  "ss_brand_cpc_from": "th_Từ",
  "ss_brand_cpc_join": "th_Tham gia",
  "ss_brand_cpc_join_now": "th_Tham khảo ngay",
  "ss_brand_cpc_join_successfully_label": "th_Bạn đã tham gia chương trình thành công",
  "ss_brand_cpc_no_access_allow": "th_Bạn không được phép truy cập vào link này",
  "ss_brand_cpc_notice": "th_Lưu ý",
  "ss_brand_cpc_notice_content": "th_Hoa hồng sẽ được trả về tài khoản của bạn sau 7 ngày kể từ khi được nghiệm thu.",
  "ss_brand_cpc_reject": "th_Ảnh nghiệm thu của bạn đã bị từ chối. Bạn có thể tải lại ảnh.",
  "ss_brand_cpc_requirement": "th_Yêu cầu",
  "ss_brand_cpc_step_1": "th_Bước 1: Tải ảnh dưới đây về và up lên 1 nền tảng mạng xã hội.",
  "ss_brand_cpc_step_1_note": "th_Lưu ý: up ảnh trong khoảng thời gian",
  "ss_brand_cpc_step_2": "th_Bước 2: Tải ảnh nghiệm thu (ảnh chụp màn hình bài đăng của bạn ở bước 1)",
  "ss_brand_cpc_step_2_note": "th_Lưu ý: ảnh nghiệm thu thể hiện thời gian đã đăng ảnh tối thiểu là 12h. VD: Ảnh chụp màn hình story đã đăng 12h trước trở lên.",
  "ss_brand_cpc_success": "th_Đã nghiệm thu thành công",
  "ss_brand_cpc_text_content": "th_đang gửi cho bạn 01 lời đề nghị tham gia vào chương trình treo banner - nhận tiền thưởng.",
  "ss_brand_cpc_text_title": "th_Nhận tiền dễ dàng cùng",
  "ss_brand_cpc_time": "th_Thời gian",
  "ss_brand_cpc_time_end": "th_Thời gian kết thúc",
  "ss_brand_cpc_time_start": "th_Thời gian bắt đầu",
  "ss_brand_cpc_to": "th_Đến",
  "ss_brand_cpc_upload_image": "th_Tải ảnh nghiệm thu",
  "ss_brand_cpc_upload_image_button": "th_Tải ảnh lên",
  "ss_brand_cpc_upload_image_successfully": "th_Bạn đã gửi ảnh nghiệm thu thành công",
  "ss_brand_cpc_upload_image_title": "th_Tải ảnh nghiệm thu",
  "ss_brand_gift_cancel_hide": "th_Hủy bỏ",
  "ss_brand_gift_confirm_hide_brand": "th_Xác nhận",
  "ss_brand_gift_confirm_hide_group": "th_Xác nhận ẩn nhóm",
  "ss_brand_gift_disable_deal_successfully": "th_Ẩn Deal thành công",
  "ss_brand_gift_disable_successfully": "th_Ẩn nhóm sản phẩm thành công",
  "ss_brand_gift_display_hide_brand": "th_Ẩn Deal",
  "ss_brand_gift_display_hide_category": "th_Ẩn nhóm sản phẩm",
  "ss_brand_gift_display_show_brand": "th_Hiện Deal",
  "ss_brand_gift_display_show_category": "th_Hiện nhóm sản phẩm",
  "ss_brand_gift_empty_title": "th_Chưa có sản phẩm nào",
  "ss_brand_gift_enable_deal_successfully": "th_Hiện Deal thành công",
  "ss_brand_gift_enable_successfully": "th_Hiện nhóm sản phẩm thành công",
  "ss_brand_gift_hide_brand": "th_Bạn có muốn ẩn Deal?",
  "ss_brand_gift_hide_brand_content": "th_Deal {{name}} sẽ không hiển thị ở giao diện của Fan. Bạn có muốn ẩn deal này?",
  "ss_brand_gift_hide_category": "th_Bạn muốn ẩn nhóm?",
  "ss_brand_gift_hide_category_content": "th_Nhóm {{name}} và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị ẩn. Bạn có chắc muốn ẩn nhóm?",
  "ss_brand_gift_list_category": "th_Danh sách nhóm sản phẩm",
  "ss_brand_gift_list_empty_content": "th_Hiện giờ Admin chưa setup dữ liệu sản phẩm",
  "ss_brand_gift_setting_cashback_default_text_button": "รับเงินคืนทันที!",
  "ss_brand_gift_setting_cashback_default_title": "ช้อปสนุก คืนเงินสุดๆ",
  "ss_brand_gift_setting_default_content_setup": "เลือกเทมเพลตหรือสร้างของคุณเอง",
  "ss_brand_gift_setting_float_icon_description": "บันทึกการตั้งค่าไอคอนลอยเพื่อเพิ่มปฏิสัมพันธ์กับแฟนๆ ของคุณ",
  "ss_brand_gift_setting_float_icon_footer_description": "เปิดใช้งานฟีเจอร์เพื่อเพิ่มปฏิสัมพันธ์กับแฟนๆ ของคุณ",
  "ss_brand_gift_setting_label_tab_bar_brand_gift": "เทมเพลต",
  "ss_brand_gift_setting_label_text_button": "ปุ่ม",
  "ss_brand_gift_setting_label_text_title_brand_gift": "หัวข้อ",
  "ss_brand_gift_setting_placeholder_text_button": "กรอกข้อความของปุ่ม",
  "ss_brand_gift_setting_placeholder_text_title": "กรอกหัวข้อของคุณ",
  "ss_brand_gift_setting_text_cashback_brand_gift": "Cashback",
  "ss_brand_gift_setting_text_custom_brand_gift": "ปรับแต่ง",
  "ss_brand_gift_setting_text_voucher_brand_gift": "Voucher",
  "ss_brand_gift_setting_title_block_footer": "ไอคอน Brand Gift",
  "ss_brand_gift_setting_voucher_default_text_button": "รับเวาเชอร์ของคุณตอนนี้!",
  "ss_brand_gift_setting_voucher_default_title": "แตะเพื่อจ่าย รับเงินคืน 5%",
  "ss_brand_rating_setting_default_content_setup_donation": "th_Danh sách các chương trình hoàn tiền giảm giá dành cho fan của bạn. Bật tính năng để nhận hoa hồng ngay!",
  "ss_build_collapse_block_label": "ย่อ",
  "ss_build_expand_block_label": "ขยาย",
  "ss_build_no_blocks_have_been_added": "ยังไม่มีการเพิ่มบล็อก",
  "ss_builde_theme_setting_block": "บล็อก",
  "ss_builder_1st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block.",
  "ss_builder_1st_block_preview_sample_title": "Block 1",
  "ss_builder_2st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block. 2nd block to see the spacing between blocks.",
  "ss_builder_2st_block_preview_sample_title": "Block 2",
  "ss_builder_add_affiliate_product_list_btn_label": "เพิ่มสินค้าอีก",
  "ss_builder_add_block_body_title": "Body",
  "ss_builder_add_block_expand_title": "ขยาย",
  "ss_builder_add_block_footer_title": "Footer",
  "ss_builder_add_block_header_title": "Header",
  "ss_builder_add_block_in_template": "เพิ่มบล็อกทเทมเพลต",
  "ss_builder_add_block_in_template_successfully": "Add block template successfully",
  "ss_builder_add_block_label": "เพิ่มบล็อก",
  "ss_builder_add_block_to": "แอดบล็อกเข้าไปยัง:",
  "ss_builder_add_image_of_image_popup_tilte": "เพิ่มรูปภาพป๊อปอัพ",
  "ss_builder_add_new_affiliate_product_btn_label": "เพิ่มสินค้าใหม่",
  "ss_builder_add_new_affiliate_product_group_btn_label": "เพิ่มกลุ่มสินค้า",
  "ss_builder_add_new_affiliate_product_image_title": "รูปภาพสินค้า",
  "ss_builder_add_new_affiliate_product_list_empty_content": "หากต้องการเพิ่มสินค้าใหม่ กรุณาใส่ url ของสินค้าด้านล่าง",
  "ss_builder_add_new_affiliate_product_name_title": "ชื่อสินค้า",
  "ss_builder_add_new_affiliate_product_title": "เพิ่มสินค้า",
  "ss_builder_add_new_block_successfully_label": "เพิ่มบล็อกใหม่เรียบร้อย",
  "ss_builder_add_new_image": "เพิ่มภาพใหม่",
  "ss_builder_add_new_image_of_image_popup_title": "รูปภาพป๊อปอัพ",
  "ss_builder_add_new_image_of_image_slider_title": "ภาพ",
  "ss_builder_add_new_image_popup_btn_label": "เพิ่ม",
  "ss_builder_add_new_image_slider_title": "เพิ่มภาพ",
  "ss_builder_add_new_label": "เพิ่ม",
  "ss_builder_add_new_product_group_successfully_label": "เพิ่มกลุ่มสินค้าใหม่สำเร็จ",
  "ss_builder_add_new_tab_successfully_label": "เพิ่ม Tab ใหม่เรียบร้อย",
  "ss_builder_add_new_tag_error_duplicate": "แท็กนี้มีอยู่แล้ว",
  "ss_builder_add_new_template_btn_label": "Save",
  "ss_builder_add_new_template_image_title": "รูปเทมเพลต",
  "ss_builder_add_new_template_label": "สร้างเทมเพลตใหม่",
  "ss_builder_add_new_template_name_placeholder": "ป้อนชื่อเทมเพลต",
  "ss_builder_add_new_template_name_title": "ชื่อเทมเพลต",
  "ss_builder_add_new_theme_btn_label": "สร้างใหม่",
  "ss_builder_add_new_theme_selection_panel_description": "เลือกวิธีการสร้างเทมเพลตธีมเพื่อเผยแพร่ไปยัง Theme Store",
  "ss_builder_add_new_theme_selection_panel_label": "สร้างเทมเพลตธีม",
  "ss_builder_add_new_theme_template_image_title": "รูปภาพเทมเพลตธีม",
  "ss_builder_add_new_theme_template_name_placeholder": "กรอกชื่อเทมเพลตธีม",
  "ss_builder_add_new_theme_template_name_title": "ชื่อเทมเพลตธีม",
  "ss_builder_add_story": "เพิ่มรายการใหม่",
  "ss_builder_add_tab_label": "เพิ่มแท็บ",
  "ss_builder_added_product_url_label": "ลิงค์ได้เพิ่มแล้ว",
  "ss_builder_advanced_group_description": "บล็อคแบบขั้นสูง",
  "ss_builder_advanced_setting": "การตั้งค่าขั้นสูง",
  "ss_builder_advanced_title": "ขั้นสูง",
  "ss_builder_aff_frame_max_size_content": "ขนาดไฟล์สูงสุด: 30MB.",
  "ss_builder_aff_frame_select_from_library": "เลือกจากไลบรารี",
  "ss_builder_aff_frame_support_type_file_content": "รูปแบบไฟล์: gif, png, svg",
  "ss_builder_affiliate_add_new_tour_content_step_1": "เลือกกลุ่มสินค้าเพื่อจัดหมวดหมู่สินค้าของคุณ สินค้าด้านล่างจะถูกเพิ่มลงในกลุ่มที่คุณเลือก",
  "ss_builder_affiliate_add_new_tour_content_step_2": "ใส่ลิงค์สินค้า ระบบจะได้ข้อมูลสินค้าอัตโนมัติ",
  "ss_builder_affiliate_add_new_tour_content_step_3": "คุณสามารถเปลี่ยนชื่อ รูปภาพ ฉลาก... ของสินค้าได้",
  "ss_builder_affiliate_add_new_tour_content_step_4": "คลิกปุ่ม “เพิ่ม” เพื่อเพิ่มรายการสินค้าด้านบนลงในกลุ่ม",
  "ss_builder_affiliate_add_new_tour_title_step_1": "เลือกกลุ่มสินค้า",
  "ss_builder_affiliate_add_new_tour_title_step_2": "ลิงค์ไปยังสินค้า",
  "ss_builder_affiliate_add_new_tour_title_step_3": "Product information",
  "ss_builder_affiliate_add_new_tour_title_step_4": "เพิ่มสินค้าใหม่",
  "ss_builder_affiliate_group_tour_content_step_1": "เพิ่มกลุ่มสินค้าเพื่อจัดหมวดหมู่สินค้าของคุณ",
  "ss_builder_affiliate_group_tour_content_step_2": "การแก้ไขและตัวเลือกของแต่ละกลุ่มทำให้คุณสามารถปรับแต่งบล็อกได้ตามใจชอบ",
  "ss_builder_affiliate_group_tour_title_step_1": "เพิ่มกลุ่มสินค้า",
  "ss_builder_affiliate_group_tour_title_step_2": "ตัวเลือกด่วน",
  "ss_builder_affiliate_product_btn_see_more_placeholder": "เพิ่มเนื้อหา",
  "ss_builder_affiliate_product_btn_see_more_title": "แสดงปุ่มป้ายเพิ่มเติม",
  "ss_builder_affiliate_product_clean_up_popover_content": "สินค้าตัวนี้จะไม่ถูกลบล้างโดยอัตโนมัติเมื่อกลุ่มสินค้าเต็มจำนวน",
  "ss_builder_affiliate_product_cleanup_lock_label": "ล็อคการลบล้าง",
  "ss_builder_affiliate_product_cleanup_unlock_label": "ปลดล็อคการลบล้าง",
  "ss_builder_affiliate_product_frame_panel_title": "เลือกเฟรม",
  "ss_builder_affiliate_product_group_add_new_panel_title": "ข้อมูลกลุ่ม",
  "ss_builder_affiliate_product_group_list_selector_label": "รายการกลุ่มสินค้า",
  "ss_builder_affiliate_product_group_management_title": "จัดการกลุ่มสินค้า",
  "ss_builder_affiliate_product_group_other": "อื่น",
  "ss_builder_affiliate_product_group_placeholder": "ใส่ชื่อบล็อก",
  "ss_builder_affiliate_product_group_quantity_label": "{{product_quantity}} สินค้า",
  "ss_builder_affiliate_product_group_quantity_warning_1": "เพิ่มไม่ได้",
  "ss_builder_affiliate_product_group_quantity_warning_2": "เกินจำนวนที่มีอยู่",
  "ss_builder_affiliate_product_group_title": "ชื่อบล็อก",
  "ss_builder_affiliate_product_image_popup_infomation_label": "รูปภาพจะถูกรวบรวมข้อมูลโดยอัตโนมัติจากลิงค์ของคุณ คุณสามารถเปลี่ยนภาพอื่นได้",
  "ss_builder_affiliate_product_label_product_discount": "ลดราคา",
  "ss_builder_affiliate_product_label_product_hot": "Hot",
  "ss_builder_affiliate_product_label_product_like": "ชื่นชอบ",
  "ss_builder_affiliate_product_label_product_title": "ฉลากสินค้า",
  "ss_builder_affiliate_product_list_empty_content": "เพิ่มสินค้า โปรดกดที่ปุ่ม \"เพิ่ม\" ด้านบน",
  "ss_builder_affiliate_product_list_empty_title": "ยังไม่มีการเพิ่มสินค้า",
  "ss_builder_affiliate_product_list_title": "List",
  "ss_builder_affiliate_product_moving_panel_title": "ย้ายไปที่",
  "ss_builder_affiliate_product_quantity_on_row_title": "จำนวนสินค้าต่อแถว",
  "ss_builder_affiliate_product_searching_placeholder": "ค้นหาสินค้าตามชื่อ",
  "ss_builder_affiliate_product_style_title": "สไตล์",
  "ss_builder_affiliate_setting_popover_manual_content": "ทำความรู้จักกับการใช้ Block Affiliate เรามากดปุ่ม “เริ่มต้น” กัน",
  "ss_builder_affiliate_setting_popover_manual_title": "Product tour",
  "ss_builder_affiliate_setting_tour_content_step_1": "ดำเนินการต่างๆ เช่น ซ่อน/แสดง ลบบล็อก",
  "ss_builder_affiliate_setting_tour_content_step_2": "ตั้งค่าหัวข้อและคำอธิบายสำหรับบล็อก ข้อมูลจะแสดงตามลำดับหลังจากบันทึก",
  "ss_builder_affiliate_setting_tour_content_step_3": "เลือกรูปแบบการแสดงสินค้าในบล็อก หลังจากเปลี่ยนและบันทึกบล็อกแล้ว คุณจะเห็นรูปแบบการแสดงผลที่เปลี่ยนไปในหน้าจอการออกแบบ",
  "ss_builder_affiliate_setting_tour_content_step_4": "สร้างใหม่และจัดการกลุ่มสินค้าเพื่อแบ่งรายการสินค้าออกเป็นกลุ่มๆ แฟนๆ จะค้นหาหมวดหมู่และกรองสินค้าตามกลุ่มได้อย่างง่ายดาย ตัวอย่างเช่น เสื้อผ้า รองเท้า เครื่องประดับ...",
  "ss_builder_affiliate_setting_tour_content_step_5": "เข้าถึงแต่ละกลุ่มสินค้าได้ง่ายเพื่อเพิ่มใหม่และจัดการสินค้าภายในกลุ่ม",
  "ss_builder_affiliate_setting_tour_content_step_6": "เพิ่มสินค้าใหม่ลงในแต่ละกลุ่มโดยคลิกปุ่ม \"เพิ่ม\"",
  "ss_builder_affiliate_setting_tour_content_step_7": "ตัวเลือกด่วนสำหรับแต่ละสินค้าช่วยให้สามารถจัดการสินค้าในรายการได้อย่างรวดเร็ว",
  "ss_builder_affiliate_setting_tour_content_step_8": "หลังจากแก้ไข เพิ่มเนื้อหาและสินค้าลงในบล็อกของคุณแล้ว ให้กดปุ่ม “บันทึก” เพื่อบันทึก และแสดงการเปลี่ยนแปลงในหน้าจอ “ออกแบบ”",
  "ss_builder_affiliate_setting_tour_title_step_1": "ตัวเลือกด่วน",
  "ss_builder_affiliate_setting_tour_title_step_2": "หัวข้อและคำอธิบาย",
  "ss_builder_affiliate_setting_tour_title_step_3": "การกำหนดค่าการแสดงสินค้า",
  "ss_builder_affiliate_setting_tour_title_step_4": "การจัดการกลุ่มสินค้า",
  "ss_builder_affiliate_setting_tour_title_step_5": "กลุ่มสินค้า",
  "ss_builder_affiliate_setting_tour_title_step_6": "เพิ่มสินค้า",
  "ss_builder_affiliate_setting_tour_title_step_7": "ตัวเลือกสินค้า",
  "ss_builder_affiliate_setting_tour_title_step_8": "บันทึกสินค้า",
  "ss_builder_affiliate_upload_product_error_content": "ไฟล์อัปโหลดต้องเป็นไฟล์ json และอยู่ในรูปแบบที่ถูกต้อง",
  "ss_builder_affiliate_upload_product_error_label": "ไฟล์ไม่ถูกต้อง",
  "ss_builder_affiliate_upload_product_label": "อัปโหลดไฟล์สินค้า",
  "ss_builder_all_setting_and_product": "การตั้งค่าทั้งหมดและสินค้าทั้งหมดภายใน",
  "ss_builder_all_the_blocks_are_in_your_list_by_the_blocks_of_the_template": "บล็อกทั้งหมดอยู่ในรายการของคุณโดยจากบล็อกเทมเพลต",
  "ss_builder_allowed_domain_label": "ยังไม่มีชื่อโดเมนนี้ คุณสามารถใช้ชื่อโดเมนนี้ได้",
  "ss_builder_an_unexpected_error_has_occurred": "มีข้อผิดพลาดที่ไม่คาดคิดเกิดขึ้น.",
  "ss_builder_apply_preview_theme_template_btn_label": "ใช้",
  "ss_builder_apply_template_successfully_label": "ใช้เทมเพลตสำเร็จ",
  "ss_builder_apply_template_title": "คุณต้องการใช้เทมเพลตนี้หรือไม่",
  "ss_builder_apply_theme_premium_warning": "ธีมนี้สำหรับแอคเคาท์ที่ชำระเงิน โปรดอัพเกรดบัญชีของคุณเพื่อใช้งานเทมเพลตนี้",
  "ss_builder_apply_theme_premium_warning_title": "คุณต้องการใช้ธีมนี้หรือไม่",
  "ss_builder_apply_theme_template_successfully_label": "ใช้เทมเพลตธีมสำเร็จ",
  "ss_builder_auto_cleanup_products_warning_content_1": "สินค้าเกินจำนวนที่ได้กำหนดไว้ ระบบจะลบล้างสินค้าจากด้านล่างของรายการเมื่อคุณเพิ่มสินค้าใหม่โดยอัตโนมัติ",
  "ss_builder_auto_cleanup_products_warning_content_2": "หมายเหตุ: สินค้าที่อยู่ในโหมด “ล็อคการลบล้าง” จะไม่ถูกลบล้างโดยอัตโนมัติ",
  "ss_builder_back": "ย้อนกลับ",
  "ss_builder_background_block": "พื้นหลัง",
  "ss_builder_background_enable_overlay": "การคุมโทน",
  "ss_builder_background_opacity": "ความเบลอ",
  "ss_builder_background_overlay_color": "การเพิ่มสีให้กับภาพ",
  "ss_builder_background_popover_manual_content": "ทำความรู้จักกับการใช้งานการตั้งค่าพื้นหลัง เรามากดปุ่ม “เริ่มต้นใช้งาน” กัน",
  "ss_builder_background_popover_manual_title": "Product tour",
  "ss_builder_background_setting_title": "การตั้งค่าพื้นหลัง",
  "ss_builder_background_tour_content_step_1": "พื้นที่แสดงตัวอย่างสำหรับการตั้งค่าด้านล่าง อัปเดตเรียลไทม์สำหรับการเปลี่ยนแปลงใดๆ",
  "ss_builder_background_tour_content_step_2": "คุณสามารถตั้งค่าพื้นหลังของไซต์โดยใช้ \"รูปภาพ\" หรือ \"สี\" ด้วยสี คุณสามารถเลือกสี \"ทึบ\" หรือ \"ไล่ระดับสี\"",
  "ss_builder_background_tour_title_step_1": "พื้นที่ดูตัวอย่าง",
  "ss_builder_background_tour_title_step_2": "พื้นหลังตามภาพหรือสี",
  "ss_builder_basic_group_description": "บล็อคแบบพื้นฐาน",
  "ss_builder_basic_title": "ขั้นพื้นฐาน",
  "ss_builder_bio_add_social_link_title": "เพิ่มลิงค์ช่องโซเชียล",
  "ss_builder_bio_avatar_title": "รูปโปรไฟล์",
  "ss_builder_bio_layout_title": "เลย์เอาต์",
  "ss_builder_bio_name_sub_placeholder": "Enter the sub title",
  "ss_builder_bio_name_title": "ชื่อตั้งค่า",
  "ss_builder_bio_name_title_placeholder": "Enter the display name",
  "ss_builder_bio_social_link_btn_select_icon_label": "เลือกไอคอน",
  "ss_builder_bio_social_link_icon_title": "ไอคอน",
  "ss_builder_bio_social_link_url_title": "เพิ่มลิงค์ Url",
  "ss_builder_bio_sub_title": "คำบรรยาย",
  "ss_builder_bio_text_title": "ไบโอ",
  "ss_builder_block_affiliate_description": "แนะนำสินค้า Affiliate ให้กับแฟนๆ ของคุณ",
  "ss_builder_block_background_blur": "เบลอ",
  "ss_builder_block_background_color": "สีพื้นหลัง",
  "ss_builder_block_bio_description": "รูปโปรไฟล์ ข้อความชีวประวัติ และลิงค์ช่องทางโซเชียลต่างๆ ของคุณ",
  "ss_builder_block_block_card_description": "รายการรูปภาพพร้อมข้อมูลเพิ่มเติมในรูปแบบที่หลาดหลายและสามารถปัดได้",
  "ss_builder_block_booking_file_alert_content": "โปรดสร้าง Booking Profile ก่อนตั้งค่า",
  "ss_builder_block_booking_file_alert_title": "คุณยังไม่ได้สร้าง Booking Profile",
  "ss_builder_block_booking_file_button_label_default": "รายละเอียดการทำงาน",
  "ss_builder_block_booking_file_label": "Booking Profile url",
  "ss_builder_block_booking_file_link_tooltip": "Booking Profile จะเชื่อมโยงไปยังลิงก์นี้",
  "ss_builder_block_booking_file_switch_tooltip": "แสดงลิงก์ Booking Profile ของคุณในหน้า Bio",
  "ss_builder_block_booking_profile_button_label": "ปุ่มคอนเทนต์",
  "ss_builder_block_booking_profile_setting_description": "อธิบาย",
  "ss_builder_block_booking_profile_setting_title": "หัวข้อ",
  "ss_builder_block_border_color": "สีขอบ",
  "ss_builder_block_border_style": "สไตล์",
  "ss_builder_block_brand_gift_description": "บันทึกการตั้งค่าไอคอนลอยเพื่อเพิ่มปฏิสัมพันธ์กับแฟนๆ ของคุณ",
  "ss_builder_block_brand_gift_footer_description": "เปิดใช้งานฟีเจอร์เพื่อเพิ่มปฏิสัมพันธ์กับแฟนๆ ของคุณ",
  "ss_builder_block_button_link_description": "ลิงค์ปุ่มเดียวที่เชื่อมโยงไปยังเว็ปไซต์ที่คุณชื่นชอบในอินเทอร์เน็ต",
  "ss_builder_block_button_multi_description": "ลิงค์หลายปุ่มที่เชื่อมโยงไปยังเว็ปไซต์ต่างๆ ที่คุณชื่นชอบในอินเทอร์เน็ต",
  "ss_builder_block_button_story_description": "แสดงลิงค์ที่โดเด่นในเว็บไซต์ของคุณด้วยรูปภาพและข้อความ",
  "ss_builder_block_combined_description": "รวมบล็อกที่มีอยู่ทั้งหมดเพื่อเพิ่มความสะดวกมากยิ่งขึ้น",
  "ss_builder_block_combined_description_label": "รวมบล็อกที่มีอยู่ทั้งหมดเพื่อเพิ่มความสะดวกมากยิ่งขึ้น",
  "ss_builder_block_combined_description_placeholder": "ป้อนข้อความ",
  "ss_builder_block_combined_heading_label": "ชื่อบล็อก",
  "ss_builder_block_description": "คำอธิบายบล็อก",
  "ss_builder_block_description_label": "คำอธิบาย",
  "ss_builder_block_description_placeholder": "กรอกเนื้อหา",
  "ss_builder_block_design_hiding_label": "บล็อกที่ซ่อนอยู่",
  "ss_builder_block_digital_product_description": "ขาย Digital Product ของคุณใน Passio Page",
  "ss_builder_block_donation_description": "รับโดเนทจาก Fan",
  "ss_builder_block_heading_label": "หัวเรื่องบล็อก",
  "ss_builder_block_heading_placeholder": "กรอกเนื้อหา",
  "ss_builder_block_image_description": "เพิ่มรูปภาพเข้าไซต์ของคุณ",
  "ss_builder_block_image_embed_description": "ฝังทุกอย่างลงในไซต์ของคุณ: วิดีโอ เสียง แบบฟอร์ม ฯลฯ",
  "ss_builder_block_image_gallery_description": "รายการรูปภาพที่ได้แสดงในหลายเลย์เอาต์และสามารถซูมได้",
  "ss_builder_block_image_popup_description": "รูปภาพจะได้แสดงเป็นแบบป๊อปอัพเมื่อเข้าถึงโปรไฟล์ของคุณ",
  "ss_builder_block_image_slider_description": "รายการรูปภาพที่แสดงในโหมดปัดรูปภาพ",
  "ss_builder_block_is_hiding_label": "บล็อกที่ซ่อนอยู่",
  "ss_builder_block_link_copy_successfully_label": "คัดลอกแล้ว",
  "ss_builder_block_list_combined_description": "รวมบล็อกที่มีอยู่ทั้งหมดเพื่อเพิ่มความสะดวกมากยิ่งขึ้น",
  "ss_builder_block_main_color": "สีหลัก",
  "ss_builder_block_outside_space": "ระยะห่างภายนอกบล็อก",
  "ss_builder_block_rating_answer_1": "อินเตอร์เฟซ",
  "ss_builder_block_rating_answer_2": "เวลาในการโหลดหน้าเพจ",
  "ss_builder_block_rating_answer_3": "ประสบการณ์",
  "ss_builder_block_rating_answer_4": "ซัพพอร์ต",
  "ss_builder_block_rating_answer_5": "อื่นๆ",
  "ss_builder_block_rating_button_submit_rating": "ส่งคะแนน",
  "ss_builder_block_rating_button_title_open_popup": "ส่งคะแนน",
  "ss_builder_block_rating_content_rating": "ฟีดแบคของคุณ",
  "ss_builder_block_rating_content_rating_placeholder": "ข้อเสนอแนะสำหรับ Passio Page",
  "ss_builder_block_rating_description": "จะดีมากหาก Passio Page ได้รับคะแนนของคุณเพื่อปรับปรุงคุณภาพการบริการ",
  "ss_builder_block_rating_description_auto_show": "ฟีดแบคของคุณเกี่ยวกับ Passio Page",
  "ss_builder_block_rating_description_star_1": "คุณไม่พึงพอใจเพราะ",
  "ss_builder_block_rating_description_star_2": "คุณไม่พึงพอใจเพราะ",
  "ss_builder_block_rating_description_star_3": "Passio Page ควรปรับปรุงเรื่อง",
  "ss_builder_block_rating_description_star_4": "คุณพึงพอใจกับ",
  "ss_builder_block_rating_description_star_5": "คุณพึงพอใจกับ",
  "ss_builder_block_rating_star_1": "แย่",
  "ss_builder_block_rating_star_2": "เบื่อ",
  "ss_builder_block_rating_star_3": "เฉยๆ",
  "ss_builder_block_rating_star_4": "ดี",
  "ss_builder_block_rating_star_5": "เยี่ยม",
  "ss_builder_block_rating_title": "คะแนนของคุณ",
  "ss_builder_block_rating_toast_success_rating": "ขอบคุณสำหรับฟีดแบค",
  "ss_builder_block_second_color": "สีรอง",
  "ss_builder_block_setting_title": "การตั้งค่าบล็อก",
  "ss_builder_block_shadow_color": "สีเงา",
  "ss_builder_block_space": "ระยะห่างระหว่างบล็อก",
  "ss_builder_block_spacer_and_divider_description": "Add spacer or divider between your blocks",
  "ss_builder_block_text_description": "เพิ่มเนื้อหาเข้าไซต์ของคุณด้วยข้อความที่ได้จัดรูปแบบแล้ว",
  "ss_builder_block_text_see_more_btn_default_title": "ดูเพิ่มเติม",
  "ss_builder_block_truth_or_dare_description": "ลุยกันเลยกัลเกม Truth or Dare",
  "ss_builder_block_truth_or_dare_footer_description": "แสดงไอคอน Truth or Dare ที่มุมหน้าจอ",
  "ss_builder_block_virtual_gift_description": "เปิดฟีเจอร์เพื่อรับของขวัญจาก Fan",
  "ss_builder_block_virtual_gift_footer_description": "แสดงไอคอนสำหรับ Virtual Gift ที่มุมหน้าจอ",
  "ss_builder_blocks_general_settings_label": "การตั้งค่าทั่วไปของบล็อก",
  "ss_builder_blocks_general_settings_popover_content": "เปิดใช้งานเพื่อใช้ \"การตั้งค่าบล็อก\" ใน \"การตั้งค่า\" ของบล็อกในรายการด้านล่าง",
  "ss_builder_blocks_label": "บล็อก",
  "ss_builder_blocks_list": "รายการ",
  "ss_builder_blocks_list_empty_content": "หากต้องการเพิ่มบล็อกใหม่ โปรดคลิกปุ่ม \"เพิ่ม\" ด้านบน",
  "ss_builder_blocks_list_empty_title": "ยังไม่มีการเพิ่มบล็อก",
  "ss_builder_body_description": "คุณสามารถใช้แท็บเพื่อแบ่งรายการบล็อกออกเป็นหลายส่วนได้",
  "ss_builder_body_introduction_description": "\"Body\" เป็นส่วนหลักของไซต์ ประกอบด้วยบล็อกต่างๆ และได้แบ่งออก นำทางโดยแท็บ ตัวอย่างเช่น: เนื้อหา รูปภาพ สินค้า ...",
  "ss_builder_body_introduction_title": "Body Position",
  "ss_builder_body_title": "Body",
  "ss_builder_bold": "หนา",
  "ss_builder_booking_profile_style_1": "การ์ด",
  "ss_builder_booking_profile_style_2": "Embed code",
  "ss_builder_border_dash": "Dash",
  "ss_builder_border_dot": "Dot",
  "ss_builder_border_radius_label": "โค้งมน",
  "ss_builder_border_radius_style": "แบบโค้งมน",
  "ss_builder_border_solid": "Solid",
  "ss_builder_bottom_position_tab_label": "ล่าง",
  "ss_builder_box_icon_button_filter": "Filter",
  "ss_builder_box_icon_empty_search": "ไม่พบผลลัพธ์",
  "ss_builder_box_icon_empty_searching_description": "โปรดลองใช้คีย์เวิร์ดอื่นๆ",
  "ss_builder_box_icon_filter_category_all": "ทั้งหมด",
  "ss_builder_box_icon_filter_category_sub_title": "เลือกหมวดหมู่ไอคอน",
  "ss_builder_box_icon_filter_category_title": "Filter",
  "ss_builder_box_icon_search_bar_placeholder": "ค้นหาไอคอนตามชื่อ...",
  "ss_builder_bp_apply_block_settings_instruction": "Turn off to display full screen width. Turn on to display by settings in Block Settings as normal blocks.",
  "ss_builder_bp_open_new_tab_instruction": "Turn on to show the icon to open a new tab in the right corner",
  "ss_builder_bp_open_new_tab_title": "Open new tab",
  "ss_builder_brand_gift_introduction_popup_title": "เพิ่มไอคอน Brand Gift",
  "ss_builder_browser_opening_label": "เปิดในเบราว์เซอร์",
  "ss_builder_button_background_color": "สีพื้นหลัง",
  "ss_builder_button_border_color": "สีเส้นขอบ",
  "ss_builder_button_border_width": "ความหนาของเส้นขอบ (px)",
  "ss_builder_button_close_introduction_service": "ปิด",
  "ss_builder_button_font_size": "ขนาด font (px)",
  "ss_builder_button_font_weight": "น้ำหนัก font",
  "ss_builder_button_line_height": "ความสูงของเส้น",
  "ss_builder_button_link_content_title": "หัวข้อ",
  "ss_builder_button_link_description": "คำอธิบายลิงค์",
  "ss_builder_button_link_icon_btn_selection_label": "เลือกไอคอน",
  "ss_builder_button_link_icon_origin_url_label": "ลิงค์",
  "ss_builder_button_link_icon_title": "ไอคอน",
  "ss_builder_button_link_large_size_label": "ใหญ่",
  "ss_builder_button_link_middle_size_label": "กลาง",
  "ss_builder_button_link_not_enought_infomation": "ลิงค์มีข้อมูลไม่เพียงพอ",
  "ss_builder_button_link_placeholder_content_title": "กรอกเนื้อหา",
  "ss_builder_button_link_size_selection_title": "เลือกขนาด",
  "ss_builder_button_link_small_size_label": "เล็ก",
  "ss_builder_button_setting_title": "การตั้งค่าปุ่ม",
  "ss_builder_button_text_color": "สีข้อความ",
  "ss_builder_button_title": "ชื่อปุ่ม",
  "ss_builder_cancel": "ยกเลิก",
  "ss_builder_cancel-delete_block_label": "ยกเลิก",
  "ss_builder_cancel-delete_image_label": "แก้ไขต่อ",
  "ss_builder_cancel-delete_social_link_btn_label": "ยกเลิก",
  "ss_builder_cancel-delete_tab_label": "ยกเลิก",
  "ss_builder_cancel-restore_theme_label": "ยกเลิก",
  "ss_builder_cancel-set_default_tab_label": "ยกเลิก",
  "ss_builder_cancel_affiliate_product_group_add_new_panel_label": "ยกเลิก",
  "ss_builder_cancel_apply_theme_template_btn_label": "ยกเลิก",
  "ss_builder_cancel_block_btn_label": "ยกเลิก",
  "ss_builder_cancel_delete_aff_block_btn_label": "ยกเลิก",
  "ss_builder_cancel_delete_card_modal_btn_label": "แก้ไขต่อ",
  "ss_builder_cancel_delete_link_modal_btn_label": "ดำเนินการแก้ไขต่อ",
  "ss_builder_cancel_delete_product_label": "ยกเลิก",
  "ss_builder_cancel_delete_theme_template_modal_btn_label": "ยกเลิก",
  "ss_builder_cancel_edit_block_content": "การปรับเปลี่ยนของคุณจะไม่ถูกบันทึก คุณแน่ใจต้องการออกหรือไม่",
  "ss_builder_cancel_edit_block_title": "ยกเลิกการแก้ไข",
  "ss_builder_cancel_hide_product_label": "ยกเลิก",
  "ss_builder_cancel_image_slider_add_new_btn_label": "ยกเลิก",
  "ss_builder_cancel_overwrite_theme_template_btn_label": "ยกเลิก",
  "ss_builder_cancel_preview_theme_template_btn_label": "ปิด",
  "ss_builder_cancel_product_addition_modal_confirm_btn_label": "เพิ่มสินค้าต่อ",
  "ss_builder_cancel_product_addition_modal_description": "สินค้าที่ได้เพิ่มในหน้าจอนี้จะไม่ถูกบันทึก",
  "ss_builder_cancel_product_addition_modal_title": "ยกเลิกการเพิ่มสินค้าใช่หรือไม่?",
  "ss_builder_cancel_replace_theme_template_btn_label": "ยกเลิก",
  "ss_builder_cancel_select_multi_items": "ยกเลิก",
  "ss_builder_cancel_theme_selection_panel_label": "ยกเลิก",
  "ss_builder_cancel_upload_image": "ยกเลิิก",
  "ss_builder_card_add_new_description_label": "คำอธิบาย",
  "ss_builder_card_add_new_label": "ฉลาก",
  "ss_builder_card_add_new_subtitle_label": "หัวข้อรอง",
  "ss_builder_card_add_new_title_label": "หัวข้อ",
  "ss_builder_card_add_new_url_label": "Url",
  "ss_builder_card_additional_btn_label": "เพิ่มการ์ด",
  "ss_builder_card_btn_content_label": "ปุ่มคอนเทนต์",
  "ss_builder_card_layout_alert_1": "เลย์เอาต์นี้จะไม่แสดงคำอธิบาย ป้ายกำกับ และปุ่ม",
  "ss_builder_card_layout_alert_2": "เลย์เอาต์นี้จะไม่แสดงคำอธิบาย ป้ายกำกับ และปุ่ม",
  "ss_builder_card_layout_label": "Layout",
  "ss_builder_card_layout_label_1": "ข้อความใต้ภาพ - จัดชิดซ้าย",
  "ss_builder_card_layout_label_2": "ข้อความใต้ภาพ - จัดกึ่งกลาง",
  "ss_builder_card_layout_label_3": "ข้อความบนรูปภาพ - จัดกึ่งกลาง",
  "ss_builder_card_layout_label_4": "ข้อความบนรูปภาพ - จัดชิดซ้าย",
  "ss_builder_card_layout_label_5": "ข้อความด้านขวา",
  "ss_builder_card_layout_label_6": "ข้อความด้านซ้าย",
  "ss_builder_card_layout_label_7": "ข้อความบนรูปภาพ - จัดชิดซ้าย 2",
  "ss_builder_card_layout_label_8": "ข้อความบนรูปภาพ - จัดกึ่งกลาง 2",
  "ss_builder_card_url_placeholder": "http://domain.com",
  "ss_builder_category_14_up": "14+",
  "ss_builder_category_all": "ทั้งหมด",
  "ss_builder_category_art": "ศิลปะ",
  "ss_builder_category_creative": "ความคิดสร้างสรรค์",
  "ss_builder_category_doctor": "หมอ",
  "ss_builder_category_education": "การศึกษา",
  "ss_builder_category_entertainment": "การบันเทิง",
  "ss_builder_category_fashion": "แฟชั่น",
  "ss_builder_category_fitness": "ฟิตเนส",
  "ss_builder_category_food_fmcg": "อาหารและสินค้าอุปโภคบริโภค",
  "ss_builder_category_game": "เกม",
  "ss_builder_category_health_and_beauty": "สุขภาพและความงาม",
  "ss_builder_category_ict": "ไอซีที",
  "ss_builder_category_life_style": "ไลฟ์สไตล์",
  "ss_builder_category_lifestyle": "ไลฟ์สไตล์",
  "ss_builder_category_mom_and_kid": "แม่และเด็ก",
  "ss_builder_category_others": "อื่น",
  "ss_builder_category_pet": "สัตว์เลี้ยง",
  "ss_builder_category_spirit": "จิตวิญญาณ",
  "ss_builder_change_image_button_label": "เปลี่ยนรูปภาพ",
  "ss_builder_change_name_product_group_successfully_label": "เปลี่ยนชื่อกลุ่มสินค้าสำเร็จ",
  "ss_builder_change_theme_label": "เปลี่ยนธีม",
  "ss_builder_choose_a_prototying_method": "เลือกตัวเลือกเพื่อสร้างเทมเพลตใหม่",
  "ss_builder_choose_at_least_category": "เลือกอย่างน้อย 1 หมวดหมู่ แล้วเราจะแนะนำธีมตามการเลือกของคุณ 👇",
  "ss_builder_choose_image_below": "หากต้องการเพิ่มรายการใหม่ โปรดคลิกที่ปุ่ม \"เพิ่มใหม่\" ด้านล่าง",
  "ss_builder_choose_one_theme_preview_you_can_customize_it_later": "เลือกธีมที่เหมาะสมซึ่งคุณสามารถปรับแต่งได้หลังจากใช้✨",
  "ss_builder_choose_the_look_that_right_for_you": "กรุณาเลือกธีม",
  "ss_builder_choose_this_template": "ใช้",
  "ss_builder_cleanup_lock_product_successfully_label": "ล็อคการลบล้างสำเร็จ",
  "ss_builder_cleanup_unlock_product_successfully_label": "ปลดล็อคการลบล้างสำเร็จ",
  "ss_builder_close_panel": "ยกเลิก",
  "ss_builder_close_panel_affiliate_product-selection-label": "ยกเลิก",
  "ss_builder_close_panel_edit_text_block": "ปิด",
  "ss_builder_collapse": "ดูน้อยลง",
  "ss_builder_color_1": "สีที่ 1",
  "ss_builder_color_2": "สีที่ 2",
  "ss_builder_color_picker_panel_title": "เลือกสี",
  "ss_builder_confirm-cancel_image_slider_add_new_content": "การกระทำของคุณยังไม่ได้รับการบันทึก\nคุณต้องการบันทึกก่อนออกหรือไม่",
  "ss_builder_confirm-cancel_image_slider_add_new_title": "ออกจากการแก้ไข",
  "ss_builder_confirm-delete_block_label": "หากคุณลบบล็อก การตั้งค่าบล็อกและข้อมูลที่เพิ่มลงในบล็อกนั้นจะถูกลบอย่างถาวร คุณแน่ใจหรือไม่ว่าต้องการลบ",
  "ss_builder_confirm-delete_block_title": "คุณต้องการลบบล็อกนี้หรือไม่",
  "ss_builder_confirm-delete_image_label": "คุณแน่ใจจะลบภาพนี้ใช่หรือไม่",
  "ss_builder_confirm-delete_image_title": "ลบภาพ",
  "ss_builder_confirm-delete_social_link_content": "คุณแน่ใจหรือไม่ว่าต้องการลบรายการนี้",
  "ss_builder_confirm-delete_social_link_title": "ลบลิงค์ช่องโซเชียลมีเดีย",
  "ss_builder_confirm_affiliate_product_group_add_new_panel_label": "บันทึก",
  "ss_builder_confirm_apply_template_description": "การใช้เทมเพลตจะแทนที่บล็อกที่มีอยู่ทั้งหมดในรายการของคุณด้วยบล็อกเทมเพลต",
  "ss_builder_confirm_apply_template_label": "แทนที่",
  "ss_builder_confirm_apply_theme_template_btn_label": "ยืนยัน",
  "ss_builder_confirm_apply_theme_template_content": "การใช้ธีมที่คุณเลือกจะเปลี่ยนรูปลักษณ์ของทั้งไซต์",
  "ss_builder_confirm_apply_theme_template_title": "คุณต้องการใช้ธีมนี้หรือไม่",
  "ss_builder_confirm_cancel_image_slider_add_new_btn_label": "ออก",
  "ss_builder_confirm_cleanup_lock_product_btn_label": "Confirm to lock",
  "ss_builder_confirm_cleanup_lock_product_selection_modal_content": "Are you sure you want to lock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_cleanup_unlock_product_btn_label": "Confirm to unlock",
  "ss_builder_confirm_cleanup_unlock_product_selection_modal_content": "Are you sure you want to unlock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_delete_aff_block_content": "การดำเนินการนี้ไม่สามารถยกเลิกได้ โปรดยืนยันว่าคุณเข้าใจแล้วว่าการลบบล็อกนี้จะลบสินค้าทั้งหมดในบล็อก",
  "ss_builder_confirm_delete_aff_block_input_placeholder": "OK",
  "ss_builder_confirm_delete_aff_block_label_enter_ok": "กด “OK” เพื่อยืนยัน",
  "ss_builder_confirm_delete_aff_block_understood": "เข้าใจแล้ว",
  "ss_builder_confirm_delete_aff_block_warining": "คำเตือน",
  "ss_builder_confirm_delete_block_btn_label": "ยืนยันการลบ",
  "ss_builder_confirm_delete_card_modal_btn_label": "ลบการ์ด",
  "ss_builder_confirm_delete_card_modal_description": "คุณแน่ใจหรือไม่ว่าต้องการลบการ์ดใบนี้",
  "ss_builder_confirm_delete_card_modal_label": "ลบการ์ด",
  "ss_builder_confirm_delete_combined_label": "คุณแน่ใจหรือไม่ว่าต้องการลบประเภทของบล็อก",
  "ss_builder_confirm_delete_image_popup_image_label": "ยืนยันการลบ",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบ {{quantity}} ลิงค์เหล่านี้",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_title": "ลบลิงค์",
  "ss_builder_confirm_delete_item_checkbox_card_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบ {{quantity}} การ์ดเหล่านี้",
  "ss_builder_confirm_delete_item_checkbox_card_modal_title": "ลบการ์ด",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบบล็อกทั้งหมดเหล่านี้",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_title": "ลบบล็อก",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบรูปภาพจำนวน {{quantity}} เหล่านี้",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_title": "ลบภาพแกลเลอรี่",
  "ss_builder_confirm_delete_item_checkbox_story_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบรายการเด่น {{quantity}} เหล่านี้",
  "ss_builder_confirm_delete_item_checkbox_story_modal_title": "ลบรายการสปอตไลท์",
  "ss_builder_confirm_delete_link_description": "คุณแน่ใจหรือไม่ว่าต้องการลบลิงค์นี้",
  "ss_builder_confirm_delete_product_group_modal_description": "กลุ่ม \"{{group_name}}\" และข้อมูลทั้งหมดของสินค้าในกลุ่มจะถูกลบออกอย่างถาวร คุณแน่ใจต้องการลบหรือไม่",
  "ss_builder_confirm_delete_product_group_modal_title": "คุณต้องการลบกลุ่มนี้หรือไม่",
  "ss_builder_confirm_delete_product_label": "ลบ",
  "ss_builder_confirm_delete_product_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบสินค้านี้",
  "ss_builder_confirm_delete_product_modal_title": "ลบสินค้า",
  "ss_builder_confirm_delete_product_selection_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการลบ {{product_quantity}}  สินค้านี้",
  "ss_builder_confirm_delete_tab_label": "ลบ",
  "ss_builder_confirm_delete_template_modal_content": "ลบเทมเพลตนี้ออกจากรายการเทมเพลตอย่างถาวร แต่การบล็อกที่ใช้เทมเพลตนี้ก่อนหน้านี้จะไม่ได้รับผลกระทบ",
  "ss_builder_confirm_delete_template_modal_title": "คุณต้องการลบเทมเพลตนี้หรือไม่",
  "ss_builder_confirm_delete_theme_template_modal_btn_label": "ยืนยันการลบ",
  "ss_builder_confirm_delete_theme_template_modal_content": "ลบเทมเพลตธีมอย่างถาวรจาก Theme Store ทุกธีมที่สร้างจากธีมนี้จะไม่ได้รับผลกระทบ",
  "ss_builder_confirm_delete_theme_template_modal_title": "คุณต้องการลบเทมเพลทของธีมนี้หรือไม่",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อน {{quantity}} ลิงค์เหล่านี้",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_title": "ซ่อนลิงค์",
  "ss_builder_confirm_disable_item_checkbox_card_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อน {{quantity}} การ์ดเหล่านี้",
  "ss_builder_confirm_disable_item_checkbox_card_modal_title": "ซ่อนการ์ด",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อนบล็อกทั้งหมดเหล่านี้",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_title": "ซ่อนบล็อก",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อน {{quantity}} รูปภาพแกลเลอรีเหล่านี้",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_title": "ซ่อนรูปภาพในแกลเลอรี",
  "ss_builder_confirm_disable_item_checkbox_story_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อน {{quantity}} สปอตไลท์เหล่านี้",
  "ss_builder_confirm_disable_item_checkbox_story_modal_title": "ซ่อนสปอตไลท์",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการแสดง {{quantity}} ลิงค์เหล่านี้",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_title": "โชว์ลิงค์",
  "ss_builder_confirm_enable_item_checkbox_card_modal_content": "คุณแน่ใจไหมว่าต้องการแสดง {{quantity}} การ์ดเหล่านี้",
  "ss_builder_confirm_enable_item_checkbox_card_modal_title": "โชว์การ์ด",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการแสดงบล็อกทั้งหมดเหล่านี้",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_title": "แสดงบล็อก",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการแสดง {{quantity}} รูปภาพแกลเลอรีเหล่านี้",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_title": "แสดงภาพแกลเลอรี่",
  "ss_builder_confirm_enable_item_checkbox_story_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการแสดง {{quantity}} สปอตไลท์เหล่านี้",
  "ss_builder_confirm_enable_item_checkbox_story_modal_title": "แสดงสปอตไลท์",
  "ss_builder_confirm_enable_product_selection_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการแสดง {{product_quantity}} สินค้านี้",
  "ss_builder_confirm_hide_product_btn_label": "ยืนยันเพื่อซ่อน",
  "ss_builder_confirm_hide_product_group_modal_description": "กลุ่มสินค้า \"{{group_name}}\" และข้อมูลทั้งหมดของสินค้าจะถูกซ่อนไว้ คุณแน่ใจหรือไม่ว่าต้องการซ่อนกลุ่ม",
  "ss_builder_confirm_hide_product_group_modal_title": "คุณต้องการซ่อนกลุ่มหรือไม่",
  "ss_builder_confirm_hide_product_label": "ยืนยันซ่อนกลุ่ม",
  "ss_builder_confirm_hide_product_selection_modal_content": "คุณแน่ใจหรือไม่ว่าต้องการซ่อน {{product_quantity}} สินค้านี้",
  "ss_builder_confirm_overwrite": "ยืนยันการเขียนทับ",
  "ss_builder_confirm_overwrite_theme_template_btn_label": "ยืนยันการบันทึกทับ",
  "ss_builder_confirm_overwrite_theme_template_modal_content": "การบันทึกทับจะสวมตำแหน่งข้อมูลและการตั้งค่าทั้งหมดของเทมเพลตธีมด้วยข้อมูลและการตั้งค่าของเทมเพลตธีมปัจจุบัน",
  "ss_builder_confirm_overwrite_theme_template_modal_title": "คุณต้องการบันทึกทับหรือไม่",
  "ss_builder_confirm_panel": "ยืนยัน",
  "ss_builder_confirm_panel_affiliate_product-selection-label": "ยืนยัน",
  "ss_builder_confirm_panel_edit_text_block": "ยืนยัน",
  "ss_builder_confirm_replace_theme_template_btn_label": "ใช้ข้อมูลตัวอย่าง",
  "ss_builder_confirm_replace_theme_template_content": "การดำเนินการนี้ไม่สามารถยกเลิกได้ \nโปรดยืนยันว่าคุณเข้าใจแล้วว่าการใช้เนื้อหาตัวอย่างจะแทนข้อมูลทั้งหมดที่มีอยู่ในเว็บไซต์",
  "ss_builder_confirm_replace_theme_template_input_placeholder": "OK",
  "ss_builder_confirm_replace_theme_template_label_enter_ok": "กด “OK” เพื่อยืนยัน",
  "ss_builder_confirm_replace_theme_template_understood": "เข้าใจแล้ว",
  "ss_builder_confirm_replace_theme_template_warining": "คำเตืือน",
  "ss_builder_confirm_restore_theme_label": "กู้คืนการแก้ไข",
  "ss_builder_confirm_set_default_tab_label": "ตั้งค่าพื้นฐาน",
  "ss_builder_confirm_show_product_btn_label": "ยืนยันเพื่อแสดง",
  "ss_builder_confirm_theme_template_preview_mode_btn_label": "ใช้",
  "ss_builder_confirm_to_edit_label": "แก้ไขต่อ",
  "ss_builder_content_1_introduction_service": "Brand Gift เป็นรูปแบบหนึ่งของการส่งเสริมการขาย เสนอดีลสุดพิเศษจากแบรนด์ให้แก่แฟนๆ/ผู้ติดตาม เพื่อเพิ่มอัตราการเกิดคำสั่งซื้อ และช่วยให้ Creators ได้รับค่าคอมมิชชั่นสุดปัง ด้วยการเก็บบัตรกำนัลที่สำเร็จจากลิงค์ Brand Gift แต่ละครั้ง คุณจะได้รับค่าคอมมิชชั่นตามนโยบายที่น่าสนใจอย่างมาก",
  "ss_builder_content_2_introduction_service": "เปิด Brand Gift เพื่อไม่พลาดโอกาสได้รับโบนัส!",
  "ss_builder_content_creators_joined_the_passio_system": "100K+ คอนเทนต์ครีเอเตอร์เข้าร่วม Passio eco-system",
  "ss_builder_content_editor_title": "คอนเทนต์",
  "ss_builder_content_list_1_introduction_service": "ค่าคอมมิชชั่นพื้นฐาณ",
  "ss_builder_content_list_2_introduction_service": "ค่าคอมมิชชั่นจากแบรนด์ (ถึง 30%)",
  "ss_builder_content_list_3_introduction_service": "แบรนด์รายใหญ่มากกว่า 500 แห่งในหลากหลายสาขาพร้อมโปรแกรมโบนัสมากมาย",
  "ss_builder_continue_add_brand_gift": "ค้นพบตอนนี้",
  "ss_builder_continue_edit_block_label": "แก้ไขต่อไป",
  "ss_builder_continue_edit_image_popup_image_label": "ยักเลิก",
  "ss_builder_continue_to_add_block": "ต่อไป",
  "ss_builder_convert_and_go_to_new_version": "ซิงค์ข้อมูลและไปยังหน้า Passio Page",
  "ss_builder_cover_image": "ภาพหน้าปก",
  "ss_builder_create_bio_page_free_fast": "สร้างหน้าไบโอฟรีและรวดเร็ว",
  "ss_builder_create_link_with_your_name_and_imprint": "สร้างหน้าไบโอด้วยชื่อและที่อยู่ของคุณ Theme store มีหมวดหมู่ธีมมากมาย สามารถปรับแต่งได้อย่างง่ายดายบนโทรศัพท์",
  "ss_builder_create_new_theme_template_label": "สร้างเทมเพลตธีมใหม่",
  "ss_builder_create_template": "สร้างเทมเพลต",
  "ss_builder_cusom": "กำหนดเอง",
  "ss_builder_custom": "กำหนดเอง",
  "ss_builder_dashed_type_label": "เส้นประ",
  "ss_builder_data_auto_convert": "ข้อมูลเก่าของคุณจะได้ซิงค์จากหน้า Landing Page รุ่นเก่าไปยังหน้า Passio Page รุ่นใหม่โดยอัตโนมัติ",
  "ss_builder_data_can_be_convert": "คุณสามารถซิงค์ข้อมูลจากหน้า Landing Page รุ่นเก่าของคุณไปยังหน้า Passio Page รุ่นใหม่ได้",
  "ss_builder_data_will_be_override": "หมายเหตุ: หากคุณซิงค์ ข้อมูลเหล่านั้นจะถูกเขียนทับข้อมูลที่มีอยู่ในหน้า Passio Page",
  "ss_builder_deep_blue_sky": "ท้องฟ้าสีฟ้า",
  "ss_builder_default_meta_description": "Passio Page - Home of {{creator_name}}",
  "ss_builder_defaults_label": "ค่าเริ่มต้น",
  "ss_builder_delete_block_label": "ลบบล็อก",
  "ss_builder_delete_block_successfully_label": "ลบบล็อกเรียบร้อยแล้ว",
  "ss_builder_delete_card_label": "ลบการ์ด",
  "ss_builder_delete_card_successfully_label": "ลบการ์ดสำเร็จ",
  "ss_builder_delete_image_label": "ลบรูปภาพ",
  "ss_builder_delete_image_successfully_label": "ลบรูปภาพเรียบร้อยแล้ว",
  "ss_builder_delete_item_checkbox_button_multi_link_successfully_label": "ลบลิงค์สำเร็จ",
  "ss_builder_delete_item_checkbox_card_successfully_label": "ลบการ์ดสำเร็จ",
  "ss_builder_delete_item_checkbox_combined_successfully_label": "ลบบล็อกที่สำเร็จ",
  "ss_builder_delete_item_checkbox_image_gallery_successfully_label": "ลบรูปภาพในแกลเลอรีสำเร็จ",
  "ss_builder_delete_item_checkbox_story_successfully_label": "ลบสปอตไลท์สำเร็จ",
  "ss_builder_delete_link_label": "ลบลิงค์",
  "ss_builder_delete_link_successfully_label": "ลบลิงค์เรียบร้อยแล้ว",
  "ss_builder_delete_product_group_error_btn_confirm_label": "กลับ",
  "ss_builder_delete_product_group_error_content": "กลุ่ม \"{{group_name}}\" ไม่สามารถลบได้ ต้องแสดงอย่างน้อย 1 กลุ่ม",
  "ss_builder_delete_product_group_error_title": "เกิดข้อผิดพลาดในการลบกลุ่ม",
  "ss_builder_delete_product_group_label": "ลบกลุ่ม",
  "ss_builder_delete_product_group_successfully_label": "ลบกลุ่มสินค้าสำเร็จ",
  "ss_builder_delete_product_label": "ลบสินค้า",
  "ss_builder_delete_product_successfully_label": "ลบสินค้าสำเร็จ",
  "ss_builder_delete_social_link_btn_label": "ยืนยันการลบ",
  "ss_builder_delete_social_link_label": "ลบ",
  "ss_builder_delete_social_link_successfully_label": "ลบลิงค์ช่องโซเชียลมีเดียเรียบร้อยแล้ว",
  "ss_builder_delete_tab_content": "ข้อมูลทั้งหมดในบล็อกจะถูกลบอย่างถาวร คุณต้องการลบหรือไม่",
  "ss_builder_delete_tab_label": "ลบ",
  "ss_builder_delete_tab_successfully_label": "ลบ Tab เรียบร้อยแล้ว",
  "ss_builder_delete_tab_title": "คุณต้องการจะลบแท็บใช่หรือไม่",
  "ss_builder_delete_template_successfully_label": "ลบเทมเพลตสำเร็จ",
  "ss_builder_delete_theme_template_label": "ลบ",
  "ss_builder_delete_theme_template_successfully_label": "ลบเทมเพลตธีมสำเร็จ",
  "ss_builder_description": "คำอธิบาย",
  "ss_builder_description_page_builder": "สร้างไบโอลิงค์ของคุณด้วยหนึ่งคลิกและเริ่มสร้างรายได้ทันที",
  "ss_builder_description_replace_template_content": "การใช้ข้อมูลตัวอย่างจจากเทมเพลตธีมเพื่อแทนที่เนื้อหาปัจจุบันทั้งหมดในไซต์ของคุณ",
  "ss_builder_design_group_floating_title": "ไอคอนลอย",
  "ss_builder_design_group_image_popup_title": "Popup",
  "ss_builder_design_label": "ออกแบบ",
  "ss_builder_dialog_error_confirm_text": "รีเฟรช",
  "ss_builder_dialog_error_content": "มีการเปลี่ยนแปลงจากอุปกรณ์อื่น คุณต้องรีเฟรชหน้านี้",
  "ss_builder_dialog_error_title": "มีการอัปเดตในไซต์ของคุณ",
  "ss_builder_digital_product_free_label": "ฟรี",
  "ss_builder_digital_product_list_title": "รายการสินค้า",
  "ss_builder_direction_of_background_image_label": "ตำแหน่งภาพพื้นหลัง",
  "ss_builder_disable_block_successfully_label": "ซ่อนบล็อกเรียบร้อย",
  "ss_builder_disable_image_successfully_label": "ซ่อนรูปภาพเรียบร้อยแล้ว",
  "ss_builder_disable_item_checkbox_button_multi_link_successfully_label": "ซ่อนลิงค์สำเร็จ",
  "ss_builder_disable_item_checkbox_card_successfully_label": "ซ่อนการ์ดสำเร็จ",
  "ss_builder_disable_item_checkbox_combined_successfully_label": "ซ่อนบล็อกที่สำเร็จ",
  "ss_builder_disable_item_checkbox_image_gallery_successfully_label": "ซ่อนรูปภาพในแกลเลอรีสำเร็จ",
  "ss_builder_disable_item_checkbox_story_successfully_label": "ซ่อนสปอตไลท์สำเร็จ",
  "ss_builder_disable_product_group_successfully_label": "ซ่อนกลุ่มสินค้าสำเร็จ",
  "ss_builder_disable_product_successfully_label": "ซ่อนสินค้าสำเร็จ",
  "ss_builder_disable_social_link_successfully_label": "ซ่อนลิงค์โซเชียลมีเดียเรียบร้อยแล้ว",
  "ss_builder_disable_tab_successfully_label": "ซ่อน Tab เรียบร้อย",
  "ss_builder_disable_theme_successfully_label": "ซ่อนเทมเพลตสำเร็จ",
  "ss_builder_discover_now": "ค้นพบตอนนี้",
  "ss_builder_distance_blocks": "ช่องว่างระหว่างบล็อก",
  "ss_builder_divider_color_label": "สีคั่น",
  "ss_builder_divider_image_label": "ภาพคั่น",
  "ss_builder_divider_label": "ตัวคั่น",
  "ss_builder_divider_thickness_label": "ความหนา (px)",
  "ss_builder_do_you_want_replace_sample_content": "คุณต้องการใช้ข้อมูลธีมตัวอย่างหรือไม่",
  "ss_builder_domain_site_setting_alert_infomation": "หากคุณเปลี่ยนชื่อโดเมน คุณจะต้องรอ 30 วันสำหรับครั้งถัดไป",
  "ss_builder_domain_site_setting_rule": "เริ่มจากตัวอักษร มีแต่ตัวเลขและตัวอักษรเท่านั้น",
  "ss_builder_done_panel": "เสร็จแล้ว",
  "ss_builder_dont_add_block_from_template_description": "ไม่สามารถเพิ่มจำนวนบล็อกจากเทมเพลตได้เนื่องจากมีขีดจำกัด 10 บล็อกในรายการ โปรดลบบล็อกที่มีอยู่เพื่อเพิ่มบล็อกเทมเพลตใหม่",
  "ss_builder_dont_add_block_from_template_title": "เพิ่มบล็อกไม่ได้",
  "ss_builder_dont_add_product_exist_at_add_new_content": "สินค้ามีอยู่ในรายการแล้ว",
  "ss_builder_dont_add_product_exist_at_list_content": "สินค้าที่คุณต้องการเพิ่มมีอยู่แล้ว\n\"{{ชื่อกลุ่ม}}\"",
  "ss_builder_dont_add_same_affiliate_product_confirm_label": "ยืนยัน",
  "ss_builder_dont_add_same_affiliate_product_modal_label": "ไม่สามารถเพิ่มสินค้าใหม่ได้",
  "ss_builder_dont_apply_template_content_modal_description": "ไม่สามารถดำเนินการใช้เทมเพลตได้เนื่องจากไม่มีเทมเพลตแล้ว",
  "ss_builder_dont_apply_theme_content_modal_btn_confirm_label": "ปิด",
  "ss_builder_dont_apply_theme_content_modal_description": "ไม่สามารถดำเนินการข้อมูลตัวอย่างได้ เนื่องจากเทมเพลตธีมไม่มีอยู่ใน Theme Store อีกต่อไป",
  "ss_builder_dont_apply_theme_content_modal_label": "ไม่สามารถใช้",
  "ss_builder_dot_strokes_type_label": "จุด",
  "ss_builder_dp_title_intro": "Digital products",
  "ss_builder_drop_here_label": "เพิ่มบล็อกที่นี่",
  "ss_builder_duplicated_product_url_label": "ลิงค์ซ้ำ",
  "ss_builder_duplicated_theme_template_name_warning": "ชื่อเทมเพลนี้มีอยู่แล้ว กรุณาใส่ชื่ออื่น!",
  "ss_builder_earn_additional_commissions_label": "Earn additional commissions when using Passio's affiliate links",
  "ss_builder_edit_affiliate_product_group_title": "ชื่อกลุ่ม",
  "ss_builder_edit_affiliate_product_title": "แก้ไขสินค้า",
  "ss_builder_edit_block_label": "แก้ไข",
  "ss_builder_edit_block_successfully_label": "แก้ไขบล็อกเรียบร้อย",
  "ss_builder_edit_image_of_image_popup_title": "แก้ไขรูปภาพป๊อปอัพ",
  "ss_builder_edit_name_affiliate_product_group_title": "เปลี่ยนชื่อกลุ่มสินค้า",
  "ss_builder_edit_name_block_successfully_label": "แก้ไขชื่อบล็อกเรียบร้อย",
  "ss_builder_edit_product_group_label": "เปลี่ยนชื่อ",
  "ss_builder_edit_tab_label": "เปลี่ยนชื่อ",
  "ss_builder_edit_tab_successfully_label": "แก้ไข Tab เรียบร้อย",
  "ss_builder_edit_template_btn_label": "Save",
  "ss_builder_edit_template_title": "แก้ไขเทมเพลต",
  "ss_builder_edit_text_block_label": "ชื่อบล็อก",
  "ss_builder_edit_theme_btn_label": "บันทึก",
  "ss_builder_edit_theme_template_label": "แก้ไขข้อมูล",
  "ss_builder_edit_theme_template_successfully_label": "แก้ไขเทมเพลตธีมสำเร็จ",
  "ss_builder_edit_theme_template_title": "แก้ไขข้อมูลเทมเพลตของธีม",
  "ss_builder_editor_btn-add-more": "เพิ่ม",
  "ss_builder_editor_btn-cancel": "ยกเลิก",
  "ss_builder_editor_btn-save": "บันทึก",
  "ss_builder_email_placeholder": "กรอกอีเมล",
  "ss_builder_embed_code_label": "Embed code",
  "ss_builder_embed_code_placeholder": "กรุณากรอก embed code",
  "ss_builder_empty_block": "ไม่มีบล็อก",
  "ss_builder_empty_search": "ไม่พบผลลัพธ์",
  "ss_builder_empty_social_link_list_content": "หากต้องการเพิ่มโซเชียลเน็ตเวิร์ก โปรดกดปุ่ม “เพิ่ม” ด้านบน",
  "ss_builder_empty_social_link_list_title": "ยังไม่มีการเพิ่มเครือข่ายโซเชียล",
  "ss_builder_empty_template_label": "ไม่มีเทมเพลตบล็อก",
  "ss_builder_enable_block_successfully_label": "แสดงชื่อบล็อกเรียบร้อย",
  "ss_builder_enable_border": "แสดง",
  "ss_builder_enable_button_border": "แสดงเส้นขอบ",
  "ss_builder_enable_image_successfully_label": "แสดงรูปภาพสำเร็จ",
  "ss_builder_enable_item_checkbox_button_multi_link_successfully_label": "แสดงลิงค์สำเร็จ",
  "ss_builder_enable_item_checkbox_card_successfully_label": "แสดงการ์ดเรียบร้อย",
  "ss_builder_enable_item_checkbox_combined_successfully_label": "แสดงบล็อกที่สำเร็จ",
  "ss_builder_enable_item_checkbox_image_gallery_successfully_label": "แสดงภาพแกลเลอรี่สำเร็จ",
  "ss_builder_enable_item_checkbox_story_successfully_label": "แสดงสปอตไลต์สำเร็จ",
  "ss_builder_enable_product_group_successfully_label": "แสดงกลุ่มสินค้าสำเร็จ",
  "ss_builder_enable_product_successfully_label": "โชว์สินค้าสำเร็จ",
  "ss_builder_enable_shadow": "แสดง",
  "ss_builder_enable_social_link_successfully_label": "โชว์ลิงค์โซเชียลมีเดีย",
  "ss_builder_enable_tab_successfully_label": "แสดง Tab เรียบร้อย",
  "ss_builder_enable_theme_successfully_label": "แสดงเทมเพลตสำเร็จ",
  "ss_builder_enable_tracking_link_product_label": "เปิดใช้งาน tracking link",
  "ss_builder_enable_tracking_link_product_successfully_label": "เปิดใช้งาน tracking link สำเร็จ",
  "ss_builder_enter_domain_site_setting_label": "เส้นทางโดเมนส่วนตัว",
  "ss_builder_enter_font_name": "ใส่ชื่อฟอนต์",
  "ss_builder_enter_url_label": "ใส่ URL",
  "ss_builder_enter_your_url": "ใส่ URL ของคุณ",
  "ss_builder_error_block_label": "บล็อกผิดพลาด",
  "ss_builder_error_invalid_email_message": "อีเมลไม่ถูกต้อง",
  "ss_builder_error_invalid_phone_message": "หมายเลขโทรศัพท์ไม่ถูกต้อง",
  "ss_builder_error_network_confirm_btn_label": "ปิด",
  "ss_builder_error_network_content": "โปรดรอสักครู่ก่อนลองอีกครั้ง",
  "ss_builder_error_network_title": "ระบบโอเวอร์โหลด",
  "ss_builder_error_page_title": "Passio Page Builder - {{country}} - Error Page",
  "ss_builder_exceeding_label": "เกินจำนวนบล็อกสูงสุด",
  "ss_builder_existed_product_group_label": "กลุ่มนี้มีอยู่แล้ว",
  "ss_builder_expand": "ดูเพิ่มเติม",
  "ss_builder_expand_introduction_description": "\"ตำแหน่งที่ขยาย\" ประกอบด้วยบล็อกที่ไม่มีตำแหน่งที่แน่นอนบนไซต์ เช่น: ป๊อปอัพ ไอคอนลอย",
  "ss_builder_expand_introduction_title": "ตำแหน่งที่ขยาย",
  "ss_builder_filter_select_combined_count": "บล็อกที่เลือก",
  "ss_builder_filter_select_multi_items": "{{num_items}} ล่าสุด",
  "ss_builder_filter_select_multi_items_card_count": "การ์ดที่เลือก",
  "ss_builder_filter_select_multi_items_gallery_count": "รูปภาพที่เลือก",
  "ss_builder_filter_select_multi_items_multi_links_count": "ลิงค์ที่เลือก",
  "ss_builder_filter_select_multi_items_spotlight_count": "ไฟสปอร์ตไลท์ที่เลือก",
  "ss_builder_first_guide_add_block_btn_title": "เพิ่มบล็อก",
  "ss_builder_first_guide_add_block_description": "คลิกหรือลากและวางปุ่ม \"เพิ่มบล็อก\" ไปยังตำแหน่งที่ต้องการเพื่อเพิ่มบล็อกใหม่ลงในไซต์ของคุณ บล็อกหลายประเภทพร้อมที่จะเพิ่มลงในไซต์ของคุณ ช่วยให้คุณสร้างหน้าชีวประวัติที่สวยงามและอเนกประสงค์ มาสำรวจกันเถอะ!",
  "ss_builder_first_guide_add_block_title": "ปุ่มเพิ่มบล็อก",
  "ss_builder_first_guide_preview_btn_title": "ดูตัวอย่างไซต์",
  "ss_builder_first_guide_preview_description": "ดูตัวอย่างไซต์เพื่อดูการเปลี่ยนแปลงปัจจุบันของไซต์ ควรเผยแพร่เมื่อคุณได้ดูตัวอย่างและพอใจกับการเปลี่ยนแปลงแล้วเท่านั้น",
  "ss_builder_first_guide_preview_title": "ปุ่มแสดงตัวอย่างไซต์",
  "ss_builder_first_guide_publish_btn_title": "เผยแพร่",
  "ss_builder_first_guide_publish_description": "การแก้ไขการออกแบบจะอยู่ในโหมด \"ฉบับร่าง\" เสมอ การเปลี่ยนแปลงจะปรากฏให้แฟนๆ ของคุณได้เห็นเมื่อคุณตัดสินใจ \"เผยแพร่\"",
  "ss_builder_first_guide_publish_title": "ปุ่มเผยแพร่ไซต์",
  "ss_builder_floating_full": "สามารถเพิ่มสองบล็อกในกลุ่มไอคอนลอยได้เท่านั้น",
  "ss_builder_floating_introduction_description": "บล็อกที่อยู่ในตำแหน่งนี้จะลอยอยู่ด้านล่าง/ขวาของหน้าจอ แสดงเสมอเพื่อให้เข้าถึงได้ง่าย",
  "ss_builder_floating_introduction_title": "ไอคอนลอย",
  "ss_builder_font_name_not_exist": "ไม่มีฟอนต์นี้",
  "ss_builder_font_option_panel_title": "แบบอักษร",
  "ss_builder_footer_description": "บล็อกเหล่านี้จะปรากฏในทุกแท็บ",
  "ss_builder_footer_introduction_description": "“Footer” ประกอบด้วยบล็อกต่างๆ ซึ่งจะปรากฏที่ด้านล่างของหน้าไซต์เสมอ ตัวอย่างเช่น ลิงค์ย่อ ข้อมูลของไซต์...",
  "ss_builder_footer_introduction_title": "Footer Position",
  "ss_builder_footer_title": "Footer",
  "ss_builder_frame_aff": "เฟรมรูปสินค้า",
  "ss_builder_general_setting_title": "การตั้งค่าทั่วไป",
  "ss_builder_generate_income_from_your_own_fan_community": "สร้างรายได้จากชุมชนแฟนคลับของคุณเอง!",
  "ss_builder_gift_warning": "สามารถเพิ่มบล็อกเดียวเท่านั้น",
  "ss_builder_go_back_to_site": "กลับไปที่ไซต์หลัก",
  "ss_builder_google_font": "ฟอนต์ของกูเกิล",
  "ss_builder_gradient": "ไล่โทนสี",
  "ss_builder_group_is_full_of_product_modal_description": "กลุ่มสินค้าที่คุณเลือกเต็มแล้ว กรุณาเลือกกลุ่มอื่น",
  "ss_builder_group_is_full_of_product_modal_title": "เพิ่มไม่ได้",
  "ss_builder_group_is_hiding_label": "กลุ่มสินค้าที่ซ่อนอยู่",
  "ss_builder_hard_shadow": "ใส่เงาแบบหนา",
  "ss_builder_header_description": "บล็อกเหล่านี้จะปรากฏในทุกแท็บ",
  "ss_builder_header_introduction_description": "“Header” ประกอบด้วยบล็อก ซึ่งจะปรากฏที่ด้านบนสุดของหน้าไซต์เสมอ ตัวอย่างเช่นรูปโปรไฟล์ คำแนะนำ...",
  "ss_builder_header_introduction_title": "Header Position",
  "ss_builder_header_title": "Header",
  "ss_builder_heading_and_subtitle_site_setting_label": "หัวข้อหน้าและคำบรรยาย",
  "ss_builder_heading_site_setting_description": "แสดงโดยเครื่องมือค้นหาในหน้าตัวอย่างเพจของคุณ นี่เป็นสิ่งสำคัญสำหรับทั้ง SEO และการแบ่งปันทางโซเชียลมีเดีย",
  "ss_builder_heading_site_setting_label": "หัวข้อหน้า",
  "ss_builder_hide_block_label": "ซ่อนบล็อก",
  "ss_builder_hide_card_label": "ซ่อนการ์ด",
  "ss_builder_hide_card_successfully_label": "ซ่อนการ์ดสำเร็จ",
  "ss_builder_hide_image_label": "ซ่อนรูปภาพ",
  "ss_builder_hide_link_label": "ซ่อนลิงค์",
  "ss_builder_hide_link_successfully_label": "ซ่อนลิงค์เรียบร้อยแล้ว",
  "ss_builder_hide_product_group_error_btn_confirm_label": "ปิด",
  "ss_builder_hide_product_group_error_content": "กลุ่ม \"{{group_name}}\" ไม่สามารถซ่อนได้ ต้องแสดงอย่างน้อย 1 กลุ่ม",
  "ss_builder_hide_product_group_error_title": "เกิดข้อผิดพลาดในการซ่อนกลุ่ม",
  "ss_builder_hide_product_group_label": "ซ่อนกลุ่ม",
  "ss_builder_hide_product_label": "ซ่อนสินค้า",
  "ss_builder_hide_social_link_label": "ซ่อน",
  "ss_builder_hide_tab_label": "ซ่อนแท็บ",
  "ss_builder_hide_template_successfully_label": "ซ่อนเทมเพลตสำเร็จ",
  "ss_builder_hide_theme_template_label": "Hide template",
  "ss_builder_hightlight_block_label": "ฟีเจอร์",
  "ss_builder_icon_tab_title": "ไอคอน",
  "ss_builder_if_delete": "หากคุณลบบล็อก Affiliate นี้",
  "ss_builder_image": "ภาพ",
  "ss_builder_image_description_place_holder": "กรอกคอนเทนต์",
  "ss_builder_image_gallery": "แกลเลอรี่ภาพ",
  "ss_builder_image_list_empty_content": "หากต้องการเพิ่มรูปภาพใหม่ โปรดคลิกปุ่มด้านบน",
  "ss_builder_image_list_empty_title": "ไม่มีรายการรูปภาพ",
  "ss_builder_image_orientation": "การวางแนวภาพ",
  "ss_builder_image_orientation_label": "การวางแนวภาพ",
  "ss_builder_image_popup_introduction_description": "บล็อกที่อยู่ในตำแหน่งนี้จะแสดงเป็นป๊อปอัพเมื่อแฟนๆ ดูไซต์ของคุณ ป๊อปอัพสามารถใช้เพื่อแสดงข้อความสำคัญ คำกระตุ้นการตัดสินใจ ป้ายโฆษณา...",
  "ss_builder_image_popup_introduction_title": "Popup",
  "ss_builder_image_popup_warning": "คุณสามารถเพิ่มบล็อกประเภทนี้ได้เพียงบล็อกเดียวต่อหนึ่งหน้า",
  "ss_builder_image_ratio_title": "อัตราส่วนภาพ",
  "ss_builder_image_selection_label": "เลือกรูปภาพ",
  "ss_builder_image_slider_arrow_switch_title": "ลูกศร",
  "ss_builder_image_slider_auto_play_switch_title": "เล่นอัตโนมัติ",
  "ss_builder_image_slider_dot_switch_title": "Dot slider",
  "ss_builder_image_slider_list_title": "รายการรูปภาพ",
  "ss_builder_image_slider_ratio_selected_title": "อัตราส่วนภาพ",
  "ss_builder_image_title_place_holder": "กรอกคอนเทนต์",
  "ss_builder_import_multi_product_url_popover_content": "คุณสามารถป้อนหนึ่งหรือหลาย URL  ป้อนหนึ่ง URL ต่อบรรทัด",
  "ss_builder_import_site_url_label": "อิมพอร์ทสินค้าจาก URL",
  "ss_builder_import_site_url_modal_button_label": "เพิ่มสินค้า",
  "ss_builder_import_site_url_modal_description": "ใส่ URL เพื่ออิมพอร์ท ซัพพอร์ต: koc.asia  mycollection.shop, linktr.ee, msha.ke",
  "ss_builder_import_site_url_modal_title": "ลิงค์หน้าสินค้า",
  "ss_builder_in_block_delete_forever_are_you_sure": "จะถูกลบอย่างถาวร คุณแน่ใจหรือไม่ว่าต้องการลบ?",
  "ss_builder_information_handler_alert": "กำลังประมวลผลข้อมูล โปรดรอสักครู่",
  "ss_builder_inner_space": "ขนาดข้างใน",
  "ss_builder_intro_brand_gift_floating_continue": "เพิ่มเลย",
  "ss_builder_intro_brand_gift_floating_description": "เพิ่มไอคอน Brand Gift ทันทีเพื่อช่วยให้ผู้ติดตามค้นหาคูปองส่วนลดสุดฮิต",
  "ss_builder_intro_brand_gift_floating_reject": "ยกเลิก",
  "ss_builder_intro_brand_gift_floating_title": "เพิ่มรายได้ของคุณเป็นสองเท่าด้วยไอคอนลอย Brand Gift",
  "ss_builder_intro_brand_rating_floating_description": "th_Thêm tính năng ngay để giúp Fan săn deal thành công và tăng thu nhập",
  "ss_builder_intro_brand_rating_floating_title": "th_Brand Ratings",
  "ss_builder_introduce_double_tap_button": "แก้ไขต่อ",
  "ss_builder_introduce_double_tap_content": "นอกจากการเลือก “แก้ไข” ในแต่ละบล็อกแล้ว คุณยังสามารถดับเบิลคลิกที่บล็อกโดยตรงเพื่อแก้ไขได้อีกด้วย",
  "ss_builder_introduce_double_tap_label_check": "ไม่แสดงอีกครั้ง",
  "ss_builder_introduce_double_tap_title": "เคล็ดลับการแก้ไขบล็อก",
  "ss_builder_invalid_product_url_label": "ลิงค์ไม่ถูกต้อง",
  "ss_builder_invalid_url": "URL ไม่ถูกต้อง",
  "ss_builder_items_per_row": "จำนวนรายการต่อแถว",
  "ss_builder_keep_the_content": "เก็บเนื้อหา",
  "ss_builder_landscape_image_orientation_label": "แนวนอน",
  "ss_builder_limit_reached": "ถึงขีดจำกัดแล้ว",
  "ss_builder_link_additional_btn_label": "เพิ่มลิงค์",
  "ss_builder_link_select_service": "เลือกบริการ",
  "ss_builder_link_text_left_layout": "ข้อความด้านซ้าย",
  "ss_builder_link_text_right_layout": "ข้อความด้านขวา",
  "ss_builder_link_text_under_layout": "ข้อความด้านล่าง",
  "ss_builder_link_text_zig_zag_layout": "ซิกแซก",
  "ss_builder_link_type_email": "อีเมล",
  "ss_builder_link_type_phone": "โทรศัพท์",
  "ss_builder_link_type_service": "บริการ",
  "ss_builder_link_type_tel": "Tel",
  "ss_builder_link_type_web": "Web",
  "ss_builder_link_url": "ลิงค์ URL",
  "ss_builder_list_button_multi_link_label": "ลิส์ต",
  "ss_builder_list_card_empty_content": "หากต้องการเพิ่มการ์ด โปรดกดปุ่มด้านบนเพื่อเพิ่ม",
  "ss_builder_list_card_empty_title": "ยังไม่มีการเพิ่มแท็กเนื้อหา",
  "ss_builder_list_card_label": "รายการการ์ด",
  "ss_builder_list_image_popup_title": "รายการรูปภาพป๊อปอัพ",
  "ss_builder_list_label": "รายการ",
  "ss_builder_list_link_empty_content": "หากต้องการเพิ่มลิงค์ใหม่ โปรดคลิกปุ่ม \"เพิ่ม\" อยู่ด้านบน",
  "ss_builder_list_link_empty_title": "ยังไม่มีการเพิ่มลิงค์",
  "ss_builder_list_title_digital_product": "List",
  "ss_builder_loading_label": "กำลังโหลด",
  "ss_builder_malformed_domain_warning": "ชื่อโดเมนไม่ถูกต้อง",
  "ss_builder_malformed_embed_code_warning": "embed code ไม่ถูกต้อง",
  "ss_builder_manage_block_description": "Ss Builder Manage Block Description",
  "ss_builder_manage_block_title": "จัดการบล็อก",
  "ss_builder_manage_tab_description": "แท็บใช้เพื่อแบ่งรายการบล็อกออกเป็นหลายส่วน",
  "ss_builder_manage_tab_title": "การจัดการแท็บ",
  "ss_builder_management_digital_product_description": "List of your digital products",
  "ss_builder_management_digital_product_title": "Digital Product",
  "ss_builder_management_label": "Management",
  "ss_builder_medium": "กลาง",
  "ss_builder_missing_required_product_url_label": "ขาดข้อมูลที่จำเป็น",
  "ss_builder_modal_button_close": "ปิด",
  "ss_builder_move_block_successfully_label": "ย้ายบล็อกสำเร็จ",
  "ss_builder_move_down_block_label": "เลื่อนไปข้างล่าง",
  "ss_builder_move_product_label": "ย้ายไปที่",
  "ss_builder_move_product_quantity_to_group_alert_label": "ย้าย {{product_quantity}} สินค้าไปที่",
  "ss_builder_move_product_successfully_label": "ย้ายสินค้าเรียบร้อย",
  "ss_builder_move_to_bottom_label": "เลื่อนไปข้างล่างสุด",
  "ss_builder_move_to_top_label": "เลื่อนไปข้างบนสุด",
  "ss_builder_move_up_block_label": "เลื่อนไปข้างบน",
  "ss_builder_multi_product_url_imported_label": "เพิ่ม {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} ลิงค์แล้ว",
  "ss_builder_multi_product_url_importing_label": "กำลังเพิ่ม {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} ลิงค์",
  "ss_builder_name_tab_input_title": "ชื่อแท็บ",
  "ss_builder_need_to_display_at_least_one_block": "Need to display at least one block",
  "ss_builder_need_to_display_at_least_one_card": "ต้องการแสดงการ์ดอย่างน้อยหนึ่งใบ",
  "ss_builder_need_to_display_at_least_one_image": "ต้องการแสดงภาพอย่างน้อยหนึ่งภาพ",
  "ss_builder_need_to_display_at_least_one_link": "ต้องการแสดงอย่างน้อยหนึ่งลิงค์",
  "ss_builder_need_to_display_at_least_one_spotlight": "ต้องการแสดงสปอตไลต์อย่างน้อยหนึ่งดวง",
  "ss_builder_next": "ต่อไป",
  "ss_builder_no_image_selected": "รายการว่าง",
  "ss_builder_no_photo_has_been_selected": "ยังไม่ได้เลือกรูปภาพ",
  "ss_builder_no_shadow": "ไม่ใส่เงา",
  "ss_builder_no_themes_found_theme_store": "ไม่มีธีม",
  "ss_builder_none_of_image_description": "คลิกปุ่ม \"เพิ่ม\" เพื่อเพิ่มรูปภาพป๊อปอัพ",
  "ss_builder_none_of_image_popup_alert": "ไม่มีรูปภาพป๊อปอัพ",
  "ss_builder_not_add_yet_product_url_label": "ยังไม่ได้เพิ่มลิงค์",
  "ss_builder_not_enough_space_product_group_modal_content": "กลุ่มสินค้าที่คุณเลือกมีพื้นที่ไม่เพียงพอที่จะเพิ่มสินค้า {{num_products}} รายการในรายการสินค้า โปรดลบบางสินค้าออกจากรายการนี้หรือเลือกกลุ่มอื่น",
  "ss_builder_not_enough_space_product_group_modal_title": "ไม่สามารถเลือกกลุ่มได้",
  "ss_builder_notes": "อธิบายโดยละเอียดของข้อผิดพลาด",
  "ss_builder_off": "ปิด",
  "ss_builder_on": "เปิด",
  "ss_builder_onboarding_choose_a_suitable_theme": "จงเลือกธีมที่เหมาะสม คุณสามารถปรับแต่งได้หลังจากเลือกใช้✨",
  "ss_builder_onboarding_choose_the_look_for_you": "จงเลือกธีมที่เหมาะกับคุณ!",
  "ss_builder_onboarding_create_a_personal_page": "สร้างหน้าไบโอ ทำ Affiliate Marketing, Booking, Donation, ขายสินค้าดิจิทัล (เอกสาร, รูปภาพ, วิดีโอ, การนำเสนอ,...)",
  "ss_builder_onboarding_no_need_know_technology": "ไม่จำเป็นต้องมีความรู้เกี่ยวกับเทคโนโลยี ง่ายต่อการใช้ชุดเครื่องมือเพื่อสร้างหน้าไบโอ",
  "ss_builder_onboarding_theme_store_template": "คลังเก็บธีม",
  "ss_builder_open_browser_label": "เปิดในเบราว์เซอร์",
  "ss_builder_open_link_in_new_tab": "เปิดลิงก์ในแท็บใหม่",
  "ss_builder_open_link_in_new_tab_description": "เปิดลิงก์ในแท็บใหม่",
  "ss_builder_open_new_tab_description": "ยกเลิกการเลือกเพื่อเปิดลิงค์ในหน้าไซต์ปัจจุบัน กดเลือกเพื่อเปิดลิงก์ในหน้าต่างใหม่",
  "ss_builder_open_new_tab_label": "เปิดลิงค์ในหน้าต่างใหม่",
  "ss_builder_optional_color_panel_label": "ตัวเลือกสีอื่นๆ",
  "ss_builder_overwrite_description": "การเขียนทับจะแทนที่การตั้งค่าทั้งหมดของเทมเพลตที่เลือก",
  "ss_builder_overwrite_template_btn_label": "เขียนทับ",
  "ss_builder_overwrite_template_successfully_label": "ขียนทับเทมเพลตสำเร็จ",
  "ss_builder_overwrite_template_title": "เขียนทับเทมเพลต",
  "ss_builder_overwrite_theme_btn_label": "บันทึกทับ",
  "ss_builder_overwrite_theme_template_successfully_label": "บันทึกทับเทมเพลตธีมสำเร็จ",
  "ss_builder_overwrite_theme_template_title": "บันทึกทับเทมเพลตธีม",
  "ss_builder_overwrite_title": "คุณต้องการเขียนทับหรือไม่",
  "ss_builder_panel_add_icon_tab_title": "เลือกไอคอน",
  "ss_builder_panel_add_new_tab_title": "ข้อมูลของแท็บ",
  "ss_builder_panel_edit_text_block_title": "แก้ไขชื่อบล็อก",
  "ss_builder_panel_search_label": "ค้นหา...",
  "ss_builder_panel_title": "เลือกประเภทบล็อก",
  "ss_builder_paragraph_setting_font_available": "ฟอนต์ที่มีอยู่",
  "ss_builder_paragraph_setting_font_custom": "ฟอนต์ที่กำหนดเอง",
  "ss_builder_paragraph_setting_option": "ฟอนต์ที่เลือกเอง",
  "ss_builder_paragraph_setting_title": "การตั้งค่า",
  "ss_builder_partial_visible_slide_type_1": "มองเห็นได้บางส่วนทางด้านขวา",
  "ss_builder_partial_visible_slide_type_2": "มองเห็นได้บางส่วนทั้งสองด้าน",
  "ss_builder_partial_visible_slide_type_3": "ไม่สามารถมองเห็นได้บางส่วน",
  "ss_builder_partial_visible_slide_type_4": "Posters",
  "ss_builder_partial_visible_slide_type_5": "พาโนรามา",
  "ss_builder_passio_page_ready": "Passio Page พร้อมแล้ว",
  "ss_builder_passio_page_ready_description": "หน้า Passio Page พร้อมใช้งานและแทนหน้า Landing Page รุ่นเก่า",
  "ss_builder_passio_premium": "Passio Premium",
  "ss_builder_personal_link_coppy_label": "คัดลอก",
  "ss_builder_personal_link_coppy_successfully_label": "คัดลอกแล้ว",
  "ss_builder_personal_link_sharing_title": "แชร์ลิงค์เว็บไซต์ของคุณ",
  "ss_builder_personal_link_title": "URL เว็บไซต์ของคุณ",
  "ss_builder_phone_placeholder": "ป้อนหมายเลขโทรศัพท์",
  "ss_builder_placeholder_affiliate_product_enter_product_name": "ป้อนชื่อสินค้า",
  "ss_builder_placeholder_dragging_content": "วางที่นี่",
  "ss_builder_please_wait_for_the_product_to_be_updated": "รออัพเดทสินค้ากัน",
  "ss_builder_popover_manual_button": "เริ่มต้นใช้งาน",
  "ss_builder_popover_manual_button_close": "ปิด",
  "ss_builder_popover_manual_content": "สำหรับคู่มือการใช้ฟีเจอร์การออกแบบ โปรดกดปุ่ม \"เริ่มต้นใช้งาน\"",
  "ss_builder_popover_manual_title": "คู่มือการใช้",
  "ss_builder_popover_review_manual_content": "คุณสามารถอ่านคู่มือผู้ใช้ได้ทุกเมื่อโดยคลิกปุ่มความช่วยเหลือนี้",
  "ss_builder_popular_font_families": "แบบอักษรทั่วไป",
  "ss_builder_popup_confirm_button": "ยืนยัน",
  "ss_builder_popup_management_title": "Management",
  "ss_builder_portrait_image_orientation_label": "แนวตั้ง",
  "ss_builder_position_ghim_tab_selection_label": "ตำแหน่ง",
  "ss_builder_powered_by_passio": "Powered by Ecomobi",
  "ss_builder_preview": "แสดงตัวอย่าง",
  "ss_builder_preview_image_label": "ตัวอย่าง",
  "ss_builder_preview_theme_template_title": "ดูตัวอย่างรูปภาพ",
  "ss_builder_product_design_empty_description": "Please wait for the product to be updated",
  "ss_builder_product_design_empty_title": "ไม่มีสินค้า",
  "ss_builder_product_detect_link_malformed_error": "รูปแบบลิงค์ไม่ถูกต้อง",
  "ss_builder_product_detect_link_placeholder": "ป้อนลิงค์สินค้า",
  "ss_builder_product_detect_link_title": "ลิงค์สินค้า",
  "ss_builder_product_empty_searching_description": "โปรดลองใช้คีย์เวิร์ดอื่นๆ",
  "ss_builder_product_empty_searching_title": "ไม่พบสินค้า",
  "ss_builder_product_exceeding_allowed_amount_content": "หากต้องการเพิ่มสินค้าใหม่ โปรดลบสินค้าที่มีอยู่ออก",
  "ss_builder_product_exceeding_allowed_amount_title": "จำนวนสินค้าถึงขีดจำกัดแล้ว",
  "ss_builder_product_group_add_new_btn_label": "เพิ่มกลุ่มสินค้า",
  "ss_builder_product_group_default_title": "สินค้า",
  "ss_builder_product_group_limitation_warning_description": "The total number of products in the group has been reached the limit of {{max_products}} products. Please delete existing products if you want to add new ones.",
  "ss_builder_product_group_selection_placeholder": "กรุณาเลือกกลุ่มสินค้า",
  "ss_builder_product_group_selection_title": "เลือกกลุ่มสินค้า affiliate",
  "ss_builder_product_group_total_limitation_warning_content": "จำนวนกลุ่มทั้งหมดถึงขีดจำกัด {{group_quantity_maximum}} กลุ่มแล้ว โปรดลบกลุ่มที่มีอยู่ถ้าคุณต้องการเพิ่มกลุ่มใหม่",
  "ss_builder_product_group_total_limitation_warning_title": "ไม่สามารถเพิ่มกลุ่มสินค้าใหม่",
  "ss_builder_product_is_hiding_label": "Hidden product",
  "ss_builder_product_link_wrapped_label": "ลิงค์ย่อ",
  "ss_builder_product_list_style_grid_label": "แบบกริด",
  "ss_builder_product_list_style_grid_one_label": "1 สินค้า",
  "ss_builder_product_list_style_grid_three_label": "3 สินค้า",
  "ss_builder_product_list_style_grid_two_label": "2 สินค้า",
  "ss_builder_product_list_style_slide_label": "แบบเลื่อน",
  "ss_builder_product_list_style_text_over_image_label": "Text over image",
  "ss_builder_product_list_style_zig_zag_label": "Zigzag",
  "ss_builder_product_searching_description": "โปรดป้อนชื่อสินค้าเพื่อค้นหา",
  "ss_builder_product_searching_title": "ค้นหาสินค้า",
  "ss_builder_product_selection_option_1": "10 อันสุดท้าย",
  "ss_builder_product_selection_option_2": "20 อันสุดท้าย",
  "ss_builder_product_selection_option_3": "50 อันสุดท้าย",
  "ss_builder_product_selection_option_4": "5 อันสุดท้าย",
  "ss_builder_product_swiper_see_all": "ดูทั้งหมด",
  "ss_builder_product_title_error": "Missing product name",
  "ss_builder_product_title_missing_warning": "ป้อนชื่อสินค้า",
  "ss_builder_product_total_limitation_warning_description": "จำนวนสินค้าทั้งหมดในกลุ่มสินค้านี้ถึงขีดจำกัด {{max_products_all_groups}} อันแล้ว โปรดลบสินค้าที่มีอยู่ หากคุณต้องการเพิ่มสินค้าใหม่",
  "ss_builder_product_total_limitation_warning_title": "ไม่สามารถเพิ่มสินค้า",
  "ss_builder_product_tour_button_step_0": "เริ่มต้้นใช้งาน",
  "ss_builder_product_tour_content_step_0": "เริ่มต้นการทำตามคู่มือการใช้ของเราเพื่อสัมผัสกับฟีเจอร์ที่น่าสนใจของ Passio Page!",
  "ss_builder_product_tour_content_step_1": "สร้างสรรค์ด้วยหน้าจอการออกแบบ แก้ไขและจัดเรียงบล็อกตามสไตล์ของคุณเองได้อย่างง่ายดาย",
  "ss_builder_product_tour_content_step_10": "แทนที่เนื้อหาทั้งหน้าไซต์ของคุณเป็นเนื้อหาเดียวกันกับเทมเพลตตัวอย่างที่กำลังใช้ ช่วยให้เนื้อหาของหน้าไซต์ของคุณเหมือนกับเทมเพลตตัวอย่าง",
  "ss_builder_product_tour_content_step_11": "ดูตัวอย่างไซต์เพื่อดูการเปลี่ยนแปลงปัจจุบันของไซต์ ควรเผยแพร่เมื่อคุณได้ดูตัวอย่างและพอใจกับการเปลี่ยนแปลงแล้วเท่านั้น",
  "ss_builder_product_tour_content_step_12": "โดยการกดค้างไว้และลากเพื่อย้ายหรือใช้ \"ดำเนินการอย่างรวดเร็ว\" เพื่อเลื่อนบล็อกขึ้น/ลง",
  "ss_builder_product_tour_content_step_13": "เลือก \"แก้ไข\" ในส่วน \"ดำเนินการอย่างรวดเร็ว\" หรือกดที่บล็อกโดยตรงเพื่อแก้ไข",
  "ss_builder_product_tour_content_step_14": "เปลี่ยนภาพพื้นหลังอย่างรวดเร็วด้วยรูปภาพ สีทึบ หรือไล่ระดับสีที่เต็มไปด้วยบุคลิกภาพ",
  "ss_builder_product_tour_content_step_15": "ธีมที่หลากหลาย สวยงาม และได้รับความนิยมให้คุณเลือกใช้ได้อย่างอิสระ",
  "ss_builder_product_tour_content_step_16": "การตั้งค่าขั้นสูงช่วยปรับแต่งการออกแบบธีม เปลี่ยนชื่อโดเมน แบรนด์ส่วนบุคคล...",
  "ss_builder_product_tour_content_step_17": "คุณสามารถดูคู่มือการใช้อีกครั้งได้ทุกเมื่อ โดยการคลิกปุ่มช่วยเหลือตัวนี้",
  "ss_builder_product_tour_content_step_2": "บล็อกหลายประเภทพร้อมที่จะเพิ่มลงในไซต์ของคุณ ช่วยให้คุณสร้างหน้าชีวประวัติที่สวยงามและอเนกประสงค์ คลิกหรือลากและวางปุ่มนี้ไปยังตำแหน่งที่ต้องการเพื่อเพิ่มบล็อกใหม่ มาสำรวจกันเถอะ!",
  "ss_builder_product_tour_content_step_3": "แก้ไขตัวเลือกของแต่ละบล็อก ช่วยให้คุณปรับแต่งบล็อกได้ตามความต้องการ",
  "ss_builder_product_tour_content_step_4": "การแก้ไขการออกแบบจะอยู่ในโหมด \"ร่าง\" เสมอ การเปลี่ยนแปลงจะปรากฏให้แฟนๆ ของคุณได้เห็นเมื่อคุณตัดสินใจ \"เผยแพร่\"",
  "ss_builder_product_tour_content_step_5": "แท็บได้ใช้ในการแบ่ง \"Body\" ออกเป็นหลายส่วน เช่น แท็บสินค้า แท็บวิดีโอ แท็บรหัสส่วนลด ฯลฯ คลิกที่แต่ละแท็บเพื่อดูและเปลี่ยนเนื้อหาภายใน แก้ไขบล็อกนี้เพื่อเพิ่มใหม่ หรือจัดการแท็บ",
  "ss_builder_product_tour_content_step_6": "“Header” ประกอบด้วยบล็อก ซึ่งจะปรากฏที่ด้านบนสุดของหน้าไซต์เสมอ ตัวอย่างเช่น Avatar บทนำ...",
  "ss_builder_product_tour_content_step_7": "\"Body\" เป็นส่วนหลักของไซต์ ประกอบด้วยบล็อกต่างๆ และได้แบ่งออก นำทางโดยแท็บ ตัวอย่างเช่น: เนื้อหา รูปภาพ สินค้า ...",
  "ss_builder_product_tour_content_step_8": "“Footer” ประกอบด้วยบล็อกต่างๆ ซึ่งจะปรากฏที่ด้านล่างของหน้าไซต์เสมอ ตัวอย่างเช่น ลิงค์ย่อ ข้อมูลของหน้าไซต์...",
  "ss_builder_product_tour_content_step_9": "ใช้ฟีเจอร์นี้ในขณะการเปลี่ยนแปลงที่คุณได้ทำไปไม่ตรงใจคุณ ซึ่งระบบจะกู้คืนหน้าจอการออกแบบของคุณกลับเป็นเวอร์ชั่นไซต์ที่กำลังเผยแพร่",
  "ss_builder_product_tour_done_label": "เสร็จสิ้น",
  "ss_builder_product_tour_next_label": "ต่อไป",
  "ss_builder_product_tour_prev_label": "กลับ",
  "ss_builder_product_tour_skip_label": "ข้าม",
  "ss_builder_product_tour_title_step_0": "ยินดีต้อนรับเข้าสู่ Passio Page",
  "ss_builder_product_tour_title_step_1": "ออกแบบธีม",
  "ss_builder_product_tour_title_step_10": "ใช้เนื้อหาตัวอย่าง",
  "ss_builder_product_tour_title_step_11": "ดูตัวอย่างไซต์",
  "ss_builder_product_tour_title_step_12": "ย้ายบล็อก",
  "ss_builder_product_tour_title_step_13": "แก้ไขบล็อก",
  "ss_builder_product_tour_title_step_14": "เปลี่ยนภาพพื้นหลัง",
  "ss_builder_product_tour_title_step_15": "ร้านธีม",
  "ss_builder_product_tour_title_step_16": "การตั้งค่าขั้นสูง",
  "ss_builder_product_tour_title_step_17": "คู่มือการใช้",
  "ss_builder_product_tour_title_step_2": "เพิ่มบล็อกใหม่",
  "ss_builder_product_tour_title_step_3": "ดำเนินการอย่างรวดเร็ว",
  "ss_builder_product_tour_title_step_4": "เผยแพร่การออกแบบ",
  "ss_builder_product_tour_title_step_5": "แท็บเมนู",
  "ss_builder_product_tour_title_step_6": "Header",
  "ss_builder_product_tour_title_step_7": "Body",
  "ss_builder_product_tour_title_step_8": "Footer",
  "ss_builder_product_tour_title_step_9": "กู้คืนการแก้ไข",
  "ss_builder_product_tour_use_manual": "คู่มือการใช้",
  "ss_builder_product_tour_use_manual_content": "เพื่อดูคู่มือการใช้การออกแบบ โปรดกดปุ่ม “เริ่มการสำรวจ”",
  "ss_builder_product_url_lists_label": "ตารางลิงค์",
  "ss_builder_publish_button_label": "เผยแพร่",
  "ss_builder_publish_result_description": "Passio Page ของคุณสร้างเรียบร้อยแล้ว\nคัดลอกลิงค์เพจของคุณจากลิงค์ด้านล่าง",
  "ss_builder_publish_result_successfully": "เผยแพร่ธีมสำเร็จแล้ว!",
  "ss_builder_publish_result_title": "ผลลัพธ์การเผยแพร่",
  "ss_builder_publish_saving": "กำลังบันทึก",
  "ss_builder_publish_share": "แชร์",
  "ss_builder_publish_view_page": "ชมไซต์",
  "ss_builder_randomize": "สุ่มตัวอย่าง",
  "ss_builder_ready_to_build_your_personal_brand": "พร้อมสร้างแบรนด์ส่วนตัวของคุณด้วย Passio เลย 💖",
  "ss_builder_recently_color_panel_label": "สีที่ใช้ล่าสุด",
  "ss_builder_redo_theme_label": "ทำซ้ำ",
  "ss_builder_reload_label": "รีเฟรช",
  "ss_builder_replace": "แทนที่",
  "ss_builder_replace_preview_theme_template_btn_label": "ใช้",
  "ss_builder_replace_theme_label": "ใช้เนื้อหาตัวอย่าง",
  "ss_builder_replace_theme_modal_content": "ใช้เนื้อหาธีมเทมเพลตธีมจะแทนที่บล็อกทั้งหมดในไซต์ของคุณโดยบล็อกจากธีมธีมเทมเพลต",
  "ss_builder_replace_theme_modal_title": "คุณต้องการใช้ข้อมูลธีมตัวอย่างหรือไม่",
  "ss_builder_replace_theme_template_successfully_label": "นำมาใช้สำเร็จ",
  "ss_builder_report_sent_report_successfully": "ส่งข้อความแสดงข้อผิดพลาดสำเร็จ",
  "ss_builder_report_sent_report_successfully_description": "ขอขอบคุณ! ส่งข้อความแสดงข้อผิดพลาดไปยังฝ่ายสนับสนุนเรียบร้อยแล้ว",
  "ss_builder_restore_theme_content": "การตั้งค่าธีมและข้อมูลทั้งหมดของบล็อกภายในธีมจะถูกกู้คืนเป็นธีมที่เผยแพร่แล้ว คุณต้องการดำเนินการต่อหรือไม่",
  "ss_builder_restore_theme_label": "กู้คืนการแก้ไข",
  "ss_builder_restore_theme_successfully_label": "คืนค่าการแก้ไขเรียบร้อย",
  "ss_builder_restore_theme_title": "คุณต้องการกู้คืนการแก้ไขใช่หรือไม่",
  "ss_builder_revenue_group_description": "เริ่มสร้างรายได้ออนไลน์ตอนนี้ด้วยบริการของ Passio Page",
  "ss_builder_revenue_title": "สร้างรายได้",
  "ss_builder_round_photo_label": "ภาพกลม",
  "ss_builder_rounded": "โค้งมน",
  "ss_builder_sample_change_label": "เปลี่ยนเทมเพลต",
  "ss_builder_sample_selection_label": "เลือกตัวอย่าง",
  "ss_builder_sampling_change_label": "เลือกเทมเพลต",
  "ss_builder_save": "บันทึก",
  "ss_builder_save_new_template_title": "บันทึกเป็นเทมเพลตใหม่",
  "ss_builder_save_new_theme_template_successfully_label": "บันทึกธีมใหม่สำเร็จ",
  "ss_builder_save_new_theme_template_title": "บันทึกเทมเพลตธีมใหม่",
  "ss_builder_save_succesfully_description": "การเปลี่ยนแปลงบันทึกเรียบร้อยแล้ว",
  "ss_builder_save_succesfully_label": "บันทึกสำเร็จ",
  "ss_builder_search_results_theme_store": "ผลการค้นหา",
  "ss_builder_search_type_block": "ค้นหาประเภทบล็อกตามชื่อ",
  "ss_builder_see_less": "ดูน้อยลง",
  "ss_builder_see_more": "ดูเพิ่มเติม",
  "ss_builder_see_more_btn_default_title": "ดูเพิ่มเติม",
  "ss_builder_select_label": "เลือก",
  "ss_builder_select_link_type": "เลือกประเภทลิงค์",
  "ss_builder_select_multi_items": "เลือก",
  "ss_builder_select_position_add_block": "เลือกสถานทีเพื่อเพิ่มบล็อก",
  "ss_builder_select_ratio_title": "เลือกอัตราส่วน",
  "ss_builder_select_theme_setting_primary_color_label": "เลือกสีหลัก",
  "ss_builder_selected_product_in_selection_label": "สินค้าที่ได้เลือก",
  "ss_builder_send": "ส่ง",
  "ss_builder_send_report": "ส่งรายงานข้อผิดพลาด",
  "ss_builder_service_placeholder": "เลือกบริการ",
  "ss_builder_set_default_tab_disable_content": "Tab พื้นฐานจะแสดงขึ้นก่อนเมื่อผู้ใช้เข้าสู่หน้าของคุณ Tab ${defaultNameTab} ถูกซ่อนอยู่ ตั้งค่า Tab นี้เป็นค่าพื้นฐานจะเปลี่ยนสถานะ Tab นี้ให้มองเห็นได้ คุณต้องการตั้งค่า Tab \"{{tab_name}}\" เป็นค่าพื้นฐานหรือไม่",
  "ss_builder_set_default_tab_enable_content": "Tab พื้นฐานจะแสดงขึ้นก่อนเมื่อผู้ใช้เข้าสู่หน้าของคุณ คุณต้องการตั้งค่า Tab \"{{tab_name}}\" เป็นค่าพื้นฐานหรือไม่",
  "ss_builder_set_default_tab_label": "ตั้งค่าพื้นฐาน",
  "ss_builder_set_default_tab_successfully_label": "การตั้งพื้นฐานเรียบร้อย",
  "ss_builder_set_default_tab_title": "ยืนยันการตั้งค่าพื้นฐาน",
  "ss_builder_setting_font_weight": "น้ำหนัก font",
  "ss_builder_setting_line_height": "ความสูง",
  "ss_builder_setting_popover_manual_content": "ทำความรู้จักกับการตั้งค่า เรามากดปุ่ม “เริ่มต้นใช้งาน” กัน",
  "ss_builder_setting_popover_manual_title": "Product tour",
  "ss_builder_setting_saving": "กำลังบันทึกข้อมูล",
  "ss_builder_setting_text_font": "ฟอนต์อักษร",
  "ss_builder_setting_text_scale": "อัตรา",
  "ss_builder_setting_text_size": "ขนาด font (px)",
  "ss_builder_setting_title": "การตั้งค่า",
  "ss_builder_setting_tour_content_step_1": "นี่คือ URL ไซต์ของคุณ คุณสามารถคัดลอก เปิด หรือแชร์เส้นทางของหน้า นอกจากนี้ยังสามารถแก้ไข favicon ของหน้าและชื่อโดเมนได้อย่างรวดเร็ว",
  "ss_builder_setting_tour_content_step_2": "คุณสามารถปรับแต่งการตั้งค่าธีมทั่วไปได้ที่นี่ การตั้งค่าเหล่านี้จะนำไปใช้กับทั้งไซต์ของคุณ",
  "ss_builder_setting_tour_content_step_3": "คุณสามารถตั้งค่าการสร้างแบรนด์ส่วนบุคคล การตั้งค่า SEO ชื่อโดเมนสำหรับไซต์ของคุณได้ที่นี่",
  "ss_builder_setting_tour_title_step_1": "Site url",
  "ss_builder_setting_tour_title_step_2": "การตั้งค่าธีม",
  "ss_builder_setting_tour_title_step_3": "การตั้งค่าไซต์",
  "ss_builder_settings_label": "การตั้งค่า",
  "ss_builder_setup_site_setting": "Site settings",
  "ss_builder_setup_theme_setting": "Theme settings",
  "ss_builder_shadows_style": "ใส่เงา",
  "ss_builder_shape_label": "รูปทรง",
  "ss_builder_share_label": "แชร์",
  "ss_builder_show_block_label": "โชว์บล็อก",
  "ss_builder_show_card_label": "โชว์การ์ด",
  "ss_builder_show_card_successfully_label": "โชว์การ์ดสำเร็จ",
  "ss_builder_show_image_label": "แสดงรูปภาพ",
  "ss_builder_show_link_label": "แสดงลิงค์",
  "ss_builder_show_link_successfully_label": "แสดงลิงค์เรียบร้อยแล้ว",
  "ss_builder_show_product_group_label": "แสดงกลุ่ม",
  "ss_builder_show_product_label": "โชว์สินค้า",
  "ss_builder_show_social_link_label": "โชว์",
  "ss_builder_show_tab_label": "โชว์แท็บ",
  "ss_builder_show_template_successfully_label": "แสดงเทมเพลตสำเร็จ",
  "ss_builder_show_theme_template_label": "Show template",
  "ss_builder_site_brand_setting_label": "แบรนด์ส่วนตัว",
  "ss_builder_site_domain_setting_label": "โดเมน",
  "ss_builder_site_setting_browser_label": "เบราเซอร์",
  "ss_builder_site_setting_coppy_label": "คัดลอก",
  "ss_builder_site_setting_favicon_title": "Favicon",
  "ss_builder_site_setting_favicon_upload_description": "Favicon แสดงข้างลิงค์ของเพจ อัปโหลดภาพโปรดของคุณเพื่อสร้าง Favicon",
  "ss_builder_site_setting_label": "การตั้งค่าเว็บไซต์",
  "ss_builder_site_setting_premium": "พรีเมียม",
  "ss_builder_site_setting_share_label": "แชร์",
  "ss_builder_site_setting_standard": "มาตรฐาน",
  "ss_builder_site_setting_upgrade": "อัพปเกรด",
  "ss_builder_skip": "ข้าม",
  "ss_builder_slide_type_label": "แบบสไลด์",
  "ss_builder_social_link_btn_add_new_label": "เพิ่มลิงค์ช่องโซเชียลมีเดีย",
  "ss_builder_social_links_list_title": "รายการลิงค์ช่องโซเชียลมีเดีย",
  "ss_builder_social_network_label": "โซเชียลเน็ตเวิร์ก",
  "ss_builder_soft_shadow": "ใส่เงาแบบบาง",
  "ss_builder_solid": "สีทึบ",
  "ss_builder_solid_type_label": "ของแข็ง",
  "ss_builder_something_went_wrong": "ขอโทษ เกิดข้อผิดพลาด",
  "ss_builder_spacer_label": "ระยะห่าง (px)",
  "ss_builder_spotlight_list_empty_content": "หากต้องการเพิ่มสปอตไลท์ใหม่ โปรดกดปุ่ม \"เพิ่ม\" ด้านบน",
  "ss_builder_spotlight_list_empty_title": "ยังไม่มีการเพิ่มสปอตไลต์",
  "ss_builder_square": "สี่เหลี่ยม",
  "ss_builder_square_photo_label": "ภาพสี่เหลี่ยมจัตุรัส",
  "ss_builder_start_use_passio_page": "เริ่มใช้งานหน้า Passio Page ทันที!",
  "ss_builder_stop_label": "หยุด",
  "ss_builder_story": "สปอร์ตไลท์",
  "ss_builder_story_title_place_holder": "หัวข้อสปอร์ตไลท์",
  "ss_builder_style": "สไตล์",
  "ss_builder_style_always_ghim_tab_label": "ปักหมุดเสมอ",
  "ss_builder_style_auto_ghim_tab_label": "ปักหมุดอัตโนมัติ",
  "ss_builder_style_ghim_tab_selection_label": "ปักหมุดแท็บ",
  "ss_builder_style_grid_label": "แบบกริด",
  "ss_builder_style_masonry_label": "Masonry",
  "ss_builder_style_not_ghim_tab_label": "ไม่ปักหมุด",
  "ss_builder_style_slide_label": "แบบเลื่อน",
  "ss_builder_style_tab_icon_label": "ไอคอนเท่านั้น",
  "ss_builder_style_tab_only_icon": "ใช้ไอคอนเท่านั้น",
  "ss_builder_style_tab_only_image": "ใช้รูปภาพเท่านั้น",
  "ss_builder_style_tab_selection_label": "สไตล์",
  "ss_builder_style_tab_text_and_icon_label": "ข้อความ&ไอคอน",
  "ss_builder_style_tab_text_and_image_label": "Text & image",
  "ss_builder_style_tab_text_label": "ข้อความเท่านั้น",
  "ss_builder_subtitle_site_setting_description": "เว็บไซต์ให้ภาพรวมของเนื้อหาและข้อมูลเพื่อเครื่องมือค้นหานำไปแสดงคำอธิบายเว็บไซต์",
  "ss_builder_subtitle_site_setting_label": "คำบรรยาย",
  "ss_builder_suggested_color_panel_label": "จานสีที่แนะนำ",
  "ss_builder_support_upload_image": "สนับสนุน: JPG, JPEG, GIF, PNG, BMP, WebP, up to 30MB",
  "ss_builder_support_upload_image_v2": "สนับสนุน: JPG, JPEG, GIF, PNG, BMP up to 30MB",
  "ss_builder_support_upload_large_image": "รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP, up to 50MB",
  "ss_builder_swiper_setting_premium_description_1": "ฟีเจอร์ไม่จำกัด",
  "ss_builder_swiper_setting_premium_title_1": "แอคเคาท์พรีเมียม",
  "ss_builder_swiper_setting_premium_title_2": "ธีมพิเศษ",
  "ss_builder_swiper_setting_premium_title_3": "กำหนดโดเมน yourdomain.com",
  "ss_builder_swiper_setting_premium_title_4": "แบรนด์แคมเปญพิเศษ",
  "ss_builder_swiper_setting_premium_title_5": "ถอนอย่างรวดเร็ว",
  "ss_builder_swiper_setting_premium_title_6": "ประหยัดค่าธรรมเนียมแพลตฟอร์ม 25%",
  "ss_builder_tab_layout_1": "ข้อความด้านขวา",
  "ss_builder_tab_layout_2": "ข้อความด้านล่าง",
  "ss_builder_tabs_label": "แท็บ",
  "ss_builder_template_overwrite_title": "เทมเพลตการเขียนทับ",
  "ss_builder_template_search_bar_placeholder": "ค้นหาเทมเพลตตามชื่อ...",
  "ss_builder_template_store_instruction_subtitle": "ข้อมูลและการตั้งค่าของเทมเพลตบล็อกที่เลือกจะถูกนำไปใช้กับบล็อกปัจจุบัน",
  "ss_builder_template_store_instruction_title": "โปรดเลือกเทมเพลตบล็อกที่คุณต้องการ",
  "ss_builder_template_title": "เทมเพลต",
  "ss_builder_text_editor_max_length": "ป้อนอักขระสูงสุด {{editor_max_length}} ตัว",
  "ss_builder_text_spin_loading": "Loading page",
  "ss_builder_the_application_of_the_template_will": "แอปพลิเคชันของเทมเพลตจะ",
  "ss_builder_theme_big_button_setting": "ปุ่มใหญ่",
  "ss_builder_theme_button_setting_general": "ทั่่วไป",
  "ss_builder_theme_display_setting": "การตั้งค่าทั่ั่วไป",
  "ss_builder_theme_medium_button_setting": "ปุ่มกลาง",
  "ss_builder_theme_setting_background": "พื้นหลัง",
  "ss_builder_theme_setting_background_color_preview_title": "ดูตัวอย่าง",
  "ss_builder_theme_setting_background_color_selector_label": "สี",
  "ss_builder_theme_setting_background_content": "ขนาดไฟล์สูงสุด: 30MB.\nรูปแบบไฟล์: jpg, jpeg, gif, png, bmp, webp",
  "ss_builder_theme_setting_background_content_v2": "ขนาดไฟล์สูงสุด: 30MB.\nรูปแบบไฟล์: jpg, jpeg, gif, png, bmp",
  "ss_builder_theme_setting_background_image_preview_label": "ภาพพื้นหลัง",
  "ss_builder_theme_setting_background_image_selector_label": "ภาพ",
  "ss_builder_theme_setting_background_image_title": "ภาพพื้นหลัง",
  "ss_builder_theme_setting_block_background": "สีพื้นหลัง",
  "ss_builder_theme_setting_block_border": "ขอบ",
  "ss_builder_theme_setting_block_shadow": "เงา",
  "ss_builder_theme_setting_block_style": "สไตล์",
  "ss_builder_theme_setting_button": "ปุ่ม",
  "ss_builder_theme_setting_font_title": "การตั้งค่าข้อความ",
  "ss_builder_theme_setting_font_type_content": "เลือกแบบอักษรที่คุณนำไปใช้กับทั้งเว็บไซต์ของคุณ",
  "ss_builder_theme_setting_font_type_title": "แบบอักษร",
  "ss_builder_theme_setting_gadient_color": "สี",
  "ss_builder_theme_setting_general": "ทั่วไป",
  "ss_builder_theme_setting_gradient_color_direction_label": "ทิศทาง",
  "ss_builder_theme_setting_hard_shadow": "ใส่เงาแบบหนา",
  "ss_builder_theme_setting_intensity_color_label": "ความเข้มของสี",
  "ss_builder_theme_setting_label": "การตั้งค่าธีม",
  "ss_builder_theme_setting_no_shadow": "ไม่ใส่เงา",
  "ss_builder_theme_setting_paragraph": "ย่อหน้า",
  "ss_builder_theme_setting_primary_color": "สี",
  "ss_builder_theme_setting_primary_color_content": "เลือกสีหลักที่คุณนำไปใช้กับทั้งเว็บไซต์ของคุณ",
  "ss_builder_theme_setting_primary_color_title": "สีหลัก",
  "ss_builder_theme_setting_save_failed_content": "เกิดความผิดพลาดอย่างไม่ได้คาดคิด. กรุณาลองอีกครั้ง.",
  "ss_builder_theme_setting_save_failed_title": "ข้อผิดพลาด",
  "ss_builder_theme_setting_save_successfully_label": "บันทึกการตั้งค่าเรียบร้อย",
  "ss_builder_theme_setting_select_background_color_label": "เลือกประเภทสี",
  "ss_builder_theme_setting_select_gradient_color_label": "เลือกการไล่ระดับสี",
  "ss_builder_theme_setting_select_solid_color_label": "เลือกสี",
  "ss_builder_theme_setting_shadow": "ใส่เงา",
  "ss_builder_theme_setting_shadow_color": "สีเงา",
  "ss_builder_theme_setting_soft_shadow": "ใส่เงาแบบบาง",
  "ss_builder_theme_setting_text": "ปุ่มข้อความ",
  "ss_builder_theme_setting_title": "หัวข้อ",
  "ss_builder_theme_small_button_setting": "ปุ่มเล็ก",
  "ss_builder_theme_store_label": "Template",
  "ss_builder_theme_store_popover_manual_content": "ทำความรู้จักกับการใช้ Theme store กดปุ่ม “เริ่มต้นใช้งาน”",
  "ss_builder_theme_store_popover_manual_title": "Product tour",
  "ss_builder_theme_store_tour_content_step_1": "ค้นหาธีมอย่างรวดเร็วโดยใช้ชื่อธีม",
  "ss_builder_theme_store_tour_content_step_2": "Passio Page มีเทมเพลตธีมมากมายให้คุณเลือก ลองใช้เทมเพลตที่คุณต้องการสัมผัส",
  "ss_builder_theme_store_tour_title_step_1": "ค้นหาเทมเพลตธีม",
  "ss_builder_theme_store_tour_title_step_2": "เทมเพลตธีม",
  "ss_builder_theme_template_format_create_label": "สร้าง",
  "ss_builder_theme_template_label_tag": "แท็ก",
  "ss_builder_theme_template_label_tag_placeholder": "ป้อนแท็ก",
  "ss_builder_theme_template_no_option": "ไม่มีตัวเลือก",
  "ss_builder_theme_template_preview_info": "ดูตัวอย่างไซต์ของคุณหลังจากใช้เทมเพลตธีม",
  "ss_builder_theme_template_preview_mode_title": "โหมดดูตัวอย่าง",
  "ss_builder_theme_template_search_bar_placeholder": "ค้นหาเทมเพลตธีมตามชื่อ...",
  "ss_builder_theme_template_store_instruction_subtitle": "การตั้งค่าธีมของเทมเพลตธีมที่เลือกจะถูกนำไปใช้กับทั้งไซต์",
  "ss_builder_theme_template_store_instruction_title": "โปรดเลือกธีมที่คุณต้องการ!",
  "ss_builder_theme_template_store_title": "Theme store",
  "ss_builder_theme_template_theme_store": "{{num_results}} ธีมตัวอย่าง(s)",
  "ss_builder_theme_template_view_theme_info": "เทมเพลตธีมพร้อมข้อมูลตัวอย่าง",
  "ss_builder_theme_template_view_theme_title": "ดูเทมเพลต",
  "ss_builder_title": "หัวข้อ",
  "ss_builder_title_block_affiliate_product": "Affiliate",
  "ss_builder_title_block_bio": "Bio",
  "ss_builder_title_block_booking_profile": "Booking Profile",
  "ss_builder_title_block_booking_profile_description": "เพิ่มลิงก์ Booking Profile ของคุณหรือแสดง Booking Profile ในหน้า Passio",
  "ss_builder_title_block_booking_profile_warning": "สร้าง Booking Profile  เพื่อใช้บล็อคนี้",
  "ss_builder_title_block_brand_gift": "Brand Gift",
  "ss_builder_title_block_brand_gift_description": "แสดงไอคอนของขวัญจากแบรนด์เพื่อช่วยให้แฟนๆ เข้าถึงและสั่งซื้อได้อย่างรวดเร็ว",
  "ss_builder_title_block_brand_gift_footer": "ไอคอน Brand Gift",
  "ss_builder_title_block_brand_rating": "Brand Ratings",
  "ss_builder_title_block_brand_rating_description": "th_Bật tính năng để tăng tương tác với Fan",
  "ss_builder_title_block_button_link": "ลิงค์",
  "ss_builder_title_block_button_multi_link": "มัลติลิงก์",
  "ss_builder_title_block_card": "การ์ด",
  "ss_builder_title_block_digital_product": "Digital Product",
  "ss_builder_title_block_donation": "Donation",
  "ss_builder_title_block_embed": "Embed",
  "ss_builder_title_block_image": "ภาพ",
  "ss_builder_title_block_image_gallery": "แกลเลอรี่ภาพ",
  "ss_builder_title_block_image_popup": "รูปภาพป๊อปอัพ",
  "ss_builder_title_block_image_slider": "Image Slider",
  "ss_builder_title_block_story": "สปอร์ตไลท์",
  "ss_builder_title_block_tab_bar": "Tab Menu",
  "ss_builder_title_block_text": "ข้อความ",
  "ss_builder_title_block_truth_or_dare": "Truth Or Dare",
  "ss_builder_title_block_truth_or_dare_footer": "Truth Or Dare Icon",
  "ss_builder_title_block_video_shoutout": "Video Shoutout",
  "ss_builder_title_block_video_shoutout_description": "ของขวัญวิดีโอจากไอดอลของคุณ",
  "ss_builder_title_block_virtual_gift": "Virtual Gift",
  "ss_builder_title_block_virtual_gift_footer": "Virtual Gift Icon",
  "ss_builder_title_brand_gift_footer": "ไอคอน Brand Gift",
  "ss_builder_title_color": "สี",
  "ss_builder_title_combined": "บล็อกรวม",
  "ss_builder_title_introduction_service": "ข้อมูลบริการ",
  "ss_builder_title_page_builder": "Passio Page Builder",
  "ss_builder_title_setting_title": "การตั้งค่าหัวข้อ",
  "ss_builder_title_spacer_and_divider": "Spacer & Divider",
  "ss_builder_to_add_products_please_press_the_add_button_above": "หากต้องการเพิ่มสินค้าใหม่ โปรดกดปุ่ม \"เพิ่ม\" ด้านบน",
  "ss_builder_to_new_version": "ไปยังหน้า Passio Page",
  "ss_builder_top_position_tab_label": "บน",
  "ss_builder_transparent": "ความโปร่งใส",
  "ss_builder_truth_or_dare_warning": "สามารถเพิ่มบล็อกเดียวเท่านั้น",
  "ss_builder_try_another_keyword_theme_store": "ไม่พบผลลัพธ์ กรุณาลองใช้คีย์เวิร์ดอื่น",
  "ss_builder_try_new_key": "โปรดลองใช้คีย์เวิร์ดอื่นๆ",
  "ss_builder_type_label": "ชนิด",
  "ss_builder_type_of_color": "ประเภทสี",
  "ss_builder_undo_theme_label": "เลิกทำ",
  "ss_builder_update_account": "อัปเกรดบัญชี",
  "ss_builder_upload_button_label": "อัพโหลดภาพ",
  "ss_builder_upload_file_format_invalid_content": "Invalid image format. Supported format: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_file_format_invalid_content_for_gif_png_svg": "รูปแบบของภาพไม่ถูกต้อง โปรดเลือกรูปแบบใดรูปแบบหนึ่ง: GIF, PNG, SVG",
  "ss_builder_upload_file_format_invalid_content_v2": "Invalid image format. Supported format: JPG, JPEG, GIF, PNG, BMP",
  "ss_builder_upload_file_size_invalid_content": "โปรดเลือกไฟล์ที่มีขนาดสูงสุด 30MB",
  "ss_builder_upload_file_size_invalid_content_min_256": "โปรดเลือกรูปภาพที่มีขนาดขั้นต่ำ 256x256px",
  "ss_builder_upload_large_file_format_invalid_content": "รูปแบบไม่ถูกต้อง รูปแบบที่รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_large_file_size_invalid_content": "โปรดเลือกไฟล์ที่มีขนาดสูงสุด 50MB",
  "ss_builder_uploading_image": "อัปโหลดรูปภาพ",
  "ss_builder_url_error_content": "รับข้อมูลไม่ได้ กรุณาตรวจสอบลิงค์อีกครั้ง",
  "ss_builder_url_placeholder": "ป้อน URL",
  "ss_builder_use_advanced_setting_description": "เปิดใช้งานการตั้งค่าขั้นสูงเพื่อปรับแต่งการตั้งค่าการแสดงผลของบล็อกรวมนี้",
  "ss_builder_use_affiliate_link_description": "ติ๊กเพื่อใช้ Affiliate Link ของ Ecomobi Passio ลบติ๊กเพื่อใช้ลิงค์ต้นฉบับ",
  "ss_builder_use_affiliate_link_label": "ใช้ลิงค์ติดตามของ Ecomobi Passio",
  "ss_builder_use_ecomobi_tracking_link": "ใช้ลิงค์ติดตามของ Ecomobi Passio",
  "ss_builder_validate_gen_link_error_E1": "โทเค็นไม่ถูกต้อง",
  "ss_builder_validate_gen_link_error_E2": "บัญชีของคุณถูกล็อค คุณจะไม่ได้รับค่าคอมมิชชั่น",
  "ss_builder_validate_gen_link_error_E3": "ลิงค์นี้ไม่ได้รับการสนับสนุนการทำแอฟฟิลิเอท คุณจะไม่ได้รับค่าคอมมิชชั่น",
  "ss_builder_validate_gen_link_error_E5": "ลิงค์นี้จะไม่สร้างค่าคอมมิชชันเนื่องจากแคมเปญไม่ได้รับการอนุมัติ คุณต้องส่งคำขอเพื่อเข้าร่วมแคมเปญ",
  "ss_builder_validate_gen_link_error_E6": "ลิงค์นี้จะไม่สร้างค่าคอมมิชชันเนื่องจากคุณถูกบล็อกจากแคมเปญนี้",
  "ss_builder_validate_gen_link_error_E7": "ไม่สามารถสร้างลิงค์ย่อเนื่องจากข้อผิดพลาดของระบบ",
  "ss_builder_validate_gen_link_error_E8": "ลิงก์นี้จะไม่สร้างค่าคอมมิชชันเนื่องจากแคมเปญหยุดชั่วคราว",
  "ss_builder_video_shoutout_answer_method_description": "หากคุณยอมรับคำขอของแฟน ๆ ผู้สร้างคุณต้องตอบกลับด้วยวิดีโอ",
  "ss_builder_video_shoutout_answer_method_title": "วิธีการตอบ",
  "ss_builder_video_shoutout_btn_setup": "ตั้งไว้ตอนนี้",
  "ss_builder_video_shoutout_des_intro_fan": "คุณมี 7 วันในการตัดสินใจหรือปฏิเสธคำขอของแฟน ๆ หากคุณปฏิเสธที่จะทำ Ecomobi จะคืนเงินให้กับผู้ร้องขอภายใน 30 วัน",
  "ss_builder_video_shoutout_des_intro_gift": "ผ่าน Passio แฟน ๆ ของคุณสามารถส่งข้อกำหนดวิดีโอให้คุณในโอกาสพิเศษ: วันเกิด, ขอแสดงความยินดี, วันครบรอบ, การให้กำลังใจวิดีโอ, แรงบันดาลใจ ... ด้วยค่าธรรมเนียมบางอย่างที่ออกแบบด้วยตัวเอง",
  "ss_builder_video_shoutout_des_intro_policy": "คุณและแฟน ๆ สามารถแบ่งปันวิดีโอที่มีความหมายเหล่านี้กับผู้คนผ่านเว็บไซต์เครือข่ายสังคมออนไลน์",
  "ss_builder_video_shoutout_how_to_work": "วิดีโอ Shoutout ทำงานอย่างไร?",
  "ss_builder_video_shoutout_introduction": "Video Shoutout เป็นบริการที่อนุญาตให้แฟน ๆ ส่งไปยังข้อกำหนดของผู้สร้างสำหรับวิดีโอส่วนบุคคลสำหรับโอกาสเฉพาะเช่นวันเกิดการสำเร็จการศึกษาขอแสดงความยินดี ... ผู้สร้างจะตอบสนองต่อข้อกำหนดเหล่านั้นด้วยหนึ่งกับหนึ่งกับหนึ่งกับหนึ่งวิดีโอ.",
  "ss_builder_video_shoutout_participate_description": "ผู้สร้างสามารถเลือกที่จะสร้างหรือปฏิเสธข้อกำหนดของแฟน ๆ",
  "ss_builder_video_shoutout_participate_title": "สิทธิ์ในการเข้าร่วมกับคุณ",
  "ss_builder_video_shoutout_response_description": "หลังจากแฟน ๆ ส่งคำขอผู้สร้างมีเวลา 7 วันในการทำวิดีโอ หลังจากเวลานี้จำนวนคำขอนั้นจะถูกส่งกลับไปยังแฟน ๆ",
  "ss_builder_video_shoutout_response_title": "เวลาตอบสนอง",
  "ss_builder_video_shoutout_title_intro": "การแนะนำบริการ",
  "ss_builder_video_shoutout_title_intro_fan": "ทำวิดีโอ",
  "ss_builder_video_shoutout_title_intro_gift": "รับข้อกำหนดจากแฟน ๆ",
  "ss_builder_video_shoutout_title_intro_policy": "แบ่งปันช่วงเวลาที่มีความหมาย",
  "ss_builder_virtual_gift_list_title": "รายการของขวัญ",
  "ss_builder_wait_for_saving": "กระบวนการบันทึกยังไม่เสร็จสมบูรณ์ โปรดรอสักครู่.",
  "ss_builder_warning_brand_gift_floating_continue": "ดำเนินการต่อ",
  "ss_builder_warning_brand_gift_floating_description": "ตำแหน่งไอคอนลอยของคุณเต็มแล้ว คุณต้องลบบล็อกในกลุ่มไอคอนลอยในตำแหน่งขยาย ดู",
  "ss_builder_warning_brand_gift_floating_description_link": "คำแนะนำโดยละเอียด",
  "ss_builder_warning_brand_gift_floating_reject": "ยกเลิก",
  "ss_builder_warning_brand_gift_floating_title": "ตำแหน่งไอคอนลอยเต็ม",
  "ss_builder_warning_brand_gift_rating_description": "th_Hiện tại thân trang đã đạt tới giới hạn hiển thị. Bạn cần xoá bớt khối để bật tính năng Brand Ratings",
  "ss_builder_warning_brand_rating_floating_continue": "th_Đã hiểu",
  "ss_builder_warning_brand_rating_floating_title": "th_Brand Ratings",
  "ss_builder_web_browser": "เว็บเบราว์เซอร์",
  "ss_builder_web_label": "Ss Builder Web Label",
  "ss_builder_website_config_site_title_description": "แสดงที่ด้านบนสุดของหน้าเสมอเมื่อแฟน ๆ เยี่ยมชม",
  "ss_builder_website_config_site_title_label": "ชื่อเว็บไซต์",
  "ss_builder_website_config_site_title_placeholder": "ป้อนเนื้อหา",
  "ss_builder_what_field_are_you_operating_in": "คุณทำงานด้านไหน",
  "ss_builder_what_went_wrong": "เนื้อหาข้อความแสดงข้อผิดพลาด",
  "ss_builder_width_tab_setting_label": "Tab width",
  "ss_builder_width_tab_type_1": "อัตโนมัติ",
  "ss_builder_width_tab_type_2": "แบ่งเท่ากัน",
  "ss_builder_your_page_is_ready": "หน้าไบโอของคุณพร้อมแล้ว!",
  "ss_cancle_delete": "Cancel",
  "ss_challenge": "การท้าทาย",
  "ss_confirm_delete": "Confirm delete",
  "ss_content_confirm_delete": "Are you sure you want to delete this product?",
  "ss_content_delete_forever": "ในการลบผลิตภัณฑ์โปรดกลับไปที่หน้าการจัดการผลิตภัณฑ์",
  "ss_content_intro_dp": "Digital products are understood as online products, not grasped, containing useful content for users. For example: Video, photos, ebook, online course, ..",
  "ss_content_item1_dp": "Depending on your strengths and desires, Passio offers a full range of digital products such as photos, videos, online courses, ebooks, links, ...",
  "ss_content_item2_dp": "With less than 3 minutes, you can configure and sell a new digital product. Moreover, after the buyer successfully buys, the product will be sent immediately to their email, helping to simplify shipping and save time",
  "ss_content_success_TOD": "คุณได้ติดตั้งคุณสมบัติ Truth หรือ Dare สำเร็จ",
  "ss_cover_image": "ภาพหน้าปก",
  "ss_cover_image_empty": "ยังไม่ได้เลือกรูปภาพ",
  "ss_cover_image_helper": "สนับสนุน: JPG, JPEG, GIF, PNG, BMP, WebP, max 4MB",
  "ss_cover_image_upload": "อัพโหลดรูปภาพ",
  "ss_delete_forever": "ถูกลบอย่างถาวร",
  "ss_delete_product": "Delete display",
  "ss_display_delete": "Delete display",
  "ss_donation_currency_shortcut": "฿",
  "ss_donation_setting_default_content_setup_donation": "บริการนี้ได้ตั้งค่าโดยอัตโนมัติ หากคุณต้องการเปลี่ยนแปลง โปรดไปยังส่วนโดเนทที่ account.passio.eco",
  "ss_donation_setting_default_title_setup_donation": "ตั้งค่าบริการ",
  "ss_dp_add": "Add",
  "ss_dp_cat_course": "คอร์ส",
  "ss_dp_cat_ebook_document": "Ebook/Document",
  "ss_dp_cat_online_consultion": "Online consulting 1-1",
  "ss_dp_cat_random_product": "Products any",
  "ss_dp_cat_ticket_account": "ตั๋ว / แอคเคาท์",
  "ss_dp_cat_video": "Exclusive video",
  "ss_dp_sell_photo": "รูป",
  "ss_edit_TOD": "แก้ไข",
  "ss_example_TOD": "ตัวอย่าง: 100,000",
  "ss_exit_TOD": "ย้อนกลับ",
  "ss_first_person": "เป็นคนแรกที่จะท้าทายในขณะนี้!",
  "ss_gift_button_preview": "ส่งของขวัญทันที!",
  "ss_gift_invitation": "เพื่อให้ {user} มีแรงจูงใจมากขึ้นมอบของขวัญทันที",
  "ss_had": "มีอยู่แล้ว",
  "ss_iframe_add_new_product": "เพิ่มผลิตภัณฑ์ใหม่",
  "ss_iframe_add_product": "เพิ่มผลิตภัณฑ์",
  "ss_iframe_choose_product_from_store": "เลือกจากคลังข้อมูล",
  "ss_inline_image_empty": "คุณไม่สามารถเว้นว่างฟิลด์นี้",
  "ss_inline_txtbtn_max_length": "ฟิลด์นี้เกิน 40 ตัวอักษร",
  "ss_input_placehoder": "ป้อนเนื้อหา",
  "ss_invitation_TOD": "เนื้อหาข้อเสนอเชิญชวนดำเนินการท้าทาย",
  "ss_invitation_TOD_placeholder": "กรอกเนื้อหาเชิญแฟนๆทุกคน เข้าร่วม",
  "ss_logout": "ออกจากระบบ",
  "ss_modal_cancel_confirm": "ยกเลิก",
  "ss_modal_text_confirm": "ไปที่หน้าการบริหาร",
  "ss_no": "ไม่",
  "ss_placeholder_message_TOD": "กรอกเนื้อหาความท้าทาย",
  "ss_preview_TOD": "ดำเนินการต่อ",
  "ss_profile": "ไฟล์",
  "ss_push_TOD": "อัพโหลด",
  "ss_rount_tod": "รอบท้าทาย",
  "ss_service_introduction": "แนะนำบริการ",
  "ss_setting_TOD_background_tooltip": "เลือกภาพพื้นหลังด้วยอัตราส่วน 3: 2 และความจุสูงสุด 4 MB",
  "ss_setting_TOD_field_image_background": "เพิ่มวอลล์เปเปอร์",
  "ss_setting_TOD_field_image_background_bottom": "ภาพพื้นหลัง",
  "ss_setting_TOD_field_welcome_tooltip": "เนื้อหาของคำเรียกให้แฟนๆทุกคน มาท้าทายจะกรอกโดยอัตโนมัติหากคุณเว้นว่างไว้",
  "ss_setup_now": "Set now",
  "ss_shoutout_btn_content": "เนื้อหาปุ่ม",
  "ss_shoutout_change_file": "เปลี่ยนรูปภาพ/วิดีโอ",
  "ss_shoutout_create_request": "สร้างทันที",
  "ss_shoutout_description": "อธิบาย",
  "ss_shoutout_description_placeholder": "ป้อนเนื้อหาบทนำ",
  "ss_shoutout_error_price_required": "กรุณากรอกราคา",
  "ss_shoutout_error_upload": "เกิดข้อผิดพลาด อัพโหลดไม่สำเร็จ",
  "ss_shoutout_error_video_size": "กรุณาอัปโหลดรูปภาพ/ วิดีโอสูงสุด 500MB",
  "ss_shoutout_error_video_type": "โปรดอัปโหลดไปยังไฟล์เป็นหนึ่งใน JPG, JPEG, GIF, PNG, BMP, Webp, Heic, Heic, Heic, MP4, MOV",
  "ss_shoutout_file_intro": "การแนะนำรูปภาพ/วิดีโอ",
  "ss_shoutout_file_intro_note": "อัปโหลดรูปภาพหรือวิดีโอเพื่อโทรหาแฟน ๆ ให้ใช้วิดีโอ Shoutout จากคุณ",
  "ss_shoutout_no_file_selected": "ไม่มีการเลือกรูปภาพ/วิดีโอ",
  "ss_shoutout_price": "ราคา",
  "ss_shoutout_price_placeholder": "ป้อนราคา",
  "ss_shoutout_select_file": "เลือกรูปภาพ/วิดีโอ",
  "ss_shoutout_type_support": "การสนับสนุน: JPG, JPEG, GIF, PNG, BMP, Webp, Heif, Heic, MP4, Mov สูงสุด 500MB",
  "ss_shoutout_upload_success": "อัปโหลดวิดีโอสู่ความสำเร็จ",
  "ss_success_TOD": "ประสบความสำเร็จ",
  "ss_success_off_TOD": "คุณได้ปิดคุณสมบัติ Truth หรือ Dare สำเร็จ",
  "ss_success_on_TOD": "คุณได้เปิดคุณสมบัติ Truth หรือ Dare สำเร็จ",
  "ss_success_setup_TOD": "คุณได้ติดตั้งคุณสมบัติ Truth หรือ Dare สำเร็จ",
  "ss_title_intro_dp": "What is digital product",
  "ss_title_item1_dp": "Diverse types of products for you to choose",
  "ss_title_item2_dp": "Easy to manage products and orders with a few operations",
  "ss_tod_challenge_number": "th_จำนวนคำท้า",
  "ss_tod_content": "เนื้อหาคำเชิญ",
  "ss_tod_dare_content": "เชิญชวนแฟนๆ มาส่งคำท้า",
  "ss_tod_dare_fee": "ยอดเงินขั้นต่ำที่แฟนจ่ายให้ออร์เดอร์ Dare",
  "ss_tod_default_content": "ลองเลย กลัวอะไรเอ่ย",
  "ss_tod_default_dare_content": "แกล้งได้ แกล้งไป! ส่งข้อท้าทายมาให้ฉันสิ",
  "ss_tod_min_price_dare_alias": "ยอดเงินขั้นต่ำที่แฟนจ่ายให้ออร์เดอร์ Dare",
  "ss_tod_min_price_dare_note": "ชำระอย่างน้อย 1 บาท",
  "ss_tod_setting_cover_image": "ภาพหน้าปก",
  "ss_tod_setting_cover_image_helper": "สนับสนุน: JPG, JPEG, GIF, PNG, BMP, WebP, max 4MB",
  "ss_tod_setting_field_welcome_tooltip": "เนื้อหาของคำเรียกให้แฟนๆทุกคน มาท้าทายจะกรอกโดยอัตโนมัติหากคุณเว้นว่างไว้",
  "ss_tod_setting_input_placehoder": "ป้อนเนื้อหา",
  "ss_tod_tooltip_content": "เนื้อหาของข้อเสนอสูงสุด 60 อักขระ",
  "ss_tod_tooltip_min_price_dare": "เนื้อหาคำเชิญมีความยาวไม่เกิน 60 ตัวอักษร",
  "ss_txt_btn_TOD": "แฟนๆทุกคน ส่งความท้าทายมาได้เลย",
  "ss_validate_domain_error_E1": "ชื่อโดเมนเปลี่ยนภายใน 30 วัน ไม่สามารถเปลี่ยนได้ในขณะนี้",
  "ss_validate_domain_error_E2": "มีชื่อโดเมนนี้แล้ว โปรดลองใช้ชื่ออื่น",
  "ss_validate_domain_error_E3": "ชื่อโดเมนนี้ถูกแบน",
  "ss_validate_origin_url_error_E203": "ไม่พบชื่อโดเมนนี้ กรุณาตรวจสอบและลองอีกครั้ง",
  "ss_validate_origin_url_error_E5": "คุณยังไม่ได้อนุมัติเพื่อเข้าร่วมแคมเปญนี้ โปรดขออนุมัติแคมเปญ",
  "ss_validate_origin_url_error_E6": "คุณไม่สามารถเข้าร่วมในแคมเปญนี้ โปรดตรวจสอบกับเจ้าหน้าที่ฝ่ายสนับสนุน",
  "ss_validate_origin_url_error_E7": "ระบบไม่รองรับ URL นี้",
  "ss_validate_origin_url_error_E9": "แคมเปญสิ้นสุดลงแล้ว โปรดเลือก URL อื่น",
  "ss_validate_origin_url_error_V0": "URL ไม่ถูกต้อง",
  "ss_validate_origin_url_error_sys": "โปรดกรอก URL ที่ถูกต้อง",
  "ss_validate_origin_url_warning_E5": "คุณยังไม่ได้รับการอนุมัติสำหรับแคมเปญนี้ คำสั่งซื้อและค่าคอมมิชชั่นจะไม่ถูกบันทึก",
  "ss_validate_origin_url_warning_E7": "ไม่รองรับลิงค์นี้ คำสั่งซื้อและค่าคอมมิชชั่นจะไม่ถูกบันทึก",
  "ss_validate_origin_url_warning_E9": "แคมเปญนี้สิ้นสุดแล้ว คำสั่งซื้อและค่าคอมมิชชั่นจะไม่ถูกบันทึก",
  "text_cashback_brand_gift": "Cashback",
  "text_custom_brand_gift": "ปรับแต่ง",
  "text_voucher_brand_gift": "Voucher",
  "undefined": "Undefined",
  "voucher_default_text_button": "รับเวาเชอร์ของคุณตอนนี้!",
  "voucher_default_title": "แตะเพื่อจ่าย รับเงินคืน 5%",
  "warning_text_TOD": "การตั้งค่าของคุณยังไม่ได้รับการบันทึก คุณยังต้องการออกหรือไม่?",
  "warning_text_switch_TOD": "คุณต้องการปิดคุณสมบัติ Truth หรือ Dare หรือไม่"
} 
} 
export default th