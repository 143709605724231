import { InformationTriangleIcon } from "@share/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-1 items-center justify-center text-center py-[7px] px-4 bg-[#FF8F1F] rounded-full max-w-fit m-auto">
      <InformationTriangleIcon
        className="w-4 h-4"
        fillColor="#FFFFFF"
      />
      <span className="text-[#FFFFFF] text-[13px] leading-[131%] font-medium">
        {t("ss_builder_error_block_label")}
      </span>
    </div>
  );
};

export default ErrorBlock;
