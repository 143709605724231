const  en =  {
 translation: {
  "button_confirm_exit_TOD": "Exit",
  "button_confirm_off_TOD": "Turn off",
  "button_title_default_donation": "Make a donation to {name}",
  "cashback_default_text_button": "Get a refund now!",
  "cashback_default_title": "Shopping spree, juicy cashback",
  "cm_home": "Home",
  "default_content_setup_brand_gift": "Choose a template or create your own",
  "default_content_setup_donation": "This service is being set as default. If you want to make changes, please access the Donation service here: account.passio.eco",
  "default_title_setup_brand_gift": "Settings",
  "default_title_setup_donation": "Service Settings",
  "error_thumbnail_size_TOD": "Image exceeds size limit",
  "invitation_default_donation": "How much do you love {name}?",
  "label_tab_bar_brand_gift": "Template",
  "label_text_button_brand_gift": "Button",
  "label_text_title_brand_gift": "Title",
  "login": "Login",
  "message_default_donation": "Thank you for your donation to {name}. This would contribute a lot to the improvement of my upcoming content.",
  "placeholder_text_button_brand_gift": "Type the button's text",
  "placeholder_text_title_brand_gift": "Type your Title",
  "ss_background_TOD": "Wallpaper",
  "ss_brand_cpc_bonus": "en_Hoa hồng",
  "ss_brand_cpc_campaign_title": "en_Nhận hoa hồng",
  "ss_brand_cpc_description": "en_Mô tả chương trình",
  "ss_brand_cpc_download": "en_Tải xuống",
  "ss_brand_cpc_from": "en_Từ",
  "ss_brand_cpc_join": "en_Tham gia",
  "ss_brand_cpc_join_now": "en_Tham khảo ngay",
  "ss_brand_cpc_join_successfully_label": "en_Bạn đã tham gia chương trình thành công",
  "ss_brand_cpc_no_access_allow": "en_Bạn không được phép truy cập vào link này",
  "ss_brand_cpc_notice": "en_Lưu ý",
  "ss_brand_cpc_notice_content": "en_Hoa hồng sẽ được trả về tài khoản của bạn sau 7 ngày kể từ khi được nghiệm thu.",
  "ss_brand_cpc_reject": "en_Ảnh nghiệm thu của bạn đã bị từ chối. Bạn có thể tải lại ảnh.",
  "ss_brand_cpc_requirement": "en_Yêu cầu",
  "ss_brand_cpc_step_1": "en_Bước 1: Tải ảnh dưới đây về và up lên 1 nền tảng mạng xã hội.",
  "ss_brand_cpc_step_1_note": "en_Lưu ý: up ảnh trong khoảng thời gian",
  "ss_brand_cpc_step_2": "en_Bước 2: Tải ảnh nghiệm thu (ảnh chụp màn hình bài đăng của bạn ở bước 1)",
  "ss_brand_cpc_step_2_note": "en_Lưu ý: ảnh nghiệm thu thể hiện thời gian đã đăng ảnh tối thiểu là 12h. VD: Ảnh chụp màn hình story đã đăng 12h trước trở lên.",
  "ss_brand_cpc_success": "en_Đã nghiệm thu thành công",
  "ss_brand_cpc_text_content": "en_đang gửi cho bạn 01 lời đề nghị tham gia vào chương trình treo banner - nhận tiền thưởng.",
  "ss_brand_cpc_text_title": "en_Nhận tiền dễ dàng cùng",
  "ss_brand_cpc_time": "en_Thời gian",
  "ss_brand_cpc_time_end": "en_Thời gian kết thúc",
  "ss_brand_cpc_time_start": "en_Thời gian bắt đầu",
  "ss_brand_cpc_to": "en_Đến",
  "ss_brand_cpc_upload_image": "en_Tải ảnh nghiệm thu",
  "ss_brand_cpc_upload_image_button": "en_Tải ảnh lên",
  "ss_brand_cpc_upload_image_successfully": "en_Bạn đã gửi ảnh nghiệm thu thành công",
  "ss_brand_cpc_upload_image_title": "en_Tải ảnh nghiệm thu",
  "ss_brand_gift_cancel_hide": "en_Hủy bỏ",
  "ss_brand_gift_confirm_hide_brand": "en_Xác nhận",
  "ss_brand_gift_confirm_hide_group": "en_Xác nhận ẩn nhóm",
  "ss_brand_gift_disable_deal_successfully": "en_Ẩn Deal thành công",
  "ss_brand_gift_disable_successfully": "en_Ẩn nhóm sản phẩm thành công",
  "ss_brand_gift_display_hide_brand": "en_Ẩn Deal",
  "ss_brand_gift_display_hide_category": "en_Ẩn nhóm sản phẩm",
  "ss_brand_gift_display_show_brand": "en_Hiện Deal",
  "ss_brand_gift_display_show_category": "en_Hiện nhóm sản phẩm",
  "ss_brand_gift_empty_title": "en_Chưa có sản phẩm nào",
  "ss_brand_gift_enable_deal_successfully": "en_Hiện Deal thành công",
  "ss_brand_gift_enable_successfully": "en_Hiện nhóm sản phẩm thành công",
  "ss_brand_gift_hide_brand": "en_Bạn có muốn ẩn Deal?",
  "ss_brand_gift_hide_brand_content": "en_Deal {{name}} sẽ không hiển thị ở giao diện của Fan. Bạn có muốn ẩn deal này?",
  "ss_brand_gift_hide_category": "en_Bạn muốn ẩn nhóm?",
  "ss_brand_gift_hide_category_content": "en_Nhóm {{name}} và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị ẩn. Bạn có chắc muốn ẩn nhóm?",
  "ss_brand_gift_list_category": "Product group list",
  "ss_brand_gift_list_empty_content": "en_Hiện giờ Admin chưa setup dữ liệu sản phẩm",
  "ss_brand_gift_setting_cashback_default_text_button": "Get a refund now!",
  "ss_brand_gift_setting_cashback_default_title": "Shopping spree, juicy cashback",
  "ss_brand_gift_setting_default_content_setup": "Choose a template or create your own",
  "ss_brand_gift_setting_float_icon_description": "Save floating icon setting in order to increase interaction with your Fan",
  "ss_brand_gift_setting_float_icon_footer_description": "Enable feature to increase interaction with your Fan",
  "ss_brand_gift_setting_label_tab_bar_brand_gift": "Template",
  "ss_brand_gift_setting_label_text_button": "Button",
  "ss_brand_gift_setting_label_text_title_brand_gift": "Title",
  "ss_brand_gift_setting_placeholder_text_button": "Type the button's text",
  "ss_brand_gift_setting_placeholder_text_title": "Type your Title",
  "ss_brand_gift_setting_text_cashback_brand_gift": "Cashback",
  "ss_brand_gift_setting_text_custom_brand_gift": "Customize",
  "ss_brand_gift_setting_text_voucher_brand_gift": "Voucher",
  "ss_brand_gift_setting_title_block_footer": "Brand Gift Icon",
  "ss_brand_gift_setting_voucher_default_text_button": "Get your voucher now!",
  "ss_brand_gift_setting_voucher_default_title": "Tap pay, 5% cashback",
  "ss_brand_rating_setting_default_content_setup_donation": "en_Danh sách các chương trình hoàn tiền giảm giá dành cho fan của bạn. Bật tính năng để nhận hoa hồng ngay!",
  "ss_build_collapse_block_label": "Collapse",
  "ss_build_expand_block_label": "Expand",
  "ss_build_no_blocks_have_been_added": "No block has been added yet",
  "ss_builde_theme_setting_block": "Block",
  "ss_builder_1st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block.",
  "ss_builder_1st_block_preview_sample_title": "Block 1",
  "ss_builder_2st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block. 2nd block to see the spacing between blocks.",
  "ss_builder_2st_block_preview_sample_title": "Block 2",
  "ss_builder_add_affiliate_product_list_btn_label": "Add more product",
  "ss_builder_add_block_body_title": "Body",
  "ss_builder_add_block_expand_title": "Extended Position",
  "ss_builder_add_block_footer_title": "Footer",
  "ss_builder_add_block_header_title": "Header",
  "ss_builder_add_block_in_template": "Add block template",
  "ss_builder_add_block_in_template_successfully": "Add block template successfully",
  "ss_builder_add_block_label": "Add block",
  "ss_builder_add_block_to": "Add block to:",
  "ss_builder_add_image_of_image_popup_tilte": "Add image popup",
  "ss_builder_add_new_affiliate_product_btn_label": "Add new product",
  "ss_builder_add_new_affiliate_product_group_btn_label": "Add product group",
  "ss_builder_add_new_affiliate_product_image_title": "Product image",
  "ss_builder_add_new_affiliate_product_list_empty_content": "To add new product, please enter product url below",
  "ss_builder_add_new_affiliate_product_name_title": "Product name",
  "ss_builder_add_new_affiliate_product_title": "Add new product",
  "ss_builder_add_new_block_successfully_label": "Add new block successfully",
  "ss_builder_add_new_image": "Add new image",
  "ss_builder_add_new_image_of_image_popup_title": "Image popup",
  "ss_builder_add_new_image_of_image_slider_title": "Image",
  "ss_builder_add_new_image_popup_btn_label": "Add",
  "ss_builder_add_new_image_slider_title": "Add image",
  "ss_builder_add_new_label": "Add",
  "ss_builder_add_new_product_group_successfully_label": "Add new product group successfully",
  "ss_builder_add_new_tab_successfully_label": "Add new tab successfully",
  "ss_builder_add_new_tag_error_duplicate": "Tag is already existed",
  "ss_builder_add_new_template_btn_label": "Save",
  "ss_builder_add_new_template_image_title": "Template image",
  "ss_builder_add_new_template_label": "Create new template",
  "ss_builder_add_new_template_name_placeholder": "Enter template name",
  "ss_builder_add_new_template_name_title": "Template name",
  "ss_builder_add_new_theme_btn_label": "Create new",
  "ss_builder_add_new_theme_selection_panel_description": "Choose a method to create a Theme Template to publish to the Theme Store",
  "ss_builder_add_new_theme_selection_panel_label": "Create the theme template",
  "ss_builder_add_new_theme_template_image_title": "Theme template image",
  "ss_builder_add_new_theme_template_name_placeholder": "Enter the theme template name",
  "ss_builder_add_new_theme_template_name_title": "Theme template name",
  "ss_builder_add_story": "Add new",
  "ss_builder_add_tab_label": "Add Tab",
  "ss_builder_added_product_url_label": "Link has been added",
  "ss_builder_advanced_group_description": "Advanced blocks",
  "ss_builder_advanced_setting": "Advanced settings",
  "ss_builder_advanced_title": "Advanced",
  "ss_builder_aff_frame_max_size_content": "Max file size: 30MB.",
  "ss_builder_aff_frame_select_from_library": "Select from library",
  "ss_builder_aff_frame_support_type_file_content": "Support file format: gif, png, svg",
  "ss_builder_affiliate_add_new_tour_content_step_1": "Select a product group to categorize your products. The products below will be added to the group you choose.",
  "ss_builder_affiliate_add_new_tour_content_step_2": "Enter the link to the product, the system will automatically get the product information.",
  "ss_builder_affiliate_add_new_tour_content_step_3": "You can change the name, image, label... of product.",
  "ss_builder_affiliate_add_new_tour_content_step_4": "Click “Add” button to add the above product list to the group.",
  "ss_builder_affiliate_add_new_tour_title_step_1": "Select product group",
  "ss_builder_affiliate_add_new_tour_title_step_2": "Link to product",
  "ss_builder_affiliate_add_new_tour_title_step_3": "Product information",
  "ss_builder_affiliate_add_new_tour_title_step_4": "Add new products",
  "ss_builder_affiliate_group_tour_content_step_1": "Add product groups to categorize your product categories",
  "ss_builder_affiliate_group_tour_content_step_2": "Editing and options of each group, allowing you to customize the block as you like.",
  "ss_builder_affiliate_group_tour_title_step_1": "Add product groups",
  "ss_builder_affiliate_group_tour_title_step_2": "Quick Actions",
  "ss_builder_affiliate_product_btn_see_more_placeholder": "Enter content",
  "ss_builder_affiliate_product_btn_see_more_title": "Show more button label",
  "ss_builder_affiliate_product_clean_up_popover_content": "This product will not be cleaned up automatically when the product group is full",
  "ss_builder_affiliate_product_cleanup_lock_label": "Lock cleanup",
  "ss_builder_affiliate_product_cleanup_unlock_label": "Unlock cleanup",
  "ss_builder_affiliate_product_frame_panel_title": "Select a frame",
  "ss_builder_affiliate_product_group_add_new_panel_title": "Group information",
  "ss_builder_affiliate_product_group_list_selector_label": "Product group list",
  "ss_builder_affiliate_product_group_management_title": "Product group management",
  "ss_builder_affiliate_product_group_other": "Other",
  "ss_builder_affiliate_product_group_placeholder": "Enter block title",
  "ss_builder_affiliate_product_group_quantity_label": "{{product_quantity}} products",
  "ss_builder_affiliate_product_group_quantity_warning_1": "Couldn't add",
  "ss_builder_affiliate_product_group_quantity_warning_2": "Exceeded the amount available",
  "ss_builder_affiliate_product_group_title": "Block title",
  "ss_builder_affiliate_product_image_popup_infomation_label": "Image is automatically crawl from your link. You can change to another image.",
  "ss_builder_affiliate_product_label_product_discount": "Sale",
  "ss_builder_affiliate_product_label_product_hot": "Hot",
  "ss_builder_affiliate_product_label_product_like": "Favorite",
  "ss_builder_affiliate_product_label_product_title": "Product labels",
  "ss_builder_affiliate_product_list_empty_content": "To add products please press the “Add” button above",
  "ss_builder_affiliate_product_list_empty_title": "No products have been added yet",
  "ss_builder_affiliate_product_list_title": "List",
  "ss_builder_affiliate_product_moving_panel_title": "Move product",
  "ss_builder_affiliate_product_quantity_on_row_title": "No. of products per row",
  "ss_builder_affiliate_product_searching_placeholder": "Search product by name...",
  "ss_builder_affiliate_product_style_title": "Style",
  "ss_builder_affiliate_setting_popover_manual_content": "Get to know how to use the Affiliate Block, let's press the “Get Started” button.",
  "ss_builder_affiliate_setting_popover_manual_title": "Product tour",
  "ss_builder_affiliate_setting_tour_content_step_1": "Perform operations such as Hide/Show, Delete blocks.",
  "ss_builder_affiliate_setting_tour_content_step_2": "Set Title and Description for the block, the information will be displayed respectively after saving.",
  "ss_builder_affiliate_setting_tour_content_step_3": "Select the display style of the products in the block. After changing and saving the block you will see the changed display style in the design screen.",
  "ss_builder_affiliate_setting_tour_content_step_4": "Create new and manage Product Groups to divide the product list into groups. Fans will easily categorize and filter products by group. For example: Clothing, Shoes, Accessories,...",
  "ss_builder_affiliate_setting_tour_content_step_5": "Easy access to each product group to add new and manage products within the group.",
  "ss_builder_affiliate_setting_tour_content_step_6": "Add new products to each group by clicking the “Add” button.",
  "ss_builder_affiliate_setting_tour_content_step_7": "Quick options for each product allow quick manipulation of the products in the list.",
  "ss_builder_affiliate_setting_tour_content_step_8": "After editing, adding content and products to your block, press the “Save” button to save and will see the changes in the “Design” screen.",
  "ss_builder_affiliate_setting_tour_title_step_1": "Quick Actions",
  "ss_builder_affiliate_setting_tour_title_step_2": "Title and Description",
  "ss_builder_affiliate_setting_tour_title_step_3": "Product display configuration",
  "ss_builder_affiliate_setting_tour_title_step_4": "Product group management",
  "ss_builder_affiliate_setting_tour_title_step_5": "Product Groups",
  "ss_builder_affiliate_setting_tour_title_step_6": "Add products",
  "ss_builder_affiliate_setting_tour_title_step_7": "Product options",
  "ss_builder_affiliate_setting_tour_title_step_8": "Save products",
  "ss_builder_affiliate_upload_product_error_content": "Uploaded file need to be a json file with compatible format",
  "ss_builder_affiliate_upload_product_error_label": "Invalid file",
  "ss_builder_affiliate_upload_product_label": "Upload a product file",
  "ss_builder_all_setting_and_product": "all settings and all products inside",
  "ss_builder_all_the_blocks_are_in_your_list_by_the_blocks_of_the_template": "all the blocks are in your list by the blocks from the template",
  "ss_builder_allowed_domain_label": "The domain name does not exist, you can use this domain name",
  "ss_builder_an_unexpected_error_has_occurred": "An unexpected error has occurred.",
  "ss_builder_apply_preview_theme_template_btn_label": "Apply",
  "ss_builder_apply_template_successfully_label": "Apply template successfully",
  "ss_builder_apply_template_title": "Do you want to apply this template?",
  "ss_builder_apply_theme_premium_warning": "Theme for paid account only. Please upgrade your account to apply this template.",
  "ss_builder_apply_theme_premium_warning_title": "Do you want to apply this theme?",
  "ss_builder_apply_theme_template_successfully_label": "Apply theme template successfully",
  "ss_builder_auto_cleanup_products_warning_content_1": "Exceeded the number of products. The system will automatically cleanup the products from the bottom of the list when you add new ones.",
  "ss_builder_auto_cleanup_products_warning_content_2": "Note: Products that are on “Cleanup Lock” will not be automatically cleaned.",
  "ss_builder_back": "Back",
  "ss_builder_background_block": "Background",
  "ss_builder_background_enable_overlay": "Overlay",
  "ss_builder_background_opacity": "Blur",
  "ss_builder_background_overlay_color": "Overlay color",
  "ss_builder_background_popover_manual_content": "Get to know how to use the Background settings, let's press the “Get Started” button.",
  "ss_builder_background_popover_manual_title": "Product tour",
  "ss_builder_background_setting_title": "Background Settings",
  "ss_builder_background_tour_content_step_1": "Preview area for the settings below, real time update for any change.",
  "ss_builder_background_tour_content_step_2": "You can set site background using \"Image\" or \"Color\". With color, you can choose \"Solid\" or \"Gradient\" color.",
  "ss_builder_background_tour_title_step_1": "Preview area",
  "ss_builder_background_tour_title_step_2": "Background by Image or Color",
  "ss_builder_basic_group_description": "Basic blocks",
  "ss_builder_basic_title": "Basic",
  "ss_builder_bio_add_social_link_title": "Add social link",
  "ss_builder_bio_avatar_title": "Avatar",
  "ss_builder_bio_layout_title": "Layout",
  "ss_builder_bio_name_sub_placeholder": "Enter the sub title",
  "ss_builder_bio_name_title": "Display name",
  "ss_builder_bio_name_title_placeholder": "Enter the display name",
  "ss_builder_bio_social_link_btn_select_icon_label": "Select an icon",
  "ss_builder_bio_social_link_icon_title": "Icon",
  "ss_builder_bio_social_link_url_title": "Enter the url",
  "ss_builder_bio_sub_title": "Sub title",
  "ss_builder_bio_text_title": "Bio text",
  "ss_builder_block_affiliate_description": "Recommend Affiliate Products to your Fans",
  "ss_builder_block_background_blur": "Background blur",
  "ss_builder_block_background_color": "Background color",
  "ss_builder_block_bio_description": "Your profile picture, bio text and your social links",
  "ss_builder_block_block_card_description": "List of images with additional information in a flexible and slideable layout",
  "ss_builder_block_booking_file_alert_content": "Please create a Booking Profile before setting up",
  "ss_builder_block_booking_file_alert_title": "You have not created Booking Profile",
  "ss_builder_block_booking_file_button_label_default": "Working Profile",
  "ss_builder_block_booking_file_label": "Booking Profile url",
  "ss_builder_block_booking_file_link_tooltip": "The Booking Profile block will link to this url",
  "ss_builder_block_booking_file_switch_tooltip": "Display a link to your Booking Profile in the Bio area",
  "ss_builder_block_booking_profile_button_label": "Button label",
  "ss_builder_block_booking_profile_setting_description": "Description",
  "ss_builder_block_booking_profile_setting_title": "Title",
  "ss_builder_block_border_color": "Border Color",
  "ss_builder_block_border_style": "Style",
  "ss_builder_block_brand_gift_description": "Save floating icon setting in order to increase interaction with your Fan",
  "ss_builder_block_brand_gift_footer_description": "Enable feature to increase interaction with your Fan",
  "ss_builder_block_button_link_description": "Single button link to link to your favorite place on the internet",
  "ss_builder_block_button_multi_description": "Multi button links to link to your favorite places on the internet",
  "ss_builder_block_button_story_description": "Display featured links on your site with images and text",
  "ss_builder_block_combined_description": "Combine existing blocks into a new block type",
  "ss_builder_block_combined_description_label": "Combine existing blocks into a new block type",
  "ss_builder_block_combined_description_placeholder": "Enter content",
  "ss_builder_block_combined_heading_label": "Block title",
  "ss_builder_block_description": "Block description",
  "ss_builder_block_description_label": "Description",
  "ss_builder_block_description_placeholder": "Enter content",
  "ss_builder_block_design_hiding_label": "Hidden Block",
  "ss_builder_block_digital_product_description": "Sell your Digital Product on Passio Page",
  "ss_builder_block_donation_description": "Receive donations from Fans",
  "ss_builder_block_heading_label": "Block title",
  "ss_builder_block_heading_placeholder": "Enter content",
  "ss_builder_block_image_description": "Add an image to your site",
  "ss_builder_block_image_embed_description": "Embed anything to your site: video, audio, form,...",
  "ss_builder_block_image_gallery_description": "List of images displayed in multiple layouts and zoomable",
  "ss_builder_block_image_popup_description": "Image will be displayed as popup when fans view your site",
  "ss_builder_block_image_slider_description": "List of images displayed in an image slider",
  "ss_builder_block_is_hiding_label": "Hidden Block",
  "ss_builder_block_link_copy_successfully_label": "Coppied",
  "ss_builder_block_list_combined_description": "Combine existing blocks into a new block type",
  "ss_builder_block_main_color": "Primary color",
  "ss_builder_block_outside_space": "Block's margin",
  "ss_builder_block_rating_answer_1": "Interface",
  "ss_builder_block_rating_answer_2": "Page loading time",
  "ss_builder_block_rating_answer_3": "Experience",
  "ss_builder_block_rating_answer_4": "Support",
  "ss_builder_block_rating_answer_5": "Other",
  "ss_builder_block_rating_button_submit_rating": "Submit rating",
  "ss_builder_block_rating_button_title_open_popup": "Submit rating",
  "ss_builder_block_rating_content_rating": "Your feedback",
  "ss_builder_block_rating_content_rating_placeholder": "Please leave suggestions for Passio Page",
  "ss_builder_block_rating_description": "It would be great if Passio Page received your rating to improve the quality of service",
  "ss_builder_block_rating_description_auto_show": "Your feedback about Passio Page",
  "ss_builder_block_rating_description_star_1": "You feel unsatisfied because",
  "ss_builder_block_rating_description_star_2": "You feel unsatisfied because",
  "ss_builder_block_rating_description_star_3": "Passio Page need to improvement on",
  "ss_builder_block_rating_description_star_4": "You feel satisfied with",
  "ss_builder_block_rating_description_star_5": "You feel satisfied with",
  "ss_builder_block_rating_star_1": "Terrible",
  "ss_builder_block_rating_star_2": "Bored",
  "ss_builder_block_rating_star_3": "So far OK",
  "ss_builder_block_rating_star_4": "Nice",
  "ss_builder_block_rating_star_5": "Excellent",
  "ss_builder_block_rating_title": "Your rating",
  "ss_builder_block_rating_toast_success_rating": "Thank you for your feedback",
  "ss_builder_block_second_color": "Secondary color",
  "ss_builder_block_setting_title": "Block Settings",
  "ss_builder_block_shadow_color": "Shadow color",
  "ss_builder_block_space": "Spacing between blocks",
  "ss_builder_block_spacer_and_divider_description": "Add spacer or divider between your blocks",
  "ss_builder_block_text_description": "Add any content to your site with formatted text",
  "ss_builder_block_text_see_more_btn_default_title": "See more",
  "ss_builder_block_truth_or_dare_description": "Go all out with Truth or Dare",
  "ss_builder_block_truth_or_dare_footer_description": "Show Icon for Truth or Dare in the screen corner",
  "ss_builder_block_virtual_gift_description": "Enable to receive gifts from Fans",
  "ss_builder_block_virtual_gift_footer_description": "Show Icon for Virtual Gift in the screen corner",
  "ss_builder_blocks_general_settings_label": "General setting of blocks",
  "ss_builder_blocks_general_settings_popover_content": "Enable to apply “Block Settings” in the “Settings” to the blocks in the list below",
  "ss_builder_blocks_label": "Blocks",
  "ss_builder_blocks_list": "List",
  "ss_builder_blocks_list_empty_content": "To add a new block please click the “Add” button above",
  "ss_builder_blocks_list_empty_title": "No block have been added yet",
  "ss_builder_body_description": "You can use Tabs to divide the list of block into multi parts",
  "ss_builder_body_introduction_description": "\"Body\" is the main area of the site, contains the Blocks and is divided, navigated by Tabs. For example: content, images, products, ...",
  "ss_builder_body_introduction_title": "Body Position",
  "ss_builder_body_title": "Body",
  "ss_builder_bold": "Bold",
  "ss_builder_booking_profile_style_1": "Cards",
  "ss_builder_booking_profile_style_2": "Embed code",
  "ss_builder_border_dash": "Dash",
  "ss_builder_border_dot": "Dot",
  "ss_builder_border_radius_label": "Radius",
  "ss_builder_border_radius_style": "Border radius style",
  "ss_builder_border_solid": "Solid",
  "ss_builder_bottom_position_tab_label": "Bottom",
  "ss_builder_box_icon_button_filter": "Filter",
  "ss_builder_box_icon_empty_search": "No results found",
  "ss_builder_box_icon_empty_searching_description": "Please try with another keyword",
  "ss_builder_box_icon_filter_category_all": "All",
  "ss_builder_box_icon_filter_category_sub_title": "Select icon category",
  "ss_builder_box_icon_filter_category_title": "Filter",
  "ss_builder_box_icon_search_bar_placeholder": "Search icons by name...",
  "ss_builder_bp_apply_block_settings_instruction": "Turn off to display full screen width. Turn on to display by settings in Block Settings as normal blocks.",
  "ss_builder_bp_open_new_tab_instruction": "Turn on to show the icon to open a new tab in the right corner",
  "ss_builder_bp_open_new_tab_title": "Open new tab",
  "ss_builder_brand_gift_introduction_popup_title": "Add Brand Gift icon",
  "ss_builder_browser_opening_label": "Open in browser",
  "ss_builder_button_background_color": "Background color",
  "ss_builder_button_border_color": "Border color",
  "ss_builder_button_border_width": "Border width (px)",
  "ss_builder_button_close_introduction_service": "Close",
  "ss_builder_button_font_size": "Font size (px)",
  "ss_builder_button_font_weight": "Font weight",
  "ss_builder_button_line_height": "Line height",
  "ss_builder_button_link_content_title": "Title",
  "ss_builder_button_link_description": "Link description",
  "ss_builder_button_link_icon_btn_selection_label": "Select an icon",
  "ss_builder_button_link_icon_origin_url_label": "Link",
  "ss_builder_button_link_icon_title": "Select icon",
  "ss_builder_button_link_large_size_label": "Large",
  "ss_builder_button_link_middle_size_label": "Middle",
  "ss_builder_button_link_not_enought_infomation": "Link has not enough infomation",
  "ss_builder_button_link_placeholder_content_title": "Enter content",
  "ss_builder_button_link_size_selection_title": "Select a size",
  "ss_builder_button_link_small_size_label": "Small",
  "ss_builder_button_setting_title": "Button Settings",
  "ss_builder_button_text_color": "Text color",
  "ss_builder_button_title": "Button title",
  "ss_builder_cancel": "Cancel",
  "ss_builder_cancel-delete_block_label": "Cancel",
  "ss_builder_cancel-delete_image_label": "Continue to edit",
  "ss_builder_cancel-delete_social_link_btn_label": "Cancel",
  "ss_builder_cancel-delete_tab_label": "Cancel",
  "ss_builder_cancel-restore_theme_label": "Cancel",
  "ss_builder_cancel-set_default_tab_label": "Cancel",
  "ss_builder_cancel_affiliate_product_group_add_new_panel_label": "Cancel",
  "ss_builder_cancel_apply_theme_template_btn_label": "Cancel",
  "ss_builder_cancel_block_btn_label": "Cancel",
  "ss_builder_cancel_delete_aff_block_btn_label": "Cancel",
  "ss_builder_cancel_delete_card_modal_btn_label": "Continue to edit",
  "ss_builder_cancel_delete_link_modal_btn_label": "Continue editing",
  "ss_builder_cancel_delete_product_label": "Cancel",
  "ss_builder_cancel_delete_theme_template_modal_btn_label": "Cancel",
  "ss_builder_cancel_edit_block_content": "Your modifications on block will not be saved. Are you sure you want to exit?",
  "ss_builder_cancel_edit_block_title": "Cancel editting?",
  "ss_builder_cancel_hide_product_label": "Cancel",
  "ss_builder_cancel_image_slider_add_new_btn_label": "Cancel",
  "ss_builder_cancel_overwrite_theme_template_btn_label": "Cancel",
  "ss_builder_cancel_preview_theme_template_btn_label": "Close",
  "ss_builder_cancel_product_addition_modal_confirm_btn_label": "Continue adding products",
  "ss_builder_cancel_product_addition_modal_description": "Products added at this screen will not be saved.",
  "ss_builder_cancel_product_addition_modal_title": "Cancel add products?",
  "ss_builder_cancel_replace_theme_template_btn_label": "Cancel",
  "ss_builder_cancel_select_multi_items": "Cancel",
  "ss_builder_cancel_theme_selection_panel_label": "Cancel",
  "ss_builder_cancel_upload_image": "Cancel",
  "ss_builder_card_add_new_description_label": "Description",
  "ss_builder_card_add_new_label": "Label",
  "ss_builder_card_add_new_subtitle_label": "Subtitle",
  "ss_builder_card_add_new_title_label": "Title",
  "ss_builder_card_add_new_url_label": "Url",
  "ss_builder_card_additional_btn_label": "Add card",
  "ss_builder_card_btn_content_label": "Button content",
  "ss_builder_card_layout_alert_1": "This layout will not display description, label and button",
  "ss_builder_card_layout_alert_2": "This layout will not display description and button",
  "ss_builder_card_layout_label": "Layout",
  "ss_builder_card_layout_label_1": "Text under image - Left align",
  "ss_builder_card_layout_label_2": "Text under image - Center align",
  "ss_builder_card_layout_label_3": "Text over image - Center align",
  "ss_builder_card_layout_label_4": "Text over image - Left align",
  "ss_builder_card_layout_label_5": "Text on the right",
  "ss_builder_card_layout_label_6": "Text on the left",
  "ss_builder_card_layout_label_7": "Text over image - Left align 2",
  "ss_builder_card_layout_label_8": "Text over image - Center align 2",
  "ss_builder_card_url_placeholder": "http://domain.com",
  "ss_builder_category_14_up": "14+",
  "ss_builder_category_all": "All",
  "ss_builder_category_art": "Art",
  "ss_builder_category_creative": "Creative",
  "ss_builder_category_doctor": "Doctor",
  "ss_builder_category_education": "Education",
  "ss_builder_category_entertainment": "Entertainment",
  "ss_builder_category_fashion": "Fashion",
  "ss_builder_category_fitness": "Fitness",
  "ss_builder_category_food_fmcg": "Food & FMCG",
  "ss_builder_category_game": "Game",
  "ss_builder_category_health_and_beauty": "Health & Beauty",
  "ss_builder_category_ict": "ICT",
  "ss_builder_category_life_style": "Lifestyle",
  "ss_builder_category_lifestyle": "Lifestyle",
  "ss_builder_category_mom_and_kid": "Mom & Kid",
  "ss_builder_category_others": "Others",
  "ss_builder_category_pet": "Pet",
  "ss_builder_category_spirit": "Spirit",
  "ss_builder_change_image_button_label": "Change image",
  "ss_builder_change_name_product_group_successfully_label": "Change product group name successfully",
  "ss_builder_change_theme_label": "Change theme",
  "ss_builder_choose_a_prototying_method": "Choose an option to create new template",
  "ss_builder_choose_at_least_category": "Choose at least 1 category and we will suggest themes based on your selection 👇",
  "ss_builder_choose_image_below": "To add new item, please click on \"Add new\" button below",
  "ss_builder_choose_one_theme_preview_you_can_customize_it_later": "Choose a theme, you can customize it after applying✨",
  "ss_builder_choose_the_look_that_right_for_you": "Please choose a theme",
  "ss_builder_choose_this_template": "Apply",
  "ss_builder_cleanup_lock_product_successfully_label": "Lock the product cleanup successfully",
  "ss_builder_cleanup_unlock_product_successfully_label": "Unlock the product cleanup successfully",
  "ss_builder_close_panel": "Cancel",
  "ss_builder_close_panel_affiliate_product-selection-label": "Cancel",
  "ss_builder_close_panel_edit_text_block": "Close",
  "ss_builder_collapse": "See less",
  "ss_builder_color_1": "Color 1",
  "ss_builder_color_2": "Color 2",
  "ss_builder_color_picker_panel_title": "Choose a color",
  "ss_builder_confirm-cancel_image_slider_add_new_content": "Your actions have not been saved.\nAre you sure you want to exit?",
  "ss_builder_confirm-cancel_image_slider_add_new_title": "Exit editing?",
  "ss_builder_confirm-delete_block_label": "If you delete the block, the added data will be deleted. Are you sure you want to delete?",
  "ss_builder_confirm-delete_block_title": "Do you want to delete this block?",
  "ss_builder_confirm-delete_image_label": "Are you sure you want to delete this image?",
  "ss_builder_confirm-delete_image_title": "Delete image",
  "ss_builder_confirm-delete_social_link_content": "Are you sure you want to delete this item?",
  "ss_builder_confirm-delete_social_link_title": "Delete social networks?",
  "ss_builder_confirm_affiliate_product_group_add_new_panel_label": "Save",
  "ss_builder_confirm_apply_template_description": "Applying template will replace all existing blocks in your list by the template's blocks.",
  "ss_builder_confirm_apply_template_label": "Replace",
  "ss_builder_confirm_apply_theme_template_btn_label": "Confirm",
  "ss_builder_confirm_apply_theme_template_content": "Applying the theme you choose will change the appearing of entire site",
  "ss_builder_confirm_apply_theme_template_title": "Do you want to apply this theme?",
  "ss_builder_confirm_cancel_image_slider_add_new_btn_label": "Exit",
  "ss_builder_confirm_cleanup_lock_product_btn_label": "Confirm to lock",
  "ss_builder_confirm_cleanup_lock_product_selection_modal_content": "Are you sure you want to lock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_cleanup_unlock_product_btn_label": "Confirm to unlock",
  "ss_builder_confirm_cleanup_unlock_product_selection_modal_content": "Are you sure you want to unlock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_delete_aff_block_content": "This action cannot be undone. Please confirm, you understand that deleting this block will delete all products inside.",
  "ss_builder_confirm_delete_aff_block_input_placeholder": "OK",
  "ss_builder_confirm_delete_aff_block_label_enter_ok": "Enter “OK” to confirm",
  "ss_builder_confirm_delete_aff_block_understood": "I understood",
  "ss_builder_confirm_delete_aff_block_warining": "Warning",
  "ss_builder_confirm_delete_block_btn_label": "Confirm delete",
  "ss_builder_confirm_delete_card_modal_btn_label": "Delete card",
  "ss_builder_confirm_delete_card_modal_description": "Are you sure you want to delete this card?",
  "ss_builder_confirm_delete_card_modal_label": "Delete card",
  "ss_builder_confirm_delete_combined_label": "Are you sure you want to delete the {{type}} block?",
  "ss_builder_confirm_delete_image_popup_image_label": "Confirm delete",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_content": "Are you sure you want to delete these {{quantity}} links?",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_title": "Delete links",
  "ss_builder_confirm_delete_item_checkbox_card_modal_content": "Are you sure you want to delete these {{quantity}} cards?",
  "ss_builder_confirm_delete_item_checkbox_card_modal_title": "Delete cards",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_content": "Are you sure you want to delete these {{quantity}} blocks?",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_title": "Delete block",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_content": "Are you sure you want to delete these {{quantity}} images?",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_title": "Delete gallery images",
  "ss_builder_confirm_delete_item_checkbox_story_modal_content": "Are you sure you want to delete these {{quantity}} spotlight items?",
  "ss_builder_confirm_delete_item_checkbox_story_modal_title": "Delete spotlight items",
  "ss_builder_confirm_delete_link_description": "Are you sure you want to delete this link?",
  "ss_builder_confirm_delete_product_group_modal_description": "The group \"{{group_name}}\" and all the data of the products inside will be permanently deleted. Are you sure you want to delete?",
  "ss_builder_confirm_delete_product_group_modal_title": "Do you want to delete the group?",
  "ss_builder_confirm_delete_product_label": "Delete",
  "ss_builder_confirm_delete_product_modal_content": "Are you sure you want to delete this product?",
  "ss_builder_confirm_delete_product_modal_title": "Delete product",
  "ss_builder_confirm_delete_product_selection_modal_content": "Are you sure you want to delete these {{product_quantity}} products?",
  "ss_builder_confirm_delete_tab_label": "Delete",
  "ss_builder_confirm_delete_template_modal_content": "Permanently delete this template from the template list, blocks that previously used this template will not be affected",
  "ss_builder_confirm_delete_template_modal_title": "Do you want to delete this template?",
  "ss_builder_confirm_delete_theme_template_modal_btn_label": "Confirm delete",
  "ss_builder_confirm_delete_theme_template_modal_content": "Permanently delete theme template from the theme store, themes created from this theme will not be affected",
  "ss_builder_confirm_delete_theme_template_modal_title": "Do you want to delete this theme template?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_content": "Are you sure you want to hide these {{quantity}} links?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_title": "Hide links",
  "ss_builder_confirm_disable_item_checkbox_card_modal_content": "Are you sure you want to hide these {{quantity}} cards?",
  "ss_builder_confirm_disable_item_checkbox_card_modal_title": "Hide cards",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_content": "Are you sure you want to hide these {{quantity}} blocks?",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_title": "Hide block",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_content": "Are you sure you want to hide these {{quantity}} gallery images?",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_title": "Hide gallery images",
  "ss_builder_confirm_disable_item_checkbox_story_modal_content": "Are you sure you want to hide these {{quantity}} spotlights?",
  "ss_builder_confirm_disable_item_checkbox_story_modal_title": "Hide spotlights",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_content": "Are you sure you want to show these {{quantity}} links?",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_title": "Show links",
  "ss_builder_confirm_enable_item_checkbox_card_modal_content": "Are you sure you want to show these {{quantity}} cards?",
  "ss_builder_confirm_enable_item_checkbox_card_modal_title": "Show cards",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_content": "Are you sure you want to show these {{quantity}} blocks?",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_title": "Show block",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_content": "Are you sure you want to show these {{quantity}} gallery images?",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_title": "Show gallery images",
  "ss_builder_confirm_enable_item_checkbox_story_modal_content": "Are you sure you want to show these {{quantity}} spotlights?",
  "ss_builder_confirm_enable_item_checkbox_story_modal_title": "Show spotlights",
  "ss_builder_confirm_enable_product_selection_modal_content": "Are you sure you want to show these {{product_quantity}} products?",
  "ss_builder_confirm_hide_product_btn_label": "Confirm to hide",
  "ss_builder_confirm_hide_product_group_modal_description": "The product group \"{{group_name}}\" and all the data of the products inside will be hidden. Are you sure you want to hide the group?",
  "ss_builder_confirm_hide_product_group_modal_title": "Do you want to hide the group?",
  "ss_builder_confirm_hide_product_label": "Confirm to hide group",
  "ss_builder_confirm_hide_product_selection_modal_content": "Are you sure you want to hide {{product_quantity}} products?",
  "ss_builder_confirm_overwrite": "Confirm overwrite",
  "ss_builder_confirm_overwrite_theme_template_btn_label": "Confirm overwrite",
  "ss_builder_confirm_overwrite_theme_template_modal_content": "Overwriting will replace all data and settings of theme template by data and settings of current theme template",
  "ss_builder_confirm_overwrite_theme_template_modal_title": "Do you want to overwrite?",
  "ss_builder_confirm_panel": "Confirm",
  "ss_builder_confirm_panel_affiliate_product-selection-label": "Confirm",
  "ss_builder_confirm_panel_edit_text_block": "Confirm",
  "ss_builder_confirm_replace_theme_template_btn_label": "Apply sample data",
  "ss_builder_confirm_replace_theme_template_content": "This action cannot be undone.\nPlease confirm that you understand that applying sample content will replace all data existing on the site.",
  "ss_builder_confirm_replace_theme_template_input_placeholder": "OK",
  "ss_builder_confirm_replace_theme_template_label_enter_ok": "Enter “OK” to confirm",
  "ss_builder_confirm_replace_theme_template_understood": "I understood",
  "ss_builder_confirm_replace_theme_template_warining": "Warning",
  "ss_builder_confirm_restore_theme_label": "Restore editing",
  "ss_builder_confirm_set_default_tab_label": "Set default",
  "ss_builder_confirm_show_product_btn_label": "Confirm to show",
  "ss_builder_confirm_theme_template_preview_mode_btn_label": "Apply",
  "ss_builder_confirm_to_edit_label": "Continue editing",
  "ss_builder_content_1_introduction_service": "Brand Gift gathers hot deals & promotions from Brands to end users. Creators could receive attractive bonuses from Brands by bringing these deals to their followers",
  "ss_builder_content_2_introduction_service": "Turn on Brand Gift feature now to earn more",
  "ss_builder_content_creators_joined_the_passio_system": "100K+ content creators joined the Passio eco-system",
  "ss_builder_content_editor_title": "Content",
  "ss_builder_content_list_1_introduction_service": "Base Commission",
  "ss_builder_content_list_2_introduction_service": "Brand Bonus (up to 30%)",
  "ss_builder_content_list_3_introduction_service": "More than 500+ Brands from various categories with high Brand Bonus",
  "ss_builder_continue_add_brand_gift": "Discover now",
  "ss_builder_continue_edit_block_label": "Continue edititing",
  "ss_builder_continue_edit_image_popup_image_label": "Cancel",
  "ss_builder_continue_to_add_block": "Continue",
  "ss_builder_convert_and_go_to_new_version": "Sync data and go to Passio Page",
  "ss_builder_cover_image": "Cover image",
  "ss_builder_create_bio_page_free_fast": "Create Bio Page for free & fast",
  "ss_builder_create_link_with_your_name_and_imprint": "Create a bio page with your name and imprint. Theme store with many theme categories, can be easily customized right on the phone.",
  "ss_builder_create_new_theme_template_label": "Create a new theme template",
  "ss_builder_create_template": "Create template",
  "ss_builder_cusom": "Custom",
  "ss_builder_custom": "Custom",
  "ss_builder_dashed_type_label": "Dashed",
  "ss_builder_data_auto_convert": "Your old data will be sync from your old Landing Page to new Passio Page automatically",
  "ss_builder_data_can_be_convert": "You can sync the data from your old Landing Page to new Passio Page",
  "ss_builder_data_will_be_override": "Warning: the data on Passio Page will be override if you sync the data",
  "ss_builder_deep_blue_sky": "Over the horizon comes the break of dawn",
  "ss_builder_default_meta_description": "Passio Page - Home of {{creator_name}}",
  "ss_builder_defaults_label": "Default",
  "ss_builder_delete_block_label": "Delete block",
  "ss_builder_delete_block_successfully_label": "Delete block successfully",
  "ss_builder_delete_card_label": "Delete card",
  "ss_builder_delete_card_successfully_label": "Delete card successfully",
  "ss_builder_delete_image_label": "Delete image",
  "ss_builder_delete_image_successfully_label": "Delete image successfully",
  "ss_builder_delete_item_checkbox_button_multi_link_successfully_label": "Delete links successfully",
  "ss_builder_delete_item_checkbox_card_successfully_label": "Delete cards successfully",
  "ss_builder_delete_item_checkbox_combined_successfully_label": "Delete block successfully",
  "ss_builder_delete_item_checkbox_image_gallery_successfully_label": "Delete gallery images successfully",
  "ss_builder_delete_item_checkbox_story_successfully_label": "Delete spotlights successfully",
  "ss_builder_delete_link_label": "Delete link",
  "ss_builder_delete_link_successfully_label": "Delete link successfully",
  "ss_builder_delete_product_group_error_btn_confirm_label": "Close",
  "ss_builder_delete_product_group_error_content": "Group \"{{group_name}}\" cannot be deleted, a minimum of 1 group is required.",
  "ss_builder_delete_product_group_error_title": "Error deleting group",
  "ss_builder_delete_product_group_label": "Delete group",
  "ss_builder_delete_product_group_successfully_label": "Delete product group successfully",
  "ss_builder_delete_product_label": "Delete product",
  "ss_builder_delete_product_successfully_label": "Delete product successfully",
  "ss_builder_delete_social_link_btn_label": "Confirm delete",
  "ss_builder_delete_social_link_label": "Delete",
  "ss_builder_delete_social_link_successfully_label": "Delete social network successfully",
  "ss_builder_delete_tab_content": "Tab \"{{tab_name}}\" and all data of the blocks inside will be permanently deleted. Are you sure you want to delete?",
  "ss_builder_delete_tab_label": "Delete",
  "ss_builder_delete_tab_successfully_label": "Delete tab successfully",
  "ss_builder_delete_tab_title": "Do you want to delete tab?",
  "ss_builder_delete_template_successfully_label": "Delete template successfully",
  "ss_builder_delete_theme_template_label": "Delete",
  "ss_builder_delete_theme_template_successfully_label": "Delete theme template successfully",
  "ss_builder_description": "Description",
  "ss_builder_description_page_builder": "Build a Bio Site in 1 click and start earning money",
  "ss_builder_description_replace_template_content": "Apply theme sample data will use content from theme template to replace for all the content of your site",
  "ss_builder_design_group_floating_title": "Floating icons",
  "ss_builder_design_group_image_popup_title": "Popup",
  "ss_builder_design_label": "Design",
  "ss_builder_dialog_error_confirm_text": "Refresh",
  "ss_builder_dialog_error_content": "There have been changes from another device. You need to refresh page.",
  "ss_builder_dialog_error_title": "There is an update on your site",
  "ss_builder_digital_product_free_label": "Free",
  "ss_builder_digital_product_list_title": "Product list",
  "ss_builder_direction_of_background_image_label": "Background image position",
  "ss_builder_disable_block_successfully_label": "Hide block successfully",
  "ss_builder_disable_image_successfully_label": "Hide photo successfully",
  "ss_builder_disable_item_checkbox_button_multi_link_successfully_label": "Hide links successfully",
  "ss_builder_disable_item_checkbox_card_successfully_label": "Hide cards successfully",
  "ss_builder_disable_item_checkbox_combined_successfully_label": "Hide block successfully",
  "ss_builder_disable_item_checkbox_image_gallery_successfully_label": "Hide gallery images successfully",
  "ss_builder_disable_item_checkbox_story_successfully_label": "Hide spotlights successfully",
  "ss_builder_disable_product_group_successfully_label": "Hide product group successfully",
  "ss_builder_disable_product_successfully_label": "Hide product successfully",
  "ss_builder_disable_social_link_successfully_label": "Hide social link successfully",
  "ss_builder_disable_tab_successfully_label": "Hide tab successfully",
  "ss_builder_disable_theme_successfully_label": "Hide template successfully",
  "ss_builder_discover_now": "Discover now",
  "ss_builder_distance_blocks": "Space between blocks",
  "ss_builder_divider_color_label": "Divider color",
  "ss_builder_divider_image_label": "Divider image",
  "ss_builder_divider_label": "Divider",
  "ss_builder_divider_thickness_label": "Thickness (px)",
  "ss_builder_do_you_want_replace_sample_content": "Do you want to apply theme sample data?",
  "ss_builder_domain_site_setting_alert_infomation": "If you change your domain name, you will have to wait 30 days for the next change",
  "ss_builder_domain_site_setting_rule": "Start with a letter, contain only numbers and letters",
  "ss_builder_done_panel": "Done",
  "ss_builder_dont_add_block_from_template_description": "Unable to add {{number_block}} blocks from the template due to the 10 block limit in the list. Please delete the existing block to add new template blocks.",
  "ss_builder_dont_add_block_from_template_title": "Unable to add block",
  "ss_builder_dont_add_product_exist_at_add_new_content": "The product is already in the list",
  "ss_builder_dont_add_product_exist_at_list_content": "The product you want to add is already in the product group \"{{group_name}}\".",
  "ss_builder_dont_add_same_affiliate_product_confirm_label": "Confirm",
  "ss_builder_dont_add_same_affiliate_product_modal_label": "Unable to add new product",
  "ss_builder_dont_apply_template_content_modal_description": "Applying template cannot be performed because the template no longer exists.",
  "ss_builder_dont_apply_theme_content_modal_btn_confirm_label": "Close",
  "ss_builder_dont_apply_theme_content_modal_description": "Application of sample data cannot be performed because the theme template no longer exists in the theme store.",
  "ss_builder_dont_apply_theme_content_modal_label": "Could not apply",
  "ss_builder_dot_strokes_type_label": "Dotted",
  "ss_builder_dp_title_intro": "Digital products",
  "ss_builder_drop_here_label": "Add block here",
  "ss_builder_duplicated_product_url_label": "Duplicated link",
  "ss_builder_duplicated_theme_template_name_warning": "Theme template name already exists. Please enter another name!",
  "ss_builder_earn_additional_commissions_label": "Earn additional commissions when using Passio's affiliate links",
  "ss_builder_edit_affiliate_product_group_title": "Group name",
  "ss_builder_edit_affiliate_product_title": "Edit product",
  "ss_builder_edit_block_label": "Edit",
  "ss_builder_edit_block_successfully_label": "Edit block successfully",
  "ss_builder_edit_image_of_image_popup_title": "Edit image popup",
  "ss_builder_edit_name_affiliate_product_group_title": "Change product group name",
  "ss_builder_edit_name_block_successfully_label": "Update block name successfully",
  "ss_builder_edit_product_group_label": "Change group name",
  "ss_builder_edit_tab_label": "Rename",
  "ss_builder_edit_tab_successfully_label": "Update tab successfully",
  "ss_builder_edit_template_btn_label": "Save",
  "ss_builder_edit_template_title": "Edit template",
  "ss_builder_edit_text_block_label": "Block name",
  "ss_builder_edit_theme_btn_label": "Save",
  "ss_builder_edit_theme_template_label": "Edit information",
  "ss_builder_edit_theme_template_successfully_label": "Update theme template successfully",
  "ss_builder_edit_theme_template_title": "Edit theme template info",
  "ss_builder_editor_btn-add-more": "Add",
  "ss_builder_editor_btn-cancel": "Cancel",
  "ss_builder_editor_btn-save": "Save",
  "ss_builder_email_placeholder": "Enter email",
  "ss_builder_embed_code_label": "Embed code",
  "ss_builder_embed_code_placeholder": "Please enter the embed code",
  "ss_builder_empty_block": "No block",
  "ss_builder_empty_search": "No results found",
  "ss_builder_empty_social_link_list_content": "To add a social network please press the “Add” button above",
  "ss_builder_empty_social_link_list_title": "No social link added yet",
  "ss_builder_empty_template_label": "No block template",
  "ss_builder_enable_block_successfully_label": "Show block successfully",
  "ss_builder_enable_border": "Enable",
  "ss_builder_enable_button_border": "Enable border",
  "ss_builder_enable_image_successfully_label": "Show image successfully",
  "ss_builder_enable_item_checkbox_button_multi_link_successfully_label": "Show links successfully",
  "ss_builder_enable_item_checkbox_card_successfully_label": "Show cards successfully",
  "ss_builder_enable_item_checkbox_combined_successfully_label": "Show block successfully",
  "ss_builder_enable_item_checkbox_image_gallery_successfully_label": "Show gallery images successfully",
  "ss_builder_enable_item_checkbox_story_successfully_label": "Show spotlights successfully",
  "ss_builder_enable_product_group_successfully_label": "Show product group successfully",
  "ss_builder_enable_product_successfully_label": "Show product successfully",
  "ss_builder_enable_shadow": "Enable",
  "ss_builder_enable_social_link_successfully_label": "Show social link successfully",
  "ss_builder_enable_tab_successfully_label": "Show tab successfully",
  "ss_builder_enable_theme_successfully_label": "Show template successfully",
  "ss_builder_enable_tracking_link_product_label": "Enable tracking link",
  "ss_builder_enable_tracking_link_product_successfully_label": "Enable tracking link successfully",
  "ss_builder_enter_domain_site_setting_label": "Custom subdomain",
  "ss_builder_enter_font_name": "Enter font name",
  "ss_builder_enter_url_label": "Enter URL",
  "ss_builder_enter_your_url": "Enter your url",
  "ss_builder_error_block_label": "Error block",
  "ss_builder_error_invalid_email_message": "Invalid email",
  "ss_builder_error_invalid_phone_message": "Invalid phone number",
  "ss_builder_error_network_confirm_btn_label": "Close",
  "ss_builder_error_network_content": "Please wait a few minutes before trying again",
  "ss_builder_error_network_title": "System overload",
  "ss_builder_error_page_title": "Passio Page Builder - {{country}} - Error Page",
  "ss_builder_exceeding_label": "Exceed max blocks",
  "ss_builder_existed_product_group_label": "The group already exists",
  "ss_builder_expand": "See more",
  "ss_builder_expand_introduction_description": "\"Extended Position\" contains Blocks that do not have a fixed postion on the site. Eg: Popup, Floating Icons",
  "ss_builder_expand_introduction_title": "Extended Position",
  "ss_builder_filter_select_combined_count": "selected block(s)",
  "ss_builder_filter_select_multi_items": "last {{num_items}}",
  "ss_builder_filter_select_multi_items_card_count": "selected cards",
  "ss_builder_filter_select_multi_items_gallery_count": "selected images",
  "ss_builder_filter_select_multi_items_multi_links_count": "selected links",
  "ss_builder_filter_select_multi_items_spotlight_count": "selected spotlights",
  "ss_builder_first_guide_add_block_btn_title": "Add Block",
  "ss_builder_first_guide_add_block_description": "Click or Drag & Drop \"Add Block\" button to the desired position to add a new Block to your site. Many Block types ready to add to your site, help you create beautiful, multi-purpose bio page. Let's explore!",
  "ss_builder_first_guide_add_block_title": "Add Block button",
  "ss_builder_first_guide_preview_btn_title": "Preview site",
  "ss_builder_first_guide_preview_description": "Preview the site to see the current changes of the site. Publish should only be made once you have previewed and are satisfied with the changes.",
  "ss_builder_first_guide_preview_title": "Preview site button",
  "ss_builder_first_guide_publish_btn_title": "Publish",
  "ss_builder_first_guide_publish_description": "Design edits are always in \"Draft\" mode. The changes will be visible to your Fans once you decide to \"Publish\".",
  "ss_builder_first_guide_publish_title": "Publish site button",
  "ss_builder_floating_full": "Only able to add two blocks in Floating icons group",
  "ss_builder_floating_introduction_description": "Blocks inside this position will be floating displayed at the bottom/right of screen. Always display to easy access.",
  "ss_builder_floating_introduction_title": "Floating icons",
  "ss_builder_font_name_not_exist": "Font does not exist",
  "ss_builder_font_option_panel_title": "Font type",
  "ss_builder_footer_description": "These blocks will appear in all Tabs",
  "ss_builder_footer_introduction_description": "“Footer” contains Blocks, which always appear at the bottom of the page. For example: quick links, page info...",
  "ss_builder_footer_introduction_title": "Footer Position",
  "ss_builder_footer_title": "Footer",
  "ss_builder_frame_aff": "Product image frame",
  "ss_builder_general_setting_title": "General Setting",
  "ss_builder_generate_income_from_your_own_fan_community": "Generate income from your own fan community!",
  "ss_builder_gift_warning": "Only able to add one block",
  "ss_builder_go_back_to_site": "Go back to site",
  "ss_builder_google_font": "Google Fonts",
  "ss_builder_gradient": "Gradient",
  "ss_builder_group_is_full_of_product_modal_description": "The product group you selected is full. Please choose another group.",
  "ss_builder_group_is_full_of_product_modal_title": "Couldn't add",
  "ss_builder_group_is_hiding_label": "Hidden product group",
  "ss_builder_hard_shadow": "Hard shadow",
  "ss_builder_header_description": "These blocks will appear in all Tabs",
  "ss_builder_header_introduction_description": "“Header” contains Blocks, which always appear at the top of the page. For example: Avatar image, introduction...",
  "ss_builder_header_introduction_title": "Header Position",
  "ss_builder_header_title": "Header",
  "ss_builder_heading_and_subtitle_site_setting_label": "Site title and description",
  "ss_builder_heading_site_setting_description": "Shown by search engines in their preview of your page. This is important for both SEO and social sharing.",
  "ss_builder_heading_site_setting_label": "Site title",
  "ss_builder_hide_block_label": "Hide block",
  "ss_builder_hide_card_label": "Hide card",
  "ss_builder_hide_card_successfully_label": "Hide card successfully",
  "ss_builder_hide_image_label": "Hide image",
  "ss_builder_hide_link_label": "Hide link",
  "ss_builder_hide_link_successfully_label": "Hide link successfully",
  "ss_builder_hide_product_group_error_btn_confirm_label": "Close",
  "ss_builder_hide_product_group_error_content": "Group \"{{group_name}}\" cannot be hidden, need to show at least 1 group.",
  "ss_builder_hide_product_group_error_title": "Error hiding group",
  "ss_builder_hide_product_group_label": "Hide group",
  "ss_builder_hide_product_label": "Hide product",
  "ss_builder_hide_social_link_label": "Hide",
  "ss_builder_hide_tab_label": "Hide Tab",
  "ss_builder_hide_template_successfully_label": "Hide template successfully",
  "ss_builder_hide_theme_template_label": "Hide template",
  "ss_builder_hightlight_block_label": "Featured",
  "ss_builder_icon_tab_title": "Icon",
  "ss_builder_if_delete": "If you delete this Affiliate block",
  "ss_builder_image": "Image",
  "ss_builder_image_description_place_holder": "Enter content",
  "ss_builder_image_gallery": "Image gallery",
  "ss_builder_image_list_empty_content": "To add photos please press the “Add” button above",
  "ss_builder_image_list_empty_title": "The image list is empty",
  "ss_builder_image_orientation": "Image orientation",
  "ss_builder_image_orientation_label": "Image orientation",
  "ss_builder_image_popup_introduction_description": "Blocks inside this position will show up as a popup when Fans view your site. Popup could be used to display important messages, call to action, advertising banner...",
  "ss_builder_image_popup_introduction_title": "Popup",
  "ss_builder_image_popup_warning": "You can add only one block of this type per page",
  "ss_builder_image_ratio_title": "Image ratio",
  "ss_builder_image_selection_label": "Select image",
  "ss_builder_image_slider_arrow_switch_title": "Arrow",
  "ss_builder_image_slider_auto_play_switch_title": "Auto play",
  "ss_builder_image_slider_dot_switch_title": "Dot slider",
  "ss_builder_image_slider_list_title": "Image list",
  "ss_builder_image_slider_ratio_selected_title": "Image ratio",
  "ss_builder_image_title_place_holder": "Enter content",
  "ss_builder_import_multi_product_url_popover_content": "You can enter one or multi urls. Each url per line.",
  "ss_builder_import_site_url_label": "Import products from URL",
  "ss_builder_import_site_url_modal_button_label": "Import products",
  "ss_builder_import_site_url_modal_description": "Enter the site url to import, support: koc.asia, mycollection.shop, linktr.ee, msha.ke",
  "ss_builder_import_site_url_modal_title": "Product page URL",
  "ss_builder_in_block_delete_forever_are_you_sure": "will be deleted permanently. Are you sure you want to delete?",
  "ss_builder_information_handler_alert": "Processing information. Please wait a moment.",
  "ss_builder_inner_space": "Block's padding",
  "ss_builder_intro_brand_gift_floating_continue": "Add now",
  "ss_builder_intro_brand_gift_floating_description": "Add Brand Gift icon now to help followers hunt deals at the low price",
  "ss_builder_intro_brand_gift_floating_reject": "Cancel",
  "ss_builder_intro_brand_gift_floating_title": "Double your income with Brand Gift Floating Icon",
  "ss_builder_intro_brand_rating_floating_description": "en_Thêm tính năng ngay để giúp Fan săn deal thành công và tăng thu nhập",
  "ss_builder_intro_brand_rating_floating_title": "en_Brand Ratings",
  "ss_builder_introduce_double_tap_button": "Continue editing",
  "ss_builder_introduce_double_tap_content": "In addition to selecting “Edit” in each block, you can double-click the block directly to edit it",
  "ss_builder_introduce_double_tap_label_check": "Do not show again",
  "ss_builder_introduce_double_tap_title": "Block editing tips",
  "ss_builder_invalid_product_url_label": "Invalid link",
  "ss_builder_invalid_url": "Invalid Url",
  "ss_builder_items_per_row": "Number of items per row",
  "ss_builder_keep_the_content": "Keep the content",
  "ss_builder_landscape_image_orientation_label": "Landscape orientation",
  "ss_builder_limit_reached": "Limit reached",
  "ss_builder_link_additional_btn_label": "Add Link",
  "ss_builder_link_select_service": "Choose a service",
  "ss_builder_link_text_left_layout": "Text on the left",
  "ss_builder_link_text_right_layout": "Text on the right",
  "ss_builder_link_text_under_layout": "Text below",
  "ss_builder_link_text_zig_zag_layout": "Zig Zag",
  "ss_builder_link_type_email": "Email",
  "ss_builder_link_type_phone": "Telephone",
  "ss_builder_link_type_service": "Service",
  "ss_builder_link_type_tel": "Tel",
  "ss_builder_link_type_web": "Web",
  "ss_builder_link_url": "Link Url",
  "ss_builder_list_button_multi_link_label": "List",
  "ss_builder_list_card_empty_content": "To add cards please press the button above to add",
  "ss_builder_list_card_empty_title": "No cards have been added yet",
  "ss_builder_list_card_label": "List card",
  "ss_builder_list_image_popup_title": "Image popup list",
  "ss_builder_list_label": "List",
  "ss_builder_list_link_empty_content": "To add a new link, please click the button above to add",
  "ss_builder_list_link_empty_title": "No links have been added yet",
  "ss_builder_list_title_digital_product": "List",
  "ss_builder_loading_label": "Loading",
  "ss_builder_malformed_domain_warning": "Invalid domain name",
  "ss_builder_malformed_embed_code_warning": "Invalid embed code",
  "ss_builder_manage_block_description": "Ss Builder Manage Block Description",
  "ss_builder_manage_block_title": "Blocks management",
  "ss_builder_manage_tab_description": "Tabs are used to divide list of block and navigate on the page into multi parts",
  "ss_builder_manage_tab_title": "Tab management",
  "ss_builder_management_digital_product_description": "List of your digital products",
  "ss_builder_management_digital_product_title": "Digital Product",
  "ss_builder_management_label": "Management",
  "ss_builder_medium": "Medium",
  "ss_builder_missing_required_product_url_label": "Missing required information",
  "ss_builder_modal_button_close": "Close",
  "ss_builder_move_block_successfully_label": "Move block successfully",
  "ss_builder_move_down_block_label": "Move down",
  "ss_builder_move_product_label": "Move to",
  "ss_builder_move_product_quantity_to_group_alert_label": "Move {{product_quantity}} products to",
  "ss_builder_move_product_successfully_label": "Move product successfully",
  "ss_builder_move_to_bottom_label": "Move to the bottom",
  "ss_builder_move_to_top_label": "Move to the top",
  "ss_builder_move_up_block_label": "Move up",
  "ss_builder_multi_product_url_imported_label": "Imported {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} links",
  "ss_builder_multi_product_url_importing_label": "Importing {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} links",
  "ss_builder_name_tab_input_title": "Tab name",
  "ss_builder_need_to_display_at_least_one_block": "Need to display at least one block",
  "ss_builder_need_to_display_at_least_one_card": "Need to display at least one card",
  "ss_builder_need_to_display_at_least_one_image": "Need to display at least one image",
  "ss_builder_need_to_display_at_least_one_link": "Need to display at least one link",
  "ss_builder_need_to_display_at_least_one_spotlight": "Need to display at least one spotlight",
  "ss_builder_next": "Next",
  "ss_builder_no_image_selected": "Empty list",
  "ss_builder_no_photo_has_been_selected": "No photos have been selected yet",
  "ss_builder_no_shadow": "No shadow",
  "ss_builder_no_themes_found_theme_store": "No themes found",
  "ss_builder_none_of_image_description": "Click \"Add\" button to add image popup",
  "ss_builder_none_of_image_popup_alert": "No image popup",
  "ss_builder_not_add_yet_product_url_label": "Link has not been added",
  "ss_builder_not_enough_space_product_group_modal_content": "The product group you've selected does not have enough space to accommodate {{num_products}} products in the additional product list. Please remove the some products from the add list or choose another group.",
  "ss_builder_not_enough_space_product_group_modal_title": "Unable to select group",
  "ss_builder_notes": "Describe your incident in detail",
  "ss_builder_off": "Off",
  "ss_builder_on": "On",
  "ss_builder_onboarding_choose_a_suitable_theme": "Choose a suitable theme, which you can customize after applying✨",
  "ss_builder_onboarding_choose_the_look_for_you": "Choose a theme that's suite for you!",
  "ss_builder_onboarding_create_a_personal_page": "Create a bio page, run Affiliate Marketing, Booking, Donation, Sell digital products (documents, images, videos, presentations,...)",
  "ss_builder_onboarding_no_need_know_technology": "No need to know about technology, it is still easy to use the toolkit to create a bio page.",
  "ss_builder_onboarding_theme_store_template": "Theme store",
  "ss_builder_open_browser_label": "Open in Browser",
  "ss_builder_open_link_in_new_tab": "Open link in new tab",
  "ss_builder_open_link_in_new_tab_description": "Open link in new tab",
  "ss_builder_open_new_tab_description": "Uncheck to open link in current site. Check to open link in new window",
  "ss_builder_open_new_tab_label": "Open link in new window",
  "ss_builder_optional_color_panel_label": "Optional color",
  "ss_builder_overwrite_description": "Overwriting will replace all settings of the selected template",
  "ss_builder_overwrite_template_btn_label": "Overwrite",
  "ss_builder_overwrite_template_successfully_label": "Overwrite template successfully",
  "ss_builder_overwrite_template_title": "Overwrite template",
  "ss_builder_overwrite_theme_btn_label": "Overwrite",
  "ss_builder_overwrite_theme_template_successfully_label": "Overwrite theme template successfully",
  "ss_builder_overwrite_theme_template_title": "Overwrite theme template",
  "ss_builder_overwrite_title": "Do you want to overwrite?",
  "ss_builder_panel_add_icon_tab_title": "Select an icon",
  "ss_builder_panel_add_new_tab_title": "Tab infomation",
  "ss_builder_panel_edit_text_block_title": "Edit block name",
  "ss_builder_panel_search_label": "Search...",
  "ss_builder_panel_title": "Select block type",
  "ss_builder_paragraph_setting_font_available": "Avaiable fonts",
  "ss_builder_paragraph_setting_font_custom": "Custom font",
  "ss_builder_paragraph_setting_option": "Font option",
  "ss_builder_paragraph_setting_title": "Paragraph Settings",
  "ss_builder_partial_visible_slide_type_1": "Partial visible on right",
  "ss_builder_partial_visible_slide_type_2": "Partial visible on both sides",
  "ss_builder_partial_visible_slide_type_3": "Not partial visible",
  "ss_builder_partial_visible_slide_type_4": "Posters",
  "ss_builder_partial_visible_slide_type_5": "Panorama",
  "ss_builder_passio_page_ready": "Passio Page is ready",
  "ss_builder_passio_page_ready_description": "Passio page is ready to use and replace the old Landing Page version",
  "ss_builder_passio_premium": "Passio Premium",
  "ss_builder_personal_link_coppy_label": "Copy",
  "ss_builder_personal_link_coppy_successfully_label": "Coppied",
  "ss_builder_personal_link_sharing_title": "Share your site",
  "ss_builder_personal_link_title": "Your site url",
  "ss_builder_phone_placeholder": "Enter phone number",
  "ss_builder_placeholder_affiliate_product_enter_product_name": "Enter product name",
  "ss_builder_placeholder_dragging_content": "Drop it here",
  "ss_builder_please_wait_for_the_product_to_be_updated": "Let's wait for the products to be updated",
  "ss_builder_popover_manual_button": "Get Started",
  "ss_builder_popover_manual_button_close": "Close",
  "ss_builder_popover_manual_content": "For a tutorial on how to use the design feature, please press the “Get Started” button.",
  "ss_builder_popover_manual_title": "User manual",
  "ss_builder_popover_review_manual_content": "You can review the user guide at any time, by clicking this help button.",
  "ss_builder_popular_font_families": "Popular font families",
  "ss_builder_popup_confirm_button": "Confirm",
  "ss_builder_popup_management_title": "Management",
  "ss_builder_portrait_image_orientation_label": "Portrait orientation",
  "ss_builder_position_ghim_tab_selection_label": "Position",
  "ss_builder_powered_by_passio": "Powered by Ecomobi",
  "ss_builder_preview": "Preview",
  "ss_builder_preview_image_label": "Preview",
  "ss_builder_preview_theme_template_title": "Preview image",
  "ss_builder_product_design_empty_description": "Please wait for the product to be updated",
  "ss_builder_product_design_empty_title": "No products",
  "ss_builder_product_detect_link_malformed_error": "Link format is invalid",
  "ss_builder_product_detect_link_placeholder": "Enter link of product",
  "ss_builder_product_detect_link_title": "Link to product",
  "ss_builder_product_empty_searching_description": "Please try with another keyword",
  "ss_builder_product_empty_searching_title": "No results found",
  "ss_builder_product_exceeding_allowed_amount_content": "To add new product, please remove some existing products",
  "ss_builder_product_exceeding_allowed_amount_title": "Number of products reach the limitation",
  "ss_builder_product_group_add_new_btn_label": "Add the product group",
  "ss_builder_product_group_default_title": "Products",
  "ss_builder_product_group_limitation_warning_description": "The total number of products in the group has been reached the limit of {{max_products}} products. Please delete existing products if you want to add new ones.",
  "ss_builder_product_group_selection_placeholder": "Please select product group",
  "ss_builder_product_group_selection_title": "Select affiliate product group",
  "ss_builder_product_group_total_limitation_warning_content": "The total number of groups has reached the {{group_quantity_maximum}} group limit. Please delete the existing group if you want to add a new one.",
  "ss_builder_product_group_total_limitation_warning_title": "Couldn't add new product group",
  "ss_builder_product_is_hiding_label": "Hidden product",
  "ss_builder_product_link_wrapped_label": "Wrapped link",
  "ss_builder_product_list_style_grid_label": "Grid",
  "ss_builder_product_list_style_grid_one_label": "1 product",
  "ss_builder_product_list_style_grid_three_label": "3 products",
  "ss_builder_product_list_style_grid_two_label": "2 products",
  "ss_builder_product_list_style_slide_label": "Slide",
  "ss_builder_product_list_style_text_over_image_label": "Text over image",
  "ss_builder_product_list_style_zig_zag_label": "Zigzag",
  "ss_builder_product_searching_description": "Please enter product name to search",
  "ss_builder_product_searching_title": "Search product",
  "ss_builder_product_selection_option_1": "Last 10",
  "ss_builder_product_selection_option_2": "Last 20",
  "ss_builder_product_selection_option_3": "Last 50",
  "ss_builder_product_selection_option_4": "Last 5",
  "ss_builder_product_swiper_see_all": "See all",
  "ss_builder_product_title_error": "Missing product name",
  "ss_builder_product_title_missing_warning": "Enter product name",
  "ss_builder_product_total_limitation_warning_description": "The total number of products in the entire product group has been reached the limit of {{max_products_all_groups}} products. Please delete some existing products if you want to add new ones.",
  "ss_builder_product_total_limitation_warning_title": "Couldn't add new product",
  "ss_builder_product_tour_button_step_0": "Get started",
  "ss_builder_product_tour_content_step_0": "Start by following our tutuorial to experience the exciting features of Passio Page!",
  "ss_builder_product_tour_content_step_1": "Get creative with the Design screen. Easily to edit and arrange Blocks according to your own style.",
  "ss_builder_product_tour_content_step_10": "Replace your entire page content with exactly the same content of the applicable theme template. Make your page content exactly like the template.",
  "ss_builder_product_tour_content_step_11": "Preview the site to see the current changes of the site. Publish should only be made once you have previewed and are satisfied with the changes.",
  "ss_builder_product_tour_content_step_12": "By holding and dragging to move or use “Quick actions” to move up/down a block.",
  "ss_builder_product_tour_content_step_13": "Select “Edit” under “Quick Actions” or tap the block directly to edit.",
  "ss_builder_product_tour_content_step_14": "Quickly change the background with a picture, solid color or gradient color with full of personality.",
  "ss_builder_product_tour_content_step_15": "Many diverse, beautiful and trending theme template for you to freely choose and use.",
  "ss_builder_product_tour_content_step_16": "Advanced settings help fine-tune the theme design, change the domain name, personal brand,...",
  "ss_builder_product_tour_content_step_17": "You can re-running this product tour at any time, by clicking this help button.",
  "ss_builder_product_tour_content_step_2": "Many Block types ready to add to your site, help you create beautiful, multi-purpose bio page. Click or Drag & Drop this button to the desired position to add a new Block. Let's explore!",
  "ss_builder_product_tour_content_step_3": "Editing and more options of each block, allowing you to customize the block as you like.",
  "ss_builder_product_tour_content_step_4": "Design edits are always in \"Draft\" mode. The changes will be visible to your Fans once you decide to \"Publish\".",
  "ss_builder_product_tour_content_step_5": "Tabs are used to organize the “Body” content in sections, eg: Products Tab, Videos Tab, Coupons Tab,... Click on each Tab to view and change the content inside. Edit this block to add new, manage Tabs.",
  "ss_builder_product_tour_content_step_6": "“Header” contains Blocks, which always appear at the top of the page. For example: Avatar image, introduction...",
  "ss_builder_product_tour_content_step_7": "\"Body\" is the main area of the site, contains the Blocks and is divided, navigated by Tabs. For example: content, images, products, ...",
  "ss_builder_product_tour_content_step_8": "“Footer” contains Blocks, which always appear at the bottom of the page. For example: quick links, page info...",
  "ss_builder_product_tour_content_step_9": "Use this feature, if the changes you made do not satisfy you. The system will restore your Design screen back to the site version that was publishing.",
  "ss_builder_product_tour_done_label": "Done",
  "ss_builder_product_tour_next_label": "Next",
  "ss_builder_product_tour_prev_label": "Previous",
  "ss_builder_product_tour_skip_label": "Skip",
  "ss_builder_product_tour_title_step_0": "Welcome to Passio Page",
  "ss_builder_product_tour_title_step_1": "Design the theme",
  "ss_builder_product_tour_title_step_10": "Apply sample content",
  "ss_builder_product_tour_title_step_11": "Preview site",
  "ss_builder_product_tour_title_step_12": "Move a Block",
  "ss_builder_product_tour_title_step_13": "Edit a Block",
  "ss_builder_product_tour_title_step_14": "Change the background",
  "ss_builder_product_tour_title_step_15": "Theme store",
  "ss_builder_product_tour_title_step_16": "Advanced settings",
  "ss_builder_product_tour_title_step_17": "Product tour",
  "ss_builder_product_tour_title_step_2": "Add a new Block",
  "ss_builder_product_tour_title_step_3": "Quick Actions",
  "ss_builder_product_tour_title_step_4": "Publish design",
  "ss_builder_product_tour_title_step_5": "Tab menu",
  "ss_builder_product_tour_title_step_6": "Header",
  "ss_builder_product_tour_title_step_7": "Body",
  "ss_builder_product_tour_title_step_8": "Footer",
  "ss_builder_product_tour_title_step_9": "Restore editing",
  "ss_builder_product_tour_use_manual": "Product tour",
  "ss_builder_product_tour_use_manual_content": "Get to know how to use the Design screen, let's press the “Get Started” button.",
  "ss_builder_product_url_lists_label": "Link list",
  "ss_builder_publish_button_label": "Publish",
  "ss_builder_publish_result_description": "You have successfully publish Passio Page.\nYour page is accessible via url below.",
  "ss_builder_publish_result_successfully": "Publish theme successfully!",
  "ss_builder_publish_result_title": "Publishing result",
  "ss_builder_publish_saving": "Saving",
  "ss_builder_publish_share": "Share",
  "ss_builder_publish_view_page": "View site",
  "ss_builder_randomize": "Randomize",
  "ss_builder_ready_to_build_your_personal_brand": "Ready to build your personal brand with Passio now 💖",
  "ss_builder_recently_color_panel_label": "Recently used colors",
  "ss_builder_redo_theme_label": "Redo",
  "ss_builder_reload_label": "Refresh",
  "ss_builder_replace": "replace",
  "ss_builder_replace_preview_theme_template_btn_label": "Apply",
  "ss_builder_replace_theme_label": "Apply sample content",
  "ss_builder_replace_theme_modal_content": "Apply theme template content will replace all blocks in your site by blocks from Theme Template",
  "ss_builder_replace_theme_modal_title": "Do you want to apply theme sample data?",
  "ss_builder_replace_theme_template_successfully_label": "Apply successfully",
  "ss_builder_report_sent_report_successfully": "Report sent successfully",
  "ss_builder_report_sent_report_successfully_description": "Thank you! A troubleshooting report has been sent to support",
  "ss_builder_restore_theme_content": "All theme settings and data of blocks inside the theme will be restored to published theme. Do you want to continue?",
  "ss_builder_restore_theme_label": "Restore editing",
  "ss_builder_restore_theme_successfully_label": "Successfully restored editing",
  "ss_builder_restore_theme_title": "Do you want to restore edits?",
  "ss_builder_revenue_group_description": "Start earning money now with Passio Page services",
  "ss_builder_revenue_title": "Monetization",
  "ss_builder_round_photo_label": "Rounded photo",
  "ss_builder_rounded": "Rounded",
  "ss_builder_sample_change_label": "Change template",
  "ss_builder_sample_selection_label": "Select a style",
  "ss_builder_sampling_change_label": "Select template",
  "ss_builder_save": "Save",
  "ss_builder_save_new_template_title": "Save as new template",
  "ss_builder_save_new_theme_template_successfully_label": "Save new theme successfully",
  "ss_builder_save_new_theme_template_title": "Save new theme template",
  "ss_builder_save_succesfully_description": "Your changes have been saved.",
  "ss_builder_save_succesfully_label": "Saved successfully",
  "ss_builder_search_results_theme_store": "Search results",
  "ss_builder_search_type_block": "Search block type by name",
  "ss_builder_see_less": "See less",
  "ss_builder_see_more": "See more",
  "ss_builder_see_more_btn_default_title": "See more",
  "ss_builder_select_label": "Select",
  "ss_builder_select_link_type": "Select a link type",
  "ss_builder_select_multi_items": "Select",
  "ss_builder_select_position_add_block": "Select position to add block",
  "ss_builder_select_ratio_title": "Select a ratio",
  "ss_builder_select_theme_setting_primary_color_label": "Choose the primary color",
  "ss_builder_selected_product_in_selection_label": "selected products",
  "ss_builder_send": "Send",
  "ss_builder_send_report": "Send report",
  "ss_builder_service_placeholder": "Choose a service",
  "ss_builder_set_default_tab_disable_content": "Default Tab will be displayed first when an user enters your page. Tab ${defaultNameTab} is hidden. Set this tab as default will change this tab status to visible. Do you want to set tab \"{{tab_name}}\" as default?",
  "ss_builder_set_default_tab_enable_content": "Default Tab will be displayed first when an user enters your page. Do you want to set tab \"{{tab_name}}\" as default?",
  "ss_builder_set_default_tab_label": "Set default",
  "ss_builder_set_default_tab_successfully_label": "Set default successfully",
  "ss_builder_set_default_tab_title": "Confirm to set default",
  "ss_builder_setting_font_weight": "Font weight",
  "ss_builder_setting_line_height": "Line height",
  "ss_builder_setting_popover_manual_content": "Get to know how to use the Settings screen, let's press the “Get Started” button.",
  "ss_builder_setting_popover_manual_title": "Product tour",
  "ss_builder_setting_saving": "Saving data",
  "ss_builder_setting_text_font": "Font family",
  "ss_builder_setting_text_scale": "Scale",
  "ss_builder_setting_text_size": "Font size (px)",
  "ss_builder_setting_title": "Settings",
  "ss_builder_setting_tour_content_step_1": "This is your site URL. You can copy, open or share the page path. In addition, it is also possible to quickly edit the page's favicon and domain name.",
  "ss_builder_setting_tour_content_step_2": "You can customize the general Theme Settings here. These settings will apply to your entire site.",
  "ss_builder_setting_tour_content_step_3": "You can set up your personal branding, SEO settings, domain name for your site here.",
  "ss_builder_setting_tour_title_step_1": "Site url",
  "ss_builder_setting_tour_title_step_2": "Theme settings",
  "ss_builder_setting_tour_title_step_3": "Site settings",
  "ss_builder_settings_label": "Settings",
  "ss_builder_setup_site_setting": "Site settings",
  "ss_builder_setup_theme_setting": "Theme settings",
  "ss_builder_shadows_style": "Shadow",
  "ss_builder_shape_label": "Shape",
  "ss_builder_share_label": "Share",
  "ss_builder_show_block_label": "Show block",
  "ss_builder_show_card_label": "Show card",
  "ss_builder_show_card_successfully_label": "Show card successfully",
  "ss_builder_show_image_label": "Show image",
  "ss_builder_show_link_label": "Show link",
  "ss_builder_show_link_successfully_label": "Show successful link",
  "ss_builder_show_product_group_label": "Show group",
  "ss_builder_show_product_label": "Show product",
  "ss_builder_show_social_link_label": "Show",
  "ss_builder_show_tab_label": "Show Tab",
  "ss_builder_show_template_successfully_label": "Show template successfully",
  "ss_builder_show_theme_template_label": "Show template",
  "ss_builder_site_brand_setting_label": "Personal brand",
  "ss_builder_site_domain_setting_label": "Domain",
  "ss_builder_site_setting_browser_label": "Browser",
  "ss_builder_site_setting_coppy_label": "Copy",
  "ss_builder_site_setting_favicon_title": "Favicon",
  "ss_builder_site_setting_favicon_upload_description": "The favicon is displayed next to the page's link. Upload any one of your favorite images to make Favicon.",
  "ss_builder_site_setting_label": "Site setting",
  "ss_builder_site_setting_premium": "Premium",
  "ss_builder_site_setting_share_label": "Share",
  "ss_builder_site_setting_standard": "Standard",
  "ss_builder_site_setting_upgrade": "Upgrade",
  "ss_builder_skip": "Skip",
  "ss_builder_slide_type_label": "Slider type",
  "ss_builder_social_link_btn_add_new_label": "Add social network",
  "ss_builder_social_links_list_title": "List of social links",
  "ss_builder_social_network_label": "Social network",
  "ss_builder_soft_shadow": "Soft shadow",
  "ss_builder_solid": "Solid",
  "ss_builder_solid_type_label": "Solid",
  "ss_builder_something_went_wrong": "Sorry, something went wrong",
  "ss_builder_spacer_label": "Spacing (px)",
  "ss_builder_spotlight_list_empty_content": "To add a new spotlight please press the “Add” button above",
  "ss_builder_spotlight_list_empty_title": "No spotlight has been added yet",
  "ss_builder_square": "Square",
  "ss_builder_square_photo_label": "Squared photo",
  "ss_builder_start_use_passio_page": "Start using Passio Page now",
  "ss_builder_stop_label": "Stop",
  "ss_builder_story": "Spotlight",
  "ss_builder_story_title_place_holder": "Spotlight title",
  "ss_builder_style": "Style",
  "ss_builder_style_always_ghim_tab_label": "Always sticky",
  "ss_builder_style_auto_ghim_tab_label": "Auto sticky",
  "ss_builder_style_ghim_tab_selection_label": "Tab sticky",
  "ss_builder_style_grid_label": "Grid",
  "ss_builder_style_masonry_label": "Masonry",
  "ss_builder_style_not_ghim_tab_label": "No sticky",
  "ss_builder_style_slide_label": "Slide",
  "ss_builder_style_tab_icon_label": "Icon only",
  "ss_builder_style_tab_only_icon": "Icon only",
  "ss_builder_style_tab_only_image": "Image only",
  "ss_builder_style_tab_selection_label": "Style",
  "ss_builder_style_tab_text_and_icon_label": "Text & Icon",
  "ss_builder_style_tab_text_and_image_label": "Text & image",
  "ss_builder_style_tab_text_label": "Text only",
  "ss_builder_subtitle_site_setting_description": "Gives an overview of the content and information the site has to offer. Search engines use this to display website descriptions.",
  "ss_builder_subtitle_site_setting_label": "Site description",
  "ss_builder_suggested_color_panel_label": "Suggested color palette",
  "ss_builder_support_upload_image": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 30MB",
  "ss_builder_support_upload_image_v2": "Support: JPG, JPEG, GIF, PNG, BMP up to 30MB",
  "ss_builder_support_upload_large_image": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 50MB",
  "ss_builder_swiper_setting_premium_description_1": "Unlimited features",
  "ss_builder_swiper_setting_premium_title_1": "Premium Account",
  "ss_builder_swiper_setting_premium_title_2": "Exclusive themes",
  "ss_builder_swiper_setting_premium_title_3": "Custom domain yourdomain.com",
  "ss_builder_swiper_setting_premium_title_4": "Exclusive brand campaign",
  "ss_builder_swiper_setting_premium_title_5": "Fast withdraw",
  "ss_builder_swiper_setting_premium_title_6": "Save 25% on platform fees",
  "ss_builder_tab_layout_1": "Text on the right",
  "ss_builder_tab_layout_2": "Text below",
  "ss_builder_tabs_label": "Tabs",
  "ss_builder_template_overwrite_title": "Overwrite template",
  "ss_builder_template_search_bar_placeholder": "Search templates by name...",
  "ss_builder_template_store_instruction_subtitle": "Data and settings of selected block template will be applied to current block",
  "ss_builder_template_store_instruction_title": "Please choose a block template you want!",
  "ss_builder_template_title": "Template",
  "ss_builder_text_editor_max_length": "Enter maximum {{editor_max_length}} characters",
  "ss_builder_text_spin_loading": "Loading page",
  "ss_builder_the_application_of_the_template_will": "Applying block template will",
  "ss_builder_theme_big_button_setting": "Big button",
  "ss_builder_theme_button_setting_general": "General",
  "ss_builder_theme_display_setting": "Display setting",
  "ss_builder_theme_medium_button_setting": "Medium button",
  "ss_builder_theme_setting_background": "Background",
  "ss_builder_theme_setting_background_color_preview_title": "Preview",
  "ss_builder_theme_setting_background_color_selector_label": "Color",
  "ss_builder_theme_setting_background_content": "Max file size: 30MB.\nSupport file format: jpg, jpeg, gif, png, bmp, webp",
  "ss_builder_theme_setting_background_content_v2": "Max file size: 30MB.\nSupport file format: jpg, jpeg, gif, png, bmp",
  "ss_builder_theme_setting_background_image_preview_label": "Background Image",
  "ss_builder_theme_setting_background_image_selector_label": "Image",
  "ss_builder_theme_setting_background_image_title": "Background image",
  "ss_builder_theme_setting_block_background": "Background color",
  "ss_builder_theme_setting_block_border": "Border",
  "ss_builder_theme_setting_block_shadow": "Shadow",
  "ss_builder_theme_setting_block_style": "Style",
  "ss_builder_theme_setting_button": "Button",
  "ss_builder_theme_setting_font_title": "Text settings",
  "ss_builder_theme_setting_font_type_content": "Choose the typeface to apply to your entire site",
  "ss_builder_theme_setting_font_type_title": "Font type",
  "ss_builder_theme_setting_gadient_color": "Color",
  "ss_builder_theme_setting_general": "General",
  "ss_builder_theme_setting_gradient_color_direction_label": "Direction",
  "ss_builder_theme_setting_hard_shadow": "Hard shadow",
  "ss_builder_theme_setting_intensity_color_label": "Color intensity",
  "ss_builder_theme_setting_label": "Theme setting",
  "ss_builder_theme_setting_no_shadow": "No shadow",
  "ss_builder_theme_setting_paragraph": "Paragraph",
  "ss_builder_theme_setting_primary_color": "Color",
  "ss_builder_theme_setting_primary_color_content": "Choose the primary color to apply to your entire site",
  "ss_builder_theme_setting_primary_color_title": "Primary color",
  "ss_builder_theme_setting_save_failed_content": "An unexpected error occurred. Please try again.",
  "ss_builder_theme_setting_save_failed_title": "Error",
  "ss_builder_theme_setting_save_successfully_label": "Save the setting successfully",
  "ss_builder_theme_setting_select_background_color_label": "Choose color type",
  "ss_builder_theme_setting_select_gradient_color_label": "Choose gradient color",
  "ss_builder_theme_setting_select_solid_color_label": "Choose a color",
  "ss_builder_theme_setting_shadow": "Shadow",
  "ss_builder_theme_setting_shadow_color": "Shadow color",
  "ss_builder_theme_setting_soft_shadow": "Soft shadow",
  "ss_builder_theme_setting_text": "Button text",
  "ss_builder_theme_setting_title": "Title",
  "ss_builder_theme_small_button_setting": "Small button",
  "ss_builder_theme_store_label": "Template",
  "ss_builder_theme_store_popover_manual_content": "Get to know how to use the Theme store, let's press the “Get Started” button.",
  "ss_builder_theme_store_popover_manual_title": "Product tour",
  "ss_builder_theme_store_tour_content_step_1": "Search the theme quickly using theme name",
  "ss_builder_theme_store_tour_content_step_2": "Passio Page offers a lot of theme templates for you to choose. Let's apply a template you like to experience.",
  "ss_builder_theme_store_tour_title_step_1": "Search the theme template",
  "ss_builder_theme_store_tour_title_step_2": "Theme templates",
  "ss_builder_theme_template_format_create_label": "Create",
  "ss_builder_theme_template_label_tag": "Tags",
  "ss_builder_theme_template_label_tag_placeholder": "Enter tags",
  "ss_builder_theme_template_no_option": "No option",
  "ss_builder_theme_template_preview_info": "Preview your site after applying theme template",
  "ss_builder_theme_template_preview_mode_title": "Preview mode",
  "ss_builder_theme_template_search_bar_placeholder": "Search theme templates by name...",
  "ss_builder_theme_template_store_instruction_subtitle": "Theme settings of selected theme template will be applied to entrie site",
  "ss_builder_theme_template_store_instruction_title": "Please choose the theme you want!",
  "ss_builder_theme_template_store_title": "Theme store",
  "ss_builder_theme_template_theme_store": "{{num_results}} theme template(s)",
  "ss_builder_theme_template_view_theme_info": "Theme template with sample data",
  "ss_builder_theme_template_view_theme_title": "View template",
  "ss_builder_title": "Title",
  "ss_builder_title_block_affiliate_product": "Affiliate",
  "ss_builder_title_block_bio": "Bio",
  "ss_builder_title_block_booking_profile": "Booking Profile",
  "ss_builder_title_block_booking_profile_description": "Add a link to your Booking Profile page or display your Booking Profile inside the Passio Page",
  "ss_builder_title_block_booking_profile_warning": "Create a Booking Profile to use this block",
  "ss_builder_title_block_brand_gift": "Brand Gift",
  "ss_builder_title_block_brand_gift_description": "Show Icon for Brand Gift",
  "ss_builder_title_block_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_block_brand_rating": "Brand Ratings",
  "ss_builder_title_block_brand_rating_description": "en_Bật tính năng để tăng tương tác với Fan",
  "ss_builder_title_block_button_link": "Link",
  "ss_builder_title_block_button_multi_link": "Multi Links",
  "ss_builder_title_block_card": "Cards",
  "ss_builder_title_block_digital_product": "Digital Product",
  "ss_builder_title_block_donation": "Donation",
  "ss_builder_title_block_embed": "Embed",
  "ss_builder_title_block_image": "Image",
  "ss_builder_title_block_image_gallery": "Image Gallery",
  "ss_builder_title_block_image_popup": "Image popup",
  "ss_builder_title_block_image_slider": "Image Slider",
  "ss_builder_title_block_story": "Spotlight",
  "ss_builder_title_block_tab_bar": "Tab Menu",
  "ss_builder_title_block_text": "Text",
  "ss_builder_title_block_truth_or_dare": "Truth Or Dare",
  "ss_builder_title_block_truth_or_dare_footer": "Truth Or Dare Icon",
  "ss_builder_title_block_video_shoutout": "Video Shoutout",
  "ss_builder_title_block_video_shoutout_description": "Video gifts from your idol",
  "ss_builder_title_block_virtual_gift": "Virtual Gift",
  "ss_builder_title_block_virtual_gift_footer": "Virtual Gift Icon",
  "ss_builder_title_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_color": "Text color",
  "ss_builder_title_combined": "Combined block",
  "ss_builder_title_introduction_service": "Service details",
  "ss_builder_title_page_builder": "Passio Page Builder",
  "ss_builder_title_setting_title": "Title Settings",
  "ss_builder_title_spacer_and_divider": "Spacer & Divider",
  "ss_builder_to_add_products_please_press_the_add_button_above": "To add a new product please press the “Add” button above",
  "ss_builder_to_new_version": "Go to Passio Page",
  "ss_builder_top_position_tab_label": "Top",
  "ss_builder_transparent": "Transparency",
  "ss_builder_truth_or_dare_warning": "Only able to add one block",
  "ss_builder_try_another_keyword_theme_store": "No results found. Please try with another keyword.",
  "ss_builder_try_new_key": "Please try with other keywords",
  "ss_builder_type_label": "Type",
  "ss_builder_type_of_color": "Color type",
  "ss_builder_undo_theme_label": "Undo",
  "ss_builder_update_account": "Upgrade account",
  "ss_builder_upload_button_label": "Upload image",
  "ss_builder_upload_file_format_invalid_content": "Invalid image format. Supported format: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_file_format_invalid_content_for_gif_png_svg": "Invalid image format. Supported format: GIF, PNG, SVG",
  "ss_builder_upload_file_format_invalid_content_v2": "Invalid image format. Supported format: JPG, JPEG, GIF, PNG, BMP",
  "ss_builder_upload_file_size_invalid_content": "Please choose a file with a maximum size of 30MB",
  "ss_builder_upload_file_size_invalid_content_min_256": "Please choose an image with a minimum size of 256x256px",
  "ss_builder_upload_large_file_format_invalid_content": "Invalid image format. Supported format: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_large_file_size_invalid_content": "Please choose a file with a maximum size of 50MB",
  "ss_builder_uploading_image": "Uploading image",
  "ss_builder_url_error_content": "Could not get information. Please check site URL.",
  "ss_builder_url_placeholder": "Enter url",
  "ss_builder_use_advanced_setting_description": "Enable advanced settings to customize the display settings of this Combined Block",
  "ss_builder_use_affiliate_link_description": "Check to use Affiliate Link of Ecomobi Passio. Uncheck to use original link",
  "ss_builder_use_affiliate_link_label": "Use Ecomobi Passio tracking link",
  "ss_builder_use_ecomobi_tracking_link": "Use Ecomobi Passio tracking link",
  "ss_builder_validate_gen_link_error_E1": "Token is invalid",
  "ss_builder_validate_gen_link_error_E2": "Your account is locked, you will not receive commission.",
  "ss_builder_validate_gen_link_error_E3": "This link is not supported with affiliate tracking, you will not receive commission.",
  "ss_builder_validate_gen_link_error_E5": "This link will not generate commission because campaign has not been aprroved. You need request to join the campaign.",
  "ss_builder_validate_gen_link_error_E6": "This link will not generate commission because you have been blocked from this campaign.",
  "ss_builder_validate_gen_link_error_E7": "Can not generate shorten link due system error.",
  "ss_builder_validate_gen_link_error_E8": "This link will not generate commission because the campaign is stopped.",
  "ss_builder_video_shoutout_answer_method_description": "If you accept the request of creator fans, you need to reply with a video",
  "ss_builder_video_shoutout_answer_method_title": "Answer method",
  "ss_builder_video_shoutout_btn_setup": "Set now",
  "ss_builder_video_shoutout_des_intro_fan": "You have 7 days to decide or reject fans' requests. If you refuse to do it, Ecomobi will refund all the paid money to the requester within 30 days.",
  "ss_builder_video_shoutout_des_intro_gift": "Through Passio, your fans can send you video requirements for special occasions: birthday, congratulations, anniversaries, video encouragement, inspiration ... with a certain fee Designed by yourself.",
  "ss_builder_video_shoutout_des_intro_policy": "You and fans can share these meaningful videos with people through social networking sites.",
  "ss_builder_video_shoutout_how_to_work": "How does the shoutout video work?",
  "ss_builder_video_shoutout_introduction": "Video Shoutout is a service that allows fans to send to Creator requirements for a personalized video for specific occasions such as birthday, graduation, congratulations, ... Creator will respond to those requirements with one with one with one with one with one. video",
  "ss_builder_video_shoutout_participate_description": "Creator can choose to make or reject fans' requirements",
  "ss_builder_video_shoutout_participate_title": "The right to join you",
  "ss_builder_video_shoutout_response_description": "After the fans submitted the request, Creator had 7 days to make the video. After this time, the amount of that request will be returned to fans",
  "ss_builder_video_shoutout_response_title": "Response time",
  "ss_builder_video_shoutout_title_intro": "Service introduction",
  "ss_builder_video_shoutout_title_intro_fan": "Make video",
  "ss_builder_video_shoutout_title_intro_gift": "Receive requirements from fans",
  "ss_builder_video_shoutout_title_intro_policy": "Share meaningful moments.",
  "ss_builder_virtual_gift_list_title": "Gift list",
  "ss_builder_wait_for_saving": "The saving process hasn't complete. Please wait a moment.",
  "ss_builder_warning_brand_gift_floating_continue": "Continue",
  "ss_builder_warning_brand_gift_floating_description": "Your floating icons position is now full. You need to remove the block in the floating icon group in the extended position. See",
  "ss_builder_warning_brand_gift_floating_description_link": "the detail instruction",
  "ss_builder_warning_brand_gift_floating_reject": "Cancel",
  "ss_builder_warning_brand_gift_floating_title": "Floating icons position is full",
  "ss_builder_warning_brand_gift_rating_description": "en_Hiện tại thân trang đã đạt tới giới hạn hiển thị. Bạn cần xoá bớt khối để bật tính năng Brand Ratings",
  "ss_builder_warning_brand_rating_floating_continue": "en_Đã hiểu",
  "ss_builder_warning_brand_rating_floating_title": "en_Brand Ratings",
  "ss_builder_web_browser": "Web browser",
  "ss_builder_web_label": "Ss Builder Web Label",
  "ss_builder_website_config_site_title_description": "Always displayed at the top of the page when fans visit.",
  "ss_builder_website_config_site_title_label": "Site name",
  "ss_builder_website_config_site_title_placeholder": "Enter content",
  "ss_builder_what_field_are_you_operating_in": "Which field are you working in?",
  "ss_builder_what_went_wrong": "What went wrong?",
  "ss_builder_width_tab_setting_label": "Tab width",
  "ss_builder_width_tab_type_1": "Auto",
  "ss_builder_width_tab_type_2": "Equally divided",
  "ss_builder_your_page_is_ready": "Your bio page is ready!",
  "ss_cancle_delete": "Cancel",
  "ss_challenge": "Challenge",
  "ss_confirm_delete": "Confirm delete",
  "ss_content_confirm_delete": "Are you sure you want to delete this product?",
  "ss_content_delete_forever": "To delete the product please return to the product management page",
  "ss_content_intro_dp": "Digital products are understood as online products, not grasped, containing useful content for users. For example: Video, photos, ebook, online course, ..",
  "ss_content_item1_dp": "Depending on your strengths and desires, Passio offers a full range of digital products such as photos, videos, online courses, ebooks, links, ...",
  "ss_content_item2_dp": "With less than 3 minutes, you can configure and sell a new digital product. Moreover, after the buyer successfully buys, the product will be sent immediately to their email, helping to simplify shipping and save time",
  "ss_content_success_TOD": "You have successfully installed Truth or Dare feature.",
  "ss_cover_image": "Cover image",
  "ss_cover_image_empty": "No image selected",
  "ss_cover_image_helper": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, max 4MB",
  "ss_cover_image_upload": "Upload image",
  "ss_delete_forever": "Permanently deleted",
  "ss_delete_product": "Delete display",
  "ss_display_delete": "Delete display",
  "ss_donation_currency_shortcut": "₱",
  "ss_donation_setting_default_content_setup_donation": "This service is being set as default. If you want to make changes, please access the Donation service here: account.passio.eco",
  "ss_donation_setting_default_title_setup_donation": "Service Settings",
  "ss_dp_add": "Add",
  "ss_dp_cat_course": "Course / Tutorial",
  "ss_dp_cat_ebook_document": "Ebook/Document",
  "ss_dp_cat_online_consultion": "Online consulting 1-1",
  "ss_dp_cat_random_product": "Products any",
  "ss_dp_cat_ticket_account": "Ticket/ Account",
  "ss_dp_cat_video": "Exclusive video",
  "ss_dp_sell_photo": "Image",
  "ss_edit_TOD": "Edit",
  "ss_example_TOD": "Example: 100,000",
  "ss_exit_TOD": "Exit",
  "ss_first_person": "Be the first to challenge now!",
  "ss_gift_button_preview": "Send gift now!",
  "ss_gift_invitation": "Motivate {user} by sending gifts!",
  "ss_had": "Already existed",
  "ss_iframe_add_new_product": "Add new product",
  "ss_iframe_add_product": "Add product",
  "ss_iframe_choose_product_from_store": "Choose from data store",
  "ss_inline_image_empty": "You cannot leave this field blank",
  "ss_inline_txtbtn_max_length": "This field exceeds 40 characters.",
  "ss_input_placehoder": "Enter content",
  "ss_invitation_TOD": "Challenge invitation description",
  "ss_invitation_TOD_placeholder": "Enter the content invite fans to join",
  "ss_logout": "Log out",
  "ss_modal_cancel_confirm": "Cancel",
  "ss_modal_text_confirm": "Go to the Administration page",
  "ss_no": "No",
  "ss_placeholder_message_TOD": "Input your Dare",
  "ss_preview_TOD": "Continue",
  "ss_profile": "Profile",
  "ss_push_TOD": "Publish",
  "ss_rount_tod": "Challenges",
  "ss_service_introduction": "Service introduction",
  "ss_setting_TOD_background_tooltip": "Select wallpaper in 3:2 aspect ratio and maximum size of 4 MB",
  "ss_setting_TOD_field_image_background": "Add wallpaper",
  "ss_setting_TOD_field_image_background_bottom": "Background image",
  "ss_setting_TOD_field_welcome_tooltip": "The invitation will be filled automatically if you leave it blank",
  "ss_setup_now": "Set now",
  "ss_shoutout_btn_content": "Button content",
  "ss_shoutout_change_file": "Change photos/videos",
  "ss_shoutout_create_request": "Create immediately",
  "ss_shoutout_description": "Describe",
  "ss_shoutout_description_placeholder": "Enter the introduction content",
  "ss_shoutout_error_price_required": "Please enter the price",
  "ss_shoutout_error_upload": "Error occurs, uploaded failure",
  "ss_shoutout_error_video_size": "Please upload images/ videos up to 500MB",
  "ss_shoutout_error_video_type": "Please upload to the file as one of JPG, JPEG, GIF, PNG, BMP, Webp, HEIC, HEIC, HEIC, MP4, MOV",
  "ss_shoutout_file_intro": "Photo/video introduction",
  "ss_shoutout_file_intro_note": "Upload images or videos to call fans to use the shoutout video from you",
  "ss_shoutout_no_file_selected": "No photos/videos have been selected",
  "ss_shoutout_price": "Price",
  "ss_shoutout_price_placeholder": "Enter the price",
  "ss_shoutout_select_file": "Select photos/videos",
  "ss_shoutout_type_support": "Support: JPG, JPEG, GIF, PNG, BMP, Webp, HEIF, HEIC, MP4, MOV up to 500MB",
  "ss_shoutout_upload_success": "Upload the video to success",
  "ss_success_TOD": "Successful",
  "ss_success_off_TOD": "You have successfully disabled Truth or Dre feature.",
  "ss_success_on_TOD": "You have successfully enabled the Truth or Dare feature.",
  "ss_success_setup_TOD": "You have successfully installed Truth or Dare feature.",
  "ss_title_intro_dp": "What is digital product",
  "ss_title_item1_dp": "Diverse types of products for you to choose",
  "ss_title_item2_dp": "Easy to manage products and orders with a few operations",
  "ss_tod_challenge_number": "No of Challenges",
  "ss_tod_content": "Invitation content",
  "ss_tod_dare_content": "Call for challenges from your fans",
  "ss_tod_dare_fee": "Minimum amount paid for Dare by Fan",
  "ss_tod_default_content": "Dare me - I won’t bite",
  "ss_tod_default_dare_content": "Wanna get a little bit naughty? Try me!",
  "ss_tod_min_price_dare_alias": "Minimum amount paid for Dare by Fan",
  "ss_tod_min_price_dare_note": "Minimum payment is 1",
  "ss_tod_setting_cover_image": "Cover image",
  "ss_tod_setting_cover_image_helper": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, max 4MB",
  "ss_tod_setting_field_welcome_tooltip": "The invitation will be filled automatically if you leave it blank",
  "ss_tod_setting_input_placehoder": "Enter content",
  "ss_tod_tooltip_content": "Character limit is 60.",
  "ss_tod_tooltip_min_price_dare": "Invitation content is up to 60 characters",
  "ss_txt_btn_TOD": "Challenge invitation",
  "ss_validate_domain_error_E1": "The domain name has been changed within 30 days, cannot be changed",
  "ss_validate_domain_error_E2": "The domain name is already exists, please try another one",
  "ss_validate_domain_error_E3": "This domain is banned",
  "ss_validate_origin_url_error_E203": "Could not resolved the domain. Please check and try again.",
  "ss_validate_origin_url_error_E5": "You are not approved to join this campaign, please request to approve",
  "ss_validate_origin_url_error_E6": "You are blocked in campaign, please contact your account manager",
  "ss_validate_origin_url_error_E7": "This URL is not supported",
  "ss_validate_origin_url_error_E9": "This campaign is stopped, please enter another URL",
  "ss_validate_origin_url_error_V0": "Invalid Url",
  "ss_validate_origin_url_error_sys": "Please enter a valid URL",
  "ss_validate_origin_url_warning_E5": "You haven't request for this campaign. Orders and commission will not be counted.",
  "ss_validate_origin_url_warning_E7": "This url isn't supported. Orders and commission will not be counted.",
  "ss_validate_origin_url_warning_E9": "This campaign is stopped. Order and Commission will not be counted.",
  "text_cashback_brand_gift": "Cashback",
  "text_custom_brand_gift": "Customize",
  "text_voucher_brand_gift": "Voucher",
  "undefined": "Undefined",
  "voucher_default_text_button": "Get your voucher now!",
  "voucher_default_title": "Tap pay, 5% cashback",
  "warning_text_TOD": "Your settings have not been saved. Do you still want to exit?",
  "warning_text_switch_TOD": "Do you want to disable Truth or Dare feature?"
} 
} 
export default en