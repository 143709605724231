import {
  BookingProfileInterface,
  CreatorProfileInterface,
  PassioPageProfileInterface,
  UpdateCreatorProfileInterface,
} from "@features/main/store/interface";
import axios from "axios";
import CreateAxios, { CreateAxiosSASS, CreateGatewayAxios } from "./createAxios";
import { setPassioPageProfile } from "@features/main/store/passioPageProfileSlice";
import { useAppDispatch } from "@app/hooks";

const baseUrl = process.env.NEXT_PUBLIC_BUILDER_API_PASSIO_URL;

const BUILDER_PATH = {
  SITE_SETTING: "site-setting",
  PUBLISH_SETTING: "site-setting/publish",
};

const API_PATH = {
  SELLER: "/api/v1/builder/seller",
  SITE_SETTING: "/api/v1/builder/site-setting",
  SITE_SETTING_PULISH: "/api/v1/builder/site-setting/publish",
  GET_LIST_CAMPAIGN: "/api/mini-store/v1/cpc/list/creator",
  UPDATE_CAMPAIGN_STATUS:
    "/api/mini-store/v1/cpc/creator-confirm-join-campaign",
  UPDATE_CAMPAIGN_IMAGE:
    "/api/mini-store/v1/cpc/creator-submit-acceptance-image",
  BOOKING_FILE_SELLER: "/api/v1/media-kit-builder/seller",
};

const instanceAxios = axios.create({
  baseURL: baseUrl,
});

const gatewayAxious = CreateGatewayAxios();
const Axious = CreateAxiosSASS();
const Caxios = CreateAxios();

export interface BaseUpdateDataInterface {
  user_id: string;
  site_setting_id: string;
  version_id: string;
}

export const getTestHeader = () => {
  gatewayAxious
    .get("api/builder/seller/header")
    .then((data) => console.log(data));
};

export const requestGetSite = (userId: string) =>
  instanceAxios
    .get(BUILDER_PATH.SITE_SETTING, { params: { user_id: userId } })
    .then(({ data }) => data)
    .catch((error) => {
      console.log(error);
    });

export const requestSaveSite = (dataResquest) =>
  instanceAxios
    .post(BUILDER_PATH.SITE_SETTING, dataResquest)
    .then(({ data }) => data)
    .catch((error) => {
      console.log(error);
    });

export const requestPublishSite = (dataResquest) =>
  instanceAxios
    .post(BUILDER_PATH.PUBLISH_SETTING, dataResquest)
    .then(({ data }) => data)
    .catch((error) => {
      console.log(error);
    });

export const publishSetting = async (dataRequest) => {
  const response = await gatewayAxious.post(
    API_PATH.SITE_SETTING_PULISH,
    dataRequest
  );
  return response.data;
};

export const fetchSiteSetting = async (userId: string) => {
  const response = await gatewayAxious.get(API_PATH.SITE_SETTING, {
    params: { user_id: userId },
  });
  return response.data;
};

export const saveSiteSetting = async (requestData) => {
  const response = await gatewayAxious.post(API_PATH.SITE_SETTING, requestData);
  return response.data;
};

export const fetchIcons = async (page = 1): Promise<Array<any>> => {
  const response = await gatewayAxious.get("/api/v1/builder/icons", {
    params: { page: page },
  });
  return response.data;
};

export const saveDisplaySetting = (userId: string, displaySetting) => {};

export const requestSaveThemeSettings = async (
  dataResquest
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/site-setting/display-setting",
    dataResquest
  );
  return response;
};

export const requestUploadImage = async (dataResquest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/image/upload",
    dataResquest
  );
  if (response && /^20[0-9]$/.test(response.status.toString())) {
    return response.data;
  }
  // return response;
};

export const requestRestoreTheme = async (dataResquest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/site-setting/reset",
    dataResquest
  );
  return response;
};

export const requestValidateUrl = async (urlValue): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/passio-page/aff/is-url-support",
    { params: { url: urlValue } }
  );
  return response;
};

export const fetchAffiliateProducts = async (
  search = "",
  limit = 10,
  page = 1
): Promise<Array<any>> => {
  const response = await gatewayAxious.get(
    "/api/v1/passio-page/aff/post-link/search",
    {
      params: { search: search, page: page, limit: limit },
    }
  );
  return response.data.data;
};

export const requestDetectProductUrl = async (urlValue): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/passio-page/aff/product/detect",
    { params: { url: urlValue } }
  );
  return response;
};

export const requestCreatePostLink = async (dataResquest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/passio-page/aff/post-link/create",
    dataResquest
  );
  return response;
};

export const requestGetListVirtualGift = async (data): Promise<Object> => {
  const { page, per_page, country_code, user_id } = data;
  const response = await gatewayAxious.get(
    "api/mini-store/v1/public/catalog/end-user/product/virtual-gift/list-cps",
    { params: { page, per_page, country_code, user_id } }
  );
  return response;
};

export const requestGetListDigitalProduct = async (data): Promise<Object> => {
  const { page, per_page, country_code, user_id } = data;
  const response = await gatewayAxious.get(
    "api/mini-store/v1/public/catalog/end-user/product/list-cps",
    { params: { page, per_page, country_code, user_id } }
  );
  return response;
};

export const checkLdpEmpty = async (user_id): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/passio-page/aff/is-ldp-empty",
    {
      params: { user_id },
    }
  );
  return response;
};

export const checkSettingEmpty = async (user_id): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/builder/site-setting/is-setting-empty",
    {
      params: { user_id },
    }
  );
  return response;
};

export const includeDataSettingTheme = async (user_id): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/builder/site-setting/is-setting-empty",
    {
      params: { user_id, include_data: true },
    }
  );
  return response;
};

export const fetchCategory = async () => {
  const response = await gatewayAxious.get("/api/v1/pub/builder/category");
  return response.data;
};

export const saveCategory = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/seller/update-category",
    requestData
  );
  return response;
};

export const applyThemeOnboarding = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/theme-template/apply-theme",
    requestData
  );
  return response;
};

export const finishOnboarding = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/passio-page/aff/finish-onboard",
    requestData
  );
  return response;
};

export const requestValidateDomain = async (domainValue): Promise<Object> => {
  let response = null;
  const dataRequest = {
    domain: domainValue,
  };
  try {
    response = await gatewayAxious.post(
      "/api/v1/passio-page/aff/domain/validate",
      dataRequest
    );
  } catch (error) {
    if (error) {
      response = error?.response;
    }
  }
  return response;
};

export const requestSaveSiteSetting = async (dataRequest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/website-config",
    dataRequest
  );
  return response;
};

export const requestGetSiteSetting = async (user_id): Promise<Object> => {
  const response = await gatewayAxious.get("/api/v1/builder/website-config", {
    params: { user_id },
  });
  return response;
};

export const requestSaveDomain = async (domainValue): Promise<Object> => {
  const dataRequest = {
    domain: domainValue,
  };
  const response = await gatewayAxious.post(
    "/api/v1/passio-page/aff/domain/update",
    dataRequest
  );
  return response;
};

export const requestSearchThemeTemplate = async (data): Promise<Object> => {
  const { per_page = 100, keyword, is_admin } = data;
  const response = await gatewayAxious.get(
    "/api/v1/builder/theme-template/search",
    {
      params: is_admin
        ? { keyword, per_page, is_admin: 1 }
        : { keyword, per_page },
    }
  );
  return response;
};

export const requestSaveThemeTemplate = async (
  dataRequest
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/theme-template",
    dataRequest
  );
  return response;
};

export const requestUpdateThemeTemplate = async (
  dataRequest
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/theme-template/update-info",
    dataRequest
  );
  return response;
};

export const requestDeleteThemeTemplate = async (id): Promise<Object> => {
  const response = await gatewayAxious.delete(
    "/api/v1/builder/theme-template",
    { params: { id } }
  );
  return response;
};

export const requestApplyThemeTemplate = async (
  dataRequest
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/theme-template/apply-theme",
    dataRequest
  );
  return response;
};

export const fetchPassioPageProfile = async (
  userId
): Promise<PassioPageProfileInterface> => {
  const response = await gatewayAxious.get(
    "/api/seller-landing/v1/public/seller-by-cps-id",
    { params: { cps_user_id: userId } }
  );
  return response?.data?.data;
};

export const requestReplaceThemeTemplate = async (
  dataRequest
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/theme-template/load-template-data",
    dataRequest
  );
  return response;
};

export const requestGetSelectedThemeTemplate = async (
  user_id
): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/builder/theme-template/get-selected-template",
    { params: { user_id } }
  );
  return response;
};
export const updateStore = async (params) => {
  return gatewayAxious.post("/api/mini-store/v1/store/creator", params);
};
export const saveSettingDonation = async (params) => {
  return gatewayAxious.post(
    "/api/mini-store/v1/catalog/setting-donation/gateway/save",
    params
  );
};
export const saveSettingTOD = async (params) => {
  return gatewayAxious.post(
    "/api/mini-store/v1/catalog/tod/gateway/save",
    params
  );
};

export const finishProductTour = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/passio-page/aff/finish-product-tour",
    requestData
  );

  return response;
};

export const sendReportError = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/error-report",
    requestData
  );

  return response;
};

export const requestSearchTag = async (data): Promise<Object> => {
  const { per_page = 100, keyword, is_admin, type } = data;
  const response = await gatewayAxious.get("/api/v1/builder/tag/search", {
    params: { keyword, per_page, type },
  });

  return response;
};

export const createTagThemeTemplate = async (dataRequest): Promise<Object> => {
  return await gatewayAxious.post("/api/v1/builder/tag", dataRequest);
};

export const createManyTagThemeTemplate = async (
  dataRequest
): Promise<Object> => {
  return await gatewayAxious.post(
    "/api/v1/builder/tag/create-many",
    dataRequest
  );
};

export const uploadPartFile = async (dataResquest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/image/upload-part-file",
    dataResquest
  );
  if (response && /^20[0-9]$/.test(response.status.toString())) {
    return response.data;
  }
  // return response;
};

export const mergeFileParts = async (dataResquest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/image/merge-file-parts",
    dataResquest
  );
  if (response && /^20[0-9]$/.test(response.status.toString())) {
    return response.data;
  }
  // return response;
};

export const requestSearchTemplate = async (data): Promise<Object> => {
  const { per_page = 100, keyword, is_admin, block_type } = data;
  const response = await gatewayAxious.get(
    "/api/v1/builder/block-template/list",
    {
      params: is_admin
        ? { keyword, per_page, is_admin, block_type }
        : { keyword, per_page, block_type },
    }
  );
  return response;
};

export const requestDeleteTemplate = async (id): Promise<Object> => {
  const response = await gatewayAxious.delete(
    "/api/v1/builder/block-template",
    { params: { id } }
  );
  return response;
};

export const requestUpdateTemplate = async (dataRequest): Promise<Object> => {
  const response = await gatewayAxious.post(
    "/api/v1/builder/block-template",
    dataRequest
  );
  return response;
};

export const requestGetTemplate = async (id): Promise<Object> => {
  const response = await gatewayAxious.get(
    "/api/v1/builder/block-template/get-data",
    {
      params: { id },
    }
  );
  return response;
};

export const fetchCategoryBuilder = async (
  params = {}
): Promise<Array<any>> => {
  const response = await gatewayAxious.get(
    "/api/v1/builder/icon-builder/categories",
    {
      params: params,
    }
  );
  return response.data;
};

export const getPackageOfUser = async () => {
  const response = await Axious.post("/api/auth/detail");
  return response;
};

export const getPackageSass = async (): Promise<Object> => {
  const response = await Axious.get("/api/saas/package/list");
  return response;
};

export const sendRating = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/rating-service/add",
    requestData
  );

  return response?.data;
};

export const sendRequestNoNeedToRating = async (
  requestData
): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/site-setting/no-need-to-rating",
    requestData
  );

  return response?.data;
};

export const sendRequestLogin = async (requestData): Promise<Object> => {
  const response = await gatewayAxious.post(
    "api/v1/builder/history",
    requestData
  );

  return response?.data;
};

export const fetchIconBuilder = async (params = {}): Promise<Array<any>> => {
  const response = await gatewayAxious.get("/api/v1/builder/icon-builder", {
    params: params,
  });
  return response.data;
};

export const fetchCreatorProfile =
  async (): Promise<CreatorProfileInterface> => {
    try {
      const response = await gatewayAxious.get(API_PATH.SELLER);

      return response?.data?.data;
    } catch (error) {
      console.log(error);

      return;
    }
  };

export const updateCreator = async (
  data: UpdateCreatorProfileInterface
): Promise<object> => {
  try {
    const response = await gatewayAxious.put(API_PATH.SELLER, data);
    return response?.data?.data;
  } catch (error) {
    console.log(error);

    return;
  }
};

export const getListCampaign = async () => {
  try {
    const response = await gatewayAxious.get(API_PATH.GET_LIST_CAMPAIGN);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const updateCampaignStatus = async (data) => {
  try {
    const response = await gatewayAxious.post(
      API_PATH.UPDATE_CAMPAIGN_STATUS,
      data
    );
    return response?.data?.data;
  } catch (error) {
    console.log(error);
    return;
  }
};
export const updateCampaignImage = async (data) => {
  try {
    const response = await gatewayAxious.post(
      API_PATH.UPDATE_CAMPAIGN_IMAGE,
      data
    );
    return response?.data?.success;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const fetchBookingProfile =
  async (): Promise<BookingProfileInterface> => {
    try {
      const response = await gatewayAxious.get(API_PATH.BOOKING_FILE_SELLER);

      return response?.data?.data;
    } catch (error) {
      console.log(error);
      return;
    }
  };
