import ItemsWithCheckbox from "@features/block/components/ItemsWithCheckbox";
import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";
import { ITEMS_MODE } from "@features/block/config/interface";
import { scrollToNewItem } from "@features/block/libs";
import FullScreenPopup from "@share/components/full-screen-popup";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import StickyChecking from "@share/components/StickyChecking";
import {
  AddIcon,
  CardIcon,
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
  MoreIcon,
  TickIcon,
} from "@share/icons";
import { Button, Image, List, Modal, Popover, Toast } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import { getPlaceholderProps } from "@share/lib";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CardItemAddNew from "../CardItemAddNew";
import CardModeSelect from "./CardModeSelect";

const ListItems = (props) => {
  const {
    blockType,
    listItemDataDefault,
    ITEM_MAXIMUM,
    onUpdateListDataItem,
    labelObject,
    imageRatio,
    setShowFooterBlockEdit,
  } = props;
  const [mode, setMode] = useState<ITEMS_MODE>(ITEMS_MODE.DRAG);

  const { t } = useTranslation();
  const popoverRef = useRef(null);
  const [listItemData, setListItemData] = useState(listItemDataDefault);
  const [itemEditData, setItemEditData] = useState(null);
  const [isOpenAddNewItem, setIsOpenAddNewItem] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const listItemDataSortOrder = useMemo(
    () => orderBy(listItemData, ["sort_order"], ["asc"]),
    [listItemData]
  );

  const cardEnable = useMemo(() => {
    return listItemData.filter((item) => item.enable === 1);
  }, [listItemData]);

  useEffect(() => {
    if (listItemDataSortOrder) onUpdateListDataItem(listItemDataSortOrder);
  }, [listItemDataSortOrder]);

  const handleClickMoreIcon = (event) => {
    // event.preventDefault();
    event.stopPropagation();
  };

  const handleClickPopoverItem = (item: Action, itemData: any) => {
    const key = item && item.key;
    switch (key) {
      case "eye-visible-item":
        onDisableEnableItem(itemData);
        break;
      case "delete-item":
        onRemoveItem(itemData);
        break;
      default:
      // to do
    }
  };

  const MoreElement = (props) => {
    const { itemData } = props;
    return (
      <div className="flex flex-row items-center gap-[15px]">
        <div
          className=""
          onClick={handleClickMoreIcon}
        >
          <Popover.Menu
            mode="dark"
            actions={[
              {
                key: "eye-visible-item",
                icon:
                  itemData?.enable !== 1 ? (
                    <EyeHiddenIcon className="" />
                  ) : (
                    <EyeVisibleIcon className="" />
                  ),
                text:
                  itemData?.enable !== 1
                    ? labelObject?.showItemLabel
                    : labelObject?.hideItemLabel,
              },
              {
                key: "delete-item",
                icon: <DeleteIcon className="" />,
                text: labelObject?.deleteItemLabel,
              },
            ]}
            placement="left"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => popoverRef.current}
            onAction={(item: Action) => handleClickPopoverItem(item, itemData)}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  const handleOpenAddItem = () => {
    setIsOpenAddNewItem(true);
    setItemEditData(null);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(listItemDataSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedImageList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedImageList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    setListItemData(itemsOrder);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const onRemoveItem = (itemData) => {
    const listItemDataSortOrderClone = cloneDeep(listItemDataSortOrder);
    const newItems =
      listItemDataSortOrderClone &&
      Array.isArray(listItemDataSortOrderClone) &&
      listItemDataSortOrderClone.filter((ele: any) => ele.uid !== itemData.uid);
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{labelObject?.confirmDeleteCardModalLabel}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {labelObject?.confirmDeleteCardModalDescription}
        </div>
      ),
      confirmText: labelObject?.confirmDeleteCardModalBtnLabel,
      cancelText: labelObject?.cancelDeleteCardModalBtnLabel,
      onConfirm: () => {
        setListItemData(newItems);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {labelObject?.deleteItemSuccessfullyLabel}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });
  };

  const onDisableEnableItem = (itemData) => {
    const listItemDataSortOrderClone = cloneDeep(listItemDataSortOrder);
    const newImageItems =
      listItemDataSortOrderClone &&
      Array.isArray(listItemDataSortOrderClone) &&
      listItemDataSortOrderClone.map((ele) => {
        return ele.uid === itemData.uid
          ? {
              ...ele,
              enable: itemData?.enable !== 1 ? 1 : 2,
            }
          : ele;
      });
    setListItemData(newImageItems);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {itemData?.enable === 1
            ? labelObject?.hideItemSuccessfullyLabel
            : labelObject?.showItemSuccessfullyLabel}
        </div>
      ),
    });
  };

  const listItemRate = useMemo(
    () =>
      listItemDataSortOrder &&
      `${listItemDataSortOrder.length}/${ITEM_MAXIMUM}`,
    [listItemDataSortOrder]
  );

  const handleEditItem = (item) => {
    setIsOpenAddNewItem(true);
    setItemEditData(item);
  };

  const handleSaveItemAddNew = (data) => {
    const listItemDataSortOrderClone = cloneDeep(listItemDataSortOrder);
    if (Array.isArray(listItemDataSortOrderClone)) {
      if (itemEditData) {
        const indexEditItems = listItemDataSortOrderClone.findIndex(
          (x) => x?.uid === itemEditData?.uid
        );
        listItemDataSortOrderClone[indexEditItems] = data;
      } else {
        if (listItemDataSortOrderClone.length === 0) {
          listItemDataSortOrderClone.push(data);
        } else {
          const sortIdArr = listItemDataSortOrderClone.map((x) => x.sort_order);
          listItemDataSortOrderClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      setListItemData(listItemDataSortOrderClone);
      !itemEditData && scrollToNewItem(`blocks-${data?.uid}`);
    }
  };

  const renderLinksSelectItemsMode = () => (
    <div className="mt-6">
      <ItemsWithCheckbox
        propertyIndex="uid"
        onCancel={swichToDragMode}
        item={CardModeSelect as any}
        onChange={setListItemData}
        defaultItems={listItemDataSortOrder.map((e) => ({
          id: e.uid,
          ...e,
        }))}
        header={{
          title: labelObject?.listTitle,
          sticky: true,
        }}
        footer={{
          countTitle: t("ss_builder_filter_select_multi_items_card_count"),
        }}
        blockType={blockType}
      />
    </div>
  );

  const swichToDragMode = () => {
    setMode(ITEMS_MODE.DRAG);
    setShowFooterBlockEdit(true);
  };
  const switchToSelectItemsMode = () => {
    setMode(ITEMS_MODE.SELECT_ITEMS);
    setShowFooterBlockEdit(false);
  };

  return (
    <>
      {mode === ITEMS_MODE.DRAG && (
        <div className="relative">
          <StickyChecking previousStickyElementId="block-card-editor-header">
            <div className="flex flex-row justify-between px-3 pt-4 pb-3">
              <div className="grow">
                <div className="text-[15px] text-[#666666]">
                  <span className="text-red-600">*</span>
                  {labelObject?.listTitle}
                </div>
              </div>
              <div className="flex flex-grow justify-end gap-2">
                <Button
                  size="small"
                  fill="solid"
                  color="primary"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    padding: "3px 12px 3px 12px",
                  }}
                  onClick={handleOpenAddItem}
                  disabled={listItemDataSortOrder.length === ITEM_MAXIMUM}
                >
                  <AddIcon className="w-[15px] h-[15px]" />
                  <div>{t("ss_builder_add_new_label")}</div>
                  <span>{listItemRate}</span>
                </Button>
                {listItemDataSortOrder.length > 0 && (
                  <Button
                    onClick={switchToSelectItemsMode}
                    color="primary"
                    fill="outline"
                    size="small"
                  >
                    {t("ss_builder_select_label")}
                  </Button>
                )}
              </div>
            </div>
            {cardEnable.length === 0 && listItemData.length > 0 && (
              <div
                className="px-3 pb-4 text-[13px] font-normal leading-[17px]"
                style={{ color: "var(--adm-color-danger)" }}
              >
                {t("ss_builder_need_to_display_at_least_one_card")}
              </div>
            )}
          </StickyChecking>
          {listItemDataSortOrder?.length > 0 ? (
            <div>
              <DragDropContext
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <Droppable droppableId={`blocks-${blockType}`}>
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="relative"
                    >
                      {listItemDataSortOrder?.length > 0 &&
                        listItemDataSortOrder.map((ele, index) => {
                          const linkDatavalue =
                            ele.link_url || ele?.link_data?.value;
                          return (
                            <Draggable
                              draggableId={`blocks-${ele.uid}`}
                              index={index}
                              key={`blocks-${ele.uid}`}
                            >
                              {(provided, snapshot): JSX.Element => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={cls(
                                    "mt-2",
                                    snapshot.isDragging && "adm-item-custom"
                                  )}
                                >
                                  <div ref={popoverRef}>
                                    <List className="">
                                      <List.Item
                                        prefix={<DragIcon className="" />}
                                        extra={<MoreElement itemData={ele} />}
                                        onClick={() => handleEditItem(ele)}
                                      >
                                        <div
                                          className={cls(
                                            "flex items-center gap-[6px] flex-row",
                                            ele.enable !== 1 && "opacity-40"
                                          )}
                                        >
                                          <Image
                                            src={ele.image}
                                            width={40}
                                            height={40}
                                            fit="cover"
                                            style={{
                                              borderRadius: "4px",
                                            }}
                                          />
                                          <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
                                            <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                                              {ele.card_title}
                                            </div>
                                            <div
                                              className="flex"
                                              style={{ marginTop: "2px" }}
                                            >
                                              {ele?.use_aff_url === 1 &&
                                              linkDatavalue ? (
                                                <>
                                                  <TickIcon />
                                                  <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                                                    {t(
                                                      "ss_builder_product_link_wrapped_label"
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                <span className="text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                                                  {ele?.link_data?.type !==
                                                  LinkTypeEnum.SERVICE
                                                    ? linkDatavalue
                                                    : t(
                                                        getServiceLabelKey(
                                                          linkDatavalue
                                                        )
                                                      )}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </List.Item>
                                    </List>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                      {!isEmpty(placeholderProps) &&
                        snapshot.isDraggingOver && (
                          <PlaceholderCustomDragging
                            placeholderProps={placeholderProps}
                            content={t(
                              "ss_builder_placeholder_dragging_content"
                            )}
                            style={{
                              backgroundColor: "rgba(230, 247, 255, 0.75)",
                              border: "1px dashed #1890FF",
                              borderRadius: "4px",
                              color: "#1890FF",
                            }}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center">
              <span className="inline-block mb-2">
                <CardIcon className="align-middle w-8 h-8" />
              </span>
              <div className="text-[#333333] text-lg leading-6">
                {labelObject?.listEmptyLabel}
              </div>
              <div className="text-[#8C8C8C] text-xs leading-4">
                {labelObject?.listEmptyDescription}
              </div>
            </div>
          )}
        </div>
      )}
      {mode === ITEMS_MODE.SELECT_ITEMS && renderLinksSelectItemsMode()}
      <FullScreenPopup
        visible={isOpenAddNewItem}
        onMaskClick={() => setIsOpenAddNewItem(false)}
      >
        <CardItemAddNew
          imageRatio={imageRatio}
          callBackCancelCardAddNew={() => setIsOpenAddNewItem(false)}
          cardEditData={itemEditData}
          indexCardEdit={
            itemEditData
              ? listItemData?.findIndex((x) => x?.uid === itemEditData?.uid)
              : null
          }
          blockType={blockType}
          handleSaveCardAddNew={handleSaveItemAddNew}
        />
      </FullScreenPopup>
    </>
  );
};

export default ListItems;
