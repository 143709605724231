import { useAppDispatch, useAppSelector } from "@app/hooks";
import BlockWrapper from "@features/design/components/BlockWrapper";
import { Block } from "@features/main/store/interface";
import { fetchStoreToken } from "@features/mini-store/share/api";
import { useAppTranslation } from "@features/mini-store/share/hook";
import React from "react";
import { useQuery } from "react-query";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import { KEY_BLOCK } from "@share/configs/const";
import Image from "next/image";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
export default function BlockVirtualGiftFooter(props: Props) {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;
  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const allowService = window["AllowStore"];

  const { t } = useAppTranslation();
  const loadStore = async () => {
    const store = await fetchStoreToken();
    if (store) {
      return store;
    }
  };
  const { isLoading, data: store } = useQuery("LoadStore", loadStore, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <>loading</>;
  }
  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      extraStyle={{
        padding: "0",
        // border: "0",
        borderWidth: "0",
        background: "transparent",
        backdropFilter: "none",
        borderStyle: "none",
        boxShadow: "none",
      }}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div
        className={`flex flex-col justify-center items-center p-3 rounded-lg ${
          (!store?.virtual_gift_status ||
            !allowService?.allow_view_virtual_gift) &&
          "opacity-50"
        }`}
        style={{
          border: "1px solid rgba(130, 130, 130, 0.75)",
        }}
      >
        {/* <GiftIconV2 className="align-middle inline-block rounded-lg" /> */}
        <img src="https://image.passio.eco/page-builder/page-builder/service-icon/gift.png" />
      </div>
    </BlockWrapper>
  );
}
