import { Divider, Selector, Switch } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultsLayoutSetting from "./DefaultsLayoutSetting";
import ImageLayoutSetting from "./ImageLayoutSetting";

export enum LAYOUT_TYPE_ENUM {
  DEFAULTS = "defaults",
  IMAGE = "image",
}

const DividerSetting = (props) => {
  const {
    onUpdateSettingData,
    onCheckDividerEnable,
    layout_type,
    enableDivider,
  } = props;

  const { t } = useTranslation();
  const [isShowDividerSetting, setIsShowDividerSetting] =
    useState(enableDivider);
  const [layoutType, setLayoutType] = useState(layout_type);

  useEffect(() => {
    setIsShowDividerSetting(enableDivider);
  }, [enableDivider]);

  useEffect(() => {
    setLayoutType(layout_type);
  }, [layout_type]);

  useEffect(() => {
    onCheckDividerEnable(isShowDividerSetting);
  }, [isShowDividerSetting]);

  useEffect(() => {
    onUpdateSettingData("layout_type", layoutType);
  }, [layoutType]);

  const layoutOptions = [
    {
      label: t("ss_builder_defaults_label"),
      value: LAYOUT_TYPE_ENUM.DEFAULTS,
    },
    {
      label: t("ss_builder_theme_setting_background_image_selector_label"),
      value: LAYOUT_TYPE_ENUM.IMAGE,
    },
  ];

  return (
    <div className="mb-3 bg-white px-4 py-3">
      <div>
        <div className="text-base mb-4">{t("ss_builder_divider_label")}</div>
        <Switch
          defaultChecked={isShowDividerSetting}
          uncheckedText={t("ss_builder_off")}
          checkedText={t("ss_builder_on")}
          onChange={(value) => setIsShowDividerSetting(value)}
        />
      </div>
      {isShowDividerSetting && (
        <>
          <Divider
            style={{
              margin: "12px 0px",
            }}
          />
          <div>
            <div className="text-base mb-4">{t("ss_builder_style")}</div>
            <Selector
              columns={2}
              options={layoutOptions}
              onChange={(val) => {
                if (val.length > 0) setLayoutType(val[0]);
              }}
              value={[layoutType]}
              style={{
                "--border": "solid transparent 1px",
                "--checked-border": "solid var(--adm-color-primary) 1px",
              }}
            />
          </div>
          <Divider
            style={{
              margin: "12px 0px",
            }}
          />
          {layoutType === LAYOUT_TYPE_ENUM.DEFAULTS && (
            <DefaultsLayoutSetting {...props} />
          )}
          {layoutType === LAYOUT_TYPE_ENUM.IMAGE && (
            <ImageLayoutSetting {...props} />
          )}
        </>
      )}
    </div>
  );
};

export default DividerSetting;
