import {
	COUNTRY_ID,
	COUNTRY_MY,
	COUNTRY_PH,
	COUNTRY_SG,
	COUNTRY_TH,
	COUNTRY_VN,
  USER_DEVICE
} from "@share/configs/const";
import { useDetectdevice } from "@share/hooks";
import { sleep } from "antd-mobile/es/utils/sleep";
import { parse } from "node-html-parser";
import colors from "tailwindcss/colors";

export const DESKTOP_BREAK_POINT = 768;

export const FILE_SIZE_MAXIMUM = 30000000; //30MB = 30000000 bytes
export const IMAGE_SIZE_MINIUM = 256; //256px

export const allowedImageFormat = [
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/png",
  "image/bmp",
  "image/webp",
  "image/heif",
  "image/heic",
  "image/svg+xml"
];

export const isDesktop = () => {
  const isDevice = useDetectdevice();
  return isDevice === USER_DEVICE.DESKTOP;
};

const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

const isNotAllowed = (color) => {
  if (!color.match(/^[a-z]+$/)) return true;
  const unallowed = [
    "black",
    "white",
    // "rose",
    // "lime",
    // "violet",
    "emerald",
    "fuchsia",
    // "orange",
    // "cyan",
    // "amber",
    // "teal",
    "red",
    "inherit",
    "current",
    "transparent",
  ];
  if (unallowed.includes(color)) return true;

  return false;
};

export const getRandomColor = (prevColor = "") => {
  const allColors = Object.keys(colors);
  const color = getRandomItem(allColors);
  return isNotAllowed(color) || prevColor === color
    ? getRandomColor(prevColor)
    : color;
};

export const getHexColor = (color, intensity) => {
  if (!color.includes("#")) {
    if (!isNotAllowed(color)) {
      // Check if color have intensity
      if (colors[color]) {
        if (colors[color][parseInt(intensity)]) {
          return colors[color][parseInt(intensity)];
        }
      }
    }
  }

  return color;
};

export const getGradient = (
  firstColor,
  secondColor,
  intensity = 300,
  direction = "br"
) => {
  if (firstColor.includes("#") || secondColor.includes("#")) {
    return `bg-gradient-to-${direction} from-[${firstColor}] to-[${secondColor}]`;
  } else {
    return `bg-gradient-to-${direction} from-${firstColor}-${intensity} to-${secondColor}-${intensity}`;
  }
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkImageSize = (file: File) => {
  return new Promise((resolve) => {
    const image = document.createElement("img");
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      return resolve({
        width: image.width,
        height: image.height,
      });
    };
  });
};

export async function mockUpload(
  file: File,
  maxWidth?: number,
  maxHeight?: number,
  list_accept?: string[],
  typeResize=1,
  isCheckImageSize = false
) {
  if(isCheckImageSize){
    const size = (await checkImageSize(file)) as {
      width: number;
      height: number;
    };
    if (size?.width < IMAGE_SIZE_MINIUM || size?.height < IMAGE_SIZE_MINIUM) {
      return {
        url: null,
        isInvalidMinimumSize: true,
      };
    }
  }
  if (!allowedImageFormat.includes(file?.type?.toLocaleLowerCase())) {
    return {
      url: null,
      isValidFormat: true,
    };
  } else if (file?.size > FILE_SIZE_MAXIMUM) {
    return {
      url: null,
      isValidSize: true,
    };
  } else {
    await sleep(1000);
    try {
      const dataBase64 = await getBase64(file);
      if (dataBase64) {
        const dataBase64Resize = await resize(
          dataBase64,
          file.type,
          maxWidth,
          maxHeight,
          typeResize
        );
        return {
          url: URL.createObjectURL(file),
          dataBase64: dataBase64Resize,
          fileName: file.name,
          file: file
        };
      }
    } catch (error) {
      console.log(error);
    }
  }
}

const DEFAULT_WIDTH = 900;
const DEFAULT_HEIGHT = 315;

export const resize = (source, fileType, maxWidth, maxHeight,typeResize) => {
  return new Promise((resolve) => {
    const img = document.createElement("img");
    img.src = source;
    img.onload = (e: any) => {
      // Create your canvas
      const canvas = document.createElement("canvas");

      let width = e.target.width;
      let height = e.target.height;
      // Add the resizing logic
      switch (typeResize) {
        // resize chieu lon hon
        case 1 :
          if (width > height) {
            if (maxWidth && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (maxHeight) {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            } else {
              if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            }
          }
          break;
          //resize chieu nho hon
        case 2:
          if (width < height) {
            if (maxWidth && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (maxHeight) {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            } else {
              if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            }
          }
          break;
        //resize chieu rong (W)
        case 3:
          if(maxWidth && width > maxWidth){
            height *= maxWidth/width
            width = maxWidth
          }
          break;
          //resize chieu cao (H)
        case 4:
          if(maxHeight && height > maxHeight){
            width *= maxHeight/height
            height = maxHeight;
          }
          break;
        default:

      }
      console.log("width", width);
      console.log("height", height);
      console.log("typeResize", typeResize);
      
      //Specify the resizing result
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      return resolve(canvas.toDataURL(fileType));
    };
  });
};

export const validURL = (str) => {
  const pattern1 = new RegExp(
    /^https?:\/\/([0-9a-z]+\-?[0-9a-z]*?\.)?[0-9a-z]+\-?[0-9a-z]+?\.[a-z]{2,}\/?.*/,
    "i"
  );
  const pattern2 = new RegExp("^(mailto|tel):", "i");
  return !!pattern1.test(str) || !!pattern2.test(str);
};

export const validateEmail = (email) => {
  return String(email)
    .toLocaleLowerCase()
    .match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
};

export const validDomain = (str) => {
  const domainPattern = new RegExp(/^[a-z](?:_?[a-z0-9]+)*$/i);
  return !!domainPattern.test(str);
};

export const convertRGBAStringColor = (obj) => {
  return `rgba(${obj.r},${obj.g},${obj.b},${obj.a})`;
};

export const rgbaToHex = (obj) => {
  const r = obj && obj.r;
  const g = obj && obj.g;
  const b = obj && obj.b;
  const a = obj && obj.a;
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = "0" + part;
    }
  });

  return "#" + outParts.join("");
};

export const getOpacityFromHex = (hex) => {
  const COLOR_LENGTH_DEFAULT = 9;
  const alpha = hex?.length == COLOR_LENGTH_DEFAULT ? hex?.slice(-2) : "ff";
  const opacity = parseInt(alpha, 16) / 255;

  return opacity;
};

export const hexToRgba = (hexString) => {
  const hex = hexString?.replace("#", "");
  let r, g, b, a;
  if (3 === hex?.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
  } else if (4 === hex.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
    a = hex.charAt(3);
  } else if (6 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
  } else if (8 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
    a = hex.substring(6, 8);
  } else {
    return "";
  }

  if ("undefined" === typeof a) {
    a = "ff";
  }
  if (1 === r.length) {
    r += r;
  }
  if (1 === g.length) {
    g += g;
  }
  if (1 === b.length) {
    b += b;
  }
  if (1 === a.length) {
    a += a;
  }
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  a = parseInt(a, 16) / 255;

  return (
    "rgba(" + r + "," + g + "," + b + "," + Math.round(a * 100) / 100 + ")"
  );
};

export const getGlobalColor = (hex) => {
  const globalColor = hex?.substring(
    0,
    hex?.length === 9 ? hex?.length - 2 : hex?.length
  );

  return globalColor;
};

export function htmlDecode(input) {
  try {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  } catch (error) {
    return parse(input).toString();
  }
}

export const formatNumberToCurrency = (n) => {
  return n
    .toString()
    .replace(/\D/g, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`);
};
export const formatCurrencyToNumber = (n) => {
  return n?.replace(/,/g, "");
};
export const formatNumberToCurrencyDonation = (n) => {
  return n
    ?.toString()
    .replace(/\D/g, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`);
};

export const formatUnitSymbol = (country) => {
  switch (country) {
    case COUNTRY_VN:
      return "đ";
    case COUNTRY_ID:
      return "Rp";
    case COUNTRY_MY:
      return "RM";
    case COUNTRY_SG:
      return "S$";
    case COUNTRY_PH:
      return "₱";
    case COUNTRY_TH:
      return "฿";
    default:
      return "$";
  }
};

export function colorNameToHex(color) {
  var colors = {
    amber: "#ffbf00",
    aliceBlue: "#f0f8ff",
    antiqueWhite: "#faebd7",
    aqua: "#00ffff",
    aquamarine: "#7fffd4",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    bisque: "#ffe4c4",
    black: "#000000",
    blanchedalmond: "#ffebcd",
    blue: "#0000ff",
    blueGray: "#6699cc",
    blueViolet: "#8a2be2",
    brown: "#a52a2a",
    burlyWood: "#deb887",
    cadetBlue: "#5f9ea0",
    chartReuse: "#7fff00",
    chocolate: "#d2691e",
    coral: "#ff7f50",
    coolGray: "#808080",
    cyan: "#00ffff",
    gold: "#ffd700",
    gray: "#808080",
    green: "#008000",
    greenYellow: "#adff2f",
    indigo: "#4b0082",
    ivory: "#fffff0",
    khaki: "#f0e68c",
    lavender: "#e6e6fa",
    lightBlue: "#add8e6",
    lightCoral: "#f08080",
    lightCyan: "#e0ffff",
    lightGrey: "#d3d3d3",
    lightGreen: "#90ee90",
    lightPink: "#ffb6c1",
    lightSalmon: "#ffa07a",
    lightYellow: "#ffffe0",
    lime: "#00ff00",
    limeGreen: "#32cd32",
    linen: "#faf0e6",
    magenta: "#ff00ff",
    maroon: "#800000",
    navy: "#000080",
    olive: "#808000",
    orange: "#ffa500",
    orangered: "#ff4500",
    orchid: "#da70d6",
    paleGreen: "#98fb98",
    papayaWhip: "#ffefd5",
    peachPuff: "#ffdab9",
    peru: "#cd853f",
    pink: "#ffc0cb",
    plum: "#dda0dd",
    powderBlue: "#b0e0e6",
    purple: "#800080",
    rebecCapurple: "#663399",
    red: "#ff0000",
    rosyBrown: "#bc8f8f",
    royalBlue: "#4169e1",
    salmon: "#fa8072",
    seaGreen: "#2e8b57",
    seaShell: "#fff5ee",
    sienna: "#a0522d",
    silver: "#c0c0c0",
    skyBlue: "#87ceeb",
    sky: "#87ceeb",
    slateBlue: "#6a5acd",
    slateGray: "#708090",
    slate: "#708090",
    snow: "#fffafa",
    steelBlue: "#4682b4",
    tan: "#d2b48c",
    teal: "#008080",
    thistle: "#d8bfd8",
    tomato: "#ff6347",
    turquoise: "#40e0d0",
    violet: "#ee82ee",
    wheat: "#f5deb3",
    white: "#ffffff",
    whiteSmoke: "#f5f5f5",
    yellow: "#ffff00",
    yellowGreen: "#9acd32",
    stone: "#a8a29e",
    zinc: "#a1a1aa",
    neutral: "#a3a3a3",
    rose: "#fb7185",
  };

  if (typeof colors[color.toLowerCase()] != "undefined")
    return colors[color.toLowerCase()];

  return false;
}

export const handleCapitalizeFirstLetter = (text) => {
  if (!text) return;
  const pattern = /[.\-=/_ ]/;
  const textArr = text?.split(pattern);
  const newText = textArr
    ?.map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    ?.join("");
  return newText;
};
export const formatDetectedUrls = (data) => {
  const detectedUrls = data
    ?.split(/\r?\n/)
    ?.filter((x) => x?.trim())
    ?.map((x) => x?.trim());
  return detectedUrls;
};

export const getDroppableDom = (dropId) => {
  const queryAttr = "data-rbd-droppable-id";
  const domQuery = `[${queryAttr}='${dropId}']`;
  const element = document.querySelector(domQuery) as any;
  return element;
};

export const getDraggedDom = (draggableId) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const element = document.querySelector(domQuery) as any;
  return element;
};

export const getPlaceholderProps = (event) => {
  let clientY = 0;
    const clientX = 0;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }
    const { clientWidth, clientHeight } = draggedDOM;
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom + marginTop;
      }, 0);

    return {
      clientHeight,
      clientWidth,
      clientY,
      clientX,
    };
};

