const  id =  {
 translation: {
  "button_confirm_exit_TOD": "Ya/Keluar",
  "button_confirm_off_TOD": "Ya",
  "button_title_default_donation": "Berikan dukungan ke {name}!",
  "cashback_default_text_button": "Dapatkan pengembalian dana sekarang!",
  "cashback_default_title": "Nikmati belanja mudah, cashback besar",
  "cm_home": "Home",
  "default_content_setup_brand_gift": "Pilih template atau buat sendiri",
  "default_content_setup_donation": "Layanan ini sedang diatur secara default. Jika Anda ingin mengubah, silakan kunjungi layanan Donasi di account.passio.eco",
  "default_title_setup_brand_gift": "Pengaturan",
  "default_title_setup_donation": "Pengaturan layanan",
  "error_thumbnail_size_TOD": "Ukuran gambar berlebihan",
  "invitation_default_donation": "Apa kamu menyukai {name}?",
  "label_tab_bar_brand_gift": "Template",
  "label_text_button_brand_gift": "Tombol",
  "label_text_title_brand_gift": "Judul",
  "login": "Masuk",
  "message_default_donation": "Terima kasih atas dukungan Anda ke {name}. Semoga kita memiliki lebih banyak kesempatan untuk berinteraksi satu sama lain!",
  "placeholder_text_button_brand_gift": "Masukkan teks tombol",
  "placeholder_text_title_brand_gift": "Masukkan judul",
  "ss_background_TOD": "Foto background",
  "ss_brand_cpc_bonus": "id_Hoa hồng",
  "ss_brand_cpc_campaign_title": "Ss Brand Cpc Campaign Title",
  "ss_brand_cpc_description": "id_Mô tả chương trình",
  "ss_brand_cpc_download": "id_Tải xuống",
  "ss_brand_cpc_from": "id_Từ",
  "ss_brand_cpc_join": "id_Tham gia",
  "ss_brand_cpc_join_now": "id_Tham khảo ngay",
  "ss_brand_cpc_join_successfully_label": "id_Bạn đã tham gia chương trình thành công",
  "ss_brand_cpc_no_access_allow": "id_Bạn không được phép truy cập vào link này",
  "ss_brand_cpc_notice": "id_Lưu ý",
  "ss_brand_cpc_notice_content": "id_Hoa hồng sẽ được trả về tài khoản của bạn sau 7 ngày kể từ khi được nghiệm thu.",
  "ss_brand_cpc_reject": "id_Ảnh nghiệm thu của bạn đã bị từ chối. Bạn có thể tải lại ảnh.",
  "ss_brand_cpc_requirement": "Ss Brand Cpc Requirement",
  "ss_brand_cpc_step_1": "id_Bước 1: Tải ảnh dưới đây về và up lên 1 nền tảng mạng xã hội.",
  "ss_brand_cpc_step_1_note": "id_Lưu ý: up ảnh trong khoảng thời gian",
  "ss_brand_cpc_step_2": "id_Bước 2: Tải ảnh nghiệm thu (ảnh chụp màn hình bài đăng của bạn ở bước 1)",
  "ss_brand_cpc_step_2_note": "Ss Brand Cpc Step 2 Note",
  "ss_brand_cpc_success": "id_Đã nghiệm thu thành công",
  "ss_brand_cpc_text_content": "id_đang gửi cho bạn 01 lời đề nghị tham gia vào chương trình treo banner - nhận tiền thưởng.",
  "ss_brand_cpc_text_title": "id_Nhận tiền dễ dàng cùng",
  "ss_brand_cpc_time": "id_Thời gian",
  "ss_brand_cpc_time_end": "id_Thời gian kết thúc",
  "ss_brand_cpc_time_start": "id_Thời gian bắt đầu",
  "ss_brand_cpc_to": "id_Đến",
  "ss_brand_cpc_upload_image": "id_Tải ảnh nghiệm thu",
  "ss_brand_cpc_upload_image_button": "id_Tải ảnh lên",
  "ss_brand_cpc_upload_image_successfully": "id_Bạn đã gửi ảnh nghiệm thu thành công",
  "ss_brand_cpc_upload_image_title": "id_Tải ảnh nghiệm thu",
  "ss_brand_gift_cancel_hide": "id_Hủy bỏ",
  "ss_brand_gift_confirm_hide_brand": "id_Xác nhận",
  "ss_brand_gift_confirm_hide_group": "id_Xác nhận ẩn nhóm",
  "ss_brand_gift_disable_deal_successfully": "id_Ẩn Deal thành công",
  "ss_brand_gift_disable_successfully": "id_Ẩn nhóm sản phẩm thành công",
  "ss_brand_gift_display_hide_brand": "id_Ẩn Deal",
  "ss_brand_gift_display_hide_category": "id_Ẩn nhóm sản phẩm",
  "ss_brand_gift_display_show_brand": "id_Hiện Deal",
  "ss_brand_gift_display_show_category": "id_Hiện nhóm sản phẩm",
  "ss_brand_gift_empty_title": "id_Chưa có sản phẩm nào",
  "ss_brand_gift_enable_deal_successfully": "id_Hiện Deal thành công",
  "ss_brand_gift_enable_successfully": "id_Hiện nhóm sản phẩm thành công",
  "ss_brand_gift_hide_brand": "id_Bạn có muốn ẩn Deal?",
  "ss_brand_gift_hide_brand_content": "id_Deal {{name}} sẽ không hiển thị ở giao diện của Fan. Bạn có muốn ẩn deal này?",
  "ss_brand_gift_hide_category": "id_Bạn muốn ẩn nhóm?",
  "ss_brand_gift_hide_category_content": "id_Nhóm {{name}} và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị ẩn. Bạn có chắc muốn ẩn nhóm?",
  "ss_brand_gift_list_category": "Product group list",
  "ss_brand_gift_list_empty_content": "id_Hiện giờ Admin chưa setup dữ liệu sản phẩm",
  "ss_brand_gift_setting_cashback_default_text_button": "Dapatkan pengembalian dana sekarang!",
  "ss_brand_gift_setting_cashback_default_title": "Nikmati belanja mudah, cashback besar",
  "ss_brand_gift_setting_default_content_setup": "Pilih template atau buat sendiri",
  "ss_brand_gift_setting_float_icon_description": "Simpan pengaturan floating icon untuk meningkatkan interaksi dengan fan",
  "ss_brand_gift_setting_float_icon_footer_description": "Aktifkan fitur ini untuk meningkatkan interaksi dengan fan",
  "ss_brand_gift_setting_label_tab_bar_brand_gift": "Template",
  "ss_brand_gift_setting_label_text_button": "Tombol",
  "ss_brand_gift_setting_label_text_title_brand_gift": "Judul",
  "ss_brand_gift_setting_placeholder_text_button": "Masukkan teks tombol",
  "ss_brand_gift_setting_placeholder_text_title": "Masukkan judul",
  "ss_brand_gift_setting_text_cashback_brand_gift": "Cashback",
  "ss_brand_gift_setting_text_custom_brand_gift": "Customize",
  "ss_brand_gift_setting_text_voucher_brand_gift": "Voucher",
  "ss_brand_gift_setting_title_block_footer": "Brand Gift Icon",
  "ss_brand_gift_setting_voucher_default_text_button": "Dapatkan voucher sekarang!",
  "ss_brand_gift_setting_voucher_default_title": "Klik bayar, cashback 5%",
  "ss_brand_rating_setting_default_content_setup_donation": "id_Danh sách các chương trình hoàn tiền giảm giá dành cho fan của bạn. Bật tính năng để nhận hoa hồng ngay!",
  "ss_build_collapse_block_label": "Sembunyikan",
  "ss_build_expand_block_label": "Perluas",
  "ss_build_no_blocks_have_been_added": "Belum ada blok",
  "ss_builde_theme_setting_block": "Blok",
  "ss_builder_1st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block.",
  "ss_builder_1st_block_preview_sample_title": "Block 1",
  "ss_builder_2st_block_preview_sample_description": "Here is the example content of block, you can see how the fonts and colors are displayed in the block. 2nd block to see the spacing between blocks.",
  "ss_builder_2st_block_preview_sample_title": "Block 2",
  "ss_builder_add_affiliate_product_list_btn_label": "Tambahkan lebih banyak produk",
  "ss_builder_add_block_body_title": "Body",
  "ss_builder_add_block_expand_title": "Extended Position",
  "ss_builder_add_block_footer_title": "Footer",
  "ss_builder_add_block_header_title": "Header",
  "ss_builder_add_block_in_template": "Tambah blok template",
  "ss_builder_add_block_in_template_successfully": "Add block template successfully",
  "ss_builder_add_block_label": "Tambah Blok",
  "ss_builder_add_block_to": "Tambah blok ke:",
  "ss_builder_add_image_of_image_popup_tilte": "Tambahkan gambar pop-up",
  "ss_builder_add_new_affiliate_product_btn_label": "Tambahkan produk baru",
  "ss_builder_add_new_affiliate_product_group_btn_label": "Tambahkan grup produk",
  "ss_builder_add_new_affiliate_product_image_title": "Gambar produk",
  "ss_builder_add_new_affiliate_product_list_empty_content": "Untuk menambahkan produk baru, silakan masukkan url produk di bawah ini",
  "ss_builder_add_new_affiliate_product_name_title": "Nama Produk",
  "ss_builder_add_new_affiliate_product_title": "Tambahkan produk baru",
  "ss_builder_add_new_block_successfully_label": "Berhasil tambahkan blok baru",
  "ss_builder_add_new_image": "Tambahkan gambar baru",
  "ss_builder_add_new_image_of_image_popup_title": "Gambar pop-up",
  "ss_builder_add_new_image_of_image_slider_title": "Gambar",
  "ss_builder_add_new_image_popup_btn_label": "Tambah",
  "ss_builder_add_new_image_slider_title": "Tambah gambar",
  "ss_builder_add_new_label": "Tambah",
  "ss_builder_add_new_product_group_successfully_label": "Sukses menambahkan grup produk",
  "ss_builder_add_new_tab_successfully_label": "Berhasil tambahkan tab baru",
  "ss_builder_add_new_tag_error_duplicate": "Tag sudah pernah dibuat",
  "ss_builder_add_new_template_btn_label": "Simpan",
  "ss_builder_add_new_template_image_title": "Gambar Template",
  "ss_builder_add_new_template_label": "Buat template baru",
  "ss_builder_add_new_template_name_placeholder": "Masukkan nama template",
  "ss_builder_add_new_template_name_title": "Nama Template",
  "ss_builder_add_new_theme_btn_label": "Buat baru",
  "ss_builder_add_new_theme_selection_panel_description": "Pilih metode untuk membuat Template Tema untuk dipublikasikan ke Theme Store.",
  "ss_builder_add_new_theme_selection_panel_label": "Buat template tema",
  "ss_builder_add_new_theme_template_image_title": "Gambar template tema",
  "ss_builder_add_new_theme_template_name_placeholder": "Masukkan nama template tema",
  "ss_builder_add_new_theme_template_name_title": "Nama template tema",
  "ss_builder_add_story": "Tambah yang baru",
  "ss_builder_add_tab_label": "Tambahkan Tab",
  "ss_builder_added_product_url_label": "Tautan sudah ditambahkan",
  "ss_builder_advanced_group_description": "Blok lanjutan",
  "ss_builder_advanced_setting": "Pengaturan lanjutan",
  "ss_builder_advanced_title": "Canggih",
  "ss_builder_aff_frame_max_size_content": "Ukuran file maks: 30MB.",
  "ss_builder_aff_frame_select_from_library": "Pilih dari Library",
  "ss_builder_aff_frame_support_type_file_content": "Mendukung format file: gif, png, svg",
  "ss_builder_affiliate_add_new_tour_content_step_1": "Pilih Grup Produk untuk mengkategorikan produk mu. Produk berikut akan ditambahkan ke grup yang kamu pilih.",
  "ss_builder_affiliate_add_new_tour_content_step_2": "Masukkan Link untuk produk, sistem akan otomatis mencari data produk.",
  "ss_builder_affiliate_add_new_tour_content_step_3": "Kamu dapat mengubah nama, gambar, label dari produk.",
  "ss_builder_affiliate_add_new_tour_content_step_4": "Pilih menu \"Tambah Produk\" untuk menambah produk diatas ke dalam grup.",
  "ss_builder_affiliate_add_new_tour_title_step_1": "Pilih Grup Produk",
  "ss_builder_affiliate_add_new_tour_title_step_2": "Link Produk",
  "ss_builder_affiliate_add_new_tour_title_step_3": "Info Produk",
  "ss_builder_affiliate_add_new_tour_title_step_4": "Tambah Produk",
  "ss_builder_affiliate_group_tour_content_step_1": "Tambah grup atau kategori produk",
  "ss_builder_affiliate_group_tour_content_step_2": "Mengatur dan merubah setiap grup, memudahkan dalam mengatur blok sesuai yang diinginkan.",
  "ss_builder_affiliate_group_tour_title_step_1": "Tambah Grup Produk",
  "ss_builder_affiliate_group_tour_title_step_2": "Quick Actions",
  "ss_builder_affiliate_product_btn_see_more_placeholder": "Masukkan konten",
  "ss_builder_affiliate_product_btn_see_more_title": "Tampilkan label tombol lainnya",
  "ss_builder_affiliate_product_clean_up_popover_content": "Produk ini tidak akan dibersihkan secara otomatis ketika grup produk penuh",
  "ss_builder_affiliate_product_cleanup_lock_label": "Tutup fitur pembersihan",
  "ss_builder_affiliate_product_cleanup_unlock_label": "Buka fitur pembersihan",
  "ss_builder_affiliate_product_frame_panel_title": "Pilih frame",
  "ss_builder_affiliate_product_group_add_new_panel_title": "Informasi grup",
  "ss_builder_affiliate_product_group_list_selector_label": "List Grup Produk",
  "ss_builder_affiliate_product_group_management_title": "Manajemen grup produk",
  "ss_builder_affiliate_product_group_other": "Lainnya",
  "ss_builder_affiliate_product_group_placeholder": "Masukkan judul blok",
  "ss_builder_affiliate_product_group_quantity_label": "{{product_quantity}} produk",
  "ss_builder_affiliate_product_group_quantity_warning_1": "Tidak bisa menambahkan",
  "ss_builder_affiliate_product_group_quantity_warning_2": "Melebihi jumlah yang tersedia",
  "ss_builder_affiliate_product_group_title": "Judul blok",
  "ss_builder_affiliate_product_image_popup_infomation_label": "Gambar dipasang secara otomatis dari link produk. Kamu dapat mengganti dengan gambar lain.",
  "ss_builder_affiliate_product_label_product_discount": "Diskon",
  "ss_builder_affiliate_product_label_product_hot": "Hot",
  "ss_builder_affiliate_product_label_product_like": "Favorit",
  "ss_builder_affiliate_product_label_product_title": "Label produk",
  "ss_builder_affiliate_product_list_empty_content": "To add products please press the “Add” button above",
  "ss_builder_affiliate_product_list_empty_title": "Belum ada produk yang ditambahkan",
  "ss_builder_affiliate_product_list_title": "List",
  "ss_builder_affiliate_product_moving_panel_title": "Pindahkan ke",
  "ss_builder_affiliate_product_quantity_on_row_title": "Jumlah produk per baris",
  "ss_builder_affiliate_product_searching_placeholder": "Cari produk berdasarkan nama",
  "ss_builder_affiliate_product_style_title": "Gaya",
  "ss_builder_affiliate_setting_popover_manual_content": "Cari tahu bagaimana cara kerja Blok Affiliate,mulai dengan menekan menu \"Memulai\"",
  "ss_builder_affiliate_setting_popover_manual_title": "Panduan Passio",
  "ss_builder_affiliate_setting_tour_content_step_1": "Melakukan perintah Sembunyikan/Tampilkan, Hapus blok.",
  "ss_builder_affiliate_setting_tour_content_step_2": "Atur Judul dan Deskripsi untuk blok, the info blok akan tampil setelah disimpan.",
  "ss_builder_affiliate_setting_tour_content_step_3": "Pilih style tampilan produk di dalam blok. Setelah mengubah dan menyimpan pengaturan blok ini maka akan otomatis terupdate/",
  "ss_builder_affiliate_setting_tour_content_step_4": "Buat dan atur Product Groups untuk memisahkan produk sesuai dengan kategori. Sehingga membuat fans dapat mencari produk yang diinginkan lebih mudah. Contoh : Pakaian, Sepatu, Aksesoris dan lainnya",
  "ss_builder_affiliate_setting_tour_content_step_5": "Akses cepat ke tiap Product Group untuk menambahkan produk di masing-masing kategori.",
  "ss_builder_affiliate_setting_tour_content_step_6": "Tambah produk baru dengan memilih menu \"Tambah\"",
  "ss_builder_affiliate_setting_tour_content_step_7": "Pengaturan cepat untuk setiap produk sehingga memudahkan dalam mengatur list produk.",
  "ss_builder_affiliate_setting_tour_content_step_8": "Setelah merubah, dan menambahkan produk kedalam blok, tekan menu \"Simpan\" dan akan otomatis terupdate.",
  "ss_builder_affiliate_setting_tour_title_step_1": "Quick Actions",
  "ss_builder_affiliate_setting_tour_title_step_2": "Judul dan deskripsi",
  "ss_builder_affiliate_setting_tour_title_step_3": "Tampilan List Produk",
  "ss_builder_affiliate_setting_tour_title_step_4": "Pengaturan Grup Produk",
  "ss_builder_affiliate_setting_tour_title_step_5": "Grup Produk",
  "ss_builder_affiliate_setting_tour_title_step_6": "Tambah Produk",
  "ss_builder_affiliate_setting_tour_title_step_7": "Opsi Produk",
  "ss_builder_affiliate_setting_tour_title_step_8": "Simpan Produk",
  "ss_builder_affiliate_upload_product_error_content": "File yang diunggah harus berupa json file dengan format file yang diperbolehkan",
  "ss_builder_affiliate_upload_product_error_label": "Format file salah",
  "ss_builder_affiliate_upload_product_label": "Unggah file produk",
  "ss_builder_all_setting_and_product": "semua pengaturan dan produk di dalamnya",
  "ss_builder_all_the_blocks_are_in_your_list_by_the_blocks_of_the_template": "seluruh blok yang ada di list dengan blok dari template",
  "ss_builder_allowed_domain_label": "Nama domain belum terdaftar, Anda dapat menggunakan nama domain ini",
  "ss_builder_an_unexpected_error_has_occurred": "Sebuah kesalahan yang tidak terduga telah terjadi.",
  "ss_builder_apply_preview_theme_template_btn_label": "Apply",
  "ss_builder_apply_template_successfully_label": "Sukses menggunakan template",
  "ss_builder_apply_template_title": "Kamu ingin menggunakan template ini?",
  "ss_builder_apply_theme_premium_warning": "Tema ini khusus untuk akun premium. Silahkan upgrade tipe akun mu untuk menggunakan tema ini.",
  "ss_builder_apply_theme_premium_warning_title": "Apakah kamu ingin mengaktifkan tema ini?",
  "ss_builder_apply_theme_template_successfully_label": "Sukses menerapkan template tema baru",
  "ss_builder_auto_cleanup_products_warning_content_1": "Melebihi jumlah produk. Sistem akan secara otomatis membersihkan produk terlama ketika Anda menambahkan yang baru.",
  "ss_builder_auto_cleanup_products_warning_content_2": "Catatan: Produk yang ada di “Tutup fitur pembersihan” tidak akan dibersihkan secara otomatis.",
  "ss_builder_back": "Kembali",
  "ss_builder_background_block": "Background",
  "ss_builder_background_enable_overlay": "Overlay",
  "ss_builder_background_opacity": "Blur",
  "ss_builder_background_overlay_color": "Warna Overlay",
  "ss_builder_background_popover_manual_content": "Cari tahu cara menggunakan pengaturan Background, tekan menu \"Memulai\".",
  "ss_builder_background_popover_manual_title": "Panduan Passio",
  "ss_builder_background_setting_title": "Pengaturan Background",
  "ss_builder_background_tour_content_step_1": "Menampilkan area dengan pengaturan berikut, akan diubah secara otomatis setelah pengaturan disimpan.",
  "ss_builder_background_tour_content_step_2": "Kamu dapat mengatur background dengan menggunakan \"Image\" atau \"Color\". Dengan warna kamu dapat menggunakan warna \"Solid\" atau \"Gradient\".",
  "ss_builder_background_tour_title_step_1": "Area tampilan",
  "ss_builder_background_tour_title_step_2": "Background dengan gambar atau set warna.",
  "ss_builder_basic_group_description": "Blok dasar",
  "ss_builder_basic_title": "Dasar",
  "ss_builder_bio_add_social_link_title": "Tambahkan sosial media",
  "ss_builder_bio_avatar_title": "Avatar",
  "ss_builder_bio_layout_title": "Tata Letak",
  "ss_builder_bio_name_sub_placeholder": "Enter the sub title",
  "ss_builder_bio_name_title": "Nama yang ditampilkan",
  "ss_builder_bio_name_title_placeholder": "Enter the display name",
  "ss_builder_bio_social_link_btn_select_icon_label": "PIlih Ikon",
  "ss_builder_bio_social_link_icon_title": "Ikon",
  "ss_builder_bio_social_link_url_title": "Masukkan URL",
  "ss_builder_bio_sub_title": "Judul",
  "ss_builder_bio_text_title": "Bio perkenalan",
  "ss_builder_block_affiliate_description": "Rekomendasikan Produk Affiliate kepada Fans",
  "ss_builder_block_background_blur": "Background blur",
  "ss_builder_block_background_color": "Warna background",
  "ss_builder_block_bio_description": "Gambar profil, teks bio, dan tautan sosial Anda",
  "ss_builder_block_block_card_description": "Daftar gambar dengan informasi tambahan dalam bentuk yang fleksibel dan dapat digeser",
  "ss_builder_block_booking_file_alert_content": "Silahkan buat Booking Profile sebelumnya",
  "ss_builder_block_booking_file_alert_title": "Kamu belum membuat Booking Profile",
  "ss_builder_block_booking_file_button_label_default": "Working Profile",
  "ss_builder_block_booking_file_label": "Booking Profile url",
  "ss_builder_block_booking_file_link_tooltip": "Booking Profile akan memblok link ke url",
  "ss_builder_block_booking_file_switch_tooltip": "Tampilkan link ke Booking Profile di bagian bio",
  "ss_builder_block_booking_profile_button_label": "Tombol konten",
  "ss_builder_block_booking_profile_setting_description": "Deskripsi",
  "ss_builder_block_booking_profile_setting_title": "Judul",
  "ss_builder_block_border_color": "Warna border",
  "ss_builder_block_border_style": "Gaya",
  "ss_builder_block_brand_gift_description": "Simpan pengaturan floating icon untuk meningkatkan interaksi dengan fan",
  "ss_builder_block_brand_gift_footer_description": "Aktifkan fitur ini untuk meningkatkan interaksi dengan fan",
  "ss_builder_block_button_link_description": "Tombol link untuk menautkan ke tempat favorit Anda di internet",
  "ss_builder_block_button_multi_description": "Multi tombol link untuk menautkan ke tempat favorit Anda di internet",
  "ss_builder_block_button_story_description": "Tampilkan tautan di situs Anda dengan gambar dan teks",
  "ss_builder_block_combined_description": "Gabungkan blok yang sudah kamu buat menjadi blok baru",
  "ss_builder_block_combined_description_label": "Gabungkan blok yang sudah kamu buat menjadi blok baru",
  "ss_builder_block_combined_description_placeholder": "Masukkan konten",
  "ss_builder_block_combined_heading_label": "Judul Blok",
  "ss_builder_block_description": "Deskripsi Blok",
  "ss_builder_block_description_label": "Deskripsi",
  "ss_builder_block_description_placeholder": "Masukan konten",
  "ss_builder_block_design_hiding_label": "Blok tersembunyi",
  "ss_builder_block_digital_product_description": "Jual Digital Produk di Passio Page",
  "ss_builder_block_donation_description": "Terima donasi dari penggemar",
  "ss_builder_block_heading_label": "Judul blok",
  "ss_builder_block_heading_placeholder": "Masukan konten",
  "ss_builder_block_image_description": "Tambahkan gambar ke situs Anda",
  "ss_builder_block_image_embed_description": "Embed apa pun ke situs Anda: video, audio, formulir,...",
  "ss_builder_block_image_gallery_description": "Daftar gambar yang ditampilkan dalam multi playouts dan dapat diperbesar",
  "ss_builder_block_image_popup_description": "Foto akan ditampilkan dalam bentuk popup saat penggemar mengunjungi profil Anda",
  "ss_builder_block_image_slider_description": "Daftar gambar yang ditampilkan dalam penggeser gambar",
  "ss_builder_block_is_hiding_label": "Blok tersembunyi",
  "ss_builder_block_link_copy_successfully_label": "Sudah disalin",
  "ss_builder_block_list_combined_description": "Gabungkan blok yang sudah kamu buat menjadi blok baru",
  "ss_builder_block_main_color": "Warna utama",
  "ss_builder_block_outside_space": "Jarak di luar blok",
  "ss_builder_block_rating_answer_1": "Tampilan",
  "ss_builder_block_rating_answer_2": "Waktu Loading",
  "ss_builder_block_rating_answer_3": "Pengalaman selama menggunakan apps",
  "ss_builder_block_rating_answer_4": "Dukungan Layanan",
  "ss_builder_block_rating_answer_5": "Lainnya",
  "ss_builder_block_rating_button_submit_rating": "Berikan rating",
  "ss_builder_block_rating_button_title_open_popup": "Berikan rating",
  "ss_builder_block_rating_content_rating": "Feedback mu",
  "ss_builder_block_rating_content_rating_placeholder": "Silahkan tinggalkan saran untuk Passio Page",
  "ss_builder_block_rating_description": "Rating mu sangat berharga untuk Passio Page agar dapat terus memberikan layanan terbaik kedepannya",
  "ss_builder_block_rating_description_auto_show": "Feedback tentang Passio Page",
  "ss_builder_block_rating_description_star_1": "Merasa tidak puas karena",
  "ss_builder_block_rating_description_star_2": "Merasa tidak puas karena",
  "ss_builder_block_rating_description_star_3": "Passio Page perlu ditingkatkan di bagian",
  "ss_builder_block_rating_description_star_4": "Merasa puas karena",
  "ss_builder_block_rating_description_star_5": "Merasa puas karena",
  "ss_builder_block_rating_star_1": "Kurang",
  "ss_builder_block_rating_star_2": "Biasa saja",
  "ss_builder_block_rating_star_3": "Cukup OK",
  "ss_builder_block_rating_star_4": "Bagus",
  "ss_builder_block_rating_star_5": "Terbaik!",
  "ss_builder_block_rating_title": "Your rating",
  "ss_builder_block_rating_toast_success_rating": "Terimakasih untuk sarannya!",
  "ss_builder_block_second_color": "Warna sekunder",
  "ss_builder_block_setting_title": "Pengaturan Blok",
  "ss_builder_block_shadow_color": "Warna bayangan",
  "ss_builder_block_space": "Spacing between blocks",
  "ss_builder_block_spacer_and_divider_description": "Add spacer or divider between your blocks",
  "ss_builder_block_text_description": "Tambahkan konten apa pun ke situs Anda dengan teks",
  "ss_builder_block_text_see_more_btn_default_title": "Lihat lainnya",
  "ss_builder_block_truth_or_dare_description": "Main game menyenangkan dengan Truth or Dare",
  "ss_builder_block_truth_or_dare_footer_description": "Tampilkan ikon Truth or Dare di pojok layar",
  "ss_builder_block_virtual_gift_description": "Aktifkan fitur ini untuk menerima hadiah dari penggemar",
  "ss_builder_block_virtual_gift_footer_description": "Tampilkan ikon Virtual Gift di pojok layar",
  "ss_builder_blocks_general_settings_label": "Pengaturan Blok",
  "ss_builder_blocks_general_settings_popover_content": "Aktifkan untuk mengaplikasikan \"Settingan Blok\" di bagian \"Settings\" blok dibawah ini",
  "ss_builder_blocks_label": "Blok",
  "ss_builder_blocks_list": "List",
  "ss_builder_blocks_list_empty_content": "Untuk menambah blok baru pilih menu \"Tambah/Add\" diatas",
  "ss_builder_blocks_list_empty_title": "Belum ada blok tersedia",
  "ss_builder_body_description": "Anda dapat menggunakan Tab untuk membagikan blok menjadi beberapa bagian",
  "ss_builder_body_introduction_description": "\"Body\" adalah area utama situs, berisi Blok dan terbagi-bagi oleh Tab. Misalnya: konten, gambar, produk,...",
  "ss_builder_body_introduction_title": "Body Position",
  "ss_builder_body_title": "Body",
  "ss_builder_bold": "Bold",
  "ss_builder_booking_profile_style_1": "Kartu",
  "ss_builder_booking_profile_style_2": "Embed code",
  "ss_builder_border_dash": "Dash",
  "ss_builder_border_dot": "Dot",
  "ss_builder_border_radius_label": "Radius",
  "ss_builder_border_radius_style": "Gaya sudut bulat",
  "ss_builder_border_solid": "Padat",
  "ss_builder_bottom_position_tab_label": "Bottom",
  "ss_builder_box_icon_button_filter": "Filter",
  "ss_builder_box_icon_empty_search": "Hasil tidak ditemukan",
  "ss_builder_box_icon_empty_searching_description": "Coba dengan kata kunci lain",
  "ss_builder_box_icon_filter_category_all": "Semua",
  "ss_builder_box_icon_filter_category_sub_title": "Pilih kategori ikon",
  "ss_builder_box_icon_filter_category_title": "Filter",
  "ss_builder_box_icon_search_bar_placeholder": "Cari ikon berdasarkan nama...",
  "ss_builder_bp_apply_block_settings_instruction": "Turn off to display full screen width. Turn on to display by settings in Block Settings as normal blocks.",
  "ss_builder_bp_open_new_tab_instruction": "Turn on to show the icon to open a new tab in the right corner",
  "ss_builder_bp_open_new_tab_title": "Open new tab",
  "ss_builder_brand_gift_introduction_popup_title": "Tambah Brand Gift icon",
  "ss_builder_browser_opening_label": "Buka dalam bentuk browser",
  "ss_builder_button_background_color": "Warna background",
  "ss_builder_button_border_color": "Warna border",
  "ss_builder_button_border_width": "Lebar border (px)",
  "ss_builder_button_close_introduction_service": "Tutup",
  "ss_builder_button_font_size": "Ukuran font (px)",
  "ss_builder_button_font_weight": "Berat font",
  "ss_builder_button_line_height": "Tinggi garis",
  "ss_builder_button_link_content_title": "Judul",
  "ss_builder_button_link_description": "Deskripsi Link",
  "ss_builder_button_link_icon_btn_selection_label": "Pilih Ikon",
  "ss_builder_button_link_icon_origin_url_label": "Link",
  "ss_builder_button_link_icon_title": "Ikon",
  "ss_builder_button_link_large_size_label": "Besar",
  "ss_builder_button_link_middle_size_label": "Medium",
  "ss_builder_button_link_not_enought_infomation": "Link belum cukup informasi",
  "ss_builder_button_link_placeholder_content_title": "Masukan konten",
  "ss_builder_button_link_size_selection_title": "Pilih ukuran",
  "ss_builder_button_link_small_size_label": "Kecil",
  "ss_builder_button_setting_title": "Pengaturan Tombol",
  "ss_builder_button_text_color": "Warna teks",
  "ss_builder_button_title": "Button title",
  "ss_builder_cancel": "Batal",
  "ss_builder_cancel-delete_block_label": "Batal",
  "ss_builder_cancel-delete_image_label": "Lanjutkan mengubah",
  "ss_builder_cancel-delete_social_link_btn_label": "Batal",
  "ss_builder_cancel-delete_tab_label": "Batal",
  "ss_builder_cancel-restore_theme_label": "Batal",
  "ss_builder_cancel-set_default_tab_label": "Batal",
  "ss_builder_cancel_affiliate_product_group_add_new_panel_label": "Batal",
  "ss_builder_cancel_apply_theme_template_btn_label": "Batalkan",
  "ss_builder_cancel_block_btn_label": "Keluar",
  "ss_builder_cancel_delete_aff_block_btn_label": "Batal",
  "ss_builder_cancel_delete_card_modal_btn_label": "Lanjut mengatur",
  "ss_builder_cancel_delete_link_modal_btn_label": "Lanjutkan mengedit",
  "ss_builder_cancel_delete_product_label": "Batal",
  "ss_builder_cancel_delete_theme_template_modal_btn_label": "Batalkan",
  "ss_builder_cancel_edit_block_content": "Modifikasi Anda di blok tidak akan disimpan. Anda yakin ingin keluar?",
  "ss_builder_cancel_edit_block_title": "Batalkan pengeditan?",
  "ss_builder_cancel_hide_product_label": "Batal",
  "ss_builder_cancel_image_slider_add_new_btn_label": "Batal",
  "ss_builder_cancel_overwrite_theme_template_btn_label": "Bantalkan",
  "ss_builder_cancel_preview_theme_template_btn_label": "Tutup",
  "ss_builder_cancel_product_addition_modal_confirm_btn_label": "Lanjutkan menambahkan produk",
  "ss_builder_cancel_product_addition_modal_description": "Produk yang ditambahkan pada layar ini tidak akan disimpan.",
  "ss_builder_cancel_product_addition_modal_title": "Batal tambahkan produk?",
  "ss_builder_cancel_replace_theme_template_btn_label": "Batal",
  "ss_builder_cancel_select_multi_items": "Batal",
  "ss_builder_cancel_theme_selection_panel_label": "Batalkan",
  "ss_builder_cancel_upload_image": "Batalkan",
  "ss_builder_card_add_new_description_label": "Deskripsi",
  "ss_builder_card_add_new_label": "Label",
  "ss_builder_card_add_new_subtitle_label": "Sub judul",
  "ss_builder_card_add_new_title_label": "Judul",
  "ss_builder_card_add_new_url_label": "Url",
  "ss_builder_card_additional_btn_label": "Tambahkan kartu",
  "ss_builder_card_btn_content_label": "Tombol konten",
  "ss_builder_card_layout_alert_1": "Layout ini tidak akan menampilkan deskripsi, label, dan tombol",
  "ss_builder_card_layout_alert_2": "Layout ini tidak menampilkan deskripsi dan tombol",
  "ss_builder_card_layout_label": "Layout",
  "ss_builder_card_layout_label_1": "Teks di bawah gambar - Ratakan paragraf di kiri",
  "ss_builder_card_layout_label_2": "Teks di bawah gambar - Ratakan paragraf di tengah",
  "ss_builder_card_layout_label_3": "Teks di atas gambar - Ratakan paragraf di tengah",
  "ss_builder_card_layout_label_4": "Teks di atas gambar - Ratakan paragraf di kiri",
  "ss_builder_card_layout_label_5": "Teks di kanan",
  "ss_builder_card_layout_label_6": "Teks di kiri",
  "ss_builder_card_layout_label_7": "Teks di atas gambar - Ratakan paragraf di sebelah kiri 2",
  "ss_builder_card_layout_label_8": "Teks di atas gambar - Ratakan paragraf di tengah 2",
  "ss_builder_card_url_placeholder": "http://domain.com",
  "ss_builder_category_14_up": "14+",
  "ss_builder_category_all": "Semua",
  "ss_builder_category_art": "Seni",
  "ss_builder_category_creative": "Creative",
  "ss_builder_category_doctor": "Dokter",
  "ss_builder_category_education": "Education",
  "ss_builder_category_entertainment": "Entertainment",
  "ss_builder_category_fashion": "Fashion",
  "ss_builder_category_fitness": "Fitness",
  "ss_builder_category_food_fmcg": "Food & FMCG",
  "ss_builder_category_game": "Game",
  "ss_builder_category_health_and_beauty": "Health & Beauty",
  "ss_builder_category_ict": "ICT",
  "ss_builder_category_life_style": "Lifestyle",
  "ss_builder_category_lifestyle": "Lifestyle",
  "ss_builder_category_mom_and_kid": "Mom & Kid",
  "ss_builder_category_others": "Lainnya",
  "ss_builder_category_pet": "Binatang peliharaan",
  "ss_builder_category_spirit": "Spirit",
  "ss_builder_change_image_button_label": "Ganti gambar",
  "ss_builder_change_name_product_group_successfully_label": "Sukses mengubah nama grup produk",
  "ss_builder_change_theme_label": "Ubah tema",
  "ss_builder_choose_a_prototying_method": "Pilih opsi untuk membuat template baru",
  "ss_builder_choose_at_least_category": "Pilih setidaknya 1 kategori dan kami akan menyarankan tema berdasarkan pilihan Anda👇",
  "ss_builder_choose_image_below": "Klik tombol \"Tambah baru\" di bawah untuk menambahkan yang baru",
  "ss_builder_choose_one_theme_preview_you_can_customize_it_later": "Pilih satu tema, Anda dapat menyesuaikannya setelah menerapkan✨",
  "ss_builder_choose_the_look_that_right_for_you": "Silakan pilih tema",
  "ss_builder_choose_this_template": "Apply",
  "ss_builder_cleanup_lock_product_successfully_label": "Tutup fitur pembersihan suskes",
  "ss_builder_cleanup_unlock_product_successfully_label": "Buka fitur pembersihan suskes",
  "ss_builder_close_panel": "Batal",
  "ss_builder_close_panel_affiliate_product-selection-label": "Batal",
  "ss_builder_close_panel_edit_text_block": "Tutup",
  "ss_builder_collapse": "Lihat lebih sedikit",
  "ss_builder_color_1": "Warna 1",
  "ss_builder_color_2": "Warna 2",
  "ss_builder_color_picker_panel_title": "Pilih warna",
  "ss_builder_confirm-cancel_image_slider_add_new_content": "Tindakan Anda belum disimpan.\nApakah Anda ingin menyimpan sebelum keluar?",
  "ss_builder_confirm-cancel_image_slider_add_new_title": "Batalkan pengeditan?",
  "ss_builder_confirm-delete_block_label": "Jika Anda menghapus blok, perubahan dan data yang ditambahkan akan dihapus. Yakin ingin menghapus?",
  "ss_builder_confirm-delete_block_title": "Apakah Anda ingin menghapus blok ini?",
  "ss_builder_confirm-delete_image_label": "Anda yakin ingin menghapus gambar ini?",
  "ss_builder_confirm-delete_image_title": "Hapus gambar",
  "ss_builder_confirm-delete_social_link_content": "Apakah kamu yakin menghapus item ini?",
  "ss_builder_confirm-delete_social_link_title": "Apakah kamu yakin menghapus sosial media ini?",
  "ss_builder_confirm_affiliate_product_group_add_new_panel_label": "Simpan",
  "ss_builder_confirm_apply_template_description": "Mengaktifkan template akan mengubah seluruh blok di list mu dengan yang ada di template.",
  "ss_builder_confirm_apply_template_label": "Ubah",
  "ss_builder_confirm_apply_theme_template_btn_label": "Konfirmasi",
  "ss_builder_confirm_apply_theme_template_content": "Menerapkan tema yang Anda pilih akan mengubah tampilan seluruh situs web",
  "ss_builder_confirm_apply_theme_template_title": "Apakah Anda ingin menerapkan tema ini?",
  "ss_builder_confirm_cancel_image_slider_add_new_btn_label": "Keluar",
  "ss_builder_confirm_cleanup_lock_product_btn_label": "Konfirmasi mengunci",
  "ss_builder_confirm_cleanup_lock_product_selection_modal_content": "Are you sure you want to lock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_cleanup_unlock_product_btn_label": "Konfirmasi membuka",
  "ss_builder_confirm_cleanup_unlock_product_selection_modal_content": "Are you sure you want to unlock {{product_quantity}} products cleanup?",
  "ss_builder_confirm_delete_aff_block_content": "Tindakan ini tidak bisa dibatalkan. Harap konfirmasi, Anda memahami bahwa menghapus blok ini akan menghapus semua produk di dalamnya.",
  "ss_builder_confirm_delete_aff_block_input_placeholder": "OK",
  "ss_builder_confirm_delete_aff_block_label_enter_ok": "Tekan \"OK\" untuk konfirmasi",
  "ss_builder_confirm_delete_aff_block_understood": "Saya mengerti",
  "ss_builder_confirm_delete_aff_block_warining": "Peringatan",
  "ss_builder_confirm_delete_block_btn_label": "Konfirmasi penghapusan",
  "ss_builder_confirm_delete_card_modal_btn_label": "Hapus kartu",
  "ss_builder_confirm_delete_card_modal_description": "Apakah Anda yakin hapus kartu ini?",
  "ss_builder_confirm_delete_card_modal_label": "Hapus kartu",
  "ss_builder_confirm_delete_combined_label": "Yakin ingin menghapus tipe {{type}} blok ini?",
  "ss_builder_confirm_delete_image_popup_image_label": "Konfirmasi hapus",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_content": "Apa anda yakin menghapus {{quantity}} link ini?",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_title": "Hapus link",
  "ss_builder_confirm_delete_item_checkbox_card_modal_content": "Apa anda yakin menghapus {{quantity}} kartu/cards ini?",
  "ss_builder_confirm_delete_item_checkbox_card_modal_title": "Hapus kartu/cards",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_content": "Hapus {{quantity}} Blok ini?",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_title": "Hapus Blok",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_content": "Apa anda yakin menghapus {{quantity}} gambar ini?",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_title": "Hapus galeri gambar",
  "ss_builder_confirm_delete_item_checkbox_story_modal_content": "Apa anda yakin menghapus {{quantity}} spotlight ini?",
  "ss_builder_confirm_delete_item_checkbox_story_modal_title": "Hapus Spotlight",
  "ss_builder_confirm_delete_link_description": "Anda yakin ingin menghapus link ini?",
  "ss_builder_confirm_delete_product_group_modal_description": "Grup \"{{group_name}}\" dan semua data produk di dalamnya akan dihapus secara permanen. Apakah Anda yakin ingin menghapus?",
  "ss_builder_confirm_delete_product_group_modal_title": "Apakah Anda ingin menghapus grup?",
  "ss_builder_confirm_delete_product_label": "Hapus",
  "ss_builder_confirm_delete_product_modal_content": "Apakah kamu yakin ingin menghapus produk ini?",
  "ss_builder_confirm_delete_product_modal_title": "Hapus produk",
  "ss_builder_confirm_delete_product_selection_modal_content": "Apakah Anda yakin ingin hapus {{product_quantity}} produk ini ?",
  "ss_builder_confirm_delete_tab_label": "Hapus",
  "ss_builder_confirm_delete_template_modal_content": "Hapus template ini dari list template, blok yang sebelumnya digunakan di template ini tidak akan terdampak",
  "ss_builder_confirm_delete_template_modal_title": "Kamu ingin menghapus template ini?",
  "ss_builder_confirm_delete_theme_template_modal_btn_label": "Konfirmasi penghapusan",
  "ss_builder_confirm_delete_theme_template_modal_content": "Hapus template tema secara permanen dari Theme Store, tema yang dibuat dari tema ini tidak akan terpengaruh",
  "ss_builder_confirm_delete_theme_template_modal_title": "Apakah Anda ingin menghapus template tema ini?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_content": "Sembunyikan {{quantity}} link ini?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_title": "Sembunyikan link",
  "ss_builder_confirm_disable_item_checkbox_card_modal_content": "Sembuntikan {{quantity}} kartu/cards ini?",
  "ss_builder_confirm_disable_item_checkbox_card_modal_title": "Sembunyikan kartu/cards",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_content": "Sembunyikan {{quantity}} Blok ini?",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_title": "Sembunyikan Blok",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_content": "Sembunyikan {{quantity}} galeri gambar ini?",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_title": "Sembunyikan galeri gambar",
  "ss_builder_confirm_disable_item_checkbox_story_modal_content": "Sembunyikan {{quantity}} spotlight ini?",
  "ss_builder_confirm_disable_item_checkbox_story_modal_title": "Sembunyikan spotlight",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_content": "Tampilkan {{quantity}} link ini?",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_title": "Tampilkan link",
  "ss_builder_confirm_enable_item_checkbox_card_modal_content": "Tampilkan {{quantity}} kartu/cards?",
  "ss_builder_confirm_enable_item_checkbox_card_modal_title": "Tampilkan kartu/cards",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_content": "Tampilkan {{quantity}} Blok ini?",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_title": "Tampilkan Blok",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_content": "Tampilkan {{quantity}} galeri gambar ini?",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_title": "Tampilkan galeri gambar",
  "ss_builder_confirm_enable_item_checkbox_story_modal_content": "Tampilkan {{quantity}} spotlight ini?",
  "ss_builder_confirm_enable_item_checkbox_story_modal_title": "Tampilkan spotlight",
  "ss_builder_confirm_enable_product_selection_modal_content": "Apakah Anda yakin ingin menampilkan {{product_quantity}} produk ini ?",
  "ss_builder_confirm_hide_product_btn_label": "Konfirmasi untuk menyembunyikan",
  "ss_builder_confirm_hide_product_group_modal_description": "Grup produk \"{{group_name}}\" dan semua data produk di dalamnya akan disembunyikan. Apakah Anda yakin ingin menyembunyikan grup?",
  "ss_builder_confirm_hide_product_group_modal_title": "Apakah Anda ingin menyembunyikan grup?",
  "ss_builder_confirm_hide_product_label": "Konfirmasi untuk menyembunyikan grup",
  "ss_builder_confirm_hide_product_selection_modal_content": "Apakah Anda yakin ingin menyembunyikan {{product_quantity}} produk ini ?",
  "ss_builder_confirm_overwrite": "Konfirmasi menimpa template",
  "ss_builder_confirm_overwrite_theme_template_btn_label": "Konfirmasi penimpaan",
  "ss_builder_confirm_overwrite_theme_template_modal_content": "Timpa akan menggantikan semua data dan pengaturan template tema dengan data dan pengaturan template tema terbaru",
  "ss_builder_confirm_overwrite_theme_template_modal_title": "Apakah Anda ingin menimpa?",
  "ss_builder_confirm_panel": "Konfirmasi",
  "ss_builder_confirm_panel_affiliate_product-selection-label": "Konfirmasi",
  "ss_builder_confirm_panel_edit_text_block": "Konfirmasi",
  "ss_builder_confirm_replace_theme_template_btn_label": "Terapkan sampel data",
  "ss_builder_confirm_replace_theme_template_content": "Tindakan ini tidak bisa dibatalkan.\nHarap konfirmasi bahwa Anda memahami jika menerapkan konten sampel akan menggantikan semua data yang ada di situs.",
  "ss_builder_confirm_replace_theme_template_input_placeholder": "OK",
  "ss_builder_confirm_replace_theme_template_label_enter_ok": "Tekan \"OK\" untuk mengonfirmasi",
  "ss_builder_confirm_replace_theme_template_understood": "Saya mengerti",
  "ss_builder_confirm_replace_theme_template_warining": "Peringatan",
  "ss_builder_confirm_restore_theme_label": "Pulihkan pengeditan",
  "ss_builder_confirm_set_default_tab_label": "Pengaturan standar",
  "ss_builder_confirm_show_product_btn_label": "Konfirmasi untuk menampilkan",
  "ss_builder_confirm_theme_template_preview_mode_btn_label": "Terapkan",
  "ss_builder_confirm_to_edit_label": "Lanjut ubah",
  "ss_builder_content_1_introduction_service": "Brand Gift adalah bentuk mempromosikan suatu produk promosi, hot deal dari Brand kepada fans/followers untuk meningkatkan \"jumlah orders\" dan mencapai \"komisi besar\" untuk Kreator. Setiap voucher yang berhasil dari link Brand Gift, Anda akan menerima kebijakan komisi yang sangat menarik.",
  "ss_builder_content_2_introduction_service": "Jangan ketinggalan! Aktifkan Brand Gift untuk menerima bonus",
  "ss_builder_content_creators_joined_the_passio_system": "100K+ content creators sudah bergabung dengan Passio eco-system",
  "ss_builder_content_editor_title": "Isi",
  "ss_builder_content_list_1_introduction_service": "Komisi dari marketplace",
  "ss_builder_content_list_2_introduction_service": "Brand Bonus (hingga 30%)",
  "ss_builder_content_list_3_introduction_service": "500+ Brand besar di berbagai bidang dengan banyak program bonus",
  "ss_builder_continue_add_brand_gift": "Coba Sekarang",
  "ss_builder_continue_edit_block_label": "Lanjutkan mengubah",
  "ss_builder_continue_edit_image_popup_image_label": "Batal",
  "ss_builder_continue_to_add_block": "Lanjut",
  "ss_builder_convert_and_go_to_new_version": "Sinkronkan data dan buka Passio Page",
  "ss_builder_cover_image": "Gambar Cover",
  "ss_builder_create_bio_page_free_fast": "Buat Bio Page dengan gratis & cepat",
  "ss_builder_create_link_with_your_name_and_imprint": "Buat halaman bio dengan nama dan brandmu. Theme Store dengan banyak kategori tema, dan dapat dengan mudah diatur sesuai dengan keinginan.",
  "ss_builder_create_new_theme_template_label": "Buat template tema baru",
  "ss_builder_create_template": "Buat Template",
  "ss_builder_cusom": "Custom",
  "ss_builder_custom": "Custom",
  "ss_builder_dashed_type_label": "Dashed",
  "ss_builder_data_auto_convert": "Data lama Anda akan disinkronkan dari Landing Page lama ke Passio Page baru secara otomatis",
  "ss_builder_data_can_be_convert": "Anda dapat menyinkronkan data dari Landing Page lama ke Passio Page baru",
  "ss_builder_data_will_be_override": "Peringatan: data di Passio Page akan ditimpa jika Anda menyinkronkan data",
  "ss_builder_deep_blue_sky": "Langit biru",
  "ss_builder_default_meta_description": "Passio Page - Home of {{creator_name}}",
  "ss_builder_defaults_label": "Default",
  "ss_builder_delete_block_label": "Hapus blok",
  "ss_builder_delete_block_successfully_label": "Berhasil hapus blok",
  "ss_builder_delete_card_label": "Hapus kartu",
  "ss_builder_delete_card_successfully_label": "Hapus kartu berhasil",
  "ss_builder_delete_image_label": "Hapus gambar",
  "ss_builder_delete_image_successfully_label": "Berhasil hapus gambar",
  "ss_builder_delete_item_checkbox_button_multi_link_successfully_label": "Sukses menghapus link",
  "ss_builder_delete_item_checkbox_card_successfully_label": "Sukses menghapus kartu/cards",
  "ss_builder_delete_item_checkbox_combined_successfully_label": "Sukses menghapus blok",
  "ss_builder_delete_item_checkbox_image_gallery_successfully_label": "Sukses menghapus galeri gambar",
  "ss_builder_delete_item_checkbox_story_successfully_label": "Sukses menghapus spotlight",
  "ss_builder_delete_link_label": "Hapus link",
  "ss_builder_delete_link_successfully_label": "Hapus link berhasil",
  "ss_builder_delete_product_group_error_btn_confirm_label": "Tutup",
  "ss_builder_delete_product_group_error_content": "Grup \"{{group_name}}\" tidak dapat dihapus, diperlukan minimal 1 grup.",
  "ss_builder_delete_product_group_error_title": "Terjadi kesalahan saat menghapus grup",
  "ss_builder_delete_product_group_label": "Hapus grup",
  "ss_builder_delete_product_group_successfully_label": "Sukses menghapus grup produk",
  "ss_builder_delete_product_label": "Hapus produk",
  "ss_builder_delete_product_successfully_label": "Berhasil hapus produk",
  "ss_builder_delete_social_link_btn_label": "Hapus",
  "ss_builder_delete_social_link_label": "Hapus",
  "ss_builder_delete_social_link_successfully_label": "Berhasil menghapus",
  "ss_builder_delete_tab_content": "Tab \"{{tab_name}}\" dan semua data blok di dalamnya akan dihapus secara permanen. Anda yakin ingin menghapus?",
  "ss_builder_delete_tab_label": "Hapus",
  "ss_builder_delete_tab_successfully_label": "Berhasil hapus tab",
  "ss_builder_delete_tab_title": "Apakah Anda ingin menghapus tab?",
  "ss_builder_delete_template_successfully_label": "Sukses menghapus template",
  "ss_builder_delete_theme_template_label": "Hapus",
  "ss_builder_delete_theme_template_successfully_label": "Hapus template tema berhasil",
  "ss_builder_description": "Deskripsi",
  "ss_builder_description_page_builder": "Membangun Situs Bio dalam 1 klik dan mulailah menghasilkan uang",
  "ss_builder_description_replace_template_content": "Terapkan tema data sampel akan menggunakan konten dari tema template untuk menggantikan semua konten situs Anda",
  "ss_builder_design_group_floating_title": "Floating icons",
  "ss_builder_design_group_image_popup_title": "Popup",
  "ss_builder_design_label": "Desain",
  "ss_builder_dialog_error_confirm_text": "Refresh",
  "ss_builder_dialog_error_content": "Ada perubahan dari perangkat lain. Anda perlu refresh halaman.",
  "ss_builder_dialog_error_title": "Ada pembaruan di situs Anda",
  "ss_builder_digital_product_free_label": "Gratis",
  "ss_builder_digital_product_list_title": "List produk",
  "ss_builder_direction_of_background_image_label": "Posisi Background",
  "ss_builder_disable_block_successfully_label": "Berhasil sembunyikan blok",
  "ss_builder_disable_image_successfully_label": "Sukses sembunyikan foto",
  "ss_builder_disable_item_checkbox_button_multi_link_successfully_label": "Sukses menyembunyikan link",
  "ss_builder_disable_item_checkbox_card_successfully_label": "Sukses menyembunyikan kartu/cards",
  "ss_builder_disable_item_checkbox_combined_successfully_label": "Sukses menyembunyikan blok",
  "ss_builder_disable_item_checkbox_image_gallery_successfully_label": "Sukses menyembunyikan galeri gambar",
  "ss_builder_disable_item_checkbox_story_successfully_label": "Sukses menyembunyikan spotlight",
  "ss_builder_disable_product_group_successfully_label": "Sukses menyembunyikan grup produk",
  "ss_builder_disable_product_successfully_label": "Berhasil sembunyikan produk",
  "ss_builder_disable_social_link_successfully_label": "Sembunyikan sosial media berhasil",
  "ss_builder_disable_tab_successfully_label": "Tab berhasil disembunyikan",
  "ss_builder_disable_theme_successfully_label": "Sukses menyembunyikan template",
  "ss_builder_discover_now": "Temukan sekarang",
  "ss_builder_distance_blocks": "Jarak antar Blok",
  "ss_builder_divider_color_label": "Divider color",
  "ss_builder_divider_image_label": "Divider image",
  "ss_builder_divider_label": "Divider",
  "ss_builder_divider_thickness_label": "Thickness (px)",
  "ss_builder_do_you_want_replace_sample_content": "Apakah Anda ingin menerapkan tema data sampel ?",
  "ss_builder_domain_site_setting_alert_infomation": "Jika Anda mengubah nama domain, Anda harus menunggu 30 hari untuk dapat mengubah kembali.",
  "ss_builder_domain_site_setting_rule": "Mulai dengan huruf, hanya berisi angka dan huruf",
  "ss_builder_done_panel": "Selesai",
  "ss_builder_dont_add_block_from_template_description": "Tidak dapat menambah {{number_block}} blok dari template karena batasan maksimal 10 block dari list. Silahkan hapus blok yang sudah ada untuk menambahkan blok template.",
  "ss_builder_dont_add_block_from_template_title": "Tidak dapat menambahkan blok",
  "ss_builder_dont_add_product_exist_at_add_new_content": "Produk sudah ada di list",
  "ss_builder_dont_add_product_exist_at_list_content": "Produk yang ingin Anda tambahkan sudah ada di grup produk \"{{group_name}}\".",
  "ss_builder_dont_add_same_affiliate_product_confirm_label": "Konfirmasi",
  "ss_builder_dont_add_same_affiliate_product_modal_label": "Tidak dapat menambahkan produk baru",
  "ss_builder_dont_apply_template_content_modal_description": "Template tidak bisa digunakan karena template tidak tersedia.",
  "ss_builder_dont_apply_theme_content_modal_btn_confirm_label": "Tutup",
  "ss_builder_dont_apply_theme_content_modal_description": "Aplikasi data sampel tidak dapat dilakukan karena tema template tidak tersedia di Theme Store.",
  "ss_builder_dont_apply_theme_content_modal_label": "Tidak bisa menerapkan",
  "ss_builder_dot_strokes_type_label": "Dotted",
  "ss_builder_dp_title_intro": "Digital products",
  "ss_builder_drop_here_label": "Tambah blok disini",
  "ss_builder_duplicated_product_url_label": "Tautan duplikat",
  "ss_builder_duplicated_theme_template_name_warning": "Nama antarmuka itu sudah ada. Silakan masukkan nama lain!",
  "ss_builder_earn_additional_commissions_label": "Earn additional commissions when using Passio's affiliate links",
  "ss_builder_edit_affiliate_product_group_title": "Nama grup",
  "ss_builder_edit_affiliate_product_title": "Edit produk",
  "ss_builder_edit_block_label": "Ubah",
  "ss_builder_edit_block_successfully_label": "Berhasil edit blok",
  "ss_builder_edit_image_of_image_popup_title": "Edit gambar pop-up",
  "ss_builder_edit_name_affiliate_product_group_title": "Ubah nama grup produk",
  "ss_builder_edit_name_block_successfully_label": "Berhasil edit nama blok",
  "ss_builder_edit_product_group_label": "Ganti Nama",
  "ss_builder_edit_tab_label": "Ganti nama",
  "ss_builder_edit_tab_successfully_label": "Berhasil edit tab",
  "ss_builder_edit_template_btn_label": "Simpan",
  "ss_builder_edit_template_title": "Atur template",
  "ss_builder_edit_text_block_label": "Nama blok",
  "ss_builder_edit_theme_btn_label": "Simpan",
  "ss_builder_edit_theme_template_label": "Edit Info",
  "ss_builder_edit_theme_template_successfully_label": "Update template tema berhasil",
  "ss_builder_edit_theme_template_title": "Edit info template tema",
  "ss_builder_editor_btn-add-more": "Tambah",
  "ss_builder_editor_btn-cancel": "Batal",
  "ss_builder_editor_btn-save": "Simpan",
  "ss_builder_email_placeholder": "Masukan email",
  "ss_builder_embed_code_label": "Embed code",
  "ss_builder_embed_code_placeholder": "Tolong masukkan kode embed",
  "ss_builder_empty_block": "Tidak ada Blok",
  "ss_builder_empty_search": "Hasil tidak ditemukan",
  "ss_builder_empty_social_link_list_content": "Untuk menambahkan jejaring sosial, tekan tombol \"Tambah\" di atas",
  "ss_builder_empty_social_link_list_title": "Belum ada jejaring sosial yang ditambahkan",
  "ss_builder_empty_template_label": "Tidak ada template blok",
  "ss_builder_enable_block_successfully_label": "Berhasil tampilkan blok",
  "ss_builder_enable_border": "Aktifkan",
  "ss_builder_enable_button_border": "Aktifkan border",
  "ss_builder_enable_image_successfully_label": "Sukses tampilkan gambar",
  "ss_builder_enable_item_checkbox_button_multi_link_successfully_label": "Sukses menampilkan link",
  "ss_builder_enable_item_checkbox_card_successfully_label": "Sukses menampilkan kartu/cards",
  "ss_builder_enable_item_checkbox_combined_successfully_label": "Sukses menampilkan blok",
  "ss_builder_enable_item_checkbox_image_gallery_successfully_label": "Sukses menampilkan galeri gambar",
  "ss_builder_enable_item_checkbox_story_successfully_label": "Sukses menampilkan spotlight",
  "ss_builder_enable_product_group_successfully_label": "Sukses menampilkan grup produk",
  "ss_builder_enable_product_successfully_label": "Berhasil tampilkan produk",
  "ss_builder_enable_shadow": "Aktifkan",
  "ss_builder_enable_social_link_successfully_label": "Tampilkan sosial media berhasil",
  "ss_builder_enable_tab_successfully_label": "Tab berhasil ditampilkan",
  "ss_builder_enable_theme_successfully_label": "Sukses menampilkan template",
  "ss_builder_enable_tracking_link_product_label": "Aktifkan tracking link",
  "ss_builder_enable_tracking_link_product_successfully_label": "Sukses mengaktifkan tracking link",
  "ss_builder_enter_domain_site_setting_label": "Jalur domain pribadi",
  "ss_builder_enter_font_name": "Masukkan nama font",
  "ss_builder_enter_url_label": "Masukkan link",
  "ss_builder_enter_your_url": "Masukkan URL",
  "ss_builder_error_block_label": "Blok Error",
  "ss_builder_error_invalid_email_message": "Email tidak valid",
  "ss_builder_error_invalid_phone_message": "Nomor telepon tidak valid",
  "ss_builder_error_network_confirm_btn_label": "Tutup",
  "ss_builder_error_network_content": "Harap tunggu beberapa menit sebelum mencoba lagi",
  "ss_builder_error_network_title": "System overload",
  "ss_builder_error_page_title": "Passio Page Builder - {{country}} - Halaman Error",
  "ss_builder_exceeding_label": "Mencapai maksimum blok",
  "ss_builder_existed_product_group_label": "Grup sudah ada",
  "ss_builder_expand": "Lihat lebih banyak",
  "ss_builder_expand_introduction_description": "\"Extended Position\" berisi blok yang tidak memiliki posisi yang tetap. Misal: Popup, Floating Icons",
  "ss_builder_expand_introduction_title": "Extended Position",
  "ss_builder_filter_select_combined_count": "blok dipilih",
  "ss_builder_filter_select_multi_items": "{{num_items}} terakhir",
  "ss_builder_filter_select_multi_items_card_count": "kartu/card dipilih",
  "ss_builder_filter_select_multi_items_gallery_count": "gambar dipilih",
  "ss_builder_filter_select_multi_items_multi_links_count": "link dipilih",
  "ss_builder_filter_select_multi_items_spotlight_count": "spotlight dipilih",
  "ss_builder_first_guide_add_block_btn_title": "Tambah Blok",
  "ss_builder_first_guide_add_block_description": "Tap atau Drag & Drop di menu \"Tambah Blok\" arahkan ke posisi yang ingin Anda tambahkan blok di passio page mu. Ada banyak tipe blok yang bisa kamu tambahkan agar passio page mu lebih keren dan menarik. Ayo coba!",
  "ss_builder_first_guide_add_block_title": "Tambah menu Blok",
  "ss_builder_first_guide_preview_btn_title": "Preview site",
  "ss_builder_first_guide_preview_description": "Pratinjau situs untuk melihat perubahan situs saat ini. Simpan tampilan setelah kamu puas dengan pratinjau saat ini.",
  "ss_builder_first_guide_preview_title": "Menu Pratinjau Situs",
  "ss_builder_first_guide_publish_btn_title": "Publikasikan",
  "ss_builder_first_guide_publish_description": "Pengeditan desain selalu dalam mode \"Draft\". Perubahan akan terlihat oleh Fans Anda setelah Anda memutuskan untuk mem\"Publikasikan\".",
  "ss_builder_first_guide_publish_title": "Tampilkan Ke publik",
  "ss_builder_floating_full": "Hanya bisa menambah 2 blok di grup Floating Icons",
  "ss_builder_floating_introduction_description": "Blok di Floating Icons ini akan selalu muncul di pojok kanan layar.",
  "ss_builder_floating_introduction_title": "Floating icons",
  "ss_builder_font_name_not_exist": "Font tidak tersedia",
  "ss_builder_font_option_panel_title": "Jenis huruf",
  "ss_builder_footer_description": "Blok-blok tersebut akan muncul di semua Tab",
  "ss_builder_footer_introduction_description": "“Footer” berisi Blok, yang selalu muncul di bagian bawah halaman. Misalnya: tautan cepat, info halaman...",
  "ss_builder_footer_introduction_title": "Footer Position",
  "ss_builder_footer_title": "Footer",
  "ss_builder_frame_aff": "Frame gambar produk",
  "ss_builder_general_setting_title": "Pengaturan umum",
  "ss_builder_generate_income_from_your_own_fan_community": "Monetize passion mu dan dapatkan support dari fansmu!",
  "ss_builder_gift_warning": "Hanya bisa menambah satu blok",
  "ss_builder_go_back_to_site": "Kembali ke halaman utama",
  "ss_builder_google_font": "Google Fonts",
  "ss_builder_gradient": "Gradiasi",
  "ss_builder_group_is_full_of_product_modal_description": "Grup produk yang Anda pilih sudah penuh. Silakan pilih grup lain",
  "ss_builder_group_is_full_of_product_modal_title": "Tidak bisa menambahkan",
  "ss_builder_group_is_hiding_label": "Sembunyikan grup produk",
  "ss_builder_hard_shadow": "Hard shadow",
  "ss_builder_header_description": "Blok-blok tersebut akan muncul di semua Tab",
  "ss_builder_header_introduction_description": "“Header” berisi Blok, yang selalu muncul di bagian atas halaman. Misalnya: gambar Avatar, pengantar...",
  "ss_builder_header_introduction_title": "Header Position",
  "ss_builder_header_title": "Header",
  "ss_builder_heading_and_subtitle_site_setting_label": "Judul dan subjudul",
  "ss_builder_heading_site_setting_description": "Ditampilkan oleh mesin pencari di pratinjau halaman Anda. Ini penting bagi SEO dan berbagi melalui jejaring sosial.",
  "ss_builder_heading_site_setting_label": "Judul halaman",
  "ss_builder_hide_block_label": "Sembunyikan blok",
  "ss_builder_hide_card_label": "Sembunyikan kartu",
  "ss_builder_hide_card_successfully_label": "Sembunyikan kartu berhasil",
  "ss_builder_hide_image_label": "Sembunyikan gambar",
  "ss_builder_hide_link_label": "Sembunyikan link",
  "ss_builder_hide_link_successfully_label": "Sembunyikan link berhasil",
  "ss_builder_hide_product_group_error_btn_confirm_label": "Tutup",
  "ss_builder_hide_product_group_error_content": "Grup \"{{group_name}}\" tidak dapat disembunyikan, harus menampilkan setidaknya 1 grup.",
  "ss_builder_hide_product_group_error_title": "Terjadi kesalahan saat menyembunyikan grup",
  "ss_builder_hide_product_group_label": "Sembunyikan grup",
  "ss_builder_hide_product_label": "Sembunyikan produk",
  "ss_builder_hide_social_link_label": "Sembunyikan",
  "ss_builder_hide_tab_label": "Sembunyi Tab",
  "ss_builder_hide_template_successfully_label": "Suklses menyembunyikan template",
  "ss_builder_hide_theme_template_label": "Hide template",
  "ss_builder_hightlight_block_label": "Pilihan terbaik",
  "ss_builder_icon_tab_title": "Ikon",
  "ss_builder_if_delete": "Jika Anda menghapus blok Affiliate ini,",
  "ss_builder_image": "Gambar",
  "ss_builder_image_description_place_holder": "Masukkan konten",
  "ss_builder_image_gallery": "Galeri gambar",
  "ss_builder_image_list_empty_content": "Untuk menambahkan gambar baru, silakan klik tombol di bawah ini",
  "ss_builder_image_list_empty_title": "Daftar gambar kosong",
  "ss_builder_image_orientation": "Gambar orientasi",
  "ss_builder_image_orientation_label": "Gambar orientasi",
  "ss_builder_image_popup_introduction_description": "Blok di posisi ini akan muncul sebagai popup ketika fans melihat passio page milikmu. Popup dapat digunakan untuk menampilkan info penting, call to action, atau penayangan iklan...",
  "ss_builder_image_popup_introduction_title": "Popup",
  "ss_builder_image_popup_warning": "Anda hanya dapat menambahkan satu blok jenis ini per halaman",
  "ss_builder_image_ratio_title": "Rasio gambar",
  "ss_builder_image_selection_label": "Pilih gambar",
  "ss_builder_image_slider_arrow_switch_title": "Panah",
  "ss_builder_image_slider_auto_play_switch_title": "Putar otomatis",
  "ss_builder_image_slider_dot_switch_title": "Dot slider",
  "ss_builder_image_slider_list_title": "Daftar gambar",
  "ss_builder_image_slider_ratio_selected_title": "Rasio gambar",
  "ss_builder_image_title_place_holder": "Masukkan konten",
  "ss_builder_import_multi_product_url_popover_content": "Anda dapat memasukkan satu atau beberapa url. Satu url per baris.",
  "ss_builder_import_site_url_label": "Unggah file produk dengan URL",
  "ss_builder_import_site_url_modal_button_label": "Unggah produk",
  "ss_builder_import_site_url_modal_description": "Masukkan link yang ingin diunggah, contoh: koc.asia, mycollection.shop, linktr.ee, msha.ke",
  "ss_builder_import_site_url_modal_title": "Link halaman produk",
  "ss_builder_in_block_delete_forever_are_you_sure": "akan dihapus secara permanen. Anda yakin ingin menghapus?",
  "ss_builder_information_handler_alert": "Sedang diproses. Harap tunggu sebentar.",
  "ss_builder_inner_space": "Jarak dalam",
  "ss_builder_intro_brand_gift_floating_continue": "Tambah sekarang",
  "ss_builder_intro_brand_gift_floating_description": "Tambah Brand Gift Icon sekarang untuk membantu fansmu mendapat diskon dari Brands",
  "ss_builder_intro_brand_gift_floating_reject": "Batal",
  "ss_builder_intro_brand_gift_floating_title": "Lipat gandakan pendapatanmu dengan Brand Gift Floating Icon",
  "ss_builder_intro_brand_rating_floating_description": "id_Thêm tính năng ngay để giúp Fan săn deal thành công và tăng thu nhập",
  "ss_builder_intro_brand_rating_floating_title": "id_Brand Ratings",
  "ss_builder_introduce_double_tap_button": "Lanjutkan perubahan",
  "ss_builder_introduce_double_tap_content": "Selain dengan menggunakan menu \"Edit\" di tiap blok, kamu juga dapat melakukan double klik blok yang ingin diubah untuk melakukan perubahan.",
  "ss_builder_introduce_double_tap_label_check": "Jangan tampilkan lagi",
  "ss_builder_introduce_double_tap_title": "Tips Pengaturan blok",
  "ss_builder_invalid_product_url_label": "Tautan tidak valid",
  "ss_builder_invalid_url": "URL tidak valid",
  "ss_builder_items_per_row": "Jumlah item per baris",
  "ss_builder_keep_the_content": "Pertahankan kontennya",
  "ss_builder_landscape_image_orientation_label": "Orientasi lanskap",
  "ss_builder_limit_reached": "Mencapai limit",
  "ss_builder_link_additional_btn_label": "Tambahkan Link",
  "ss_builder_link_select_service": "Pilih layanan",
  "ss_builder_link_text_left_layout": "Teks di sebelah kiri",
  "ss_builder_link_text_right_layout": "Teks di sebelah kanan",
  "ss_builder_link_text_under_layout": "Teks di bawah ini",
  "ss_builder_link_text_zig_zag_layout": "Zig Zag",
  "ss_builder_link_type_email": "Email",
  "ss_builder_link_type_phone": "Telepon",
  "ss_builder_link_type_service": "Layanan",
  "ss_builder_link_type_tel": "Tel",
  "ss_builder_link_type_web": "Web",
  "ss_builder_link_url": "Link URL",
  "ss_builder_list_button_multi_link_label": "List",
  "ss_builder_list_card_empty_content": "Untuk menambahkan kartu silahkan tekan tombol di atas untuk menambahkan",
  "ss_builder_list_card_empty_title": "Belum ada tag konten yang ditambahkan",
  "ss_builder_list_card_label": "Daftar kartu",
  "ss_builder_list_image_popup_title": "List gambar pop-up",
  "ss_builder_list_label": "List",
  "ss_builder_list_link_empty_content": "Untuk menambahkan link, silakan klik tombol “Tambah” di atas",
  "ss_builder_list_link_empty_title": "Belum ada tautan yang ditambahkan",
  "ss_builder_list_title_digital_product": "List",
  "ss_builder_loading_label": "Memuat",
  "ss_builder_malformed_domain_warning": "Nama domain tidak valid",
  "ss_builder_malformed_embed_code_warning": "Kode embed tidak valid",
  "ss_builder_manage_block_description": "Ss Builder Manage Block Description",
  "ss_builder_manage_block_title": "Manajemen blok",
  "ss_builder_manage_tab_description": "Tab digunakan untuk membagi daftar blok menjadi beberapa bagian",
  "ss_builder_manage_tab_title": "Manajemen Tab",
  "ss_builder_management_digital_product_description": "List of your digital products",
  "ss_builder_management_digital_product_title": "Digital Product",
  "ss_builder_management_label": "Management",
  "ss_builder_medium": "Medium",
  "ss_builder_missing_required_product_url_label": "Info belum lengkap",
  "ss_builder_modal_button_close": "Tutup",
  "ss_builder_move_block_successfully_label": "Sukses mengubah posisi blok",
  "ss_builder_move_down_block_label": "Turun",
  "ss_builder_move_product_label": "Pindah ke",
  "ss_builder_move_product_quantity_to_group_alert_label": "Pindahkan {{product_quantity}} produk ke",
  "ss_builder_move_product_successfully_label": "Sukses memindahkan produk",
  "ss_builder_move_to_bottom_label": "Pindah ke bawah",
  "ss_builder_move_to_top_label": "Pindah ke atas",
  "ss_builder_move_up_block_label": "Naik",
  "ss_builder_multi_product_url_imported_label": "Sudah ditambah {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} links",
  "ss_builder_multi_product_url_importing_label": "Sedang proses menambahkan {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} links",
  "ss_builder_name_tab_input_title": "Nama tab",
  "ss_builder_need_to_display_at_least_one_block": "Need to display at least one block",
  "ss_builder_need_to_display_at_least_one_card": "Harus menampilkan minimal satu kartu/card",
  "ss_builder_need_to_display_at_least_one_image": "Harus menampilkan minimal satu gambar",
  "ss_builder_need_to_display_at_least_one_link": "Harus menampilkan minimal satu link",
  "ss_builder_need_to_display_at_least_one_spotlight": "Harus menampilkan minimal satu spotlight",
  "ss_builder_next": "Lanjut",
  "ss_builder_no_image_selected": "Daftar kosong",
  "ss_builder_no_photo_has_been_selected": "Belum ada foto yang dipilih",
  "ss_builder_no_shadow": "No shadow",
  "ss_builder_no_themes_found_theme_store": "Tidak ada tema yang ditemukan",
  "ss_builder_none_of_image_description": "Klik tombol \"Tambah\" untuk menambahkan gambar pop-up",
  "ss_builder_none_of_image_popup_alert": "Tidak ada gambar pop-up",
  "ss_builder_not_add_yet_product_url_label": "Tautan belum ditambahkan",
  "ss_builder_not_enough_space_product_group_modal_content": "Grup produk yang Anda pilih tidak memiliki cukup ruang untuk menampung {{num_products}} produk di daftar produk yang akan ditambah. Harap hapus beberapa produk dari daftar produk yang akan ditambah atau pilih grup lain.",
  "ss_builder_not_enough_space_product_group_modal_title": "Tidak dapat memilih grup",
  "ss_builder_notes": "Deskripsikan laporan error mu",
  "ss_builder_off": "Off",
  "ss_builder_on": "On",
  "ss_builder_onboarding_choose_a_suitable_theme": "Pilih tema yang sesuai, Anda dapat menyesuaikan setelah menerapkan✨",
  "ss_builder_onboarding_choose_the_look_for_you": "Pilih tampilan yang sesuai dengan Anda!",
  "ss_builder_onboarding_create_a_personal_page": "Buat halaman bio, jalankan Affiliate Marketing, Booking, Donasi, Jual produk digital (dokumen, gambar, video, presentasi,...)",
  "ss_builder_onboarding_no_need_know_technology": "Tidak perlu repot-repot lagi, cukup dengan beberapa langkah kamu sudah bisa punya bio page sendiri.",
  "ss_builder_onboarding_theme_store_template": "Theme store",
  "ss_builder_open_browser_label": "Buka di Browser",
  "ss_builder_open_link_in_new_tab": "Buka tautan di tab baru",
  "ss_builder_open_link_in_new_tab_description": "Buka tautan di tab baru",
  "ss_builder_open_new_tab_description": "Hapus centang untuk membuka tautan di situs saat ini. Centang untuk membuka tautan di jendela baru",
  "ss_builder_open_new_tab_label": "Buka tautan di jendela baru",
  "ss_builder_optional_color_panel_label": "Warna opsional",
  "ss_builder_overwrite_description": "Menimpa template ini akan mengubah seluruh pengaturan dari template",
  "ss_builder_overwrite_template_btn_label": "Ubah",
  "ss_builder_overwrite_template_successfully_label": "Sukses menimpa template",
  "ss_builder_overwrite_template_title": "Timpa template",
  "ss_builder_overwrite_theme_btn_label": "Timpa",
  "ss_builder_overwrite_theme_template_successfully_label": "Sukses mengubah template tema",
  "ss_builder_overwrite_theme_template_title": "Timpa template tema",
  "ss_builder_overwrite_title": "Apakamu ingin menimpa template ini?",
  "ss_builder_panel_add_icon_tab_title": "Pilih ikon",
  "ss_builder_panel_add_new_tab_title": "Tab informasi",
  "ss_builder_panel_edit_text_block_title": "Mengubah nama blok",
  "ss_builder_panel_search_label": "Cari...",
  "ss_builder_panel_title": "Pilih jenis blok",
  "ss_builder_paragraph_setting_font_available": "Fonts tersedia",
  "ss_builder_paragraph_setting_font_custom": "Custom font",
  "ss_builder_paragraph_setting_option": "Opsi font",
  "ss_builder_paragraph_setting_title": "Pengaturan Paragraf",
  "ss_builder_partial_visible_slide_type_1": "Terlihat parsial di kanan",
  "ss_builder_partial_visible_slide_type_2": "Terlihat parsial di kanan kiri",
  "ss_builder_partial_visible_slide_type_3": "Tidak terlihat parsial",
  "ss_builder_partial_visible_slide_type_4": "Poster",
  "ss_builder_partial_visible_slide_type_5": "Panorama",
  "ss_builder_passio_page_ready": "Halaman Passio sudah siap",
  "ss_builder_passio_page_ready_description": "Halaman Passio siap digunakan dan menggantikan Landing Page versi lama",
  "ss_builder_passio_premium": "Passio Premium",
  "ss_builder_personal_link_coppy_label": "Salin",
  "ss_builder_personal_link_coppy_successfully_label": "Sudah disalin",
  "ss_builder_personal_link_sharing_title": "Bagikan profil Anda",
  "ss_builder_personal_link_title": "Tautan profil Anda",
  "ss_builder_phone_placeholder": "Masukkan nomor telepon",
  "ss_builder_placeholder_affiliate_product_enter_product_name": "Isi nama produk",
  "ss_builder_placeholder_dragging_content": "Taruh disini",
  "ss_builder_please_wait_for_the_product_to_be_updated": "Tunggu sebentar ya sambil menunggu produk di update.",
  "ss_builder_popover_manual_button": "Mulai",
  "ss_builder_popover_manual_button_close": "Close",
  "ss_builder_popover_manual_content": "Untuk melihat panduan cara menggunakan fitur Desain, silahkan tekan tombol “Mulai\".",
  "ss_builder_popover_manual_title": "Panduan pengguna",
  "ss_builder_popover_review_manual_content": "Kamu dapat membuka kembali panduan ini kapan saja dengan mengklik menu Bantuan.",
  "ss_builder_popular_font_families": "Huruf populer",
  "ss_builder_popup_confirm_button": "Konfirmasi",
  "ss_builder_popup_management_title": "Management",
  "ss_builder_portrait_image_orientation_label": "Orientasi portret",
  "ss_builder_position_ghim_tab_selection_label": "Posisi",
  "ss_builder_powered_by_passio": "Powered by Ecomobi",
  "ss_builder_preview": "Pratinjau",
  "ss_builder_preview_image_label": "Pratinjau",
  "ss_builder_preview_theme_template_title": "Preview gambar",
  "ss_builder_product_design_empty_description": "Please wait for the product to be updated",
  "ss_builder_product_design_empty_title": "Tidak ada produk",
  "ss_builder_product_detect_link_malformed_error": "Format tautan tidak valid",
  "ss_builder_product_detect_link_placeholder": "Masukkan tautan produk",
  "ss_builder_product_detect_link_title": "Link produk",
  "ss_builder_product_empty_searching_description": "Coba dengan kata kunci lain",
  "ss_builder_product_empty_searching_title": "Hasil tidak ditemukan",
  "ss_builder_product_exceeding_allowed_amount_content": "Untuk menambahkan produk baru, harap hapus beberapa produk yang sudah ada",
  "ss_builder_product_exceeding_allowed_amount_title": "Jumlah produk mencapai batasan",
  "ss_builder_product_group_add_new_btn_label": "Tambahkan grup produk",
  "ss_builder_product_group_default_title": "Produk",
  "ss_builder_product_group_limitation_warning_description": "Total produk dalam grup telah mencapai batas {{max_products}} produk. Tolong hapus produk yang telah ada jika Anda ingin menambahkan yang baru.",
  "ss_builder_product_group_selection_placeholder": "Silakan pilih grup produk",
  "ss_builder_product_group_selection_title": "Pilih grup produk afiliasi",
  "ss_builder_product_group_total_limitation_warning_content": "Maksimal {{group_quantity_maximum}} grup produk. Silahkan hapus grup produk lainnya jika ingin menambahkan grup baru.",
  "ss_builder_product_group_total_limitation_warning_title": "Tidak dapat menambah grup produk baru",
  "ss_builder_product_is_hiding_label": "Hidden product",
  "ss_builder_product_link_wrapped_label": "Wrapped link",
  "ss_builder_product_list_style_grid_label": "Grid",
  "ss_builder_product_list_style_grid_one_label": "1 produk",
  "ss_builder_product_list_style_grid_three_label": "3 produk",
  "ss_builder_product_list_style_grid_two_label": "2 produk",
  "ss_builder_product_list_style_slide_label": "Slide",
  "ss_builder_product_list_style_text_over_image_label": "Text over image",
  "ss_builder_product_list_style_zig_zag_label": "Zigzag",
  "ss_builder_product_searching_description": "Masukkan nama produk untuk mencari",
  "ss_builder_product_searching_title": "Cari produk",
  "ss_builder_product_selection_option_1": "10 produk terakhir",
  "ss_builder_product_selection_option_2": "20 produk terakhir",
  "ss_builder_product_selection_option_3": "50 produk terakhir",
  "ss_builder_product_selection_option_4": "5 produk terakhir",
  "ss_builder_product_swiper_see_all": "Lihat semua",
  "ss_builder_product_title_error": "Missing product name",
  "ss_builder_product_title_missing_warning": "Masukkan nama produk",
  "ss_builder_product_total_limitation_warning_description": "Total produk di seluruh grup telah mencapai batas {{max_products_all_groups}} produk. Tolong hapus produk yang telah ada jika Anda ingin menambahkan yang baru.",
  "ss_builder_product_total_limitation_warning_title": "Tidak dapat menambahkan produk baru",
  "ss_builder_product_tour_button_step_0": "Mulai",
  "ss_builder_product_tour_content_step_0": "Mulailah dengan mengikuti tutorial kami untuk menikmati fitur-fitur menarik dari Passio Page!",
  "ss_builder_product_tour_content_step_1": "Yuk berkreasi dengan layar Desain. Mudah untuk mengedit dan mengatur Blok sesuai dengan gaya Anda sendiri.",
  "ss_builder_product_tour_content_step_10": "Ganti seluruh konten halaman Anda dengan konten yang persis sama dari template tema yang berlaku. Jadikan konten halaman Anda persis seperti template",
  "ss_builder_product_tour_content_step_11": "Pratinjau situs untuk melihat perubahan situs saat ini. Publikasikan hanya boleh dilakukan setelah Anda melihat pratinjau dan puas dengan perubahannya.",
  "ss_builder_product_tour_content_step_12": "Tahan dan seret untuk memindahkan atau gunakan \"Tindakan cepat\" untuk memindahkan blok ke atas/bawah.",
  "ss_builder_product_tour_content_step_13": "Pilih \"Ubah\" di \"Tindakan Cepat\" atau langsung klik blok untuk mengedit.",
  "ss_builder_product_tour_content_step_14": "Pakai gambar, warna solid, atau warna gradien yang sesuai dengan keinginan Anda untuk mengubah latar belakang dengan cepat",
  "ss_builder_product_tour_content_step_15": "Banyak tema yang beragam, indah, dan sedang tren untuk Anda pilih dan gunakan dengan bebas",
  "ss_builder_product_tour_content_step_16": "Pengaturan lanjutan membantu menyempurnakan desain tema, mengubah nama domain, merek pribadi, dll",
  "ss_builder_product_tour_content_step_17": "Anda dapat menjalankan kembali panduan pengguna ini kapan saja, dengan mengeklik tombol bantuan ini.",
  "ss_builder_product_tour_content_step_2": "Banyak tipe Blok yang bisa ditambahkan untuk mempercantik halaman Anda. Kreasikan passio page sesuai dengan gayamu, cukup tap atau drag dan drop di posisi yang kamu inginkan untuk menambah blok baru. Yuk coba sekarang!",
  "ss_builder_product_tour_content_step_3": "Pengeditan dan lebih banyak opsi untuk setiap blok, izinkan Anda menyesuaikan blok seperti yang Anda inginkan.",
  "ss_builder_product_tour_content_step_4": "Pengeditan desain selalu dalam mode \"Draft\". Perubahan akan terlihat oleh Fans Anda setelah Anda memutuskan untuk \"Publikasikan\".",
  "ss_builder_product_tour_content_step_5": "Tab digunakan untuk membagi \"Body\" menjadi beberapa bagian, misalnya Tab Produk, Tab Video, Tab Kupon, ... Klik pada setiap Tab untuk melihat dan mengubah konten di dalamnya. Edit blok ini untuk menambahkan yang baru, mengelola Tab.",
  "ss_builder_product_tour_content_step_6": "“Header” berisi Blok, yang selalu muncul di bagian atas halaman. Misalnya: gambar Avatar, pengantar...",
  "ss_builder_product_tour_content_step_7": "\"Body\" adalah area utama situs, berisi Blok dan terbagi-bagi oleh Tab. Misalnya: konten, gambar, produk, ...",
  "ss_builder_product_tour_content_step_8": "“Footer” berisi Blok, yang selalu muncul di bagian bawah halaman. Misalnya: tautan cepat, info halaman...",
  "ss_builder_product_tour_content_step_9": "Gunakan fitur ini, jika perubahan yang Anda buat tidak memuaskan Anda. Sistem akan memulihkan layar Desain Anda kembali ke versi situs yang sebelumnya.",
  "ss_builder_product_tour_done_label": "Selesai",
  "ss_builder_product_tour_next_label": "Lanjut",
  "ss_builder_product_tour_prev_label": "Kembali",
  "ss_builder_product_tour_skip_label": "Lewati",
  "ss_builder_product_tour_title_step_0": "Selamat datang di Passio Page",
  "ss_builder_product_tour_title_step_1": "Desain tema",
  "ss_builder_product_tour_title_step_10": "Terapkan konten sampel",
  "ss_builder_product_tour_title_step_11": "Pratinjau situs",
  "ss_builder_product_tour_title_step_12": "Pindahkan Blok",
  "ss_builder_product_tour_title_step_13": "Mengedit Blok",
  "ss_builder_product_tour_title_step_14": "Ubah background",
  "ss_builder_product_tour_title_step_15": "Theme store",
  "ss_builder_product_tour_title_step_16": "Pengaturan lanjutan",
  "ss_builder_product_tour_title_step_17": "Panduan pengguna",
  "ss_builder_product_tour_title_step_2": "Tambahkan Blok baru",
  "ss_builder_product_tour_title_step_3": "Aksi Cepat",
  "ss_builder_product_tour_title_step_4": "Publikasikan desain",
  "ss_builder_product_tour_title_step_5": "Tab menu",
  "ss_builder_product_tour_title_step_6": "Header",
  "ss_builder_product_tour_title_step_7": "Body",
  "ss_builder_product_tour_title_step_8": "Footer",
  "ss_builder_product_tour_title_step_9": "Restore pengeditan",
  "ss_builder_product_tour_use_manual": "Panduan pengguna",
  "ss_builder_product_tour_use_manual_content": "Untuk lihat tutorial menggunakan fitur Design, silahkan tekan tombol “Mulai”.",
  "ss_builder_product_url_lists_label": "Daftar tautan",
  "ss_builder_publish_button_label": "Menerbitkan",
  "ss_builder_publish_result_description": "Anda telah berhasil menerbitkan Halaman Passio. Halaman Anda dapat diakses melalui URL di bawah ini.",
  "ss_builder_publish_result_successfully": "Tema berhasil diterbitkan!",
  "ss_builder_publish_result_title": "Hasil publikasi",
  "ss_builder_publish_saving": "Simpan",
  "ss_builder_publish_share": "Share",
  "ss_builder_publish_view_page": "Lihat halaman",
  "ss_builder_randomize": "Pilih secara acak",
  "ss_builder_ready_to_build_your_personal_brand": "Siap membangun brand pribadi Anda dengan Passio sekarang 💖",
  "ss_builder_recently_color_panel_label": "Warna yang baru saja digunakan",
  "ss_builder_redo_theme_label": "Ulangi",
  "ss_builder_reload_label": "Refresh",
  "ss_builder_replace": "mengubah",
  "ss_builder_replace_preview_theme_template_btn_label": "Apply",
  "ss_builder_replace_theme_label": "Terapkan konten sampel",
  "ss_builder_replace_theme_modal_content": "Terapkan tema template konten akan menggantikan semua blok di situs Anda dengan blok Tema Template",
  "ss_builder_replace_theme_modal_title": "Apakah Anda ingin menerapkan tema data sampel ?",
  "ss_builder_replace_theme_template_successfully_label": "Berhasil menggunakan tema",
  "ss_builder_report_sent_report_successfully": "Laporan berhasil dikirim",
  "ss_builder_report_sent_report_successfully_description": "Terimakasih atas laporannya! Permasalahan ini akan di proses dalam waktu dekat",
  "ss_builder_restore_theme_content": "Seluruh pengaturan tema dan data blok di dalam tema akan dikembalikan ke tema yang dipublikasikan. Apakah Anda ingin melanjutkan?",
  "ss_builder_restore_theme_label": "Pulihkan pengeditan",
  "ss_builder_restore_theme_successfully_label": "Berhasil memulihkan pengeditan",
  "ss_builder_restore_theme_title": "Apakah Anda ingin memulihkan hasil edit?",
  "ss_builder_revenue_group_description": "Mulai hasilkan uang sekarang dengan layanan Passio Page",
  "ss_builder_revenue_title": "Cari uang",
  "ss_builder_round_photo_label": "Rounded photo",
  "ss_builder_rounded": "Bulat",
  "ss_builder_sample_change_label": "Ubah template",
  "ss_builder_sample_selection_label": "Pilih gaya",
  "ss_builder_sampling_change_label": "Pilih template",
  "ss_builder_save": "Simpan",
  "ss_builder_save_new_template_title": "Simpan sebagai template baru",
  "ss_builder_save_new_theme_template_successfully_label": "Sukses menyimpan tema baru",
  "ss_builder_save_new_theme_template_title": "Simpan template tema baru",
  "ss_builder_save_succesfully_description": "Perubahan sudah disimpan",
  "ss_builder_save_succesfully_label": "Berhasil simpan",
  "ss_builder_search_results_theme_store": "Hasil pencarian",
  "ss_builder_search_type_block": "Cari type blok pakai nama",
  "ss_builder_see_less": "Lihat lebih sedikit",
  "ss_builder_see_more": "Lihat lainnya",
  "ss_builder_see_more_btn_default_title": "Lihat lainnya",
  "ss_builder_select_label": "Pilih",
  "ss_builder_select_link_type": "Pilih tipe link",
  "ss_builder_select_multi_items": "Pilih",
  "ss_builder_select_position_add_block": "Pilih posisi untuk menambahkan blok",
  "ss_builder_select_ratio_title": "Pilih rasio",
  "ss_builder_select_theme_setting_primary_color_label": "Pilih warna primer",
  "ss_builder_selected_product_in_selection_label": "Produk terpilih",
  "ss_builder_send": "Kirim",
  "ss_builder_send_report": "Kirim Laporan",
  "ss_builder_service_placeholder": "Pilih layanan",
  "ss_builder_set_default_tab_disable_content": "Tab standar akan ditampilkan pertama kali ketika pengguna memasuki halaman Anda. Tab \"{{tab_name}}\" sedang disembunyikan. Atur tab ini sebagai standar akan mengubah status tab ini menjadi terlihat. Apakah Anda ingin terapkan tab \"{{tab_name}}\" sebagai standar?",
  "ss_builder_set_default_tab_enable_content": "Tab standar akan ditampilkan pertama kali ketika pengguna memasuki halaman Anda. Apakah Anda ingin terapkan tab \"{{tab_name}}\" sebagai standar?",
  "ss_builder_set_default_tab_label": "Pengaturan standar",
  "ss_builder_set_default_tab_successfully_label": "Berhasil tetapkan default",
  "ss_builder_set_default_tab_title": "Konfirmasi untuk pengaturan standar",
  "ss_builder_setting_font_weight": "Berat font",
  "ss_builder_setting_line_height": "Tinggi garis",
  "ss_builder_setting_popover_manual_content": "Cari tahu bagaimana cara kerja menu \"Pengaturan\", pilih menu \"Memulai\".",
  "ss_builder_setting_popover_manual_title": "Panduan Passio",
  "ss_builder_setting_saving": "Simpan data",
  "ss_builder_setting_text_font": "Jenis Font",
  "ss_builder_setting_text_scale": "Skala",
  "ss_builder_setting_text_size": "Ukuran huruf (px)",
  "ss_builder_setting_title": "Pengaturan",
  "ss_builder_setting_tour_content_step_1": "Ini adalah link dari web passiomu. Kamu dapat mengcopy, membuka, dan membagikan passio pagemu dengan link ini. Selain itu juga kamu bisa dapat mengubah favicon dan nama domain sesuai dengan personal brandmu.",
  "ss_builder_setting_tour_content_step_2": "Kamu dapat mengubah tema tampilan passio page mu disini. Pengaturan ini akan mengubah seluruh tampilan sesuai dengan pilihanmu.",
  "ss_builder_setting_tour_content_step_3": "Kamu dapat mengatur personal branding, pengaturan SEO, nama domain dari sini.",
  "ss_builder_setting_tour_title_step_1": "Website url",
  "ss_builder_setting_tour_title_step_2": "Theme settings",
  "ss_builder_setting_tour_title_step_3": "Site settings",
  "ss_builder_settings_label": "Pengaturan",
  "ss_builder_setup_site_setting": "Site settings",
  "ss_builder_setup_theme_setting": "Theme settings",
  "ss_builder_shadows_style": "Shadow",
  "ss_builder_shape_label": "Shape",
  "ss_builder_share_label": "Bagikan",
  "ss_builder_show_block_label": "Tampilkan blok",
  "ss_builder_show_card_label": "Tampilkan kartu",
  "ss_builder_show_card_successfully_label": "Tampilkan kartu berhasil",
  "ss_builder_show_image_label": "Tampilkan gambar",
  "ss_builder_show_link_label": "Tampilkan link",
  "ss_builder_show_link_successfully_label": "Tampilkan link berhasil",
  "ss_builder_show_product_group_label": "Tampilkan grup",
  "ss_builder_show_product_label": "Tampilkan produk",
  "ss_builder_show_social_link_label": "Tampilkan",
  "ss_builder_show_tab_label": "Tampilkan Tab",
  "ss_builder_show_template_successfully_label": "Sukses menampilkan template",
  "ss_builder_show_theme_template_label": "Show template",
  "ss_builder_site_brand_setting_label": "Merek pribadi",
  "ss_builder_site_domain_setting_label": "Domain",
  "ss_builder_site_setting_browser_label": "Browser",
  "ss_builder_site_setting_coppy_label": "Salin",
  "ss_builder_site_setting_favicon_title": "Favicon",
  "ss_builder_site_setting_favicon_upload_description": "Favicon ditampilkan di sebelah tautan halaman. Unggah gambar favorit apapun untuk membuat Favicon.",
  "ss_builder_site_setting_label": "Pengaturan halaman",
  "ss_builder_site_setting_premium": "Premium",
  "ss_builder_site_setting_share_label": "Bagikan",
  "ss_builder_site_setting_standard": "Standard",
  "ss_builder_site_setting_upgrade": "Upgrade",
  "ss_builder_skip": "Lewati",
  "ss_builder_slide_type_label": "Tiper geser",
  "ss_builder_social_link_btn_add_new_label": "Tambahkan sosial media",
  "ss_builder_social_links_list_title": "Daftar sosial media",
  "ss_builder_social_network_label": "Social network",
  "ss_builder_soft_shadow": "Soft shadow",
  "ss_builder_solid": "Solid",
  "ss_builder_solid_type_label": "Solid",
  "ss_builder_something_went_wrong": "Maaf, terjadi kesalahan",
  "ss_builder_spacer_label": "Spacing (px)",
  "ss_builder_spotlight_list_empty_content": "Untuk menambahkan Spotlight baru silahkan tekan \"Tambah\" diatas",
  "ss_builder_spotlight_list_empty_title": "Belum ada Spotlight yang aktif",
  "ss_builder_square": "Kotak",
  "ss_builder_square_photo_label": "Squared photo",
  "ss_builder_start_use_passio_page": "Mulai gunakan Passio Page sekarang",
  "ss_builder_stop_label": "Berhenti",
  "ss_builder_story": "Spotlight",
  "ss_builder_story_title_place_holder": "Judul spotlight",
  "ss_builder_style": "Gaya",
  "ss_builder_style_always_ghim_tab_label": "Always sticky",
  "ss_builder_style_auto_ghim_tab_label": "Auto sticky",
  "ss_builder_style_ghim_tab_selection_label": "Sematkan bagian",
  "ss_builder_style_grid_label": "Grid",
  "ss_builder_style_masonry_label": "Masonry",
  "ss_builder_style_not_ghim_tab_label": "No sticky",
  "ss_builder_style_slide_label": "Geser",
  "ss_builder_style_tab_icon_label": "Hanya Ikon",
  "ss_builder_style_tab_only_icon": "Ikon saja",
  "ss_builder_style_tab_only_image": "Gambar saja",
  "ss_builder_style_tab_selection_label": "Tampilan",
  "ss_builder_style_tab_text_and_icon_label": "Teks & Ikon",
  "ss_builder_style_tab_text_and_image_label": "Text & image",
  "ss_builder_style_tab_text_label": "Hanya teks",
  "ss_builder_subtitle_site_setting_description": "Memberikan gambaran umum tentang konten dan informasi yang ditawarkan situs. Mesin pencari menggunakan ini untuk menampilkan deskripsi situs web.",
  "ss_builder_subtitle_site_setting_label": "Subjudul",
  "ss_builder_suggested_color_panel_label": "Palet warna disarankan",
  "ss_builder_support_upload_image": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, sampai 30MB",
  "ss_builder_support_upload_image_v2": "Support: JPG, JPEG, GIF, PNG, BMP sampai 30MB",
  "ss_builder_support_upload_large_image": "Support: JPG, JPEG, GIF, PNG, BMP, WebP, sampai 50MB",
  "ss_builder_swiper_setting_premium_description_1": "Fitur tak terbatas",
  "ss_builder_swiper_setting_premium_title_1": "Akun Premium",
  "ss_builder_swiper_setting_premium_title_2": "Tema Ekslusif",
  "ss_builder_swiper_setting_premium_title_3": "Domain custom sesuai brandingmu",
  "ss_builder_swiper_setting_premium_title_4": "Brand Campaign Eklusif",
  "ss_builder_swiper_setting_premium_title_5": "Penarikan komisi lebih cepat",
  "ss_builder_swiper_setting_premium_title_6": "Diskon 25% untuk biaya layanan",
  "ss_builder_tab_layout_1": "Teks sebelah kanan",
  "ss_builder_tab_layout_2": "Teks di bawah",
  "ss_builder_tabs_label": "Tab",
  "ss_builder_template_overwrite_title": "Menimpa Template",
  "ss_builder_template_search_bar_placeholder": "Cari template berdasarkan nama...",
  "ss_builder_template_store_instruction_subtitle": "Data dan pengaturan template blok akan di aplikasikan ke blok saat ini",
  "ss_builder_template_store_instruction_title": "Silahkan pilih template blok yang kamu inginkan!",
  "ss_builder_template_title": "Template",
  "ss_builder_text_editor_max_length": "Maksimum {{editor_max_length}} karakter",
  "ss_builder_text_spin_loading": "Loading page",
  "ss_builder_the_application_of_the_template_will": "Menggunakan template akan",
  "ss_builder_theme_big_button_setting": "Tombol besar",
  "ss_builder_theme_button_setting_general": "General",
  "ss_builder_theme_display_setting": "Pengaturan tampilan",
  "ss_builder_theme_medium_button_setting": "Tombol medium",
  "ss_builder_theme_setting_background": "Background",
  "ss_builder_theme_setting_background_color_preview_title": "Pratinjau",
  "ss_builder_theme_setting_background_color_selector_label": "Warna",
  "ss_builder_theme_setting_background_content": "Ukuran file maks: 30MB.\nMendukung format file: jpg, jpeg, gif, png, bmp, webp",
  "ss_builder_theme_setting_background_content_v2": "Ukuran file maks: 30MB.\nMendukung format file: jpg, jpeg, gif, png, bmp",
  "ss_builder_theme_setting_background_image_preview_label": "Gambar background",
  "ss_builder_theme_setting_background_image_selector_label": "Gambar",
  "ss_builder_theme_setting_background_image_title": "Gambar background",
  "ss_builder_theme_setting_block_background": "Warna background",
  "ss_builder_theme_setting_block_border": "Border",
  "ss_builder_theme_setting_block_shadow": "Shadow",
  "ss_builder_theme_setting_block_style": "Gaya",
  "ss_builder_theme_setting_button": "Tombol",
  "ss_builder_theme_setting_font_title": "Pengaturan teks",
  "ss_builder_theme_setting_font_type_content": "Pilih tipe font untuk diaplikasikan ke seluruh situs Anda",
  "ss_builder_theme_setting_font_type_title": "Tipe font",
  "ss_builder_theme_setting_gadient_color": "Warna",
  "ss_builder_theme_setting_general": "General",
  "ss_builder_theme_setting_gradient_color_direction_label": "Petunjuk",
  "ss_builder_theme_setting_hard_shadow": "Hard shadow",
  "ss_builder_theme_setting_intensity_color_label": "Intensitas warna",
  "ss_builder_theme_setting_label": "Pengaturan tema",
  "ss_builder_theme_setting_no_shadow": "No shadow",
  "ss_builder_theme_setting_paragraph": "Paragraf",
  "ss_builder_theme_setting_primary_color": "Warna",
  "ss_builder_theme_setting_primary_color_content": "Pilih warna primer untuk diterapkan ke seluruh situs Anda",
  "ss_builder_theme_setting_primary_color_title": "Warna primer",
  "ss_builder_theme_setting_save_failed_content": "Terjadi kesalahan yang tidak terduga. Silakan coba lagi.",
  "ss_builder_theme_setting_save_failed_title": "Error",
  "ss_builder_theme_setting_save_successfully_label": "Berhasil simpan pengaturan",
  "ss_builder_theme_setting_select_background_color_label": "Pilih tipe warna",
  "ss_builder_theme_setting_select_gradient_color_label": "Pilih warna gradien",
  "ss_builder_theme_setting_select_solid_color_label": "Pilih warna",
  "ss_builder_theme_setting_shadow": "Shadow",
  "ss_builder_theme_setting_shadow_color": "Warna bayangan",
  "ss_builder_theme_setting_soft_shadow": "Soft shadow",
  "ss_builder_theme_setting_text": "Tombol Teks",
  "ss_builder_theme_setting_title": "Judul",
  "ss_builder_theme_small_button_setting": "Tombol kecil",
  "ss_builder_theme_store_label": "Template",
  "ss_builder_theme_store_popover_manual_content": "Cari tahu cara menggunakan Theme Store dengan menekan menu \"Memulai\".",
  "ss_builder_theme_store_popover_manual_title": "Panduan Passio",
  "ss_builder_theme_store_tour_content_step_1": "Cari tema lebih cepat dengan mencari nama tema",
  "ss_builder_theme_store_tour_content_step_2": "Passio Page menawarkan beragam macam tampilan tema yang bisa kamu pilih. Coba template yang tersedia sekarang.",
  "ss_builder_theme_store_tour_title_step_1": "Cari template tema",
  "ss_builder_theme_store_tour_title_step_2": "Template Tema",
  "ss_builder_theme_template_format_create_label": "Buat",
  "ss_builder_theme_template_label_tag": "Tags",
  "ss_builder_theme_template_label_tag_placeholder": "Masukkan tags",
  "ss_builder_theme_template_no_option": "Tidak ada pilihan",
  "ss_builder_theme_template_preview_info": "Pratinjau passio page mu setelah mengaplikasikan tema",
  "ss_builder_theme_template_preview_mode_title": "Preview",
  "ss_builder_theme_template_search_bar_placeholder": "Cari template tema berdasarkan nama...",
  "ss_builder_theme_template_store_instruction_subtitle": "Pengaturan tema dari template tema yang dipilih akan diterapkan ke situs web",
  "ss_builder_theme_template_store_instruction_title": "Silakan pilih tema yang Anda inginkan!",
  "ss_builder_theme_template_store_title": "Theme Store",
  "ss_builder_theme_template_theme_store": "{{num_results}} tema template",
  "ss_builder_theme_template_view_theme_info": "Tema template dengan contoh data",
  "ss_builder_theme_template_view_theme_title": "Lihat template",
  "ss_builder_title": "Judul",
  "ss_builder_title_block_affiliate_product": "Affiliate",
  "ss_builder_title_block_bio": "Bio",
  "ss_builder_title_block_booking_profile": "Booking Profile",
  "ss_builder_title_block_booking_profile_description": "Tambahkan link ke halaman Booking Profile atau tampilkan Booking Profile di Passio Page",
  "ss_builder_title_block_booking_profile_warning": "Buat Booking Profile untuk menambahkan blok ini",
  "ss_builder_title_block_brand_gift": "Brand Gift",
  "ss_builder_title_block_brand_gift_description": "Tampilkan ikon Brand Gift",
  "ss_builder_title_block_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_block_brand_rating": "Brand Ratings",
  "ss_builder_title_block_brand_rating_description": "id_Bật tính năng để tăng tương tác với Fan",
  "ss_builder_title_block_button_link": "Link",
  "ss_builder_title_block_button_multi_link": "Multi Links",
  "ss_builder_title_block_card": "Kartu",
  "ss_builder_title_block_digital_product": "Produk Digital",
  "ss_builder_title_block_donation": "Donasi",
  "ss_builder_title_block_embed": "Embed",
  "ss_builder_title_block_image": "Gambar",
  "ss_builder_title_block_image_gallery": "Galeri gambar",
  "ss_builder_title_block_image_popup": "Gambar pop-up",
  "ss_builder_title_block_image_slider": "Image Slider",
  "ss_builder_title_block_story": "Spotlight",
  "ss_builder_title_block_tab_bar": "Tab Menu",
  "ss_builder_title_block_text": "Teks",
  "ss_builder_title_block_truth_or_dare": "Truth Or Dare",
  "ss_builder_title_block_truth_or_dare_footer": "Truth Or Dare Icon",
  "ss_builder_title_block_video_shoutout": "Video Shoutout",
  "ss_builder_title_block_video_shoutout_description": "Hadiah video dari idola Anda",
  "ss_builder_title_block_virtual_gift": "Virtual Gift",
  "ss_builder_title_block_virtual_gift_footer": "Virtual Gift Icon",
  "ss_builder_title_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_color": "Warna teks",
  "ss_builder_title_combined": "Gabungkan Blok",
  "ss_builder_title_introduction_service": "Layanan Informasi",
  "ss_builder_title_page_builder": "Passio Page Builder",
  "ss_builder_title_setting_title": "Pengaturan Judul",
  "ss_builder_title_spacer_and_divider": "Spacer & Divider",
  "ss_builder_to_add_products_please_press_the_add_button_above": "Untuk menambahkan Produk baru silahkan tekan “Tambah” diatas.",
  "ss_builder_to_new_version": "Pergi ke Passio Page",
  "ss_builder_top_position_tab_label": "Top",
  "ss_builder_transparent": "Transparency",
  "ss_builder_truth_or_dare_warning": "Hanya bisa menambah satu blok",
  "ss_builder_try_another_keyword_theme_store": "Tidak ada hasil yang ditemukan. Silakan coba lagi dengan kata kunci lain.",
  "ss_builder_try_new_key": "Coba dengan kata kunci lain",
  "ss_builder_type_label": "Type",
  "ss_builder_type_of_color": "Tipe Warna",
  "ss_builder_undo_theme_label": "Kembali",
  "ss_builder_update_account": "Upgrade account",
  "ss_builder_upload_button_label": "Unggah gambar",
  "ss_builder_upload_file_format_invalid_content": "Format gambar tidak valid. Format yang didukung: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_file_format_invalid_content_for_gif_png_svg": "Format gambar tidak valid. Format yang didukung: GIF, PNG, SVG",
  "ss_builder_upload_file_format_invalid_content_v2": "Format gambar tidak valid. Format yang didukung: JPG, JPEG, GIF, PNG, BMP",
  "ss_builder_upload_file_size_invalid_content": "Silakan pilih file dengan ukuran maksimum 30MB",
  "ss_builder_upload_file_size_invalid_content_min_256": "Silahkan pilih gambar dengan ukuran minimum 256x256px",
  "ss_builder_upload_large_file_format_invalid_content": "Format gambar tidak valid. Format yang didukung: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_large_file_size_invalid_content": "Silakan pilih file dengan ukuran maksimum 50MB",
  "ss_builder_uploading_image": "Uploading image",
  "ss_builder_url_error_content": "Tidak menemukan info. Silahkan periksa kembali URL",
  "ss_builder_url_placeholder": "Masukkan url",
  "ss_builder_use_advanced_setting_description": "Pengaturan lanjutan dapat digunakan untuk mengubah tampilan dari Blok gabungan ini",
  "ss_builder_use_affiliate_link_description": "Centang untuk menggunakan link Afiliasi Ecomobi Passio. Hapus centang untuk menggunakan link asli",
  "ss_builder_use_affiliate_link_label": "Gunakan tautan pelacakan Ecomobi Passio",
  "ss_builder_use_ecomobi_tracking_link": "Gunakan link tracking Ecomobi Passio",
  "ss_builder_validate_gen_link_error_E1": "Token tidak valid",
  "ss_builder_validate_gen_link_error_E2": "Akunmu di blokir, kamu tidak akan menerima komisi.",
  "ss_builder_validate_gen_link_error_E3": "Link ini tidak didukung oleh sistem affiliate tracking, kamu tidak akan menerima komisi.",
  "ss_builder_validate_gen_link_error_E5": "Link ini tidak dapat menghasilkan komisi karena campaign mu beli di setujui. Silahkan request di menu Campaign atau hubungi account managermu.",
  "ss_builder_validate_gen_link_error_E6": "Link ini tidak menghasilkan komisi karena kamu telah di blok dari campaign ini.",
  "ss_builder_validate_gen_link_error_E7": "Tidak dapat mempersingkat link karena sistem error.",
  "ss_builder_validate_gen_link_error_E8": "Link ini tidak menghasilkan komisi karena campaign sedang di pause.",
  "ss_builder_video_shoutout_answer_method_description": "Jika Anda menerima permintaan penggemar pencipta, Anda perlu membalas dengan video",
  "ss_builder_video_shoutout_answer_method_title": "Metode jawaban",
  "ss_builder_video_shoutout_btn_setup": "Setel sekarang",
  "ss_builder_video_shoutout_des_intro_fan": "Anda memiliki 7 hari untuk memutuskan atau menolak permintaan penggemar. Jika Anda menolak untuk melakukannya, Ecomobi akan mengembalikan semua uang yang dibayar kepada pemohon dalam waktu 30 hari.",
  "ss_builder_video_shoutout_des_intro_gift": "Melalui Passio, penggemar Anda dapat mengirimi Anda persyaratan video untuk acara -acara khusus: ulang tahun, selamat, peringatan, dorongan video, inspirasi ... dengan biaya tertentu yang dirancang sendiri.",
  "ss_builder_video_shoutout_des_intro_policy": "Anda dan penggemar dapat berbagi video yang bermakna ini dengan orang -orang melalui situs jejaring sosial.",
  "ss_builder_video_shoutout_how_to_work": "Bagaimana cara kerja video teriakan?",
  "ss_builder_video_shoutout_introduction": "Video Shoutout adalah layanan yang memungkinkan penggemar untuk mengirim ke persyaratan pencipta untuk video yang dipersonalisasi untuk acara -acara tertentu seperti ulang tahun, kelulusan, selamat, ... Pencipta akan menanggapi persyaratan tersebut dengan satu dengan satu dengan satu dengan satu. Video. Video.",
  "ss_builder_video_shoutout_participate_description": "Pencipta dapat memilih untuk membuat atau menolak persyaratan penggemar",
  "ss_builder_video_shoutout_participate_title": "Hak untuk bergabung dengan Anda",
  "ss_builder_video_shoutout_response_description": "Setelah penggemar mengirimkan permintaan, Creator memiliki 7 hari untuk membuat video. Setelah waktu ini, jumlah permintaan itu akan dikembalikan ke penggemar",
  "ss_builder_video_shoutout_response_title": "Waktu merespon",
  "ss_builder_video_shoutout_title_intro": "Pendahuluan Layanan",
  "ss_builder_video_shoutout_title_intro_fan": "Membuat video",
  "ss_builder_video_shoutout_title_intro_gift": "Menerima persyaratan dari penggemar",
  "ss_builder_video_shoutout_title_intro_policy": "Berbagi momen yang bermakna.",
  "ss_builder_virtual_gift_list_title": "List hadiah",
  "ss_builder_wait_for_saving": "Proses penyimpanan belum selesai. Harap tunggu sebentar.",
  "ss_builder_warning_brand_gift_floating_continue": "Lanjutkan",
  "ss_builder_warning_brand_gift_floating_description": "Posisi untuk Floating Icon penuh. Anda harus menghapus blok di bagian grup Floating Icon di dalam menu Blok Tambahan/Extended. Lihat",
  "ss_builder_warning_brand_gift_floating_description_link": "Instruksi detilnya",
  "ss_builder_warning_brand_gift_floating_reject": "Batal",
  "ss_builder_warning_brand_gift_floating_title": "Posisi untuk Floating Icon sudah penuh",
  "ss_builder_warning_brand_gift_rating_description": "id_Hiện tại thân trang đã đạt tới giới hạn hiển thị. Bạn cần xoá bớt khối để bật tính năng Brand Ratings",
  "ss_builder_warning_brand_rating_floating_continue": "id_Đã hiểu",
  "ss_builder_warning_brand_rating_floating_title": "id_Brand Ratings",
  "ss_builder_web_browser": "Web browser",
  "ss_builder_web_label": "Ss Builder Web Label",
  "ss_builder_website_config_site_title_description": "Akan muncul di bagian atas halaman saat fansmu mengunjungi passio pagemu.",
  "ss_builder_website_config_site_title_label": "Judul Halaman",
  "ss_builder_website_config_site_title_placeholder": "Masukkan isi konten",
  "ss_builder_what_field_are_you_operating_in": "Anda bergerak di bidang apa?",
  "ss_builder_what_went_wrong": "Hal apa yang salah?",
  "ss_builder_width_tab_setting_label": "Tab width",
  "ss_builder_width_tab_type_1": "Auto",
  "ss_builder_width_tab_type_2": "Dibagi rata",
  "ss_builder_your_page_is_ready": "Halaman bio Anda sudah siap!",
  "ss_cancle_delete": "Cancel",
  "ss_challenge": "Dare",
  "ss_confirm_delete": "Confirm delete",
  "ss_content_confirm_delete": "Are you sure you want to delete this product?",
  "ss_content_delete_forever": "Untuk menghapus produk, silakan kembali ke halaman manajemen produk",
  "ss_content_intro_dp": "Digital products are understood as online products, not grasped, containing useful content for users. For example: Video, photos, ebook, online course, ..",
  "ss_content_item1_dp": "Depending on your strengths and desires, Passio offers a full range of digital products such as photos, videos, online courses, ebooks, links, ...",
  "ss_content_item2_dp": "With less than 3 minutes, you can configure and sell a new digital product. Moreover, after the buyer successfully buys, the product will be sent immediately to their email, helping to simplify shipping and save time",
  "ss_content_success_TOD": "Fitur Truth Or Dare sudah aktif!",
  "ss_cover_image": "Cover image",
  "ss_cover_image_empty": "Belum ada gambar yang dipilih",
  "ss_cover_image_helper": "Dukung: JPG, JPEG, GIF, PNG, BMP, WebP, maksimal 4MB",
  "ss_cover_image_upload": "Upload gambar",
  "ss_delete_forever": "Dihapus secara permanen",
  "ss_delete_product": "Delete display",
  "ss_display_delete": "Delete display",
  "ss_donation_currency_shortcut": "Rp",
  "ss_donation_setting_default_content_setup_donation": "Layanan ini sedang diatur secara default. Jika Anda ingin mengubah, silakan kunjungi layanan Donasi di account.passio.eco",
  "ss_donation_setting_default_title_setup_donation": "Pengaturan layanan",
  "ss_dp_add": "Add",
  "ss_dp_cat_course": "Kursus",
  "ss_dp_cat_ebook_document": "Ebook/Document",
  "ss_dp_cat_online_consultion": "Online consulting 1-1",
  "ss_dp_cat_random_product": "Products any",
  "ss_dp_cat_ticket_account": "Tiket/Akun",
  "ss_dp_cat_video": "Exclusive video",
  "ss_dp_sell_photo": "Gambar",
  "ss_edit_TOD": "Edit",
  "ss_example_TOD": "Contoh: RP.100000",
  "ss_exit_TOD": "Batalkan",
  "ss_first_person": "Jadilah yang pertama untuk menantang sekarang!",
  "ss_gift_button_preview": "Kirim hadiah sekarang!",
  "ss_gift_invitation": "Motivasikan {user} dengan mengirimkannya hadiah untuk dia lebih semangat!",
  "ss_had": "Sudah ada",
  "ss_iframe_add_new_product": "Tambahkan produk baru",
  "ss_iframe_add_product": "Tambahkan produk",
  "ss_iframe_choose_product_from_store": "Pilih dari Data Warehouse",
  "ss_inline_image_empty": "Sekolah ini tidak diizinkan pergi",
  "ss_inline_txtbtn_max_length": "Sekolah ini melebihi 40 karakter.",
  "ss_input_placehoder": "Isi konten",
  "ss_invitation_TOD": "Penawaran konten untuk melakukan tantangan",
  "ss_invitation_TOD_placeholder": "Masukkan Konten Undang Penggemar untuk Bergabung",
  "ss_logout": "Keluar",
  "ss_modal_cancel_confirm": "Membatalkan",
  "ss_modal_text_confirm": "Pergi ke halaman Administrasi",
  "ss_no": "Tidak",
  "ss_placeholder_message_TOD": "Masukkan konten tantangan",
  "ss_preview_TOD": "Lanjut",
  "ss_profile": "Mengajukan",
  "ss_push_TOD": "Unggah",
  "ss_rount_tod": "Tantangan",
  "ss_service_introduction": "Perkenalkan Layanan",
  "ss_setting_TOD_background_tooltip": "Pilih gambar latar belakang dengan rasio 3: 2 dan kapasitas maksimum 4 MB",
  "ss_setting_TOD_field_image_background": "Tambahkan wallpaper",
  "ss_setting_TOD_field_image_background_bottom": "Gambar latar belakang",
  "ss_setting_TOD_field_welcome_tooltip": "Kalimat ajakan untuk penggemar yang memberi tantangan otomatis terisi di sistem.",
  "ss_setup_now": "Set now",
  "ss_shoutout_btn_content": "Konten tombol",
  "ss_shoutout_change_file": "Ubah foto/video",
  "ss_shoutout_create_request": "Buat segera",
  "ss_shoutout_description": "Menggambarkan",
  "ss_shoutout_description_placeholder": "Masukkan konten Pendahuluan",
  "ss_shoutout_error_price_required": "Harap masukkan harganya",
  "ss_shoutout_error_upload": "Kesalahan terjadi, kegagalan yang diunggah",
  "ss_shoutout_error_video_size": "Harap unggah gambar/ video hingga 500MB",
  "ss_shoutout_error_video_type": "Harap unggah ke file sebagai salah satu dari JPG, JPEG, GIF, PNG, BMP, Webp, Heic, Heic, HEIC, MP4, MOV",
  "ss_shoutout_file_intro": "PENDAHULUAN FOTO/VIDEO",
  "ss_shoutout_file_intro_note": "Unggah gambar atau video untuk menghubungi penggemar untuk menggunakan video Shoutout dari Anda",
  "ss_shoutout_no_file_selected": "Tidak ada foto/video yang dipilih",
  "ss_shoutout_price": "Harga",
  "ss_shoutout_price_placeholder": "Masukkan harga",
  "ss_shoutout_select_file": "Pilih foto/video",
  "ss_shoutout_type_support": "Dukungan: JPG, JPEG, GIF, PNG, BMP, Webp, Heif, HEIC, MP4, Pindahkan hingga 500MB",
  "ss_shoutout_upload_success": "Unggah video ke Success",
  "ss_success_TOD": "Sukses",
  "ss_success_off_TOD": "Anda telah mematikan fitur Truth Or Dare.",
  "ss_success_on_TOD": "Anda telah membuka fitur Truth Or Dare",
  "ss_success_setup_TOD": "Anda telah berhasil membuka fitur Truth Or Dare.",
  "ss_title_intro_dp": "What is digital product",
  "ss_title_item1_dp": "Diverse types of products for you to choose",
  "ss_title_item2_dp": "Easy to manage products and orders with a few operations",
  "ss_tod_challenge_number": "id_Số thử thách",
  "ss_tod_content": "Selamat datang konten",
  "ss_tod_dare_content": "Ajak para penggemar untuk kasih tantangan",
  "ss_tod_dare_fee": "Jumlah minimum yang dibayar fans untuk Dare",
  "ss_tod_default_content": "Tantang aku!",
  "ss_tod_default_dare_content": "Berani main denganku? Coba sekarang!",
  "ss_tod_min_price_dare_alias": "Số tiền tối thiểu fan thanh toán đơn Dare_id",
  "ss_tod_min_price_dare_note": "Pembayaran minimum adalah 1,000",
  "ss_tod_setting_cover_image": "Cover image",
  "ss_tod_setting_cover_image_helper": "Dukung: JPG, JPEG, GIF, PNG, BMP, WebP, maksimal 4MB",
  "ss_tod_setting_field_welcome_tooltip": "Kalimat ajakan untuk penggemar yang memberi tantangan otomatis terisi di sistem.",
  "ss_tod_setting_input_placehoder": "Isi konten",
  "ss_tod_tooltip_content": "Konten penawaran terbatas maksimum 60 karakter.",
  "ss_tod_tooltip_min_price_dare": "Selamat datang konten termasuk maksimal 60 huruf",
  "ss_txt_btn_TOD": "Ajak penggemar untuk memberi tantangan",
  "ss_validate_domain_error_E1": "Nama domain telah diubah dalam waktu 30 hari, tidak dapat diubah",
  "ss_validate_domain_error_E2": "Nama domain sudah ada, silakan coba yang lain",
  "ss_validate_domain_error_E3": "Domain ini dilarang",
  "ss_validate_origin_url_error_E203": "Nama domain ini tidak dapat ditemukan. Harap periksa dan coba lagi.",
  "ss_validate_origin_url_error_E5": "Anda tidak disetujui untuk bergabung dengan kampanye ini, harap minta persetujuan",
  "ss_validate_origin_url_error_E6": "Anda tidak diperbolehkan untuk berpartisipasi dalam kampanye ini, silakan hubungi staf pendukung",
  "ss_validate_origin_url_error_E7": "URL ini tidak didukung",
  "ss_validate_origin_url_error_E9": "Kampanye telah berakhir, silakan pilih URL lain",
  "ss_validate_origin_url_error_V0": "URL tidak valid",
  "ss_validate_origin_url_error_sys": "Harap masukkan URL yang valid",
  "ss_validate_origin_url_warning_E5": "Anda belum disetujui untuk kampanye ini. Order dan Komisi tidak akan terhitung",
  "ss_validate_origin_url_warning_E7": "URL ini tidak didukung. Order dan komisi tidak akan terhitung.",
  "ss_validate_origin_url_warning_E9": "Kampanye telah berakhir. Order dan Komisi tidak akan terhitung.",
  "text_cashback_brand_gift": "Cashback",
  "text_custom_brand_gift": "Customize",
  "text_voucher_brand_gift": "Voucher",
  "undefined": "Undefined",
  "voucher_default_text_button": "Dapatkan voucher sekarang!",
  "voucher_default_title": "Klik bayar, cashback 5%",
  "warning_text_TOD": "Pengaturan Anda belum disimpan. Apakah Anda masih ingin membatalkan?",
  "warning_text_switch_TOD": "Apakah Anda ingin mematikan truth or dare?"
} 
} 
export default id