import {
  ImageInterface,
  ImageGalleryInterface,
} from "@features/main/store/interface";
import { mergeFileParts, requestValidateUrl, uploadPartFile } from "@share/api";
import {
  CheckCircleIcon,
  ImageIcon,
  InformationIcon,
  InformationTriangleIcon,
  StoryIcon,
  TrashIcon,
} from "@share/icons";
import { getBase64, mockUpload, resize, validURL } from "@share/lib";
import {
  Button,
  Divider,
  ImageUploader,
  Input,
  Modal,
  Popover,
  ProgressCircle,
  TextArea,
} from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import FieldTitle from "@share/components/FieldTitle";
import UploadImage from "@share/components/UploadImage";
import { useAppSelector } from "@app/hooks";
import { allowedImageFormat, FILE_SIZE_MAXIMUM } from "@share/lib/upload";

type Props = {
  imageRatio: string;
  callBackCancelImageSliderAddNew: any;
  handleSaveImageSlider: any;
  imageEditData: ImageGalleryInterface;
  indexImageEdit: number;
};

const ImageGalleryNewImage = (props: Props) => {
  const DESCRIPTION_MAXIMUM = 250;
  const TITLE_MAXIMUM = 50;
  const SEGMENT_NUMBER = 20;
  const {
    imageRatio,
    callBackCancelImageSliderAddNew,
    handleSaveImageSlider,
    imageEditData,
  } = props;
  const { t } = useTranslation();

  const UID = `image-${uuidv4()}`;
  const user = useAppSelector((state) => state.user);
  const userId = user?.kol?._id;
  const valueUrlImageDefault = imageEditData && imageEditData.image;
  const defaultTitle = imageEditData?.title;
  const defaultDescription = imageEditData?.description;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [percent, setPercent] = useState(0);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [valueUrlImageBase64, setValueUrlImageBase64] =
    useState(valueUrlImageDefault);
  const [oldValueUrlImageBase64, setOldValueUrlImageBase64] =
      useState(valueUrlImageDefault);
  const [oldValueUrlImage, setOldValueUrlImage] = useState(valueUrlImageDefault);
  const isCancelUploadingFile = useRef(false);
  const percentRef = useRef(0);
  const [isDisableBtnCancel, setIsDisableBtnCancel] = useState(false);
  useEffect(() => {
    if (valueUrlImageDefault) {
      setValueUrlImage(valueUrlImageDefault);
    }
    if (message) {
      setMessage(message);
    }
  }, [valueUrlImageDefault]);

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];

    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueNameImage(imgFileItems.fileName);
      setValueUrlImageBase64(imgFileItems.url);
    }
  };

  const handleSaveImage = () => {
    const defaultImageData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      image: valueUrlImage,
      title: title,
      description: description,
      name: valueNameImage,
    };
    if (imageEditData) {
      handleSaveImageSlider({
        ...imageEditData,
        image: valueUrlImage,
        title: title,
        description: description,
      });
    } else {
      handleSaveImageSlider(defaultImageData);
    }
    callBackCancelImageSliderAddNew();
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelImageSliderAddNew = () => {
    if (valueUrlImage && valueUrlImage !== valueUrlImageDefault) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          callBackCancelImageSliderAddNew();
          setValueUrlImage(null);
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
      });
    } else {
      callBackCancelImageSliderAddNew();
      setValueUrlImage(null);
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };
  const handleSetDescription = (val) => {
    if (val?.length <= DESCRIPTION_MAXIMUM) {
      setDescription(val);
    }
  };
  const handleDisableBtnCancel = (value) => {
    setIsDisableBtnCancel(value)
  };

  const handleSetPercent = (percent: number) => {
    setPercent(percent);
    percentRef.current = percent;
  };
  const mockUploadFile = async (
    base64: string,
    fileName: string,
    type: string,
    userId: string
  ) => {
    await sleep(500);
    let dataChunk = [];
    let offset = 0;
    let sort_order = 0;
    let progress = 0;
    const dataBase64 = base64.replace(`data:${type};base64,`, "");
    const dataLength = dataBase64.length;

    if (dataLength > 0) {
      const limit = Math.ceil(dataLength / SEGMENT_NUMBER);
      while (offset <= dataLength) {
        if (isCancelUploadingFile.current) {
          handleSetPercent(0);
          setValueUrlImageBase64(oldValueUrlImageBase64);
          setValueUrlImage(oldValueUrlImage)
          break;
        }
        const sliceBase64 = dataBase64.slice(offset, offset + limit);
        progress += 100 / SEGMENT_NUMBER;
        try {
          const dataResquest = {
            user_id: userId,
            content: sliceBase64,
          };
          const uploadPart = (await uploadPartFile(dataResquest)) as any;
          if (uploadPart) {
            handleSetPercent(progress);
            dataChunk.push({
              path: uploadPart.path,
              sort_order: sort_order + 1,
            });
          }
        } catch (error) {
          console.log(error);
        }

        offset += limit;

        sort_order++;
        if (sort_order === SEGMENT_NUMBER ) {
          handleDisableBtnCancel(true)
          const dataMergeFileParts = {
            user_id: userId,
            name: fileName,
            parts: dataChunk,
          };
          const mergeFile = (await mergeFileParts(dataMergeFileParts)) as any;
          if (mergeFile && !isCancelUploadingFile.current) {
            console.log("mergeFile-progress", progress)
            handleSetPercent(0);
            handleUploadingFile(false);
            const imgFileItems = {
              url: mergeFile.url,
            };
            setValueUrlImage(imgFileItems.url);
            handleOldValueUrlImageBase64(base64)
            handleOldValueUrlImage(imgFileItems.url)
            handleDisableBtnCancel(true)
          } else {
            setValueUrlImageBase64(oldValueUrlImageBase64);
            setValueUrlImage(oldValueUrlImage)
            handleDisableBtnCancel(false)
          }
        }
      }
    }
  };
  const handleMockUploadFile = async (file) => {
    if (!allowedImageFormat.includes(file?.type?.toLocaleLowerCase())) {
      return {
        url: null,
        isValidFormat: true,
      };
    } else if (file?.size > FILE_SIZE_MAXIMUM) {
      return {
        url: null,
        isValidSize: true,
      };
    }
    handleSetPercent(0);
    handleUploadingFile(true);
    handleDisableBtnCancel(false)
    const dataBase64 = await getBase64(file);
    mockUploadFile(dataBase64 as string, file.name, file.type, userId);

    return {
      url: URL.createObjectURL(file),
      dataBase64: dataBase64,
      fileName: file.name,
    };
  };
  const handleUploadingFile = (value) => {
    setIsUploadingFile(value);
    isCancelUploadingFile.current = false;
  };
  const handleCancelUploadingFile = () => {
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setIsUploadingFile(false);
    setValueUrlImageBase64(oldValueUrlImageBase64);
    setValueUrlImage(oldValueUrlImage);
    handleSetPercent(0);
    isCancelUploadingFile.current = true;
  };
  const handleOldValueUrlImage = (value) => {
    setOldValueUrlImage(value)
  };
  const handleOldValueUrlImageBase64 = (value) => {
    setOldValueUrlImageBase64(value)
  };
  const isSaveButtonEnable =
    valueUrlImage &&
    (valueUrlImage !== valueUrlImageDefault ||
      title !== defaultTitle ||
      description !== defaultDescription) &&
    !isValidUrl &&
    !isUploadingFile;
  console.log("isDisableBtnCancel", isDisableBtnCancel)
  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div className="text-[18px]">
          {title && title != "" ? title : t("ss_builder_image_gallery")}
        </div>
      </div>
      <div className="p-4 bg-white">
        <FieldTitle
          title={t("ss_builder_image")}
          isRequire={true}
        />
        <div className="relative">
          <UploadImage
            valueUrlImage={valueUrlImageBase64}
            imageRatio={imageRatio}
            textHelper={t("ss_builder_support_upload_large_image")}
          />
          {isUploadingFile && (
            <div className="progress-bar-img">
              <ProgressCircle
                percent={percent}
                className="text-[#fff]"
              >
                {percent}%
              </ProgressCircle>
              <div className="w-full text-[#fff] mt-1 mb-2">
                {t("ss_builder_uploading_image")}
              </div>
              <Button
                size="mini"
                onClick={() => handleCancelUploadingFile()}
                style={{
                  "--background-color": "#fff",
                  "--border-color": "#EEEEEE",
                }}
                disabled={isDisableBtnCancel}
              >
                {t("ss_builder_cancel_upload_image")}
              </Button>
            </div>
          )}
        </div>

        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_large_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_large_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 2000, 2000)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            disableUpload={isUploadingFile}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
                disabled={isUploadingFile}
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
        <Divider />
        <div>
          <FieldTitle title={t("ss_builder_title")} />
          <Input
            type="text"
            value={title}
            placeholder={t("ss_builder_image_title_place_holder")}
            onChange={(value) => {
              setTitle(value);
            }}
            maxLength={TITLE_MAXIMUM}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {title ? title.length : 0}/{TITLE_MAXIMUM}
          </div>
        </div>
        <Divider />
        <div>
          <FieldTitle title={t("ss_builder_description")} />
          <TextArea
            onChange={(value) => {
              handleSetDescription(value);
            }}
            value={description}
            placeholder={t("ss_builder_image_description_place_holder")}
            maxLength={DESCRIPTION_MAXIMUM}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {description ? description.length : 0}/{DESCRIPTION_MAXIMUM}
          </div>
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelImageSliderAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveImage}
          >
            {t("ss_builder_done_panel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryNewImage;
