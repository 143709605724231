import { requestUploadImage } from "@share/api";

export const ImageGalleryMiddleware = async (data, extraData) => {
  if (data) {
    const imageArrItems =
      data.content_attributes && data.content_attributes.items;
    let response = null;

    if (response && response.every((x) => x !== undefined)) {
      const arrUrl = response.map((ele) => {
        return ele?.url ?? ele.image;
      });

      const newImageArrItems = imageArrItems.map((element, index) => {
        return {
          ...element,
          image: arrUrl[index],
        };
      });

      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          items: newImageArrItems,
        },
      };
      return newData;
    }
  }
  return data;
};
